import request from '@/utils/request'

/**
 * post接口样例
 * @param data
 */
export function postApi(params) {
    return request.get('/api/crm/web/jobWant/aiFindWorker', { shouldEncrypt: false, shouldDecrypt: true, params })
    // return request.post('/api/crm/web/jobWant/aiFindWorker', data,{shouldEncrypt:true,shouldDecrypt:true})
}

/**
 * get样例
 * @param params
 */
export function hotWorkListApi(params) {
    return request.get('/api/crm/web/index/companyWorkWant/hotList', {shouldEncrypt:false,shouldDecrypt:true,params})
}


/**
 * post接口样例
 * @param data
 */
export function modelPostApi(data) {
    return request.post('/chat/crm/web/jobWant/aiFindWorker', data,{shouldEncrypt:false,shouldDecrypt:false})
}
