import Layout from "@/layout";

const aiFindWorkRouter = {
  path: '/aiFindWork',
  component: Layout,
  redirect: '/aiFindWork/index',
  name: 'aiFindWork',
  children: [
    {
      path: 'index',
      component: () => import('@/views/aiFindWork/index'),
      name: 'aiFindWorkIndex',
    }
  ]
}

export default aiFindWorkRouter
