<template>
  <div class="resume-download-container modal-mask" ref="aiResume" v-show="display" @click="closeDialog">
    <div
        class="resume-template"
        v-for="(item, index) in resumePreviewUrls"
        :key="item"
        @click="stopPropagation"
    >
      <el-image
          class="template_img"
          :src="item"
          :fit="'fill'"></el-image>
      <el-button class="download-btn" @click="downloadResume(item)">
        <img :src="downloadIcon">
        <span>{{`点击下载简历模板${index === 0? "一" : "二"}`}}</span>
      </el-button>
    </div>
  </div>
</template>
<script>
import resume1 from "../../../../assets/resume/resume_1.png"
import resume2 from "../../../../assets/resume/resume_2.png"
import downloadIcon from "../../../../assets/resume/download_icon.png"
import {downloadPersonalResumeApi} from "@/api/aiFindWork";
import {getResumeApi} from "@/api/aiInterView";
export default {
  name: "resumeDownloadDialog",
  data(){
    return {
      resumePreviewUrls: [
          resume1,
          resume2,
      ],
      display: false,
      downloadIcon: downloadIcon,
      resumeData: null,
    }
  },
  methods: {
    async showDialog(resumeData) {
      // if(resumeData && resumeData.id) {
      //   this.resumeData = resumeData;
      // } else {
      // }
      try {
        const res = await getResumeApi();
        if(res.code !== 200) {
          throw new Error(res.msg);
        }
        this.resumeData = res.data;
        this.display = true;
      } catch (e) {
        console.error("获取用户简历失败", e);
        this.$message.error("获取模板失败!");
      }
    },
    async downloadResume(item) {
      const index = this.resumePreviewUrls.indexOf(item);
      if(index !== -1 && this.resumeData){
        try {
          // 获取简历下载链接
          const res2 = await downloadPersonalResumeApi({
            index,
            id: this.resumeData.id,
          });
          console.log(res2);
          if(res2.code !== 200) {
            throw new Error(res2.msg);
          }
          if(!res2.data.url) {
            throw new Error("resume url is null!");
          }
          const element = document.createElement('a');
          element.href = res2.data.url;
          element.filename = "我的简历";
          element.download = "我的简历";
          element.setAttribute("target", "_blank");
          document.body.appendChild(element);
          element.click();
          setTimeout(()=>{
            document.body.removeChild(element);
          });
        } catch (e) {
          console.error("生成用户模板失败", e);
          this.$message({
            showClose: true,
            message: "生成用户模板失败",
            type: 'error'
          });
        }
      }
        this.display = false;
    },
    // 点击空白处关闭
    closeDialog() {
      // console.log("点击空白处关闭")
      this.display = false;
      this.resumeData = null;
    },
    // 停止冒泡
    stopPropagation(e) {
      e.stopPropagation();
    }
  }
}
</script>

<style scoped lang="scss">

.resume-download-container {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #8d8d8d;
  //width: 500px;
  //height: 50%;
  //left: 300px;
  display: grid;
  grid-template-columns: 486px 486px;
  grid-column-gap: 76px;

  .resume-template {
    height: 762px;
    width: 486px;
    display: grid;
    grid-row-gap: 24px;
    justify-items: center;

    .template_img {
      width: 486px;
      height: 688px;
    }
    .download-btn {
      width: 238px;
      max-width: 238px;
      height: 50px;
      background-color: #FFFFFF;
      border-radius: 5px;

      img {
        width: 15px;
        height: 16px;
        margin-right: 9px;
      }
      span {
        width: 162px;
        height: 25px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 18px;
        color: #333333;
        line-height: 25px;
        text-align: justify;
        font-style: normal;
      }
      span:hover {
        color: #1A67E2;
      }
    }
  }
}
.modal-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2024;
}


</style>
