import {saveInterViewVideoApi, uploadFileApi, videoTranscodingApi, saveInterViewVideoUpdateApi} from "@/api/aiInterView";
import store from '@/store'
import axios from "axios";

const token = store.getters.token;

//异步视频转码、上传、面试记录上传
async function interviewUpload(data) {
    try {
        const idsObj = data.idsObj
        const params = {
            userJobwantId: idsObj.userJobwantId,
            companyWorkWantId: idsObj.companyWorkWantId,
            workTypeId: idsObj.workTypeId,
            sessionBatch: data.sessionBatch,
            interviewResult: data.interviewResult,
            videoRequest: {
                dialogueRecord: JSON.stringify(data.historyList)
            },
        }
        // 保存面试视频记录
        await saveInterViewVideoApi(params).then((res1) => {

        if(res1.code === 200){
            const formData = new FormData();
            const fileName = new Date().getTime()
            formData.append("file", data.blob, fileName + ".webm");
            axios.post("/api/file/upload/files", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    'Authorization': token
                },
            }).then((res) => {
                let { data, code } = res.data
                if (res.data.code == 200) {
                    let obj = {
                        interviewVideoId: res1.data.interviewVideoId,
                        videoUrl: data.url,
                        videoStatus: 1,   // 失败4,成功1
                    }
                    saveInterViewVideoUpdateApi(obj).then(res => { })

                    //上传完成，删除已完成的数据
                    //拉取缓存中的数据
                    let dataList = store.state.myTest.fixedBlobList
                    const filteredArr = dataList.filter(dataItem => dataItem.interviewCode !== data.interviewCode);
                    //存缓存
                    store.commit('SET_FIXED_BLOB_LIST', filteredArr)

                } else {
                    // 提交接口失败
                }
            }).catch (() => {
                let obj = {
                    interviewVideoId: res1.data.interviewVideoId,
                    videoUrl: '',
                    videoStatus: 4,   // 失败4,成功1
                }
                saveInterViewVideoUpdateApi(obj).then(res => {})
            })
            }
        }).catch((error) => {
            // 处理保存视频信息的错误
            console.error("Error saving video", error);
        });
        return true;
    } catch (error) {
        console.error(error);

    }
}

export {
    interviewUpload
}
