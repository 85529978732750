<template>
    <div class="barBox">
        <span class="name">{{name}}</span>
        <span class="more" @click="GoMore" v-if="noMoreBtn">
            更多<i class="el-icon-arrow-right"></i>
        </span>
    </div>
</template>

<script>
export default {
    props: {
        name: {
            type: String,
            default: ''
        },
        noMoreBtn: {
            type: Boolean,
            default: true
        }
    },
    methods: {
        GoMore() {
            this.$emit('GoMore')
        }
    }
}
</script>

<style lang="scss" scoped>
  .barBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .name {
        font-weight: 500;
        font-size: 18px;
        color: #333333;
        &:before {
            content:'';
            display: inline-block;
            width: 3px;
            height: 15px;
            margin-right: 4px;
            margin-top: 1px;
            background: #3076FF;

        }
    }

    .more {
        font-weight: 400;
        font-size: 14px;
        color: #999999;
        cursor: pointer;
    
    }
    
}
</style>