/**
 * 异步顺序执行队列
 */
export class AsyncQueue {
    constructor() {
        this.queue = Promise.resolve();
    }

    /**
     * 添加任务到队列
     * @param task 返回 thenable 对象 的函数
     */
    enqueue(task) {
        // 确保 task 是一个返回 Promise/thenable 的函数
        if (typeof task !== 'function') {
            throw new Error('Task must be a function.');
        }

        // 创建一个新的 Promise 链，并将任务添加到链中
        this.queue = this.queue.then(() => {
            // 执行并返回任务的 Promise/thenable
            const obj = task();
            if(typeof obj.then !== 'function') {
                throw new Error('Task must be a function that returns a Promise or thenable');
            }
            return obj;
        });
    }
}
