<template>
    <div
        class="myPositionList-container"
        v-loading.fullscreen.lock="fullscreenLoading">
        <div class="right-content">

            <div class="title cursorPointer mb27" @click="GoBack">
                <i class="el-icon-back"></i>
                <span class="pl9">返回</span>
            </div>
            <div class="list">
                <div
                    class="list-item flexBetweenCenter"
                    v-for="(item, index) in list"
                    :key="index">
                    <div class="left-content" v-if="item.userJobwant">
                        <img
                            :src="item.userJobwant && item.userJobwant.avatar ? item.userJobwant.avatar :  item.userJobwant.sex == '男' ? workImg : hrImg"
                            alt="">
                        <div class="ml14">
                            <div class="title">{{ item.userJobwant.userName }}</div>
                            <div class="tag"  v-if="item.userJobwant">
                                <span class="tag-item" >{{item.userJobwant.sex  }}</span>
                                <span class="tag-item" >{{item.userJobwant.workTypesName  }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="right-btnBox">
                        <div class="right-btn" @click="returnVideo(item)" v-if="item.interviewCount">面试回放</div>
                        <div class="right-btn" @click="goConnection(item)">去联系</div>
                    </div>
                </div>
            </div>
            <div class="paginationBox">
                <el-pagination
                    v-if="page.total > page.pageSize"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="page.currentPage"
                    :page-size="page.pageSize"
                    background
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="page.total">
                </el-pagination>
            </div>
			<el-empty v-if="list.length == 0" description="暂无数据"></el-empty>
        </div>

        <!-- 去联系 -->
        <scanCodePay ref="scanCodePay"/>
        <callDialog ref="callDialog" />

    </div>
</template>
<script>
import * as $api from '@/api/personalCenter'
import scanCodePay from '@/components/personalCenter/scanCodePay.vue'
import callDialog from '@/components/personalCenter/callDialog.vue'
export default {
    name: 'myPositionList',
    components: {
        // leftNav2,
        scanCodePay,
        callDialog
    },
    data() {
        return {
            hrImg: require('@/assets/intelligentInterview/hr.png'),
            workImg: require('@/assets/intelligentInterview/work.png'),
            list: [ ],
            sexSelectList: [],
            page: {
                pageSize: 12, // 每页条数
                pageNum: 1, // 当前页数
                total: 0, // 工作总记录数
            }, // 分页
            loading: false,
            fullscreenLoading: true,
            identity: ''
        }
    },
    created() {
        this.getInitData()   // 性别
        this.identity = this.$store.state.user.identity
        this.$store.watch(() => this.$store.state.user.identity, (newValue, oldValue) => {
            this.identity = newValue
            // this.getDictTypeAPI()
        })
    },
    mounted() {
    },
    methods: {
        handleSizeChange(val) {
            this.page.pageSize = val
            this.getInitData()
        },
        handleCurrentChange(val) {
            this.page.pageNum = val
            this.getInitData()
        },
        getpagination(){
            document.getElementsByClassName("el-pagination__jump")[0].childNodes[0].nodeValue = "跳至";
            document.getElementsByClassName("el-pagination__total")[0].childNodes[0].nodeValue = `总共${this.total}条 每页显示`;
        },
        // 列表
        getInit(){
            this.fullscreenLoading = true
            const param = {
                pageNum: this.page.pageNum,
                pageSize: this.page.pageSize,
                // isImmediateRecruitment: '0'
                companyWorkwantId: this.$route.query.companyWorkwantId
            }
            $api.jobWantApplyAPI(param).then(res=>{
                this.fullscreenLoading = false
                if (res.code == 200) {
                    this.list = res.data.rows || []
                    this.page.total = res.data.total || 0
                    this.sexSelectList.map(item=>{  // 回显性别
                        this.list.map(item2=>{
                            if(item2.userJobwant.sex == item.dictValue) {
                                item2.userJobwant.sex = item.dictLabel
                            }
                        })
                    })
                }
            }).catch(error=>{
                this.fullscreenLoading = false
            })
        },
        // 获取选择框的数据
        getInitData() {
            // 培训报名 性别 岗位等字典 rm_train_user_type
            $api.dictTypeAPI({ dictType: 'sys_user_sex' }).then(res => {
                let {code, msg, data} = res
                if(code == 200) {
                    this.sexSelectList = data
                    this.getInit()  // 列表
                } else {
                    this.$message.error(msg)
                }
            })

        },
        GoBack() {
            // 返回我的岗位
            // this.$emit('detailBack')
            // this.$router.push({ path: '/personalCenter', query: { name: '我的岗位'} });
            if(this.identity == 2) {   // hr
                this.$router.push({ path: '/personalCenter/indexHr', query: { name: '我的岗位' } });
            } else {  // 工人
                this.$router.push({ path: '/personalCenter/index', query: { name: '我的岗位' } });
            }
        },
        goConnection(item) {
            // this.$refs.scanCodePay.showDialog()
            // 获取电话号码
            $api.getPhoneAPI({id: item.userJobwant.id}).then(res=>{
                let {data, success} = res

                if(data && success) {
                    // 订单类型，1:招工电话,2:工人电话,3:抢单,4:充值,5:购买会员,6:充值钱包,7:ai大模型使用招工,8:ai大模型使用工匠,9:悬赏招工,10:企业会员权益
                    let obj = {
                        phone: data,
                        userName: item.userJobwant.userName,
                        avatar: item.userJobwant.avatar ? item.userJobwant.avatar :  item.userJobwant.sex == '男' ? this.workImg : this.hrImg
                    }
                    this.$refs.callDialog.showDialog(obj)
                } else {
                    let obj = {
                        id: item.userJobwant.id,
                        goodsId: item.userJobwant.id,
                        orderType: this.identity,    // 这个页面跳过去
                        phone: data,
                        userName: item.userJobwant.userName,
                        avatar: item.userJobwant.avatar ? item.userJobwant.avatar :  item.userJobwant.sex == '男' ? this.workImg : this.hrImg,
                        sex: item.userJobwant.sex,
                    }
                    this.$refs.scanCodePay.showDialog(obj)
                }

            })
        },
        returnVideo(item) {
            // 跳历史面试详情
            // historyInterviewDetail
            console.log('item', item)
            if(this.identity == 2) {   // hr
                this.$router.push({
                    path: '/personalCenter/indexHr',
                    query: {
                        name: '历史面试',
                        isDetail: 1,
                        companyWorkwantId: item.companyWorkwantId,
                        applyCustomerId: item.applyCustomerId,
                        examType: item.companyWorkWant.examType,
                        name2: '我的岗位',   // 用来返回'
                    }
                });
            } else {  // 工人
                this.$router.push({
                    path: '/personalCenter/index',
                    query: {
                        name: '历史面试',
                        isDetail: 1,
                        companyWorkwantId: item.companyWorkwantId,
                        applyCustomerId: item.applyCustomerId,
                        examType: item.companyWorkWant.examType,
                        name2: '我的岗位',   // 用来返回'
                    }
                });
            }
         }
    }
}
</script>

<style lang="scss" scoped>
.myPositionList-container {
    height: 870px;
    .title {
        font-weight: 500;
        font-size: 22px;
        color: #333333;
    }
    .right-content {
        height: 100%;
        position: relative;
    }
    .cursorPointer {
        cursor: pointer;
    }

    .pl9 {
        padding-left: 9px
    }

    .ml14 {
        margin-left: 14px;
    }

    .mb27 {
        margin-bottom: 27px;
    }

}

.list {
    display: grid;
    grid-template-columns: 384px 384px 384px;
    grid-gap: 20px;
    grid-column-gap: 23px;
    grid-row-gap: 20px;

    .list-item {
        background: #FFFFFF;
        border-radius: 8px;
        border: 1px solid #DBDBDB;
        font-size: 14px;
        font-weight: 400;

        // padding: 26px 13px;
        height: 110px;
        padding: 0 13px;
        box-sizing: border-box;

        .left-content {
            flex: 1;
            display: flex;

            .title {
                font-size: 18px;
                font-weight: 500;
                // font-weight: bold;
                color: #555;
                line-height: 28px;

                .color7e2 {
                    color: #1A67E2;
                    // letter-spacing: 2px;
                }
            }

            img {
                width: 54px;
                height: 54px;
                border-radius: 50%;
            }

            .tag {
                margin-top: 6px;
                display: flex;

                .tag-item {
                    margin-right: 7px;
                    background: #52C4A4;
                    border-radius: 2px;
                    color: #fff;
                    padding: 0 7px;
                    height: 22px;
                    line-height: 22px;
                }
            }

        }

        .right-btnBox {
            // padding: 0 12px;
           .right-btn {
                width: 80px;
                text-align: center;
                height: 34px;
                line-height: 34px;
                border-radius: 7px;
                border: 1px solid #9D9D9D;
                cursor: pointer;
                color: #333;
                font-size: 14px;

                &:first-child {
                    margin-bottom: 10px;
                }
           }
        }

    }
}

.commonCardPadding {
    padding: 31px 32px;
    background: #FFFFFF;
    border-radius: 12px;

    .title {
        font-weight: 500;
        font-size: 22px;
        color: #333333;
        line-height: 30px;
    }
}


::v-deep.personal-container .right-wrap {
    padding-bottom: 151px !important;
}
::v-deep .paginationBox {
    width: 100%;
    text-align: right;
    // right: 30px;
    position: absolute;
    bottom: -20px;
    .el-pagination.is-background .el-pager li:not(.disabled).active {
        background-color: #0066FF;
    }
}

#qrcode {
    // border: 1px solid red;
    width: 100px;
    height: 100px;
}
.el-empty {
    min-height: 720px;
}
</style>
