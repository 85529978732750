<template>
    <!--更换手机号弹窗-->
    <el-dialog class="dialog-phone" :visible.sync="dialogFormVisible" :show-close="false" :close-on-click-modal="false">
        <div class="dialog-name">
            更换手机号
            <div class="tip" v-if="isShowTip">验证即登录，未注册将自动创建工匠AI账号</div>
            <i class="el-icon-close" @click="closeFn"></i>
        </div>
        <div class="phone-form">
            <div class="form-item">
                <span class="label">新手机号</span>
                <el-input
                    class="w330"
                    v-model="phoneForm.phone"
                    placeholder="请输入内容"
                    maxlength="11"
                    show-word-limit></el-input>
                    <!-- @blur="checkPhone"  -->
            </div>
            <div class="form-item">
                <span class="label">验证码</span>
                <el-input length="11" class="w201" v-model="phoneForm.captcha" placeholder="请输入内容"></el-input>
                <img class="codeBox codeImg" :src="captchaImg" alt="">
                <span class="change-codeImg" @click="changeValidCode()">换一张</span>
            </div>
            <div class="form-item">
                <span class="label">短信验证</span>
                <el-input class="w330" v-model="phoneCaptcha" placeholder="请输入内容" maxlength="6" show-limit-word></el-input>
                <el-button
                    type="primary"
                    :class="['getCodeBtn']"
                    :disabled="messageSendTime  ? true : false"
                    @click="sendCaptcha">获取验证码 {{ messageSendTime || '' }}</el-button>
            </div>
            <div class="checkBox" v-if="isShowTip">
                <el-checkbox v-model="phoneForm.checked">已阅读并同意</el-checkbox>
                <span class="colorCe0">《招聘协议》</span>
                <span class="colorCe0">《用户协议》</span>
            </div>
            <el-button
                class="submit"
                type="primary"
                v-no-more-click
                @click="loginFn">确定</el-button>
        </div>
    </el-dialog>
</template>

<script>
import {
    changePhoneNumberAPI,
    logoutAPI,
    sendCaptchaAPI
 } from '@/api/personalCenter'
 import axios  from 'axios'
 import store  from '@/store'
export default {
    name: 'loginDialog',
    components: {
    },
    props: {
        isShowTip: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            dialogFormVisible: false,
            phoneForm: {
                phone: '',
                captcha: '',
                // checked: false,
                uuid: ''
            },
            phoneCaptcha: '',   //
            // 获取验证码相关数据
            captchaImgCode: '',
            captchaImg: '',
            timer: null,
            messageSendTime: null,  // 短信时间
            isGetPhoneCodeStatus: false,   // 是否获取手机号
        }
    },
    computed: {
        loginBtn : function(){
            for(let key in this.phoneForm) {
                if(this.phoneForm[key] == '') {
                   return false
                }
            }
            if(!this.phoneCaptcha) {
                return false
            } else {
                return true
            }
        }
    },
    mounted() {
    },
    methods: {
        showDialog() {
            clearInterval(this.timer)
            this.dialogFormVisible = true
            this.isGetPhoneCodeStatus = false
            for(let key in this.phoneForm) {
                this.phoneForm[key] = ''
            }
            this.phoneCaptcha = ''
            this.changeValidCode()   // 获取验证码
        },
        // 手机  获取验证码
        sendCaptcha() {
            if(!this.phoneForm.phone) {
              return  this.$message.warning('手机号不能为空')
            } else {
                const reg = /^1[3456789]\d{9}$/
                if(!reg.test(this.phoneForm.phone)) {
                    return  this.$message.warning('请输入正确的手机号')
                }
            }
            if(!this.phoneForm.captcha) {
                return this.$message.warning('请输入图片验证码')
            }
            if(!this.messageSendTime) {
                sendCaptchaAPI(this.phoneForm).then(res=>{
                    if (res.code !== 200) {
                        // this.$message.error(res.msg)
                    } else {
                        this.isGetPhoneCodeStatus = true
                        this.messageSendTime = 60
                        this.timer = setInterval(() => {
                            this.messageSendTime--
                            if (this.messageSendTime === 0) {
                                clearInterval(this.timer)
                            }
                        }, 1000)
                        this.$message.success('短信发送成功，请注意短信接收')
                    }
                }).catch((res)=>{
                    console.log('错误', res)
                    // this.$message.error(res.msg)
                })
            }

        },
        //获取图片验证码
        changeValidCode() {

            axios.get('/api/code').then(res=> {
                this.phoneForm.uuid = res.data.uuid
                this.captchaImg = 'data:image/gif;base64,' + res.data.img

            }).catch(()=>{
                // this.$message({
                //     message: '验证码获取失败',
                //     type: 'error'
                // });
            })
        },
        loginFn() {
            // for(let key in this.phoneForm) {
            //     if(this.phoneForm[key] == '') {
            //       return  this.$message.warning('请填写完成信息')
            //     }
            // }
            if(!this.phoneForm.phone) {
              return  this.$message.warning('手机号不能为空')
            } else {
                let reg = /^[1][1-9][0-9]{9}$/; // 1到9的所有数字作为第二位
                if(!reg.test(this.phoneForm.phone)) {
                    return  this.$message.error('请输入正确的手机号')
                }
            }
            if(!this.phoneForm.captcha) {
                return this.$message.warning('请输入验证码')
            }
            if(!this.isGetPhoneCodeStatus) {
                return this.$message.warning('请先获取手机验证码')
            }
            // if () {
            //     return this.$message.warning('请输入正确的验证码')
            // }
            // 验证验证码
            let params = {
                captcha: this.phoneForm.captcha,
                phoneCode: this.phoneCaptcha,
                phone: this.phoneForm.phone,
                uuid:  this.phoneForm.uuid
            }
            changePhoneNumberAPI(params).then(res => {
                this.isGetPhoneCodeStatus = false
                if(res.code == 200) {
                    this.$message.success(res.msg)
                    // this.dialogFormVisible = false
                    this.closeFn()

                    // 成功之后推出登录
                    logoutAPI({}).then(res=>{
                        if(res.code == 200) {
                            this.$router.push('/home')
                            store.commit('SET_TOKEN', null)
                        }
                    })
                }
            })
        },
        closeFn() {
            clearInterval(this.timer)
            this.dialogFormVisible = false
            this.messageSendTime = null
        },
    },
    destroyed() {
        this.timer = null
    },
}
</script>

<style lang="scss" scoped>
// 更换手机弹窗
.dialog-phone {
    ::v-deep .el-dialog {
        width: 532px;

        .el-dialog__header {
            padding: 0 !important;
        }

        .el-dialog__body {
            padding: 0 !important;
        }

        //  更换手机 标题
        .dialog-name {
            // height: 69px;
            // line-height: 69px;
            padding: 19px 0 13px 0;
            text-align: center;
            border-bottom: 1px solid #E9E9E9;
            font-weight: 600;
            font-size: 24px;
            color: #333333;

            position: relative;

            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translate(-60%);
                width: 93px;
                height: 2px;
                background: #236CE0;
            }

            .tip {
                font-weight: 400;
                font-size: 14px;
                color: #999999;
                line-height: 24px;
            }


            // 关闭按钮
            .el-icon-close {
                position: absolute;
                right: 23px;
                top: 25%;
                font-size: 28px;
                cursor: pointer;
            }

        }

        // 填写手机号
        .phone-form {
            padding: 29px 62px 43px 56px;

            .form-item {
                display: flex;
                align-items: center;
                position: relative;
                box-sizing: border-box;
                margin-bottom: 26px;
            }

            .el-input__inner {
                height: 48px;
                line-height: 48px;
                border-radius: 4px;
                border: 1px solid #BEBEBE;
            }

            .label {
                margin-right: 20px;
                font-weight: 400;
                font-size: 16px;
                color: #555555;
                min-width: 64px;
            }


            .w330 {
                width: 330px
            }

            .w201 {
                width: 190px
            }

            .getCodeBtn {
                position: absolute;
                right: 5px;
                background: #236CE0;
                border-radius: 5px;
            }

            .submit {
                width: 100%;
                font-weight: 500;
                font-size: 18px;
                color: #fff;
                background: #236CE0;
                border-radius: 5px;
            }

            // 获取验证码
            .codeBox {
                margin-left: 10px;
                width: 100px;
                height: 33px;
                line-height: 33px;
                text-align: center;
                font-size: 20px;
                border: 1px solid #BEBEBE;
            }


            // 换一张
            .changeCode {
                font-weight: 400;
                font-size: 14px;
                color: #555555;
                cursor: pointer;
                border-bottom: 1px solid #555;
            }
            .change-codeImg {
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                font-size: 14px;
                width: 42px;
                white-space: nowrap;
                text-decoration: underline;
                margin-left: 6px;
                color: #555555;
                cursor: pointer;
            }

            .el-icon-refresh {
                font-size: 22px;
                margin-left: 5px;
                color: #236CE0;
                cursor: pointer;
            }

        }

        // 用户协议
        .checkBox {
            text-align: center;
            margin-top: 30px;
            margin-bottom: 12px;
            .el-checkbox__input.is-checked+.el-checkbox__label {
                color: #236CE0;
            }
            .el-checkbox__input.is-checked .el-checkbox__inner,
            .el-checkbox__input.is-indeterminate .el-checkbox__inner {
                color: #236CE0;
                background-color: #236CE0;
                border-color: #236CE0;
                // margin-top: 5px;
            }

            .colorCe0 {
                color: #236CE0;
                margin-left: 3px;
                cursor: pointer;
            }
        }

    }
}
</style>
