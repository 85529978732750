//import Cookies from 'js-cookie'
import { getRSAKey } from '@/utils/auth'

const secret = {
    RSAKey: getRSAKey() || ''
}

export default {
    secret
}
