<template>
    <div class="vedio-item" >
        <div class="vedio-item-top" @click="GoDetail(item)">
            <img src="@/assets/intelligentInterview/centerBg.png" alt="">
            <img class="play-img" src="@/assets/intelligentInterview/play.png" alt="">
            <div class="tip">
                <span>{{ item.createTime ? item.createTime.substring(0, 10) : '' }}</span>
                <span></span>
            </div>
        </div>
        <!-- <div class="name">【{{ item.companyWorkWantId ? item.workTypeName : '模拟面试' }}】 {{ item.userName }}</div> -->
        <el-tooltip effect="dark" placement="top-end">
            <div slot="content">
                <div class="name">【{{ item.companyWorkWantId ? item.workTypeName : '模拟面试' }}】 {{ item.userName }}</div> 
            </div>
            <!-- <span class="singeLine" style="width: 50%;">{{ item.workTypeName }}</span> -->
            <div class="name singeLine">【{{ item.companyWorkWantId ? item.workTypeName : '模拟面试' }}】 {{ item.userName }}</div>
        </el-tooltip>
    </div>
</template>

<script>

export default {
    props: {
        // vedioList: {
        //     type: Array,
        //     default: ()=>{}
        // },
        item: {
            type: Object,
            default: ()=>{}
        }
    },
    name: 'vedioItem',
    components: {
    },

    data() {
        return {
            identity: ''
        }
    },
    mounted() {
    },
    created() {
        this.identity = this.$store.state.user.identity
        this.$store.watch(() => this.$store.state.user.identity, (newValue, oldValue) => {
            this.identity = newValue
            // this.getDictTypeAPI()
        })
    },
    methods: {
        GoDetail(item) {
             if(this.identity == 2) {   // hr
                this.$router.push({ path: '/personalCenter/indexHr', query: { name: '历史面试', isDetail: 1,id: item.id, examType: item.examType || null } });
            } else {  // 工人
                this.$router.push({ path: '/personalCenter/index', query: { name: '历史面试', isDetail: 1, id: item.id , examType: item.examType || null } });
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.vedio-item {
    width: 100%;
    &-top {   //  视频播放的图片
        border-radius: 8px;
        height: 122px;
        background: #D8D8D8;
        border-radius: 4px;
        position: relative;
        cursor: pointer;

        img {
            width: 100%;
            height: 100%;
            border-radius: 4px;
        }

  // 播放的按钮
  .play-img {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(30%,-45%);
  }

  // 年月日的提示
  .tip {
    position: absolute;
    bottom: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-size: 14px;
    color: #FFFFFF;
    line-height: 20px;
  }

}

.name {
  padding-left: 4px;
  margin-top: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #333333;
  // white-space: nowrap;
  // overflow: hidden;
  // text-overflow: ellipsis;
  // cursor: pointer;
}
}
</style>
