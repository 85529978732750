<template>
    <div class="MBTITest-container" v-if="detailInfo">
        <titleBar name="MBTI测试结果报告" />
        <div class="content-wrap">
            <div class="grid-cloumn">
                <div class="test-wrap">
                    <div class="name">您的MBTI类型是：</div>
                    <div class="type">
                        {{ detailInfo.mbtiType }} 
                        <span class="type-tag">{{ detailInfo.mbtiName }}</span>
                    </div>
                    <div class="info" v-if="detailInfo.mbtiDesc">{{ detailInfo.mbtiDesc }}</div>
                </div>
                <div class="dimensional-wrap">
                    <span class="name">维度数据对比</span>
                    <div class="echartsBox">
                        <div class="lineBox" v-for="(item, index) in dimensionalData" :key="index">
                            <span class="labelLeft">{{ item.leftName }}</span>
                            <div class="bg">
                                <div class="lineLeft">
                                    <span class="progressBar" :style="{ width: item.leftValue > 30 ? '50%' : '30%' }"></span>
                                </div>
                                <span class="lineRight">
                                    <span class="progressBar"
                                        :style="{ width: item.rightValue > 30 ? '50%' : '30%' }"></span>
                                </span>
                            </div>
                            <span class="labelRight">{{ item.rightName }}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="analysisReport-wrap">
                <div class="content">
                    <div class="name">基本分析报告</div>
                    <baseContent 
                        v-if="detailInfo.basicDesc" 
                        name="基本描述" 
                        style="margin-top: 18px"
                        :detailInfo="detailInfo.basicDesc" />
                    <baseContent 
                        v-if="detailInfo.characterTrait" 
                        name="性格分析" 
                        style="margin-top: 34px"
                        :tagList="detailInfo.characterTraitTagList" 
                        :detailInfo="detailInfo.characterTrait" />

                </div>
                <div class="content">
                    <div class="name">职业分析报告</div>
                    <baseContent 
                        v-if="detailInfo.suitWorkEnv" 
                        name="适合的工作环境" 
                        style="margin-top: 18px"
                        :detailInfo="detailInfo.suitWorkEnv" />
                    <baseContent 
                        v-if="detailInfo.willInterestWork" 
                        name="可能感兴趣的工作" 
                        style="margin-top: 42px"
                        :detailInfo="detailInfo.willInterestWork" />
                </div>
            </div>
            <baseContent 
                v-if="detailInfo.personalDevelopAdvice" 
                name="个人发展建议" 
                style="margin-top: 43px"
                :detailInfo="detailInfo.personalDevelopAdvice" />
        </div>

    </div>
</template>

<script>
import baseContent from './base-content.vue'
import titleBar from './titleBar'
export default {
    components: {
        baseContent,
        titleBar
    },
    data() {
        return {
            dimensionalData: [   // 维度数据   根据detailInfo.mbtiType    INFP I多 N多 F多  P多
                { leftProp: 'E', rightProp: 'I', leftName: '外向(E)', leftValue: 0, rightName: '内向(I)', rightValue: 0 },
                { leftProp: 'S', rightProp: 'N', leftName: '实感(S)', leftValue: 0, rightName: '直觉(N)', rightValue: 0 },
                { leftProp: 'T', rightProp: 'F', leftName: '理性(T)', leftValue: 0, rightName: '感性(F)', rightValue: 0 },
                { leftProp: 'J', rightProp: 'P', leftName: '判断(J)', leftValue: 0, rightName: '知觉(P)', rightValue: 0 },
            ],
            detailInfo: {},
        };
    },

    methods: {
        initInfo(detailInfo) {
            this.detailInfo = detailInfo
            let mbtiType = detailInfo.mbtiType.split('')
            this.dimensionalData.forEach(item => {
                let obj = mbtiType.find(item2 => item2 == item.leftProp || item2 == item.rightProp)
                if (obj == item.leftProp) {
                    item.leftValue = 70
                    item.rightValue = 30
                } else {
                    item.rightValue = 70
                    item.leftValue = 30
                }
            })
        },
    }
}
</script>
<style lang='scss' scoped>
* {
    box-sizing: border-box;
}

.MBTITest-container {
    margin-top: 30px;
    // padding: 26px 24px;
    background: #FFFFFF;
    border-radius: 12px;
    font-family: PingFangSC, PingFang SC;
    padding: 20px;
    .content-wrap {
        margin-top: 38px;
        padding: 0 23px; 
    }
    .grid-cloumn {
        display: grid;
        grid-template-columns: auto 600px;
        grid-column-gap: 67px;
        // margin-bottom: 36px;

        // MBTI类型是：
        .test-wrap {
            .name {
                font-weight: 600;
                font-size: 22px;
                color: #333333;
            }

            .type {
                margin-top: 10px;
                font-weight: 600;
                font-size: 55px;
                color: #1A67E2;
                display: flex;
                align-items: center;
                line-height: 77px;

                .type-tag {
                    font-weight: 500;
                    font-size: 22px;
                    color: #FFFFFF;
                    margin-left: 14px;
                    padding: 0 12px;
                    height: 43px;
                    line-height: 43px;
                    background: #1A67E2;
                    border-radius: 4px;
                }
            }

            .info {
                margin-top: 10px;
                font-weight: 400;
                font-size: 16px;
                color: #333333;
            }
        }

        // 维度数据对比
        .dimensional-wrap {
            border: 1px dashed #a09d9d;
            box-sizing: border-box;
            position: relative;
            border-radius: 12px;

            .name {
                position: absolute;
                top: -15px;
                left: 50%;
                transform: translateX(-50%);
                padding: 0 20px;
                background: #fff;
            }

            .echartsBox {
                padding: 33px;

                .lineBox {
                    margin-bottom: 48px;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    width: 100%;
                    height: 16px;
                    font-weight: 400;
                    font-size: 16px;
                    color: #333333;

                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .bg {
                        width: 75%;
                        height: 16px;
                        border-radius: 10px;
                        display: flex;
                        // position: relative;
                        .lineLeft {
                            width: 50%;
                            height: 16px;
                            border-radius: 10px 0px 0px 10px;
                            background: #E4E5E9;
                            position: relative;
                            .progressBar {
                                width: 50%;
                                height: 16px;
                                right: 0;
                                position: absolute;
                                background: #1A67E2;
                                border-radius: 10px 0px 0px 10px;
                            }
                        }

                        .lineRight {
                            margin-left: 1px;
                            width: 50%;
                            height: 16px;
                            border-radius: 0px 10px 10px 0px;
                            background: #E4E5E9;
                            position: relative;

                            .progressBar {
                                width: 30%;
                                height: 16px;
                                position: absolute;
                                background: #52C4A4;
                                border-radius: 0px 10px 10px 0px;
                            }
                        }
                    }

                }
            }
        }
    }

    // 分析报告
    .analysisReport-wrap {
        margin-top: 63px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 30px;

        .content {
            padding: 22px 26px 90px;
            background: #F2F3F6;
            border-radius: 12px;

            .name {
                font-weight: 600;
                font-size: 22px;
                color: #1A67E2;

                &::after {
                    content: '';
                    display: inline-block;
                    margin-left: 14px;
                    width: 70%;
                    height: 1px;
                    background: #1A67E2;
                }
            }
        }

    }

}
</style>