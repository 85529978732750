import request from '@/utils/request'


/**
 * 获取敏感词总数据
 * @param params
 */
export function getSensitiveWordsListApi() {
    return request.get('/api/crm/web/index/getSensitiveWords', { shouldEncrypt: false, shouldDecrypt: true })
}

/**
 *  // 敏感词校验
 * @param params
 */
export function inputTipsApi(params) {
    return request.get('/api/crm/webapp/record/selectInputTips',  { shouldEncrypt: true, shouldDecrypt: true,params })
}


/**
 * 获取问题列表数据
 * serviceType : 1-AI招工，2-AI找活，3-工作伙伴AI助手，4-权益守护AI助手，5-智能面试
 * @param params
 */
export function getQuestionListApi(params) {
    return request.get('/api/crm/web/index/modelDialogueList', { shouldEncrypt: false, shouldDecrypt: true, params })
}

/**
 *  // 点击历史记录标题，获取历史所有消息
 * sessionBatch : 批次
 * @param data
 */
export function getMessageListApi(data) {
    return request.post('/api/crm/webapp/record/selectSessionDetail', data, { shouldEncrypt: true, shouldDecrypt: true })
}

/**
 * // 获取左侧历史消息记录标题
 * @param params
 */
export function getHistoryListApi(params) {
    return request.get('/api/crm/webapp/record/historyListLimit', { shouldEncrypt: false, shouldDecrypt: true,params })
}

/**
 * // 将用户和ai回复等数据保存到后端
 * @param data
 */
export function saveMessgaeApi(data) {
    return request.post('/api/crm/webapp/record/addHistoryRecord', data, { shouldEncrypt: true, shouldDecrypt: true })
}

/**
 * // 知识问答AI
 * @param data
 */
export function reqAiCommon(data) {
    return request.post('/qus/common', data, { shouldEncrypt: false, shouldDecrypt: false })
}



/**
 * // 法律问答AI
 * @param data
 */
export function reqAiLaw(data) {
    return request.post('/chat/law/support', data, { shouldEncrypt: false, shouldDecrypt: false })
}

