<!--终端机现使用这一个文件作为面试页面-->
<template>
  <div class="centerCon-container">
    <!-- 摄像头 和 ai -->
    <div class="center-main" v-if="!examStart && !startBtn">
      <div class="aiBgImg" id="aibG">
        <div class="aiBgImg-box" id="aiBgBox">
          <div id="videoPop">
            <video ref="backgroundVideo" :src="videoSrc" loop muted playsinline></video>
          </div>
        </div>

        <!--    摄像头    -->
        <div class="camera_box-body">
          <video id="videoCamera" class="canvas" :width="videoWidth" :height="videoHeight" autoPlay muted></video>
          <canvas id="canvasCamera" class="canvas" :style="{display:'none'}" :width="videoWidth"
                  :height="videoHeight"></canvas>
        </div>
      </div>
    </div>

    <!--  测评  -->
    <div class="test-main" v-show="startBtn || examStart">
      <!-- 开始测评按钮 -->
    <el-dialog
        title="提示"
        :visible.sync="startBtn"
        :close-on-click-modal="false"
        width="50%"
        :show-close="false"
        class="tip-dialog">
        <div class="exam-start--box" >
            <div class="exam-start--text-box">
            <span class="exam-start--text">
                接下来，请你完成人格测试题，没有时间限制
            </span>
            <span class="exam-start--text">
                为了更准确的判断你适合的岗位，请如实作答
            </span>
            <el-button type="primary" class="start-btn" @click="startCoach()">开始测评</el-button>
            </div>
        </div>
    </el-dialog>

      <!-- 测评组件 -->
      <div class="test-box" v-show="examStart">
        <div class="test-box-card">
          <testCard style="margin-top: 22px;" ref="testCard" @subExamAnswers="subExamAnswers"/>
        </div>
      </div>
    </div>
    <div class="message-wrap" v-show="!examStart && !startBtn">
      <!-- 对话 -->
      <div :class="['chatBox', companyWorkWantId ? 'interview-chatBox' : '']">
        <div v-show="!examStart" ref="chatBox"
             :class="['conversation-wrap', companyWorkWantId ? 'interview-wrap' : '']">
          <div class="list">
            <div class="list-item cleafix" v-if="startView">
              <div class="AIContent">
                <span
                    style="color: #1249a1">面式即将开始，本场面试将全程录音录像，请将电脑摄像头清晰对准本人并保证麦克风正常使用!</span>
              </div>
            </div>
            <div class="list-item cleafix" v-if="startView">
              <div class="AIContent">
                <span
                    style="color: #1249a1">您好，我是今天的AI面试官诺恩！面试已经开始，本轮面试将有几个问题，您可通过语音进行回答。</span>
              </div>
            </div>
            <div class="list-item cleafix" v-for="(item, index) in wordList" :key="index">
              <div class="AIContent" v-if="item.type === 'ai'">
                <span v-if="!item.isLoading">{{ item.content }}</span>
                <img
                    class="AI-1"
                    src="../../../assets/common/video-loading.gif"
                    alt="Loading..."
                    style=""
                    v-show="item.isLoading && !isFinished"/>
              </div>
              <div class="tips-context" v-if="item.type === 'ai-ready'">
                <div>
                  <span>{{ tipContent }}</span>
                  <br>
                  <span>语音回答共{{ timesText }}时间，请简明扼要进行回答</span>
                </div>
                <div class="tips-num">{{ tipsTime }}</div>
              </div>
              <div class="AIContent" v-if="item.type === 'ai' && isSummy">
                <span v-if="!item.isLoading">{{ item.content }}</span>
                <img class="AI-1" src="../../../assets/common/video-loading.gif" alt="Loading..."
                     v-show="item.isLoading && isSummy && !isFinished"/>
              </div>
              <div class="AIContent" v-if="item.type === 'ai' && isSummy && isFinished">
                <span v-if="!item.isLoading">{{ item.content }}</span>
                <img class="AI-1" src="../../../assets/common/video-loading.gif" alt="Loading..."
                     v-show="item.isLoading && isSummy && isFinished"/>
              </div>
              <div class="userContent" v-if="item.type === 'user'">
                <span>{{ item.content }}</span>
              </div>
            </div>
          </div>
          <!--          <div class="over-btn"></div>-->
        </div>
      </div>
      <div :class="['bottom-btnBox-close', companyWorkWantId ? 'end-btn-close' : '']">
        <img src="../../../assets/common/end-icon.png" class="end-img" @click="endViewFn" v-if="!isOver"/>
        <div class="bottom-btning" @click="clickBackHome" v-else>
          返回主页
        </div>
      </div>
    </div>
    <div
        :class="['recordVideoDialog', recordVideoDialog ? 'recordVideoDialog-show' : '']"
        v-show="recordVideoDialog">
      <div class="dialog-content">
        <!-- 弹窗内容 -->
        <span class="title" v-if="voiceInputCount == 0">语音输入中，{{ timesText }}内请完成作答</span>
        <span class="title" v-else>未检测到您的语音，请再次作答</span>
        <img src="@/assets/common/video-recording.gif" alt=""/>
        <span class="tip">{{ videoContent }}</span>
        <div class="btn-row">
          <button class="sumbit" @click="clickStopVoiceTranslation">
            {{ videoTitle }} ({{ remainingTime }}秒)
          </button>
        </div>
      </div>
    </div>

    <!--  重试弹框  -->
    <div
        :class="['recordVideoDialog', retryVisible ? 'recordVideoDialog-show' : '']"
        v-show="retryVisible">
      <div class="dialog-content">
        <!-- 弹窗内容 -->
        <span class="title">未检测到您的语音，请点击重试</span>

        <div class="btn-row">
          <button class="sumbit" @click="clickRetry">
            重试
          </button>
        </div>
      </div>
    </div>

    <!--  无效弹框  -->
    <div
        :class="['recordVideoDialog', invalidVisible ? 'recordVideoDialog-show' : '']"
        v-show="invalidVisible">
      <div class="dialog-content">
        <!-- 弹窗内容 -->
        <span class="title">多次未检测到您的语音，请检查麦克风</span>

        <div class="btn-row">
          <button class="sumbit" @click="clickBackHome">
            返回首页
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DraggableCamera from "@/components/userCamera/index.vue";
import debounce from "lodash/debounce";
import {saveMessgaeApi} from "@/api/com";
import Recorder from "js-audio-recorder";
import {questionNumApi, getExamTypeApi} from "@/api/aiInterView";
import fixWebmDuration from "fix-webm-duration";
import {throwOnResponseFailure} from "@/utils/request/error";
import {getEvalExamQuAndOpByExamIdAPI, subExamAnswersAPI} from "@/api/myTest";
import {interviewUpload} from "../../../utils/upload/upload.js"
import store from '@/store'
import {req} from "@/utils/aiChat/request";
import {sensitiveWordDetection} from "@/utils/input";

export default {
  props: {
    //面试完成后是否需要回调告知父组件刷新列表
    needFlush: {
      type: Boolean,
      default: false,
    },
    defaultQuestion: {
      type: String,
      default: "人力资源",
    },
    workTypeId: {
      type: String,
      default: "",
    },
    userJobwantId: {
      type: String,
      default: "",
    },
    companyWorkWantId: {
      type: String,
      default: "",
    },
    proName: {  //岗位名称
      type: String,
      default: "",
    },
  },
  name: "centerCon",
  components: {
      // DraggableCamera
  },
  data() {
    return {
      videoWidth: '100%',
      videoHeight: '100%',

      examStart: false,  //开始测试
      chunks: [],
      interviewResult: "",
      videoSrc: require("@/assets/video/videoBack.mp4"),
      dragBoxArea: {
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        speed: 5000, // 打字机效果的速度（毫秒）
      },
      startView: false, // 开始i面试
      wordList: [
        // {
        //   content: "您好，我是您的面试官小招～请简单的介绍一下自己哈哈哈哈",
        //   type: "ai",
        //   isLoading: false,
        // },
      ],
      tipsTime: 15,  //查看问题的准备时间
      tipsTimer: null, // 查看问题的准备时间阶段定时器
      cameraStatus: true, // 摄像头
      userInput: "",
      userHalfInput: "", //输入前半段的语音的文字
      questionList: [], //AI问题列表 结构：[q1,q2,....]
      questionAndSummaryList: [], //Ai的问题和用户回答的评价一起存，结构：[[query1,query2],[query3,query4],...]
      isFinished: false,
      isSummy: false,
      sessionBatch: null, //会话批次,用来判断是新会话,还是旧会话,如果是新会话就不用传
      isNewSession: "0", //是否新会话（0-新会话，1-历史会话）
      recordVideoDialog: false,  // 语音输入中 提示框
      recorder: null,
      videoRecorder: null, //视频录制对象
      displayMediaStream: null, //窗口录制对象
      timer: null,
      videoContent: "",
      videoTitle: "提交",
      remainingTime: null,
      recordingStartTime: null,
      questionNum: 5,


      historyList: [], // 所有面试问题记录
      history: [],// 当前问题记录
      startTime: 0,  // 面试计时
      openingRemarks: '您好，我是今天的AI面试官诺恩！面试已经开始，本轮面试将有几个问题，您可通过语音进行回答。',
      audio: null,  //语音播放对象
      voiceInputCount: 0, //语音输入次数
      count: 3, //允许语音输入次数
      retryVisible: false, //重试弹框
      invalidVisible: false, //无效语音弹框
      isOver: false,  //是否结束面试
      testShow: false,
      tipContent: "您有15s的时间思考，15s后请语音作答", //提示
      isAgain: false,// 是否是重试的语音输入
      timesText: '120s',
      isFinishTest: false, //
      isFinishInterView: false, //是否完成面试
      startBtn: false,
      needCoach: false,
      thisCancas: null,
      thisContext: null,
      thisVideo: null,

    };
  },
  mounted() {
    this.startTime = new Date().getTime()
    this.getDragArea();
    this.scrollToBottom();
    this.getQuestionNum(this.workTypeId)
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  },
  methods: {
    startCoach() {
      this.examStart = true;
      this.startBtn = false;
    },
    // 提交答案
    subExamAnswers(answerList) {
      this.loading = true
      answerList.forEach((item, index) => {
        item.workWantId = this.companyWorkWantId
      })
      subExamAnswersAPI(answerList).then(res => {
        this.loading = false
        let {code, msg, data} = res
        this.loading = false
        if (code == 200) {
          this.$refs.testCard._data.showSucess = true
        }
      }).catch(error => {
        this.loading = false
      })
    },
    //获取问题个数
    getQuestionNum(id) {
      questionNumApi(id).then(res => {
        if (res.data.interviewQueNum) {
          this.questionNum = res.data.interviewQueNum
        } else {
          this.questionNum = 5
        }
      }).catch(() => {
        this.questionNum = 5
      })
    },

    flushEmitData() {
      this.$emit("flushEmitData", null);
    },

    startInterview() {
      // this.startRecording()
      this.startAction()
    },

    //获取音视频流,并开始录制
    startAction() {
      const _this = this
      this.thisCancas = document.getElementById('canvasCamera');
      this.thisContext = this.thisCancas.getContext('2d');
      this.thisVideo = document.getElementById('videoCamera');
      // 旧版本浏览器可能根本不支持mediaDevices，我们首先设置一个空对象
      if (navigator.mediaDevices === undefined) {
        navigator.mediaDevices = {}
      }
      // 一些浏览器实现了部分mediaDevices，我们不能只分配一个对象
      // 使用getUserMedia，因为它会覆盖现有的属性。
      // 这里，如果缺少getUserMedia属性，就添加它。
      if (navigator.mediaDevices.getUserMedia === undefined) {
        navigator.mediaDevices.getUserMedia = function (constraints) {
          // 首先获取现存的getUserMedia(如果存在)
          let getUserMedia = navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.getUserMedia;
          // 有些浏览器不支持，会返回错误信息
          // 保持接口一致
          if (!getUserMedia) {
            return Promise.reject(new Error('getUserMedia is not implemented in this browser'))
          }
          // 否则，使用Promise将调用包装到旧的navigator.getUserMedia
          return new Promise(function (resolve, reject) {
            getUserMedia.call(navigator, constraints, resolve, reject)
          })
        }
      }
      const constraints = {
        audio: {
          echoCancellation: true, // 可选：是否包含回音消除
          noiseSuppression: true, // 可选：是否包含噪声抑制
          sampleRate: 16000, // 可选：音频采样率
        },
        video: {width: _this.videoWidth, height: _this.videoHeight, transform: 'scaleX(-1)'}
      };

      navigator.mediaDevices.getUserMedia(constraints).then(function (stream) {
        //stream 是媒体流，包含了视频和音频。
        //stream.getAudioTracks() 获取音频轨道
        //stream.getVideoTracks() 获取视频轨道
        //新建一个MediaRecorder对象，并且传入一个视频轨道和多个音频轨道

        // 旧的浏览器可能没有srcObject
        if ('srcObject' in _this.thisVideo) {
          _this.thisVideo.srcObject = stream
        } else {
          // 避免在新的浏览器中使用它，因为它正在被弃用。
          _this.thisVideo.src = window.URL.createObjectURL(stream)
        }
        _this.thisVideo.onloadedmetadata = function (e) {
          _this.thisVideo.play()
        }

        // 使用MediaRecorder开始录制
        _this.videoRecorder = new MediaRecorder(stream, {
          mimeType: "video/webm",
          audioBitsPerSecond: 128000, // 音频比特率
          videoBitsPerSecond: 2500000, // 视频比特率
          numberOfAudioChannels: 2
        });
        _this.videoRecorder.ondataavailable = _this.handleDataAvailable.bind(_this);
        _this.videoRecorder.start(); // 开始录制
        _this.recordingStartTime = new Date()
        _this.startViewFn();
      }).catch(err => {
        console.error('出先错误:', err)
        _this.$message({
          message: '没有开启摄像头权限或浏览器版本不兼容',
          type: 'warning'
        });
      });
    },

    async startRecording() {
      try {
        await navigator.mediaDevices.getUserMedia({
          audio: {
            echoCancellation: true, // 可选：是否包含回音消除
            noiseSuppression: true, // 可选：是否包含噪声抑制
            sampleRate: 16000, // 可选：音频采样率
          }
        }).then(micStream => {
          //获取用户摄像头流
          return navigator.mediaDevices.getUserMedia({
            video: {
              width: 1280,
              height: 720,
              frameRate: 30,
            },
            /*video: {
                cursor: "always", // 可选：是否包含鼠标指针
                displaySurface: "browser", // 只录制浏览器区域
                logicalSurface: true, // 使用逻辑表面，提高录制质量（仅Chrome 97+）
            },*/
            /*audio: {
                echoCancellation: true, // 可选：是否包含回音消除
                noiseSuppression: true, // 可选：是否包含噪声抑制
                sampleRate: 44100, // 可选：音频采样率
            },*/
          }).then(screenStream => {
            let allTracks = [...screenStream.getTracks(), ...micStream.getAudioTracks()];
            let audioTracks = allTracks.filter(track => track.kind === 'audio')
            let videoTracks = allTracks.filter(track => track.kind === 'video')
            let audioContext = new AudioContext();
            let localAudioStream = audioContext.createMediaStreamSource(micStream)
            let audioDestination = audioContext.createMediaStreamDestination();
            localAudioStream.connect(audioDestination);
            audioTracks.forEach(track => {
              let audioStream = new MediaStream()
              if (track) {
                audioStream.addTrack(track)
              }
              let audioStreamNode = audioContext.createMediaStreamSource(audioStream)
              audioStreamNode.connect(audioDestination);
            })
            // 合并视频、音频两个流
            this.displayMediaStream = new MediaStream([...videoTracks, ...audioDestination.stream.getTracks()]);
          });
        }).catch(error => {
          console.error('Error accessing media devices', error);
          this.$message.warning('请检查麦克风连接！')
        });

        // 使用MediaRecorder开始录制
        this.videoRecorder = new MediaRecorder(this.displayMediaStream, {
          mimeType: "video/webm",
          audioBitsPerSecond: 128000, // 音频比特率
          videoBitsPerSecond: 2500000, // 视频比特率
          numberOfAudioChannels: 2
        });
        this.videoRecorder.ondataavailable = this.handleDataAvailable.bind(this);
        this.videoRecorder.start(); // 开始录制

        this.recordingStartTime = new Date()
        this.startViewFn();
      } catch (error) {
        console.error("用户取消录制:", error);
      }
    },
    async stopRecording() {
      const endTime = new Date();
      this.videoRecorder.stop();
      // 等待所有数据块收集完成
      await new Promise((resolve) => {
        this.videoRecorder.onstop = resolve;
      });
      // 合并Blob数据为一个完整视频文件
      const recordingBlob = new Blob(this.chunks, {type: "video/webm"});
      //获取视频时长
      const duration = (endTime - this.recordingStartTime) / 1000;
      // 调用 fixWebmDuration 方法，传入 blob 和时长，获取修正后的 blob
      const that = this
      fixWebmDuration(recordingBlob, duration, function (fixedBlob) {

        // //拉取缓存中的数据
        let dataList = store.state.myTest.fixedBlobList

        dataList.push({interviewCode: store.state.user.userInfo.userId, data: fixedBlob})

        //存缓存
        store.commit('SET_FIXED_BLOB_LIST', dataList)

        const data = {
          blob: fixedBlob,
          idsObj: {
            workTypeId: that.workTypeId,
            userJobwantId: that.userJobwantId,
            companyWorkWantId: that.companyWorkWantId
          },
          sessionBatch: that.sessionBatch,
          interviewResult: that.interviewResult,
          historyList: that.historyList,
          interviewCode: store.state.user.userInfo.userId,
        }

        interviewUpload(data)

        setTimeout(() => {
          //提交完面试评估结果，视为面试完成
          that.isFinishInterView = true

          //模拟面试
          if (that.companyWorkWantId) {
            that.isHasInterviewExam(that.companyWorkWantId)
          }
        }, 1000)
      })

      // 清空数据块列表
      this.chunks = [];
    },

    //判断是否有人格测试
    isHasInterviewExam(id) {
      //获取测评类型
      getExamTypeApi(id).then(res => {
        if (res.data.id == '-1') {
          this.needCoach = false
          this.$message.success('已完成面试,将回到首页');
          setTimeout(() => {
            this.endViewFn()
          }, 1500)
        } else {
          getEvalExamQuAndOpByExamIdAPI({id: res.data.id}).then(value => {
            //判断是否需要测评
            this.needCoach = true
            this.$refs.testCard._data.interview = true
            this.$refs.testCard.getEmpEvalQuestionList(value.data.empEvalQuestionList)
            //判断是否需要测评
            this.startBtn = true;
          })
        }
      }).catch(err => {
        console.error('error:', err)
      })
    },

    //确认去测评
    confirmTest() {
      if (this.isFinishTest) {
        // 完成了测评返回首页
        this.clickBackHome()
      } else {
        //未完成测评，进入测评
        this.testShow = true
      }
    },

    //blobToArrayBuffer
    blobToArrayBuffer(blob) {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.onload = function (event) {
          resolve(event.target.result);
        };
        fileReader.onerror = function (error) {
          reject(error);
        };
        fileReader.readAsArrayBuffer(blob);
      });
    },
    //保存面试信息
    handleDataAvailable(event) {
      if (event.data.size > 0) {
        this.chunks.push(event.data);
      }
    },
    // 开始录制
    recordVideo() {
      Recorder.getPermission().then(
          () => {
            //记录开始录制语言
            this.recordUserStartVoiceTime()
            if (!this.isAgain) {  //重来不用删除
              this.wordList.pop();  // 清除提示 15s
            }
            clearInterval(this.tipsTimer)
            this.tipsTimer = null;
            this.tipsTime = 15;
            this.videoContent = '';
            this.recordVideoDialog = true;
            this.isFinished = true;
            this.initRecorder();

            //将重来状态设置为正常
            this.isAgain = false
          },
          (error) => {
            this.$message({
              message: "请先允许该网页使用麦克风",
              type: "error",
            });
            return;
          }
      );
    },
    closeVideoDialog() {
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
      this.recorder?.stop();
      this.handleDestroy();
      this.recordVideoDialog = false;
      this.isFinished = false;
    },
    initRecorder() {
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
      this.recorder = new Recorder({
        sampleBits: 16, // 采样位数，支持 8 或 16，默认是 16
        sampleRate: 16000, // 采样率，支持 11025、16000、22050、24000、44100、48000，根据浏览器默认值，Chrome 是 48000
        numChannels: 1, // 声道数，支持 1 或 2， 默认是 1
      });

      this.recorder.start(); // 开始录音
      this.remainingTime = 120;
      this.timer = setInterval(() => {
        this.remainingTime--;

        //到30s 提交一次语音转文字
        if (this.remainingTime === 30 || this.remainingTime === 60 || this.remainingTime === 90) {
          this.NX30sStopVoiceInput()
        }

        //倒计时结束，再请求一次
        if (this.remainingTime <= 0) {
          clearInterval(this.timer);
          this.timer = null;
          this.videoContent = `语音已到${this.timesText}，请提交`;
          this.videoTitle = "提交";
          this.stopVideo();
        }
      }, 1000);
    },
    //敏感词检测
    checkInput(userInput) {
      const text = sensitiveWordDetection(userInput)  //将敏感词转为*
      const sensitiveWords = JSON.parse(
          localStorage.getItem("sensitiveWords") || "[]"
      );
      let a = false
      sensitiveWords.forEach((word) => {
        let b = text.indexOf(word);
        // 如果匹配到敏感词，则替换为***
        if (b >= 0) {
          a = true
        }
      })
      if (a) {
        this.$message.error("您的消息包含敏感词，请重新编辑后发送。");
        this.closeVideoDialog()
        return false
      } else {
        return true
      }
    },

    //点击停止语音翻译
    clickStopVoiceTranslation: debounce(function () {
      //判断语音输入是否超30s
      //超过30秒
      if (this.remainingTime < 90) {
        if (this.remainingTime > 1) {
          clearInterval(this.timer);
          this.timer = null;
          this.stopVideo();
        }
      } else {  //未超30s
        this.NX30sStopVoiceInput(true)
      }
    }, 1000),

    /**
     * 语音输入未超过30s
     * @param clickStopVoice  停止语音输入
     * */
    NX30sStopVoiceInput(clickStopVoice) {
      this.recorder.stop();
      const formData = new FormData();
      const blob = this.recorder.getWAVBlob(); // 获取 pcm 格式音频数据
      // 此处获取到 blob 对象后需要设置 fileName 满足项目上传需求，这里选择直接把 blob 作为 file 塞入 formData
      const fileOfBlob = new File([blob], new Date().getTime() + ".wav");
      formData.append("file", fileOfBlob);

      req("post", "/qps/tools/audio2text", formData)
          .then((res) => {
            if (res.data) {
              if (this.checkInput(res.data)) {

                if (clickStopVoice) {
                  //记录用户回答的时间点
                  this.addUserHistory(res.data)

                  //有语音输入，将次数设置为0
                  this.voiceInputCount = 0

                  this.userInput = res.data;

                  this.sendMessage();
                  if (this.timer) {
                    clearInterval(this.timer);
                    this.timer = null;
                  }
                  this.handleDestroy();
                }

                //获取前半段语音的文字
                this.userHalfInput = this.userHalfInput + res.data

              }
            } else {
              if (clickStopVoice)
                this.noVoiceDetectedAction()
            }
          })

      if (clickStopVoice) {
        //关闭语音输入弹窗
        this.recordVideoDialog = false;
        this.recorder.stop();
      } else {
        this.recorder.start(); // 开始录音
      }
    },

    stopVideo() {
      //关闭语音输入弹窗
      this.recordVideoDialog = false;

      // 清除计时器
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
      this.recorder.stop();
      const formData = new FormData();
      const blob = this.recorder.getWAVBlob(); // 获取 pcm 格式音频数据
      // 此处获取到 blob 对象后需要设置 fileName 满足项目上传需求，这里选择直接把 blob 作为 file 塞入 formData
      const fileOfBlob = new File([blob], new Date().getTime() + ".wav");
      formData.append("file", fileOfBlob);

      //语音转文字
      // audio2textApi(formData)
      req("post", "/qps/tools/audio2text", formData)
          .then((res) => {
            if (res.data) {
              if (this.checkInput(res.data)) {
                //有语音输入，将次数设置为0
                this.voiceInputCount = 0

                //将前半段的回答文字与后半段连接起来
                this.userInput = this.userHalfInput + res.data;

                //记录用户回答的时间点
                this.addUserHistory(this.userInput)

                this.sendMessage();
                if (this.timer) {
                  clearInterval(this.timer);
                  this.timer = null;
                }
                this.handleDestroy();
              }
            } else {
              this.noVoiceDetectedAction()
            }
          });
    },

    //未检测到语音的操作
    noVoiceDetectedAction() {
      this.$message({
        message: "语音并未识别到您说话",
        type: "warning",
        customClass: 'zZindex'
      });
      this.isFinished = false;
      //关闭弹窗
      this.recordVideoDialog = false;

      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
      this.handleDestroy();

      //语音输入加一
      this.voiceInputCount++

      //重新调起语音输入
      if (this.voiceInputCount < this.count) {
        this.retryVisible = true
      } else {  //多次输入无效，弹出无效弹框
        this.invalidVisible = true
      }
    },

    //点击重试
    clickRetry() {
      this.retryVisible = false
      this.isAgain = true
      this.goBackSound()
      this.recordVideo()
    },

    //返回首页
    clickBackHome() {
      this.$emit('endInterview')
    },

    //销毁实例
    handleDestroy() {
      if (this.recorder) {
        this.recorder = null;
      }
    },
    //获取摄像头父组件位置-计算摄像头元素区域可拖动范围
    getDragArea() {
      const dragBox = document.querySelector(".center-main");
      const dragBoxLeft = dragBox.offsetLeft;
      const dragBoxTop = dragBox.offsetTop;
      const dragBoxWidth = dragBox.clientWidth;
      const dragBoxHeight = dragBox.clientHeight;
      this.dragBoxArea = {
        left: dragBoxLeft,
        top: dragBoxTop,
        right: dragBoxWidth,
        bottom: dragBoxHeight,
      };
    },
    // 开始面试
    startViewFn() {
      this.wordList = [];
      this.questionList = [];
      this.startView = true;

      //记录ai的开场白
      this.addAIHistory(this.openingRemarks)
      this.recordAIVoiceEndTime()

      // 获取问题
      this.getQuestion(1);
      // this.$nextTick(() => {
      //   let videoBox = document.getElementById("videoPop")
      //   let camera = document.getElementById("camera")
      //   let box = document.getElementById("aibG")
      //   let canvasBox = document.getElementById("videoCamera")
      //   let aiBgBox = document.getElementById("aiBgBox")
      //   box.style.height = 'auto'
      //   // videoBox.style.width = '35%'
      //   // videoBox.style.height = canvasBox.clientHeight + 'px'
      //   // videoBox.children[0].style.width = '100%'
      //   // videoBox.children[0].style.height = 'auto'
      //   // videoBox.style.overflow = 'hidden'
      //   // aiBgBox.style.justifyContent = 'space-between'
      //   canvasBox.style.height = '100%'
      //   // aiBgBox.style.backgroundImage = 'none'
      //   // aiBgBox.style.backgroundColor = 'white'
      // })
    },
    // 结束面试
    endViewFn() {
      // 未答完题目
      if (!this.isFinishInterView) {
        this.$confirm(`<span style="color:red" >您还有问题未解答完毕</span>,点击确,您本次面试即将作废, 是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          dangerouslyUseHTMLString: true,
          customClass: 'confirm2'
        }).then(() => {
          //测试上传， 正常流程 注释
          // this.stopRecording()

          this.aheadClose()
        }).catch(() => {

        });
      } else {
        //
        this.aheadClose()
      }
    },
    // 提前结束
    aheadClose() {
      this.isFinishInterView = false
      this.isFinished = false;
      this.isSummy = false;
      this.startView = false;
      this.dialogFormVisible = false
      this.examStart = false;

      if (this.audio)
        this.audio.pause()

      this.audio = null

      if (this.$refs.backgroundVideo) {
        this.$refs.backgroundVideo.pause()
        this.$refs.backgroundVideo.currentTime = 0;
      }
      // //查看录屏插件未停止执行
      if (this.videoRecorder && this.videoRecorder.state !== "inactive") {
        this.videoRecorder.stop();  //测试转码先注释
      }
      this.chunks = [];
      clearInterval(this.tipsTimer)
      clearInterval(this.timer);

      this.clickBackHome()
    },

    // 获取面试问题方法
    getQuestion(isFirst, finished = false) {
      //如果面试问题达到要求数量，那么不需要再获取问题，提交面试
      if (this.questionList.length >= this.questionNum || finished) {
        let newAIMessage = {
          content: "",
          type: "ai",
          isLoading: true,
        };

        this.wordList.push(newAIMessage);
        this.$nextTick(() => {
          this.scrollToBottom();
        });

        // 提交总的面试评估结果
        req("post", "/qps/interview/summary", {
          data: this.questionAndSummaryList,
        })
            .then((res) => {
              throwOnResponseFailure(res);
              if (res.data) {
                this.questionAndSummaryList = [];
                newAIMessage.isLoading = false;
                newAIMessage.content = res.data;
                this.animateMessage(newAIMessage).then(() => {
                  // 第一个动画完成，开始第二个动画
                  let newAIMessage3 = {
                    content: "",
                    type: "ai",
                    isLoading: true,
                  };
                  this.wordList.push(newAIMessage3);
                  this.$nextTick(() => {
                    this.scrollToBottom();
                  });

                  newAIMessage3.isLoading = false;
                  newAIMessage3.content =
                      "您的面试已完成，您可点击“结束面试”按钮结束。";
                  this.animateMessage(newAIMessage3).then(() => {
                    this.interviewResult = newAIMessage.content;
                    this.stopRecording();
                  }); // 启动第二个打字机动画
                  this.goBackSound();
                  this.$message({
                    message: "面试已完成！请等待结果上传！",
                    type: "success",
                    duration: 1500,
                  });
                  this.isFinished = false;
                });
                return;
              }
            })
            .catch((error) => {
              newAIMessage.isLoading = false;
              newAIMessage.content = "面试总结失败";
              console.error("提交面试总结失败:", error);
              this.$message({
                message: "提交面试总结失败",
                type: "error",
                duration: 1500,
              });
              this.animateMessage(newAIMessage);
              return;
            });
      } else {
        //继续获取面试题目
        if (isFirst && isFirst == 1) {
          this.isFinished = true;
        }
        let newAIMessage2 = {
          content: "",
          type: "ai",
          isLoading: true,
        };
        this.wordList.push(newAIMessage2);
        this.$nextTick(() => {
          this.scrollToBottom();
        });
        const data = {
          post: this.defaultQuestion,
          question_list: this.questionList,
        };
        //获取面试问题
        req("post", "/qps/interview/question", data)
            .then((res) => {
              if (res.data) {
                //记录问题的时间点
                this.addAIHistory(res.data)

                //打字机进行时不允许重复输入
                this.isFinished = true;
                //获取文字转语音并播放
                req(
                    "post",
                    "/qps/tools/text2audio",
                    {text: res.data},
                    {
                      headers: {
                        "Content-Type": "multipart/form-data",
                      },
                    }
                )
                    .then((iii) => {
                      if (iii.data) {
                        //返回的res.data是base64的音频文件，转换之后播放
                        let wavBlob = this.base64ToBlob(iii.data, "audio/wav");
                        let audioUrl = this.blobToUrl(wavBlob);
                        this.audio = new Audio(audioUrl);
                        this.audio.play();
                        this.$refs.backgroundVideo.play();
                      }
                      //添加问题到问题列表
                      this.questionList.push(res.data);

                      newAIMessage2.isLoading = false;
                      newAIMessage2.content = res.data;
                      const tip = this.tipContent
                      this.animateMessage(newAIMessage2).then(() => {
                            this.wordList.push({
                              content: tip,
                              type: "ai-ready",
                              isLoading: false,
                            })
                            //限制阅读15秒后才能开始答题
                            this.createTipsTime()

                            //获取AI语音播放结束时间点
                            this.recordAIVoiceEndTime()

                            //记录tip的时间点
                            this.addThinkingTipHistory(tip, 15000)
                          }
                      );
                    })
                    .catch(() => {
                      this.questionList.push(res.data);
                      newAIMessage2.isLoading = false;
                      newAIMessage2.content = res.data;
                      this.animateMessage(newAIMessage2);
                    });
              }
            });
      }
    },
    createTipsTime() {
      this.tipsTime = 15;
      this.tipsTimer = setInterval(() => {
        if (this.tipsTime <= 0) {
          this.recordVideo();
        } else {
          this.tipsTime--;
        }
      }, 1000);
    },
    base64ToBlob(base64String, type) {
      const decodedData = window.atob(base64String);
      const arrayBuffer = new ArrayBuffer(decodedData.length);
      const uint8Array = new Uint8Array(arrayBuffer);
      for (let i = 0; i < decodedData.length; i++) {
        uint8Array[i] = decodedData.charCodeAt(i);
      }
      return new Blob([uint8Array], {type});
    },
    blobToUrl(blob) {
      return URL.createObjectURL(blob);
    },
    //保存消息数据到后端
    saveHistory(userAnswer, AiQuestion) {
      //将获取的单个时间点保存到列表中
      this.historyList.push(this.history)

      const data = {
        serviceType: "5",
        fileType: "0",
        modelSearch: userAnswer,
        modelRecover: AiQuestion,
        modelType: "1",
      };
      // 如果 sessionBatch 有值，则添加到请求数据中
      if (this.sessionBatch) {
        data.sessionBatch = this.sessionBatch;
      }
      saveMessgaeApi(data)
          .then((res) => {
            if (this.sessionBatch == null && res.data.sessionBatch) {
              this.sessionBatch = res.data.sessionBatch;
            }
          })
          .catch((error) => {
            console.error("错误信息:", error);
          });
    },
    sendMessage() {
      if (this.userInput.trim() !== "" && this.checkInput(this.userInput)) {
        this.isFinished = true;
        // 添加用户的回答
        this.wordList.push({
          content: this.userInput,
          type: "user",
          isLoading: false,
        });

        this.$nextTick(() => {
          this.scrollToBottom();
        });
        const userAnswer = this.userInput;
        const AiQuestion = this.questionList[this.questionList.length - 1];
        const data = {
          question: AiQuestion, //这里的question需要去this.questionList这个数组找到最后一条问题赋值进去
          answer: userAnswer,
        };

        // 清空输入框
        this.userInput = "";
        this.userHalfInput = ''

        this.saveHistory(userAnswer, AiQuestion);



        //获取单次评估的结果
        // interviewEvaluation(data)
        req("post", "/qps/interview/evaluation", data)
            .then((res) => {
              //将单次结果添加到总结果的数组
              this.questionAndSummaryList.push([AiQuestion, res.data]);
              //获取下一道问题
              this.getQuestion();
            });
      }
    },
    //打字效果动画显示消息
    animateMessage(message) {
      return new Promise((resolve) => {
        let fullContent = message.content;
        message.content = "";
        let i = 0;
        const step = () => {
          if (i < fullContent.length) {
            message.content += fullContent.charAt(i++);
            this.$nextTick(() => {
              setTimeout(() => {
                step();
                this.scrollToBottom();
              }, 180);
            });
          } else {
            this.$refs.backgroundVideo.pause();
            this.$refs.backgroundVideo.currentTime = 0;
            this.isFinished = false;
            resolve(); // 动画完成，解决Promise
          }
        };
        step();
      });
    },

    // 使用lodash的debounce函数
    scrollToBottom: debounce(function () {
      // this.setOverflowHidden;
      this.$nextTick(() => {
        // this.setOverflowHidden();
        if (this.$refs.chatBox) {
          const container = this.$refs.chatBox;
          container.scrollTop = container.scrollHeight;
          this.setOverflowAuto(); // 恢复滚动能力
        }
      });
    }, 50),
    setOverflowHidden() {
      if (this.$refs.chatBox) {
        this.$refs.chatBox.style.overflow = "hidden";
      }
    },
    setOverflowAuto() {
      if (this.$refs.chatBox) {
        this.$refs.chatBox.style.overflow = "auto";
      }
    },
    // 语音输入
    goBackSound() {
      this.startView = true;
    },

    /**
     * 获取时间戳
     */
    getCurrentTime() {
      return new Date().getTime() - this.startTime
    },

    /**
     * 添加 AI 文本到历史记录缓存
     * @param {Object} content 文本内容
     */
    addAIHistory(content) {
      this.history = {
        robot: {
          type: 'robot',
          time: this.getCurrentTime(),
          content
        }
      }
    },
    /**
     * 记录 AI 语音结束时刻
     */
    recordAIVoiceEndTime() {
      this.history.robot.endTime = this.getCurrentTime()
    },
    /**
     * 记录 思考文字提示
     * @param {Object} content 文本内容
     * @param {Object} duration 持续毫秒数
     */
    addThinkingTipHistory(content, duration) {
      const curTime = this.getCurrentTime()
      this.history.tip = {
        type: 'tip',
        time: curTime,
        content,
        endTime: curTime + duration
      }
    },
    /**
     * 添加用户回答到历史记录缓存
     * @param {Object} content
     */
    addUserHistory(content) {
      this.history.user = {
        ...(this.history.user || {}),
        type: 'user',
        endTime: this.getCurrentTime(),
        content
      }
    },
    /**
     * 记录用户开始录音时刻
     */
    recordUserStartVoiceTime() {
      this.history.user = {
        ...(this.history.user || {}),
        type: 'user',
        time: this.getCurrentTime(),
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.centerCon-container {
  position: relative;
  height: 100%;
  width: 100%;

  .test-main {
    background: #FFFFFF;
    border-radius: 24px;
    padding: 20px;
  }

  .message-wrap {
    flex: 1;
    position: relative;
    background: #FFFFFF;
    border-radius: 12px;
    margin-top: 20px;
    height: 575px;

    .interview-chatBox {
      height: 500px;
    }

    .chatBox {
      width: 100%;

      .interview-wrap {
        // height: 16rem!important;
      }

      .conversation-wrap {
        margin-top: 18px;
        overflow-y: scroll;
        /* 保持滚动功能 */
        scrollbar-width: none;
        /* Firefox */
        // padding: 0 12px;
        width: 100%;
        height: 450px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        position: relative;

        // 左侧对话框
        .list {
          height: 80%;

          .list-item {
            // margin-top: 18px;
            font-weight: 400;
            font-size: 18px;
            position: relative;
            width: 100%; // 确保列表项占满所有可用空间
            display: flex;
            flex-direction: column;
            padding: 12px 15px;

            .AIContent {
              // 您好，我是您的工匠AI面试官！
              // width: max-content;
              display: flex;
              justify-content: start;
              //margin-bottom: 10px;
              max-width: 70%;
              background-color: #fff;
              border-radius: 14px;

              .AI-1 {
                width: 20px;
                height: 20px;
                background-color: #fff;
                padding: 15px;
                color: #000;
                border-radius: 14px;
              }

              span {
                background-color: #EBEBEB;
                padding: 15px;
                color: #000;
                border-radius: 14px;
                font-size: 18px;
              }

              @keyframes typing {
                from {
                  width: 0;
                }

                to {
                  width: 100%;
                }
              }

              @keyframes blink-caret {

                from,
                to {
                  border-color: transparent;
                }

                50% {
                  border-color: black;
                }
              }
            }

            .tips-context {
              // 您有15s的时间作答
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: max-content;
              background-color: #EBEBEB;
              padding: 16px 20px;
              color: #000;
              border-radius: 14px;
              font-size: 18px;

              .tips-num {
                width: 50px;
                height: 50px;
                border-radius: 50%;
                border: 1px solid #1A67E2;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 24px;
                color: #1A67E2;
                margin-left: 30px;
              }
            }

            .userContent {
              //   max-width: 300px;
              //   width: max-content;
              display: flex;
              justify-content: flex-end;

              span {
                margin-right: 40px;
                max-width: 70%;
                border-radius: 14px;
                padding: 10px;
                background: #2768d8;
                color: #fff;
                font-size: 18px;
                margin-bottom: 16px;
                white-space: normal;
                /* 允许换行 */
                overflow-wrap: break-word;
                /* 在单词边界内断行 */
                display: inline-block;
                /* 使span表现得像块级元素，支持宽度和高度的设置 */
              }
            }
          }

          .bgColorff {
            background: #fff;
            color: #333;
          }

          .bgColord8 {
            background: #2768d8;
            color: #fff;
          }
        }

        .over-btn {
          height: 120px;
          width: 100%;
        }
      }
    }
  }

  // 中间ai对话
  .center-main {
    /* 保持滚动功能 */
    height: 372px;
    width: 100%;
    /* Firefox */
    // ai背景
    .aiBgImg {
      height: 372px;
      width: 100%;
      z-index: -1;
      display: grid;
      grid-template-columns: 300px 1fr;
      background: #ffffff;
      border-radius: 0.7rem;
      padding: 0.2rem 0;

      .camera_box-body {
        width: 100%;
        height: 100%;
        overflow: hidden;
        position: relative;
        border-radius: 0 0.7rem 0.7rem 0;

        .canvas {
          transform: scale(1.3)
        }

        .camera_box-tip {
          height: 45px;
          border-radius: 4px;
          background-color: #FFFFFF;
          display: flex;
          align-items: center;

          span {
            font-family: PingFangSC, PingFang SC;
            font-size: 18px;
            font-weight: bold;
            color: #333333;
            margin-left: 11px;
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }

      .aiBgImg-box {
        width: 100%;
        height: 372px;
        overflow: hidden;
        position: relative;
        border-radius: 0.7rem;

        #videoPop {
          //position: absolute;
          //top: -5%;
        }

        video {
          height: 26.05263rem;
          width: 15.10526rem;
          //transform: translateY(-80px);
          animation-duration: 0.5s;
          /* 动画持续时间 */
          animation-timing-function: linear;
          /* 动画速度曲线 */
          animation-iteration-count: 1;
          /* 动画播放次数 */
          animation-fill-mode: forwards;
          /* 动画结束后的状态 */
        }

        .videoMove {
          animation: videoMoveAni;
          animation-duration: 0.5s;
          /* 动画持续时间 */
          animation-timing-function: linear;
          /* 动画速度曲线 */
          animation-iteration-count: 1;
          /* 动画播放次数 */
          animation-fill-mode: forwards;
          /* 动画结束后的状态 */
        }

        @keyframes videoMoveAni {
          100% {
            width: 260px;
            height: 380px;
            transform: translateY(-100px) translateX(-100%);
          }
        }

        img {
          width: 100%;
          height: 100%;
        }
      }
    }


  }

  // 语音输入  结束提交的提示
  .recordVideoDialog {
    position: absolute;
    z-index: 9999999;
    width: max-content;
    top: 30%;
    right: 30%;
    //transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transition: opacity 1s ease, visibility 1s;
    opacity: 0;
    visibility: hidden;

    &.recordVideoDialog-show {
      opacity: 1;
      visibility: visible;
    }

    .dialog-content {
      width: 306px;
      padding: 15px;
      background: linear-gradient(180deg, #1d2136 0%, #3e5466 100%);
      box-shadow: inset 0px -12px 56px 0px rgba(0, 0, 0, 0.4);
      border-radius: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .title {
        font-weight: 500;
        font-size: 18px;
        color: #9AF6FF;
        color: #9AF6FF;
      }

      & img {
        margin-top: 20px;
        width: 110px;
        height: 50px;
      }

      .tip {
        margin-top: 10px;
        font-size: 18px;
        color: #ffffff;
      }

      .btn-row {
        display: flex;
        flex-direction: row;
        margin-top: 15px;

        .cancel {
          text-align: center;
          width: auto;
          border-radius: 4px;
          border: 1px solid #ffffff;
          color: #ffffff;
          font-size: 28px;
          background: linear-gradient(180deg, #1d2136 0%, #3e5466 100%);
          padding: 15px;
          box-sizing: border-box;

          margin-right: 10px;
        }

        .sumbit {
          text-align: center;
          background-color: #ffffff;
          border-radius: 9px;
          color: #333333;
          font-size: 16px;
          padding: 15px 48px;
          cursor: pointer;
        }
      }
    }
  }

  // 电话 摄像头按钮
  .bottom-btnBox {
    text-align: center;
    // height: 142px;
    // line-height: 142px;

    border-radius: 12px;

    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    box-sizing: border-box;
    // 模拟面试按钮
    .bottom-btn {
      height: inherit;
      width: 100%;
      border-radius: 12px;
      background: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        z-index: 99;
        font-size: 36px;
        font-weight: bold;
        transform: skewX(-15deg);
        cursor: pointer;
      }

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        cursor: pointer;
      }

      .bgImg {
        width: 326px;
        height: 70px;
      }

      .textImg {
        width: 90px;
        height: 32px;
        top: 48%;
      }
    }

    // 关闭摄像头 结束面试  文字输入按钮
    .bottom-btning {
      height: 100%;
      border-radius: 12px;
      background: rgba(255, 255, 255, 0.8);
      display: flex;
      justify-content: space-around;

      .img-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: all 0.5s;
        // &:hover {
        //     transform: scale(1.1);
        // }

        img {
        }

        .imgBox {
          width: 75px;
          height: 75px;
          //background: rgba(73, 73, 73, 0.45);
          border-radius: 19px;
          backdrop-filter: blur(13.16239316239316px);

          display: flex;
          // flex-direction: column;
          justify-content: center;
          align-items: center;

          // img {
          //     width: 50px;
          //     height: 42px;
          // }
          &.colorRed {
            //background: rgba(238, 87, 83, 1);
          }
        }

        .text {
          margin-top: 14px;
          font-weight: 400;
          font-size: 15px;
          color: #333333;
          line-height: 21px;
        }
      }
    }

    // 文字输入框
    .bottom-input {
      border: 1px solid transparent;
      height: 100%;
      padding-left: 18px;
      border-radius: 12px;
      background: rgba(0, 0, 0, 0.1);

      // justify-content: space-around;
      .btnIcon {
        margin-top: 18px;
        width: max-content;
        padding: 10px 18px;
        border-radius: 24px;
        background: #2768d8;
        font-size: 16px;
        color: #fff;
        cursor: pointer;
      }

      .textareaBox {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 10px;
        width: 100%;
        height: 50px;
        padding-right: 18px;
        box-sizing: border-box;

        .text-video {
          width: 58px;
          height: 58px;
          cursor: pointer;
          margin-top: 10px;
          margin-left: 6px;
        }

        .no-click {
          pointer-events: none;
          /* 这将使元素不响应点击事件 */
          cursor: no-drop;
        }

        ::v-deep .el-textarea {
          width: 100%;
          height: 100%;

          .el-textarea__inner {
            line-height: 20px;
            height: 100%;
            padding: 10px 10px;
            font-size: 16px;
            border-radius: 8px;

            &::placeholder {
              color: #555;
              font-size: 14px;
            }
          }
        }
      }

      .el-textarea__inner {
        padding: 17px 17px;
      }
    }

    .recordVideoDialog-show {
      opacity: 1;
      visibility: visible;
    }
  }

  //正式测试结束按钮样式
  .end-btn-close {
    // bottom: -4rem!important;
    bottom: -40px;
  }

  .bottom-btnBox-close {
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);

    .end-img {
      width: 60px;
      height: 60px;
      margin-bottom: 10px;
    }

    .bottom-btning {
      // 结束面试 按钮
      cursor: pointer;
      transition: all 0.5s;
      width: 180px;
      height: 65px;
      background: #EE5753;
      border-radius: 70px;
      text-align: center;
      font-weight: 500;
      font-size: 18px;
      color: #fff;
      margin: 40px auto 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

}

.test-box {
  height: 70%;
  background: white;
  padding: 40px 20px 40px 20px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .test-box-card {
    width: 80%;
  }
}

.exam-start--box {
  height: 200px;
  background: #FFFFFF;
  opacity: 0.84;
  border-radius: 14px;

  .exam-start--text-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    .exam-start--text {
      font-size: 18px;
      margin-bottom: 5px;
    }

    .start-btn {
      margin-top: 15px;
      font-size: 18px;
      padding: 15px 60px;
    }
  }
}


.cleafix:after {
  content: ".";
  display: block;
  clear: both;
  overflow: hidden;
  height: 0;
}

// .面试倒计时
.timeBox-wrap {
  position: absolute;
  left: 0;
  width: auto;
  margin: 12px 12px;
  width: max-content;
  padding: 0 14px;
  height: 50px;
  line-height: 50px;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 4px;

  display: flex;

  .text {
    margin-right: 14px;
    font-weight: 400;
    font-size: 18px;
    color: #333333;
  }

  .timeBox {
    display: flex;
    align-items: center;
    justify-content: center;

    .time-item {
      // margin-right:
      width: 28px;
      height: 28px;
      line-height: 28px;
      text-align: center;
      background: #000000;
      border-radius: 4px;
      opacity: 0.8;

      font-weight: 400;
      font-size: 16px;
      color: #ffffff;
    }

    span {
      padding: 0 4px;
    }
  }
}

// 个人头像
.right-top-wrap {
  position: absolute;
  right: 12px;
  top: 12px;

  // 个人头像和静音按钮
  .right-top-content {
    width: 241px;
    height: 201px;
    background: #ffffff;
    border-radius: 4px;
    border: 2px solid #ffffff;

    .avatar {
      height: 155px;
      background: #d8d8d8;
      border-radius: 4px;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      img {
        width: 86px;
        height: 86px;
        border-radius: 50%;
      }
    }

    .name {
      height: 46px;
      line-height: 46px;
      padding-left: 12px;
      font-weight: 500;
      font-size: 18px;
      color: #333333;
      display: flex;
      align-items: center;

      .tag {
        margin-left: 10px;
        background: #ebf4fd;
        border-radius: 2px;

        // display: inline-block;
        .tag-item {
          height: 20px;
          line-height: 20px;
          padding: 0 6px;
          font-weight: 400;
          font-size: 11px;
          color: #285dab;
        }
      }
    }
  }

  // 静音按钮
  .soundOff {
    width: 80px;
    height: 40px;
    // line-height: 40px;
    // text-align: center;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 6px;
    font-weight: 400;
    font-size: 18px;
    color: #ffffff;

    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-top: 14px;
    float: right;
    transition: all 0.5s;

    &:hover {
      transform: scale(1.05);
    }

    img {
      margin-right: 6px;
      width: 20px;
      height: 16px;
    }
  }
}

// 按钮
.bottom-btnBox {
  border-radius: 12px;
  position: relative;

  // 模拟面试按钮
  .bottom-btn {
    height: inherit;
    width: 100%;
    border-radius: 12px;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      z-index: 99;
      font-size: 36px;
      font-weight: bold;
      transform: skewX(-15deg);
      cursor: pointer;
    }

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;
    }

    .bgImg {
      width: 326px;
      height: 70px;
    }

    .textImg {
      width: 90px;
      height: 32px;
      top: 48%;
    }
  }

  // 关闭摄像头 结束面试  文字输入按钮
  .bottom-btning {
    height: 100%;
    border-radius: 12px;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: space-around;

    .img-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: all 0.5s;
      // &:hover {
      //     transform: scale(1.1);
      // }

      img {
      }

      .imgBox {
        width: 75px;
        height: 75px;
        //background: rgba(73, 73, 73, 0.45);
        border-radius: 19px;
        backdrop-filter: blur(13.16239316239316px);

        display: flex;
        // flex-direction: column;
        justify-content: center;
        align-items: center;

        // img {
        //     width: 50px;
        //     height: 42px;
        // }
        &.colorRed {
          //background: rgba(238, 87, 83, 1);
        }
      }

      .text {
        margin-top: 14px;
        font-weight: 400;
        font-size: 15px;
        color: #333333;
        line-height: 21px;
      }
    }
  }

  // 文字输入框
  .bottom-input {
    border: 1px solid transparent;
    height: 100%;
    padding-left: 18px;
    border-radius: 12px;
    background: rgba(0, 0, 0, 0.1);

    // justify-content: space-around;
    .btnIcon {
      margin-top: 18px;
      width: max-content;
      padding: 10px 18px;
      border-radius: 24px;
      background: #2768d8;
      font-size: 16px;
      color: #fff;
      cursor: pointer;
    }

    .textareaBox {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 10px;
      width: 100%;
      height: 50px;
      padding-right: 18px;
      box-sizing: border-box;

      .text-video {
        width: 58px;
        height: 58px;
        cursor: pointer;
        margin-top: 10px;
        margin-left: 6px;
      }

      .no-click {
        pointer-events: none;
        /* 这将使元素不响应点击事件 */
        cursor: no-drop;
      }

      ::v-deep .el-textarea {
        width: 100%;
        height: 100%;

        .el-textarea__inner {
          line-height: 20px;
          height: 100%;
          padding: 10px 10px;
          font-size: 16px;
          border-radius: 8px;

          &::placeholder {
            color: #555;
            font-size: 14px;
          }
        }
      }
    }

    .el-textarea__inner {
      padding: 17px 17px;
    }
  }


}

// 电话 摄像头按钮
.bottom-btnBox1 {
  text-align: center;
  height: 142px;
  // line-height: 142px;

  border-radius: 12px;

  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 94%;
  box-sizing: border-box;

  // 模拟面试按钮
  .bottom-btn {
    height: inherit;
    width: 100%;
    border-radius: 12px;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      z-index: 99;
      font-size: 36px;
      font-weight: bold;
      transform: skewX(-15deg);
      cursor: pointer;
    }

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;
    }

    .bgImg {
      width: 326px;
      height: 70px;
    }

    .textImg {
      width: 90px;
      height: 32px;
      top: 48%;
    }
  }

  // 关闭摄像头 结束面试  文字输入按钮
  .bottom-btning {
    height: 100%;
    border-radius: 12px;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: space-around;

    .img-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: all 0.5s;
      // &:hover {
      //     transform: scale(1.1);
      // }

      img {
      }

      .imgBox {
        width: 75px;
        height: 75px;
        //background: rgba(73, 73, 73, 0.45);
        border-radius: 19px;
        backdrop-filter: blur(13.16239316239316px);

        display: flex;
        // flex-direction: column;
        justify-content: center;
        align-items: center;

        // img {
        //     width: 50px;
        //     height: 42px;
        // }
        &.colorRed {
          //background: rgba(238, 87, 83, 1);
        }
      }

      .text {
        margin-top: 14px;
        font-weight: 400;
        font-size: 15px;
        color: #333333;
        line-height: 21px;
      }
    }
  }

  // 文字输入框
  .bottom-input {
    border: 1px solid transparent;
    height: 100%;
    padding-left: 18px;
    border-radius: 12px;
    background: rgba(0, 0, 0, 0.1);

    // justify-content: space-around;
    .btnIcon {
      margin-top: 18px;
      width: max-content;
      padding: 10px 18px;
      border-radius: 24px;
      background: #2768d8;
      font-size: 16px;
      color: #fff;
      cursor: pointer;
    }

    .textareaBox {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 10px;
      width: 100%;
      height: 50px;
      padding-right: 18px;
      box-sizing: border-box;

      .text-video {
        width: 58px;
        height: 58px;
        cursor: pointer;
        margin-top: 10px;
        margin-left: 6px;
      }

      .no-click {
        pointer-events: none;
        /* 这将使元素不响应点击事件 */
        cursor: no-drop;
      }

      ::v-deep .el-textarea {
        width: 100%;
        height: 100%;

        .el-textarea__inner {
          line-height: 20px;
          height: 100%;
          padding: 10px 10px;
          font-size: 16px;
          border-radius: 8px;

          &::placeholder {
            color: #555;
            font-size: 14px;
          }
        }
      }
    }

    .el-textarea__inner {
      padding: 17px 17px;
    }
  }

  .recordVideoDialog {
    position: fixed;
    // box-sizing: border-box;
    // left: 50%;
    z-index: 9999999;
    // top: -350%;
    width: 100%;
    height: 100%;
    transform: translateY(calc(-67vh));
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10vh;
    // flex-direction: column;
    transition: opacity 1s ease, visibility 1s;
    opacity: 0;
    visibility: hidden;

    .dialog-content {
      z-index: 999;
      width: 306px;
      height: 158px;
      background: linear-gradient(180deg, #1d2136 0%, #3e5466 100%);
      box-shadow: inset 0px -12px 56px 0px rgba(0, 0, 0, 0.4);
      border-radius: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .title {
        margin-bottom: 10px;
        font-size: 18px;
        color: #9af6ff;
        line-height: 25px;
        font-weight: 550;
      }

      & img {
        margin-top: 5px;
        width: 135px;
        height: 60px;
      }

      .tip {
        margin-top: 10px;
        font-size: 16px;
        color: #ffffff;
        line-height: 22px;
      }

      .btn-row {
        display: flex;
        flex-direction: row;
        margin-top: 15px;

        .cancel {
          text-align: center;
          width: auto;
          height: 46px;
          border-radius: 4px;
          border: 1px solid #ffffff;
          color: #ffffff;
          font-size: 16px;
          background: linear-gradient(180deg, #1d2136 0%, #3e5466 100%);
          padding: 8px 24px;
        }

        .sumbit {
          margin-left: 15px;
          text-align: center;
          width: auto;
          height: 46px;
          background-color: #ffffff;
          border-radius: 4px;
          color: #333333;
          font-size: 16px;
          padding: 8px 24px;
        }
      }
    }
  }

  .recordVideoDialog-show {
    opacity: 1;
    visibility: visible;
  }
}

::v-deep.tip-dialog {
  // position: relative;
  z-index: 9999;
  .el-dialog {
    padding: 20px 20px 75px;
    border-radius: 20px;
    width: 947px;
    border-radius: 23px;
    // 居中
    margin: 0 !important;
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translateX(-50%);
    position: relative;
    z-index: 9999;

    .closeImg {
      width: 100px;
      height: 100px;
      position: absolute;
      bottom: -160px;
      left: 50%;
      transform: translateX(-50%);
      cursor: pointer;
    }

    .el-dialog__header {
      text-align: center;
      margin-top: 20px;

      .el-dialog__title {
        text-align: center;
        font-weight: 500;
        font-size: 46px;
        color: #333333;
      }
    }

    .el-dialog__body {
      margin-bottom: 45px;
      font-weight: 400;
      font-size: 34px;
      color: #555555;

      .content {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;

        div {
          margin-bottom: 10px;
          text-align: center;
        }
      }

    }

    .dialog-footer {
      .btn {
        width: 600px;
        height: 104px;
        line-height: 104px;
        text-align: center;
        background: linear-gradient(180deg, #53A0FF 0%, #2C56F7 100%);
        border-radius: 76px;
        color: #fff;
        font-size: 36px;
        margin: 0 auto;
        cursor: pointer;
      }
    }

  }
}
</style>

<style>
/* 您还有 问题没有回答的提示 */
.confirm2 .el-button--primary {
  background: #2768d8;
  border-color: #2768d8;
}

.confirm2 .el-button {
}
</style>
