<template>
    <div v-if="detail.empJobWantVO && identity == 2" class="right-con">
        <div class="title-wrap">
            <div class="flexBetweenCenter">
                <div class="title-wrap-item">
                    <div>
                        <el-avatar :size="80"
                            :src="detail.empJobWantVO.avatar ? detail.empJobWantVO.avatar : detail.empJobWantVO.sex == '男' ? workImg : hrImg"></el-avatar>
                    </div>
                    <div class="ml14 tagBox singeLine">

                        <el-tooltip class="item" effect="dark" placement="top-end">
                            <div slot="content">{{ detail.empJobWantVO.userName }}</div>
                            <div class="name singeLine" style="width: 90%; margin-bottom: 10px;">
                                {{ detail.empJobWantVO.userName }}</div>
                        </el-tooltip>

                        <el-tooltip class="item" effect="dark" placement="top-end">
                            <div slot="content">{{ detail.empJobWantVO.workTypesName }}</div>
                            <span class="tag singeLine">
                                <span class="tag-item">{{ detail.empJobWantVO.sex }}</span>
                                <span class="tag-item">{{ detail.empJobWantVO.workTypesName }}</span>
                            </span>
                        </el-tooltip>
                    </div>
                </div>
                <div class="btn" @click="goConnection">去联系</div>
            </div>
            <div style="display: flex; justify-content: flex-end;">
              <div class="btn" @click="seeResume(detail)">查看简历</div>
            </div>
        </div>

        <!-- hr -->
        <div class="info">
            <div class="info-item">
                <span class="color99">出生年月</span>
                <span class="text-ellipsis2">{{ detail.empJobWantVO.birthDate }}</span>
            </div>
            <div class="info-item">
                <span class="color99">期望岗位</span>
                <span class="text-ellipsis2">{{ detail.empJobWantVO.workTypesName }}</span>
            </div>
            <div class="info-item">
                <span class="color99">期望薪资</span>
                <span class="text-ellipsis2"
                    v-if="detail.empJobWantVO && detail.empJobWantVO.minWorkSalarys == -1">面议</span>
                <span class="text-ellipsis2" v-else>{{ detail.empJobWantVO.minWorkSalarys + '~'
                    + detail.empJobWantVO.maxWorkSalarys }} {{ detail.empJobWantVO.workSalarysUnit }}</span>
            </div>
            <div class="info-item">
                <span class="color99">工作地</span>
                <span class="text-ellipsis2">{{ detail.empJobWantVO.workPlaceWant }}</span>
            </div>
            <div class="info-item">
                <span class="color99">联系方式</span>
                <span class="text-ellipsis2">{{ detail.empJobWantVO.mobile }}</span>
            </div>
            <div class="info-item">
                <span class="color99">岗位类型</span>
                <span class="text-ellipsis2">{{ detail.empJobWantVO.typeName }}</span>
            </div>
            <div class="info-item">
                <span class="color99">自我介绍</span>
                <!-- <span class="text-ellipsis2" style="overflow-wrap: break-word;word-break: break-all">{{ detail.empJobWantVO.descr }}</span> -->
                <el-tooltip class="item" effect="dark" placement="top-end">
                    <div slot="content" style="max-width: 400px;max-height: 900px">{{ detail.empJobWantVO.descr }}</div>
                    <div class="overflowline2">{{ detail.empJobWantVO.descr ? detail.empJobWantVO.descr : '暂无' }}</div>
                </el-tooltip>
            </div>
        </div>

        <div class="work-type">
          面试岗位
          <div class="work-between">
            <el-tooltip effect="dark" placement="top-end">
                  <div slot="content">
                      <span v-if="detail.workTypeName"> 【 {{ detail.workTypeName }} 】</span>
                  </div>
                  <span class="singeLine" style="max-width: 280px;">
                    {{ detail.workTypeName }}
                  </span>
              </el-tooltip>
            <span class="btn" @click="showDetail">岗位详情</span>
          </div>
        </div>
        <!-- 去联系 -->
        <scanCodePay ref="scanCodePay"/>
        <callDialog ref="callDialog" />

        <!-- 简历 -->
        <ResumeTemplate ref="ResumeTemplate" />
        
        <!-- 岗位详情 -->
        <dialogWorkType ref="dialogWorkType"/>
    </div>
</template>

<script>
import * as $api from '@/api/personalCenter'
import dialogWorkType from './dialogWorkType'
export default {
    components: {
        dialogWorkType
    },
    data() {
        return {
            detail: {},
            identity: null,
            hrImg: require("@/assets/intelligentInterview/hr.png"),
            workImg: require("@/assets/intelligentInterview/work.png"),
        };
    },

    methods: {
        initInfo(detail, identity) {
            this.detail = detail
            this.identity = identity
        },
        // 详情
        showDetail() {
            console.log('this.detail', this.detail)
            // if (!this.detail.companyWorkWantId) {
                
            // }
            this.$refs.dialogWorkType.show(this.detail)
        },
        // 去联系
        goConnection() {
            // this.$refs.scanCodePay.showDialog()
            // 获取电话号码
            // detail.empJobWantVO
            let item = this.detail
            $api.getPhoneAPI({id: item.empJobWantVO.id}).then(res=>{
                let {data, success} = res
                if(data && success) {
                    // 订单类型，1:招工电话,2:工人电话,3:抢单,4:充值,5:购买会员,6:充值钱包,7:ai大模型使用招工,8:ai大模型使用工匠,9:悬赏招工,10:企业会员权益
                    let obj = {
                        phone: data,
                        userName: item.empJobWantVO.userName,
                        avatar: item.empJobWantVO.avatar ? item.empJobWantVO.avatar :  item.empJobWantVO.sex == '男' ? this.workImg : this.hrImg
                    }
                    this.$refs.callDialog.showDialog(obj)
                } else {
                    let obj = {
                        id: item.empJobWantVO.id,
                        goodsId: item.empJobWantVO.id,
                        orderType: this.identity == 1 ? 1 : 2,    // 这个页面跳过去 写死
                        phone: data,
                        userName: item.empJobWantVO.userName,
                        avatar: item.empJobWantVO.avatar ? item.empJobWantVO.avatar :  item.empJobWantVO.sex == '男' ? this.workImg : this.hrImg,
                        sex: item.empJobWantVO.sex,
                    }
                    this.$refs.scanCodePay.showDialog(obj)
                }

            })
        },
        // 查看简历
        seeResume(item) {
            this.$nextTick(() => {  // userJonWantId   传参格式 obj = { userJonWantId : xxxx }
            let obj = {
                userJonWantId: item.empJobWantVO.id
            }
            this.$refs.ResumeTemplate.getDetail(obj)
            })
        },
    }
}
</script>
<style lang='scss' scoped>
// hr个人简介
.right-con {
    height: auto;
    flex-shrink:0;
    width: 400px;
    // box-shadow: 0px 2px 22px 0px rgba(0, 0, 0, 0.14);
    border-radius: 8px;
    // border: 1px solid #FFFFFF;
    // background: #fff;
    overflow: hidden;
  // 岗位
  .work-type {
      margin-top: 16px;
      background: #fff;
      border-radius: 10px;
      padding: 20px 20px 30px;
      .work-title {
        font-weight: 500;
        font-size: 16px;
        color: #303133;
      }
      .work-between {
        margin-top: 20px;
        font-weight: 500;
        font-size: 18px;
        color: #3076FF;

        display: flex;
        justify-content: space-between;
        align-items: center;
        .btn {
          width: 82px;
          height: 30px;
          text-align: center;
          line-height: 30px;
          background: #3076FF;
          border-radius: 3px;

          font-weight: 400;
          font-size: 14px;
          color: #FFFFFF;

          cursor: pointer;
        }
      }
    }
    .title-wrap {
        padding: 30px 30px 21px;
        border-bottom: 1px solid rgba(151, 151, 151, .2);
        background: linear-gradient(180deg, #CBDFFF 0%, #FFFFFF 100%);
        // border-radius: 6px 6px 0px 0px;
        overflow: hidden;

        .title-wrap-item {
            display: flex;
            align-items: center;
            overflow: hidden;
        }

        // 标签
        .tagBox {
            vertical-align: middle;
            display: inline-block;
            margin-left: 14px;
            overflow: hidden;
        }

        .name {
            font-weight: 500;
            font-size: 20px;
            color: #010101;
        }

        //  Ui设计 女这种小tag
        .tag {
            margin-top: 8px;
            // display: flex;

            .tag-item {
                display: inline-block;
                margin-right: 10px;
                padding: 3px 7px;
                // height: 22px;
                // line-height: 22px;
                background: #CCDEFF;
                border-radius: 2px;

                font-weight: 400;
                font-size: 12px;
                color: #1A67E2;
            }
        }


        .btn {
            height: 28px;
            line-height: 28px;
            // padding: 0 16px;
            width: 76px;
            text-align: center;
            border-radius: 4px;

            font-weight: 400;
            font-size: 14px;
            color: #fff;
            background: #3076FF;
            cursor: pointer;
        }

    }

    .info {
        padding: 28px 36px 40px 33px;
        background: #fff;
        border-radius: 0 0 10px 10px;
        .info-item {
            margin-bottom: 20px;
            font-weight: 400;
            font-size: 16px;
            color: #333;
            line-height: 24px;
            display: flex;

            .color99 {
                color: #999999;
                margin-right: 23px;
                min-width: 64px;
                text-align: right;
            }
        }
    }

    .overflowline2 {
        text-overflow: -o-ellipisis-lastline;
        overflow:hidden;
        display:-webkit-box;
        text-overflow: ellipsis;
        -webkit-line-clamp:8;
        line-clamp: 8;
        -webkit-box-orient: vertical;
        word-break: break-all;
    }
}
</style>
