<template>
    <div class="main">
        <div class="left-content">
            <div class="list">
                <div
                    :class="['list-item', item.name == tabIndex ? 'active' : '', isOut ? 'isOutCss' : '' ]"
                    v-for="(item, index) in tabList"
                    :key="index" @click="tabClick(item, index)">
                    {{ item.name }}
                </div>
            </div>
        </div>
        <div class="right-content">
            <!-- <keep-alive>
          <transition name="fade" mode="out-in"> -->
            <component :is="currentComponent" :key="componentKey"> </component>
            <!-- </transition>
        </keep-alive> -->
        </div>

        <!-- 注销 -->
        <signOut ref="signOut"/>
    </div>
</template>

<script>
import myRegistration from "./components/myRegistration"; // 我的报名
import myResume from "./components/myResume";
import enterpriseInterview from "./components/enterpriseInterview";
import myPosition from "./components/myPosition";
import personalInformation from "./components/personalInformation";
import signOut from "./components/signOut";
// import historyInterview from "./components/historyInterview";
// import historyInterviewDetail from "./components/historyInterviewDetail";
import interviewRecordDetail from "./interviewRecord/detail";
import interviewRecord from "./interviewRecord";
import myTest from "./myTest";
import myTestResult from "./myTest/result";
import myTesting from "./myTest/test";
import store from "@/store";
import { logoutAPI } from "@/api/personalCenter";
import { mapGetters } from 'vuex'
export default {
    name: "personalCenterIndex",
    components: {
        myRegistration, // 我的报名
        myResume, // 我的简历
        enterpriseInterview, // 企业信息
        personalInformation, // 个人信息
        // historyInterview, // 历史面试
        myPosition, // 我的岗位
        //   myPositionDetail,   // 我的岗位详情
        // historyInterviewDetail,   // 历史面试详情
        myTest, // 职业测评
        myTestResult, // 职业测评结果
        myTesting,   // 测试中
        interviewRecord, // 面试记录
        interviewRecordDetail, // 记录详情
        signOut, // 注销
    },
    data() {
        return {
            tabList: [
                { name: "个人信息", components: "personalInformation" },
                { name: "我的简历", components: "myResume" },
                { name: "面试记录", components: "interviewRecord" },
                // { name: "历史面试", components: "historyInterview" },
                // { name: '我的岗位', components: 'myPosition'},
                // { name: '企业信息', components: 'enterpriseInterview'},
                { name: "我的报名", components: "myRegistration" },
                { name: "职业测评", components: "myTest" },
                { name: "注销", components: "" },
                // { name: '退出登录', components: ''},
            ],
            tabIndex: "个人信息",
            componentKey: 0,
            currentComponent: "personalInformation",
            isDetail: 0,
            FromPath: '',
            isOut: false,   // 注销
        };
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            // 面包屑跳转
            vm.FromPath = from.fullPath
        })
    },
    computed: {
        ...mapGetters(["identity"]),
        identityChange() {
            // return this.$store.state.mapbox.map;
            return this.identity;
        }
    },
    watch: {
        //$route.path == this.$route.path
        "$route.query": function (newVal, oldVal) {
            this.isActiveComponent();
        },
        identityChange(newVal, oldVal) {
            if (newVal == 2 ) {   // hr
                this.$router.push({
                path: "/personalCenter/indexHr",
                    query: { name: '个人信息' },
                });
            } else  {
                this.$router.push({
                path: "/personalCenter/index",
                    query: { name: '个人信息' },
                });
            }
        }
    },
    created() {
        // this.identity = store.state.user.identity;
        // store.watch(() => store.state.user.identity,(newValue, oldValue) => {
        //     this.identity = newValue;
        // }
        // );
    },
    mounted() {
        this.isActiveComponent();
    },
    methods: {
        // 注销 弹窗  按钮回调
        isOutFn() {
            this.isOut = false
        },
        tabClick(item) {
            if (item.name == '注销') {
                this.isOut = true
                this.$refs.signOut.show()
                return
            } else {
                this.isOut = false
            }
            this.tabIndex = item.name;
            this.currentComponent = item.components;
            this.componentKey += 1;
            this.$router.push({
                path: "/personalCenter/index",
                query: { name: item.name },
            });

            if (item.name == "退出登录") {
                // 成功之后推出登录
                logoutAPI({}).then((res) => {
                    if (res.code == 200) {
                        this.$router.push("/home");
                        store.commit("SET_TOKEN", null);
                    }
                });
            }
        },
        // 解决刷新 组件重置以及其他页面跳转的刷新的问题
        isActiveComponent() {
          console.log('mame:=', this.$route.query.name)
            let curentName = this.$route.query.name == '历史面试' ? '面试记录' : this.$route.query.name;
            console.log('curentName', curentName)
            let obj = this.tabList.find((item) => item.name == curentName);
            this.isDetail = this.$route.query.isDetail || 0;

            if (obj.name == '职业测评') {
                if (this.isDetail) {
                    obj.components =  this.isDetail == 1 ? 'myTestResult' : 'myTesting'
                } else {
                    obj.components = 'myTest'
                }
            }
            if (obj.name == '面试记录') {
                if (this.$route.query.isDetail) {
                    obj.components = 'interviewRecordDetail'
                } else {
                    obj.components = 'interviewRecord'
                }
            }
            this.tabIndex = obj.name;
            this.currentComponent = obj.components;
        },
    },
    beforeDestroy() {
        sessionStorage.setItem("personnalName", "");
    },
};
</script>

<style lang="scss" scoped>
* {
    box-sizing: border-box;
}

.main {
    display: flex;
    justify-content: center;
    margin: 28px 0;

    .left-content {
        .list {

            // flex-shrink: 0;
            .list-item {
                width: max-content;
                line-height: 25px;
                font-weight: 400;
                font-size: 18px;
                color: #333333;

                margin-bottom: 30px;
                cursor: pointer;
                transition: all 0.5s;

                &::before {
                    content: "";
                    display: inline-block;
                    width: 4px;
                    height: 16px;
                    background: transparent;
                    margin-right: 10px;
                    margin-top: 5px;
                }

                &:hover {
                    transform: scale(1.1);
                }

                &.active {
                    font-weight: bold;
                    color: #1a67e2;

                    &::before {
                        background: #1a67e2;
                    }
                }
                &.isOutCss {
                    &:last-child {
                        color: #EB4F3E;
                    }
                }
            }
        }
    }

    .right-content {
        width: 1260px;
        margin-left: 70px;

        padding: 32px 34px;

        background: #ffffff;
        border-radius: 12px;

        &-title {
            padding-left: 20px;
            font-weight: bold;
            font-size: 22px;
            color: #333333;
            line-height: 30px;
        }

        &-main {
            border: 1px solid red;
        }
    }
}

.fade-enter-active,
.fade-leave-active {
    transition: all 1s;
}

// .fade-enter,
.fade-leave-to {
    opacity: 0;
    transform: translateY(20px);
}

.w1260 {
    width: 1260px;
    margin-left: 70px;
}
</style>
