<template>
  <div class="aiResume-container" ref="aiResume">
    <el-dialog
      :title="'智能简历'"
      custom-class="aiResumeDialog"
      :close-on-click-modal="false"
      :visible.sync="display"
      :lock-scroll="false"
    >
      <ResumeForm
        ref="resumeForm"
        class="resume_form"
        @onMounted="initForm"
        @save="save"
        @goToResumeTemplateDialog="goToResumeTemplateDialog"
      ></ResumeForm>
    </el-dialog>
  </div>
</template>

<script>
import ResumeForm from "@/views/personalCenter/components/aiFindWork/ResumeForm.vue";

export default {
  name: "AIResume",
  components: { ResumeForm },
  data() {
    return {
      display: false,
      resumeData: {},
    };
  },
  methods: {
    // 打开智能简历窗口
    async showAIResumeForm(data) {
      this.display = true;
      this.resumeData = data;
    },
    initForm() {
      this.$refs.resumeForm.initForm(this.resumeData);
    },
    save() {
      this.display = false;
    },
    // 打开简历模板页面
    async goToResumeTemplateDialog(data) {
      data && this.$emit("openResumeTemplateDialog", data);
    },
  },
};
</script>

<style scoped lang="scss">
// 智能简历
::v-deep .aiResumeDialog {
  z-index: 99999 !important;
  width: 1220px;
  max-height: 832px;
  overflow-y: auto;
  border-radius: 8px;
  // 居中
  margin: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  // 隐藏滑动条
  //-ms-overflow-style: none;  /* IE 10+ */
  //scrollbar-width: none;  /* Firefox */

  .el-dialog__header {
    padding: 0 30px 0 40px !important;
    height: 86px;
    line-height: 92px;

    font-weight: 500;
    font-size: 18px;
    color: #333333;

    &::before {
      content: "";
      display: inline-block;
      vertical-align: baseline;
      width: 3px;
      height: 15px;
      background: #3076ff;
      margin: 4px 5px 0 0;
    }
  }

  .el-dialog__title {
    width: 72px;
    height: 25px;
    //font-family: PingFangSC, PingFang SC, serif;
    font-weight: 500;
    font-size: 18px;
    color: #333333;
    line-height: 25px;
    text-align: left;
    font-style: normal;
  }

  // 关闭按钮
  .el-dialog__headerbtn {
    display: none;
  }

  .el-dialog__body {
    padding: 0 !important;
  }
}

// 隐藏滑动条
//.aiResumeDialog::-webkit-scrollbar {
//  width: 0;  /* Chrome, Safari, and Opera */
//}
</style>
