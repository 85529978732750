<template>
  <el-dialog class="dialog-phone" :visible.sync="lawDialog" :center="true" @close="cancleFn" :close-on-click-modal="false">
    <div class="tip-form">
      <img src="@/assets/intelligentInterview/tip.png" alt="">
      <div class="name">无法使用-权益守护AI助手</div>
      <div class="tip">您当前角色为“我要找活“，该功能需切换至“我要招人”</div>
      <div class="btnBox">
        <el-button class="cancle" type="info" @click="cancleFn">取消</el-button>
        <el-button type="primary" @click="GoToEdit">确认切换</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>

import {updateUserIdentityApi, userInfoApi} from "@/api/user";
import store from "@/store";


export default  {
  name: 'lawIsRole',
  components: {
  },
  props: {
    // isShowTip: {
    //     type: Boolean,
    //     default: false
    // }
  },
  data() {
    return {
      lawDialog: false,
      ifQustion: '',
      nowIdentity: ''
    }

  },
  mounted() {

  },
  created() {
    this.nowIdentity = store.state.user.identity
    store.watch(() => store.state.user.identity, (newValue, oldValue) => {
      this.nowIdentity = newValue
    })
  },
  methods: {
    showDialog(question) {
      if(question){
        this.ifQustion = question
      }

      this.lawDialog = true
    },
    cancleFn() {
      // if (this.$route.path === '/hrStatute/index'&&store.state.user.identity=='1') {
      //   this.$router.push({ path: "/home/index" });
      // }
      this.lawDialog = false;
    },
      GoToEdit() {
        console.log(11111)
        let updateStatus;
        if (this.nowIdentity === '2') {
          updateStatus = '1';
        } else {
          updateStatus = '2';
        }
        updateUserIdentityApi({identity: updateStatus}).then(res => {
          if(res.success){
            this.$message({
              message: "切换成功",
              type: "success",
              duration: 1500,
              customClass: 'zZindex'
            });
            store.commit('SET_IDENTITY', updateStatus)
            this.$emit("switchSuccess", updateStatus);
            this.$router.push({ path: "/home/index" });
            this.lawDialog = false;
              // this.$router.push({
              //   path: '/hrStatute/index?serviceType=4'
              // })
            // this.cancleFn(true);
          }else{
            this.$message({
              message: "切换失败",
              type: "error",
              center: true,
              duration: 1500,
              customClass: 'zZindex'
            });
          }
        }).catch(()=>{
          this.$message({
            message: "切换失败",
            type: "error",
            center: true,
            duration: 1500,
            customClass: 'zZindex'
          });
          this.cancleFn()

        })
    },
  }
}
</script>

<style lang="scss" scoped>
// 更换手机弹窗
.dialog-phone {
  ::v-deep .el-dialog {
    width: 532px;
    border-radius: 8px;
    // 居中
    margin:0 !important;
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    .el-dialog__header {
      padding: 0 !important;
    }

    .el-dialog__body {
      padding: 0 !important;
    }
    .el-dialog__headerbtn .el-dialog__close {
      font-size: 24px;
    }

    .tip-form {
      height: 544px;
      height: 348px;
      background: url('@/assets/intelligentInterview/dialogBg.png');
      border-radius: 8px;

      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      img {
        width: 50px;
        height: 67px;
      }
      .name {
        margin-top: 18px;
        font-weight: 500;
        font-size: 20px;
        color: #333333;
      }
      .tip {
        margin-top: 18px;
        font-weight: 400;
        font-size: 16px;
        color: #555555;
      }

      .btnBox {
        margin-top: 52px;
        .el-button {
          width: 168px;
          height: 50px;
          background: #236CE0;
          border-radius: 5px;

          font-weight: 500;
          font-size: 18px;
          color: #FFFFFF;
        }
        .cancle {
          margin-right: 30px;
          border: 1px solid #9D9D9D;
          color: #333333;
          background: #fff;
        }
      }

    }
  }
}
</style>
