import Layout from "@/layout";

const testRouter = {
    path: '/test',
    component: Layout,
    redirect: '/test/index',
    name: 'hrStatute',
    children: [
        {
            path: 'index',
            component: () => import('@/views/test/index.vue'),
            name: 'testIndex',
        }
    ]
}

export default testRouter
