<template>
    <div>
        <div class="top-con">
            <div class="title-wrap">
                <div class="flexAlignCenter">
                    <el-avatar :size="80" :src="avatar ? avatar : defaultAvatar"></el-avatar>
                    <div class="ml14">
                        <div class="name wrap">{{ userName ? userName : '无简历' }}</div>
                            <div class="tag">
                            <span
                                class="tag-item"
                                v-for="(itemTag, indexTag) in tagList"
                                :key="indexTag">{{ itemTag }}</span>
                        </div>
                    </div>
                    <div class="btn" @click="editResume">编辑简历</div>
                </div>
            </div>

            <div class="info">
                <div class="info-item" v-for="(itemInfo, indexInfo) in infoList" :key="indexInfo">
                    <span class="color99">{{ itemInfo.name ? itemInfo.name : '' }}</span>
                    <el-tooltip class="item" effect="dark" placement="top-end" v-if="itemInfo.name == '自我介绍'">
                      <div slot="content" style="max-width: 400px; max-height: 1080px;">{{ itemInfo.info }}</div>
                      <span class="overflowline2">{{  itemInfo.info ? itemInfo.info : '暂无' }}</span>
                    </el-tooltip>
                    <span v-else>{{  itemInfo.info ? itemInfo.info : '暂无' }}</span>
                </div>
            </div>
        </div>

        <!-- 历史面试 -->
        <div class="bottom-con">
            <div class="top-title">
                <span class="bar">历史面试</span>
                <span class="btn" @click="GoMore">更多<i class="el-icon-arrow-right"></i> </span>
            </div>
            <div v-show="vedioList.length > 0" class="vedioList">
                <div class="vedio-item" v-for="(item,index) in vedioList" :key="index">
                    <div class="vedio" @click="GoDetail(item)">
                        <img  src="@/assets/icon/aiFindWork/aiAvatar.png" alt="">
                        <img class="play-img" src="@/assets/intelligentInterview/play.png" alt="">
                        <div class="tip">
                        <span>{{ item.createTime.substring(0, 10) }}</span>
                          <!--<span>16:41</span>-->
                        </div>
                    </div>
                    <!-- <div class="name nowrap">【{{ item.companyWorkWantId ? item.workTypeName : '模拟面试' }}】{{item.userName}}</div> -->
                    <el-tooltip effect="dark" placement="top-end">
                        <div slot="content">
                            <div class="name">【{{ item.companyWorkWantId ? item.workTypeName : '模拟面试' }}】{{item.userName}}</div>
                        </div>
                        <div class="name nowrap">【{{ item.companyWorkWantId ? item.workTypeName : '模拟面试' }}】{{item.userName}}</div>
                    </el-tooltip>
                </div>
            </div>
            <no-data v-show="vedioList.length === 0"></no-data>
        </div>

    </div>
</template>


<script>
import {getResumeApi} from "@/api/aiInterView";
import { timestampToDateString } from '@/utils/format'
import noData from '@/views/aiInterview/components/noData.vue'
import store from "@/store";
import * as $api from "@/api/personalCenter";
export default {
  computed: {
    store() {
      return store
    }
  },
    components: {
    noData
    },
    data() {
        return {
            defaultAvatar: require("@/assets/icon/home/avatar.png"),
            avatar: '',
            tagList: [],
            userName: '',
            infoList: [],
            vedioList: [],
            identity: '',
            workSalarysUnitList: []
        };
    },
  mounted() {
    this.getDictTypeData()
    // this.getResume()
    this.getInterViewHistory()
  },
  created() {
      this.identity = store.state.user.identity
      this.avatar = store.state.user.userInfo.avatar
      store.watch(() => store.state.user.identity, (newValue, oldValue) => {
          this.identity = newValue
      })
    },
  methods: {

    //获取历史面试记录
    getInterViewHistory() {
      $api.getInterviewAPI({ owner: store.state.user.identity == 2 ? 1 : 0}).then(res=> {
        if(res.data.rows.length > 0){
          this.vedioList = res.data.rows.slice(0, 4)
        }
      }).catch(()=> {

      })
    },

    //获取个人简历
    getResume() {
      getResumeApi().then(res => {
        if(res.data.workTypesName){
          this.$emit('customEvent',{workTypeName: res.data.workTypesName,workTypes : res.data.workTypes ,userJobwantId : res.data.userJobwantId})
        }
        this.userName = res.data.userName
        if (res.data.minWorkSalarys) {
          let unitObj = {}
          if (res.data.workSalarysUnit) {
            unitObj = this.workSalarysUnitList.find(item => item.dictValue == res.data.workSalarysUnit)
          }
          this.infoList = [
            { name: '出生年月', info: res.data.birthDate ? timestampToDateString(res.data.birthDate) : '暂未填写'},
            { name: '期望岗位', info: res.data.workTypesName},
            { name: '期望薪资', info: res.data.minWorkSalarys !== '-1' ? res.data.minWorkSalarys + '-' + res.data.maxWorkSalarys + unitObj.dictLabel : '面议'},
            { name: '工作地', info: res.data.workPlaceWant ? res.data.workPlaceWant : '无' ,},
            { name: '联系方式', info: res.data.mobile ? res.data.mobile : '无' },
            { name: '自我介绍', info: res.data.descr ? res.data.descr : '无' }
          ]
        }else {
          this.infoList = [
            { name: '出生年月', info: res.data.birthDate ? timestampToDateString(res.data.birthDate) : '暂未填写'},
            { name: '期望岗位', info: res.data.workTypesName},
            { name: '期望薪资', info: '无'},
            { name: '工作地', info: res.data.workPlaceWant ? res.data.workPlaceWant : '无' ,},
            { name: '联系方式', info: res.data.mobile ? res.data.mobile : '无' },
            { name: '自我介绍', info: res.data.descr ? res.data.descr : '无' }
          ]
        }
      }).catch(() => {

      })
    },
    // 编辑简历
    editResume() {
      if(this.identity == 2) {   // hr
          this.$router.push({ path: '/personalCenter/indexHr', query: { name: '我的简历' } });
      } else {  // 工人
          this.$router.push({ path: '/personalCenter/index', query: { name: '我的简历' } });
      }
    },
    // 历史面试 更多
    GoMore() {
      if(this.identity == 2) {   // hr
          this.$router.push({ path: '/personalCenter/indexHr', query: { name: '历史面试' ,} });
      } else {  // 工人
          this.$router.push({ path: '/personalCenter/index', query: { name: '历史面试',} });
      }
    },
    // 历史面试详情
      GoDetail(item) {
      if(this.identity == 2) {   // hr
          this.$router.push({ path: '/personalCenter/indexHr', query: { name: '历史面试', isDetail: 1, id: item.id, examType: item.examType || null } });
      } else {  // 工人
          this.$router.push({ path: '/personalCenter/index', query: { name: '历史面试',isDetail: 1,id: item.id, examType: item.examType || null} });
      }
    },
    getDictTypeData() {
      // 培训报名 性别 岗位等字典 rm_train_user_type
      $api.dictTypeAPI({ dictType: 'crm_pro_unit' }).then(res => {
        let { code, msg, data } = res
        if (code == 200) {
          this.workSalarysUnitList = data
          this.getResume()
        }
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.wrap{
  white-space: nowrap;
  overflow: hidden;
  width: 200px;
  text-overflow: ellipsis;
}
.nowrap{
  white-space: nowrap;
  overflow: hidden;
  width: 190px;
  text-overflow: ellipsis;
}
// 个人简介
.top-con {
    width: 100%;
    box-shadow: 0px 2px 22px 0px rgba(0,0,0,0.14);
    border-radius: 8px;
    border: 0px solid #FFFFFF;
    background: #fff;
    height: 510px;
    .title-wrap {
        padding: 30px 30px 21px;
        border-bottom: 1px solid rgba(151, 151, 151, .2);
        background: linear-gradient( 180deg, #CBDFFF 0%, #FFFFFF 100%);
        border-radius: 8px 8px 0px 0px;
        position: relative;
        .ml14 { margin-left: 14px; }
        .name {
            font-weight: 500;
            font-size: 20px;
            color: #010101;
        }
        .tag {
            margin-top: 8px;
            display: flex;
            .tag-item {
                margin-right: 10px;
                padding: 0 7px;
                height: 22px;
                line-height: 22px;
                background: #CCDEFF;
                border-radius: 2px;

                font-weight: 400;
                font-size: 12px;
                color: #1A67E2;
            }
        }

        // 编辑简历按钮
        .btn {
            width: 74px;
            height: 28px;
            line-height: 28px;
            text-align: center;
            border-radius: 4px;

            font-weight: 400;
            font-size: 14px;
            color: #555555;
            cursor: pointer;
            border: 1px solid #555;
            position: absolute;
            right: 32px;
        }

    }

    .info {
        padding: 28px 36px  40px 33px;
        .info-item {
            margin-bottom: 20px;
            font-weight: 400;
            font-size: 16px;
            color: #333;

            display: flex;
            align-items: center;

            .color99 {
                color: #999999;
                margin-right: 23px;
                min-width: 64px;
                text-align: right;
            }
        }
    }
}

// 历史面试
.bottom-con {
    height: 400px;
    margin-top: 24px;
    padding: 20px 24px 28px;
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 2px 22px 0px rgba(0,0,0,0.14);
    border-radius: 12px;
    // overflow: hidden;
    .top-title {

      display: flex;
      justify-content: space-between;
      align-items: center;

      .bar {
        font-weight: 500;
        font-size: 18px;
        color: #333333;
        &:before {
            content:'';
            display: inline-block;
            width: 3px;
            height: 15px;
            margin-right: 4px;
            background: #3076FF;
        }
      }
      .btn {
        font-weight: 400;
        font-size: 14px;
        color: #999;
        cursor: pointer;
      }


    }

    // 右下角 历史面试列表
    .vedioList {
        margin-top: 21px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 16px;
        grid-row-gap: 14px;
        .vedio-item {
            cursor: pointer;
            .vedio {
                width: 190px;
                height: 122px;
                background: #D8D8D8;
                border-radius: 4px;
                position: relative;
                img {
                    width: 100%;
                    height: 100%;
                }

                // 播放的按钮
                .play-img {
                    width: 50px;
                    height: 50px;
                    position: absolute;
                    top: 50%;
                    right: 50%;
                    transform: translate(30%,-45%);
                }

                // 年月日的提示
                .tip {
                    position: absolute;
                    bottom: 6px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 0 10px;
                    width: 100%;
                    font-size: 14px;
                    color: #FFFFFF;
                    line-height: 20px;
                }
                        }

            .name {
                padding-left: 5px;
                margin-top: 4px;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: #333333;
            }
        }
    }


}

.overflowline2 {
    text-overflow: -o-ellipisis-lastline;
    overflow:hidden;
    display:-webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp:3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
    word-break: break-all;

}

* {
    box-sizing: border-box;
}
.el-avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        width: 100%;
        height: 100%;
    }
}
</style>
