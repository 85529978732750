/**
 * 处理相关岗位列表数据
 * @param list 岗位数据列表
 */
export function filterRelativePostData(list) {
    return list.map((item) => ({
        id: item.id,
        // 岗位名称
        workTypeName: item.workTypeName,
        // 最低薪资
        minProSpend: item.minProSpend,
        // 最高薪资
        maxProSpend: item.maxProSpend,
        // 薪资单位
        proUnit: item.proUnit,
        // 岗位类型: 长期岗位、短期岗位 、兼职/代班
        postType: item.postType,
        // 招聘人数
        employeesNum: item.employeesNum,
        // 工作地点
        proArea: item.proArea,
    }));
}
