// 发布简历
export const myResumeForm = {
  userName: "",
  sex: "",
  birthDate: "",
  mobile: "",
  leaderName: "",
  leaderPhone: "",
  trainTime: "",

  minWorkSalarys: "",
  maxWorkSalarys: "",
  workSalarysUnit: "",

  types: "", // 岗位类型一级id
  workTypes: "", //岗位类型二级id
  workTypesName: "", // 擅长工种（二级岗位名称）

  trainArea: [],
  descr: "",
  workPlaceWant: [],
  typeName: "",

  certId: "",
  certSecId: "",
  certNames: "", //资格证一级名称
  certSecNames: "", //资格证二级名称
  trainType: ''
};
// 发布简历
export const saveObj = {
  userName: "",
  sex: "",
  birthDate: "",
  mobile: "",
  leaderName: "",
  leaderPhone: "",
  trainTime: "",
  descr: "",

  certId: "",
  certSecId: "",
  certNames: "",
  certSecNames: "",

  minWorkSalarys: "",
  maxWorkSalarys: "",
  workSalarysUnit: "",

  types: "",
  workTypes: "",
  workTypesName: "",

  trainArea: "",
  workPlaceWant: "",
  typeName: "",
  trainType: ''
};

// 发布岗位
export const myPositionForm = {
        infoType:"",						//'招聘信息发布类型，（1企业，2个人)
        postType:""	,						//岗位类别（0-未开启，1-长期岗位，2-短期岗位，3-业余兼职）
        proType:"",		//岗位一级id
        workType:",",		//岗位二级id
        proName:"",						//岗位名称二级
        proArea:"",						//区域
        contactPerson:"",						//岗位联系人
        contactMobile:"",				//岗位联系人电话
        employeesNum:"",						//trainType
        postDeadLineTime:"",				//岗位有效期
        minProSpend:		"",					//薪资待遇:下
        maxProSpend:	"",						//薪资待遇:上
        proUnit:		"",					//薪资单位（数据字典）
        proDesc:		"",					//岗位描述
        sexRequire:		"",					//性别要求
        ageRequire:	"",						//年龄要求（数据字典）crm_age_type
        hasCert	:		"",				//持证要求
        fringeBenefits	: '',					//福利待遇id（数据字典）crm_fringe_benefits
        fringeBenefitsCustom:	"",					//自定义福利（文本）
        employerContact:""	,					//岗位联系人
        employerPhoneContact: "",				//岗位联系人电话
        examType: "",                       // 人格测评类型
        workTypeName: '',
        proTimeLimit: '', // 工期
};
export const myPositionFormSave = {

}

// 简历数据格式（字段与服务器同步）
export const resumeDataStructure = {
    userName: "", // 用户名字 string
    sex: "", // 性别 string 列表序号 "0" "1" "2"
    birthDate: "", // 出生日期 number 毫秒级时间戳
    workPlaceWant: "", // 期望工作地点
    minWorkSalarys: "", // 期望最低薪资
    maxWorkSalarys: "", // 期望最高薪资
    workSalarysUnit: "", // 期望薪资单位（元/月、元/件 等）
    types: "", // 岗位类型一级id "装修类"
    workTypes: "", // 岗位类型二级id "普工"
    workTypesName: "", // 擅长工种（二级岗位名称）
    descr: "", // 个人简介、自我介绍
    typeName: "", // 工作类型名称
    // workTypeNames: "",// 工种集合
    certId: "", // 客户持证一级类型
    certSecId: "", // 客户持证二级类型
    certNames: "", // 持证一级名称
    certSecNames: "", // 持证二级名称
    eduLevel: "", // 文化程度
    graduate: "", // 毕业学校
    csJobExperiences: [ // 工作经历
        /**
         * workplace:string 工作单位
         * job:string 职务
         * entryTime: number 入职时间
         * exitTime: number 离职时间
         * jobContent: string 工作内容
         */
    ],
    csUserJobWantExtend: {
        skill: "", // 技能
        otherBenefitsRequired: "", // 其他需求
    },
};
