//写一个根据传入时间戳返回年月日的工具函数
/**
 * 将时间戳格式化为年月日的字符串
 * @param {number} timestamp - 毫秒级时间戳
 * @returns {string} - 格式化后的日期字符串（YYYY-MM-DD）
 */
export function timestampToDateString(timestamp) {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // 月份从0开始，需要加1并补足两位
    const day = String(date.getDate()).padStart(2, '0'); // 补足日期的两位

    return `${year}-${month}-${day}`;
}

