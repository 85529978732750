<template>
    <!--更换手机号弹窗-->
    <el-dialog class="dialog-phone" :visible.sync="dialogFormVisible" :center="true">
        <div class="tip-form">
            <img src="@/assets/intelligentInterview/tip.png" alt="">
            <div class="name">是否确认删除该岗位？</div>
            <div class="tip">点击删除之后将无法恢复</div>
            <div class="btnBox">
                <el-button class="cancle" type="info" @click="cancleFn">取消</el-button>
                <el-button type="primary" @click="GoToEdit">确认</el-button>
            </div>
        </div>
    </el-dialog>
</template>

<script>
import * as $api from '@/api/personalCenter'
export default {
    name: 'tipDialog',
    components: {
    },
    props: {
        // isShowTip: {
        //     type: Boolean,
        //     default: false
        // }
    },
    data() {
        return {
            dialogFormVisible: false,
            detail: ''
        }
        
    },
    mounted() {
    },
    methods: {
        showDialog(detail) {
            this.detail = detail
            this.dialogFormVisible = true
        },
        cancleFn() {
            this.dialogFormVisible = false
        },
        GoToEdit() {
            $api.deleteCompanyWorkwantAPI({ id: this.detail.id }).then(res => {
                this.dialogFormVisible = false
                if(res.code == 200) {
                    // this.$message.success(res.msg)
                    this.$message.success(res.msg  +  ' 即将跳回上一页 ')
                    setTimeout(()=>{
                        // 删除成功之后去哪里  待确认
                        this.$router.push({ path: this.detail.path, query: {  name: this.detail.name } })
                    }, 2000)
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
    }
}
</script>

<style lang="scss" scoped>
.dialog-phone {
    ::v-deep .el-dialog {
        width: 532px;
        border-radius: 8px;
        margin:0 !important;
        position:absolute;
        top:50%;
        left:50%;
        transform:translate(-50%,-50%);
        .el-dialog__header {
            padding: 0 !important;
        }

        .el-dialog__body {
            padding: 0 !important;
        }
        .el-dialog__headerbtn .el-dialog__close {
            font-size: 24px;
        }

       .tip-form {
            height: 544px;
            height: 348px;
            background: #fff;
            border-radius: 8px;

            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            img {
                width: 50px;
                height: 67px;
            }
            .name {
                margin-top: 18px;
                font-weight: 500;
                font-size: 20px;
                color: #333333;
            }
            .tip {
                    margin-top: 18px;
                    font-weight: 400;
                    font-size: 16px;
                    color: #555555;
            }

            .btnBox {
                margin-top: 52px;
                .el-button {
                    width: 168px;
                    height: 50px;
                    background: #236CE0;
                    border-radius: 5px;

                    font-weight: 500;
                    font-size: 18px;
                    color: #FFFFFF;
                }
                .cancle {
                    margin-right: 30px;
                    border: 1px solid #9D9D9D;
                    color: #333333;
                    background: #fff;
                }
            }
           
       }
    }
}
</style>