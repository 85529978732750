<template>
    <div class="personalInformation-container">
        <div class="title">个人信息</div>
        <div class="content">
            <div class="avatar">
                <img :src="ruleForm.avatar || avatarImg">
                <!-- <el-avatar :size="58" src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg"></el-avatar> -->
                <el-upload
                    class="upload-demo"
                    action="#"
                    :headers="headers"
                    :http-request="uploadFile"
                    :show-file-list="false"
                    :file-list="fileList"
                    accept=".jpeg,.png,.jpg,.bmp,.gif">
                    <el-button size="small" type="info" plain >修改头像</el-button>
                    <div slot="tip" class="el-upload__tip">上传真人照片最佳</div>
                </el-upload>
            </div>
            <el-form
                ref="ruleForm"
                :model="ruleForm"
                label-width="80px"
                label-position="top"
                :rules="rules">
                <el-form-item label="昵称">
                    <el-input  v-model="ruleForm.nickName" maxlength="15" show-word-limit @input="changeNickName"></el-input>
                </el-form-item>
                <el-form-item label="账号ID">
                    <el-input v-model="ruleForm.userId" disabled></el-input>
                </el-form-item>
                <el-form-item label="任职角色" v-if="identity == 2">

                    <!-- '用户性别（0男 1女 2未知）' -->
                    <el-select v-model="ruleForm.jobRoles" placeholder="请选择任职角色" @change="changeJobRoles">
                        <el-option
                            :label="item.dictLabel"
                            :value="item.dictValue"
                            v-for="(item, index) in jobRolesList"
                            :key="index"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="性别" v-if="identity == 1">
                    <!-- 工人 identity == 1 -->
                    <el-select v-model="ruleForm.sex" placeholder="请选择性别" @change="changeSex">
                        <el-option
                            :label="item.dictLabel"
                            :value="item.dictValue"
                            v-for="(item, index) in sexSelectList"
                            :key="index"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="手机号" class="phone" prop="phonenumber">
                    <span class="changePhone" @click="showDialog">更换手机号</span>
                    <el-input v-model="ruleForm.phonenumber" disabled></el-input>
                </el-form-item>
            </el-form>
            <div class="btn" @click="saveFn">保存</div>
        </div>

        <!--更换手机号弹窗-->
        <loginDialog ref="loginDialog" @submitFn="submitFn" />
    </div>
</template>

<script>
import loginDialog from '@/components/personalCenter/loginDialog.vue'
import {
    getUserInfoAPI,
    dictTypeAPI,
    avatarAPI,
    updateWebUserInfoAPI
} from '@/api/personalCenter'
import axios from 'axios';
import store from '@/store'
export default {
    name: 'personalInformation',
    components: {
        loginDialog
    },
    data() {
        var checkPhone = (rule, value, callback) => { // 手机号验证
            if (!value) {
                return callback(new Error('手机号不能为空'));
            } else {
                const reg = /^1[3456789]\d{9}$/
                if (reg.test(value)) {
                    callback();
                } else {
                    return callback(new Error('请输入正确的手机号'));
                }
            }
        };
        return {
            ruleForm: {
                avatar: '',   // 头像
                userId: '',
                nickName: '',
                phonenumber: '',
                sex: '',
            },
            rules: {
                phonenumber: [
                    // { required: true, message: "请输入正确手机号", trigger: "blur" },
                    { validator: checkPhone, trigger: 'blur' }
                ],
            },
            fileList: [],
            avatarfile: '',   // 头像

            // 头像上传
            imageUrl: '',
            jobRolesList: [],   // 角色
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            identity: '',
            sexSelectList: [],
            avatarImg: '',
        }
    },
    created() {
        this.getdictTypeAPI()
    },
    mounted() {
        this.identity = this.$store.state.user.identity
        this.$store.watch(() => this.$store.state.user.identity, (newValue, oldValue) => {
            this.identity = newValue
            // this.getDictTypeAPI()
        })
    },
    destroyed() { 
        sessionStorage.removeItem('nickName')
        sessionStorage.removeItem('sex')
        sessionStorage.removeItem('jobRoles')
    },
    methods: {
        changeNickName(value) {
            sessionStorage.setItem('nickName', value)
        },
        changeSex(value) {
            sessionStorage.setItem('sex', value)
        },
        changeJobRoles(value) {
            sessionStorage.setItem('jobRoles', value)
        },
        // 获取性别
        getdictTypeAPI() {
            dictTypeAPI({ dictType: 'crm_job_roles_type,sys_user_sex' }).then(res => {
                if (res.code == 200) {
                    let sys_user_sex = []
                    let crm_job_roles_type = []
                    res.data.map(item => {
						if (item.dictType == 'crm_job_roles_type') {  // 岗位类别
                            crm_job_roles_type.push(item)
                        }
                        if (item.dictType == 'sys_user_sex') {  // 薪资单位
                            sys_user_sex.push(item)
                        }
					})
                    this.jobRolesList = crm_job_roles_type
                    this.sexSelectList = sys_user_sex
                    this.getUserInfo()
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        getUserInfo() {
            let params = {}
            getUserInfoAPI(params).then(res => {
                if (res.code == 200) {
                    this.ruleForm = res.data
                    let nickName = sessionStorage.getItem('nickName')
                    let sex = sessionStorage.getItem('sex')
                    let jobRoles = sessionStorage.getItem('jobRoles')
                    if (nickName) {
                        this.ruleForm.nickName = nickName
                    }
                    if (sex) {
                        this.ruleForm.sex = sex
                    }
                    if (jobRoles) {
                        this.ruleForm.jobRoles = jobRoles
                    }
                    if (!nickName && !sex && !jobRoles) {
                        store.commit('SET_USER', res.data)
                    }
                    if (!res.data.jobRoles) {   // 重新赋值  否则有出现选择不动的效果
                        this.ruleForm.jobRoles = ''
                    }
                    if (!res.data.avatar) {
                        this.avatarImg = require("@/assets/icon/home/avatar.png")
                    }
                }

            })
        },
        // 修改手机号弹窗
        showDialog() {
            this.$refs.loginDialog.showDialog()
        },
        // 修改手机号提交
        submitFn() {
            // console.log('提交')
        },
        saveFn() {
            updateWebUserInfoAPI(this.ruleForm).then(res => {
                if (res.code == 200) {
                    sessionStorage.removeItem('nickName')
                    sessionStorage.removeItem('sex')
                    sessionStorage.removeItem('jobRoles')
                    this.$message.success(res.msg)
                    this.getdictTypeAPI()
                } else {
                    this.$message.error(res.msg)
                }
            })
        },


        handleRemove(file, fileList) {
            // this.fileList = fileList;
        },
        // 文件上传
        uploadFile(params) {
            let formData = new FormData();
            formData.append("avatarfile", params.file);
            const token = store.getters.token;
            axios.post('/api/crm/web/user/avatar', formData, {
                // 因为我们上传了图片,因此需要单独执行请求头的Content-Type
                headers: {
                    // 表示上传的是文件,而不是普通的表单数据
                    'Content-Type': 'multipart/form-data',
                    'Authorization': token
                }
            }).then(res => {
                if (res.data.code == 200) {
                    this.$message.success('上传成功')
                    this.ruleForm.avatar = res.data.imgUrl
                    this.getUserInfo()
                } else {
                    this.$message.error(res.data.msg)
                }
            })
        },
    },
}
</script>

<style scoped lang="scss">
.personalInformation-container {
    min-height: 820px;

    .title {
        font-weight: bold;
        font-size: 22px;
        color: #333333;
    }

    .content {
        margin-top: 40px;

        // 保存按钮
        .btn {
            margin-top: 61px;
            margin-left: 63px;
            width: 168px;
            height: 50px;
            line-height: 50px;
            text-align: center;
            background: #236CE0;
            border-radius: 5px;

            // font-weight: 500;
            font-weight: bold;
            font-size: 18px;
            color: #FFFFFF;
            cursor: pointer;
        }

        .avatar {
            display: flex;
            align-items: center;
            margin-bottom: 49px;
            margin-left: 63px;


            img {
                width: 94px;
                height: 94px;
                border-radius: 50%;
                margin-right: 26px;
            }

            .upload-demo {
                margin-top: 20px;

                .el-upload__tip {
                    color: #979797;

                }

                .el-button--info.is-plain {
                    margin-bottom: 5px;
                    font-size: 14px;
                    color: #333333;
                    background: transparent;
                    border-radius: 3px;
                    border: 1px solid #979797;
                }
            }
        }
    }


}

// 个人信息的表单
::v-deep.el-form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 84px;
    margin-left: 63px;

    .columns1 {
        grid-column: 1 / 2;
        /* 第一个元素独占第一列 */
    }

    //更换手机号
    .phone {
        position: relative;

        .changePhone {
            position: absolute;
            right: 0;
            z-index: 2;
            right: 128px;
            top: -44px;
            color: #1A67E2;
            cursor: pointer;
        }
    }

    .el-form-item__label {
        line-height: normal;
        font-size: 16px;
        line-height: 22px !important;
        color: #555;
        position: relative;
    }

    .el-input {
        width: 406px;

        .el-input__inner {
            height: 56px !important;
            line-height: 56px !important;
            border: 1px solid #BEBEBE;
        }
    }

    .el-select {
        width: 406px;
    }

}
</style>
