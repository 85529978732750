import CryptoJS from 'crypto-js';

const SECRET_KEY = process.env.VUE_APP_SECRET_KEY;
const APP_ID = process.env.VUE_APP_APP_ID;
const IV = process.env.VUE_APP_IV;
/**
 * 解密
 * @param data 要解密的数据
 * @param SECRET_KEY 密钥
 * @param IV 偏移量
 * @returns {string}
 */
export function decrypt(data) {
    const key = CryptoJS.enc.Utf8.parse(SECRET_KEY);
    const iv = CryptoJS.enc.Utf8.parse(IV);
    const decrypt = CryptoJS.AES.decrypt(data, key, {
        iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    }).toString(CryptoJS.enc.Utf8);
    return decrypt;
}
/**
 * 加密
 * @param data 要加密的数据
 * @param SECRET_KEY 密钥
 * @param IV 偏移量
 * @returns {string}
 */
export function encrypt(data) {
    const key = CryptoJS.enc.Utf8.parse(SECRET_KEY);
    const iv = CryptoJS.enc.Utf8.parse(IV);
    const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), key, {
        iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });
    return encrypted.toString();
}

/**
 * 生成MD5签名
 * @param timestamp 当前时间戳
 * @returns 生成的MD5签名
 */
export function generateSignature(timestamp) {
    const rawSignature = APP_ID + SECRET_KEY + timestamp;
    return CryptoJS.MD5(rawSignature).toString();
}
