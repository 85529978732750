import request from '@/utils/request'

/**
 * 获取热门岗位列表
 * @param params
 */
export function hotWorkListApi(params) {
    return request.get('/api/crm/web/index/companyWorkWant/hotList', { shouldEncrypt: false, shouldDecrypt: true, params })
}

/**
 *  // AI找活对话问答
 * @param data
 */
export function postAiFindWorkListApi(params) {
    // const url = `/api/crm/web/workWant/aiFindJob?pageNum=${data.pageNum}&pageSize=${data.pageSize}`;
    return request.get('/api/crm/web/workWant/aiFindJob', { shouldEncrypt: false, shouldDecrypt: true, params })
}
/**
 * AI找活预设问题
 */
export function postAiFindWorkPresetQuestionApi() {
    const url = '/api/crm/web/workWant/aiFindJobRetPreQu';
    return request.post(url, {}, { shouldEncrypt: true, shouldDecrypt: true });
}

/**
 * AI 找活预设问题对话分析
 * 上传用户回答，返回是否完成预设问题的回答，并附带剩余需要回答的问题列表
 * @param data
 */
export function postAiFindWorkCommunicationAnalyzerApi(data)  {
    const url = '/api/crm/web/JobWantAnalyzer/dialogue';
    return request.post(url, data, { shouldEncrypt: true, shouldDecrypt: true });
}

/**
 * 获取热门岗位列表
 * @param params
 */
export function modelQuestionListApi(params) {
    return request.get('/api/crm/web/index/companyWorkWant/hotList', { shouldEncrypt: false, shouldDecrypt: true, params })
}

/**
 * 获取字典列表
 * @param params
 */
export function dictTypeApi(params) {
    return request.get('/api/crm/web/index/type/dictType', { shouldEncrypt: false, shouldDecrypt: false, params })
}

/**
 * 个人简历下载
 *
 * @param data
 * @return pdf url
 */
export function downloadPersonalResumeApi(data)  {
    let url = `/api/crm/web/workWant/genJobWantPdfToFir/${data.id}`;
    if(data.index === 1) {
        url = `/api/crm/web/workWant/genJobWantPdfToSec/${data.id}`;
    }
    return request.get(url, { shouldEncrypt: true, shouldDecrypt: true });
}
