<template>
    <div class="myTestResult-container" v-loading="loading">
        <div class="back" @click="GoBack">
            <i class="el-icon-back"></i>
            <span>返回</span>
        </div>
        <testCard style="margin-top: 42px;" ref="testCard" @subExamAnswers="subExamAnswers" />
    </div>
</template>

<script>

import * as $api from '@/api/myTest'
export default {
    name: 'myTest',
    data() {
        return {
            empEvalQuestionList: [],
            percentage: 0,
            loading: true,
        };
    },
    mounted() {
        let ele = document.querySelector('.carte-content-container');
        let cloumn = document.querySelector('.grid-cloumn');
        if (ele) {
            ele.style.padding = 0
            ele.style.border = 0
            if (cloumn) {
                cloumn.style.borderWidth = 0
            }
        }
        this.getInitData()
    },
    methods: {
        // 提交答案
        subExamAnswers(answerList) {
            this.loading = true
            $api.subExamAnswersAPI(answerList).then(res => {
              console.log('面试结果:',res)
                let { code, msg, data } = res
                this.loading = false
                if (code == 200) {
                    this.$refs.testCard._data.showSucess = true
                }
            }).catch(error => {
                this.loading = false
            })
        },
        getInitData() {
            $api.getEvalExamQuAndOpByExamIdAPI({ id: this.$route.query.id }).then(res => {
                let { code, msg, data } = res
                this.loading = false
                if (code == 200) {
                    this.$refs.testCard.getEmpEvalQuestionList(data.empEvalQuestionList)  // 触发题目
                } else {
                    // this.$message.error(msg)
                }
            }).catch(error => {
                this.loading = false
                // this.$message.error(error.msg)
            })
        },
        GoBack() {
            if (this.identity == 2) {   // hr
                this.$router.push({ path: '/personalCenter/indexHr', query: { name: '职业测评',  id: this.$route.query.id, examType: this.$route.query.examType, } });
            } else {  // 工人
                this.$router.push({ path: '/personalCenter/index', query: { name: '职业测评', id: this.$route.query.id, examType: this.$route.query.examType, } });
            }
        },
    }
}
</script>
<style lang='scss' scoped>
::v-deep.myTestResult-container {
    min-height: 622px;
    font-family: PingFangSC, PingFang SC;
    position: relative;
    .progress {
        position: absolute;
        width: 100%;
        bottom: 0;
    }
    .back {
        font-weight: 500;
        // font-weight: bold;
        font-size: 22px;
        color: #333333;
        line-height: 30px;
        cursor: pointer;
        margin-bottom: 37px;

        .el-icon-back {
            font-size: 20px;
            font-weight: bold;
            margin-right: 9px;
        }
    }

    .progress {
        display: flex;
        align-items: center;
        margin-bottom: 40px;
        margin-top: 10px;

        .el-progress {
            flex: 1;
            margin: 0 18px;
        }
    }
    .test-card {
        min-height: calc(100% - 100px);
    }
}
</style>
