<template>
  <!--更换手机号弹窗-->
  <el-dialog
    class="dialog-phone"
    :visible.sync="dialogFormVisible"
    :center="true"
    @close="cancelFn"
    :close-on-click-modal="false"
  >
    <div class="tip-form">
      <img src="@/assets/intelligentInterview/tip.png" alt="" />
      <div class="name" v-show="this.nowIdentity === '2'">是否将角色切换至“我要找活”？</div>
      <div class="tip" v-show="this.nowIdentity === '2'">您当前角色为“我要招人“，该功能需切换至“我要找活”</div>
      <div class="name" v-show="this.nowIdentity === '1'">是否将角色切换至“我要招人”？</div>
      <div class="tip" v-show="this.nowIdentity === '1'">您当前角色为“我要找活“，该功能需切换至“我要招人”</div>
      <div class="btnBox">
        <el-button class="cancel" type="info" @click="cancelFn">取消</el-button>
        <el-button type="primary" @click="GoToEdit">确认切换</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { updateUserIdentityApi } from "@/api/user";
import store from "@/store";

// 切换提示窗口 resolve
let tipDialogResolve = null;
export default {
  name: "tipDialog",
  data() {
    return {
      dialogFormVisible: false,
      nowIdentity: "",
    };
  },
  created() {
    this.nowIdentity = store.state.user.identity;
    store.watch(
      () => store.state.user.identity,
      (newValue, oldValue) => {
        this.nowIdentity = newValue;
      }
    );
  },
  methods: {
    /**
     * 显示切换角色提示面板
     * @param success {Function?} 切换成功回调
     * @param fail {Function?} 切换失败回调
     * @return {Promise<Boolean>}
     */
    showDialog(success, fail) {
      if (typeof success !== "function") {
        success = () => {};
      }
      if (typeof fail !== "function") {
        fail = () => {};
      }
      this.dialogFormVisible = true;
      /** @type Promise<Boolean> */
      const promise = new Promise((resolve, reject) => {
        tipDialogResolve = resolve;
      });
      promise
        .then(state => {
          tipDialogResolve = null;
          if (state) {
            const identity = store.state.user.identity === "1" ? "2" : "1";
            store.commit("SET_IDENTITY", identity);
            this.$message({
              message: "切换成功",
              type: "success",
              duration: 1500,
            });
            success();
          } else {
            fail();
          }
        })
        .catch(reason => {
          console.error(reason);
          this.$message({
            message: "切换失败",
            type: "error",
            center: true,
            duration: 1500,
          });
        })
        .finally(() => {
          this.dialogFormVisible = false;
        });
      return promise;
    },
    cancelFn() {
      tipDialogResolve && tipDialogResolve(false);
    },
    GoToEdit() {
      const identity = store.state.user.identity === "1" ? "2" : "1";

      updateUserIdentityApi({ identity })
        .then(res => {
          tipDialogResolve && tipDialogResolve(!!res.success);
          // if(res.success){
          //   store.commit('SET_IDENTITY', updateStatus)
          //   this.$message({
          //     message: "切换成功",
          //     type: "success",
          //     duration: 1500,
          //   });
          //   // this.confirmCancel();
          //   if(this.nowIdentity=='2'){
          //     this.dialogFormVisible = false
          //     this.nowIdentity='1';
          //     if(this.ifAiQustion){
          //       this.$router.push({
          //         path: "/aiFindWork/index?serviceType=2",
          //         query: {
          //           context: this.ifAiQustion,
          //         },
          //       });
          //     }else{
          //       this.$router.push({
          //         path: "/aiFindWork/index?serviceType=2"
          //       });
          //     }
          //     // return;
          //   }else{
          //     this.dialogFormVisible = false
          //     this.nowIdentity='2';
          //     if(this.ifAiQustion){
          //       this.$router.push({
          //         path: "/aiFindWorker/index?serviceType=1",
          //         query: {
          //           context: this.ifAiQustion,
          //         },
          //       });
          //     }else{
          //       this.$router.push({
          //         path: "/aiFindWorker/index?serviceType=1"
          //       });
          //     }
          //     // return;
          //   }
          // }else{
          //   this.$message({
          //     message: "切换失败",
          //     type: "error",
          //     center: true,
          //     duration: 1500,
          //   });
          //   this.cancelFn(true);
          // }
        })
        .catch(error => {
          console.error(error);
          this.cancelFn();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
// 更换手机弹窗
.dialog-phone {
  ::v-deep .el-dialog {
    width: 532px;
    border-radius: 8px;
    // 居中
    margin: 0 !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .el-dialog__header {
      padding: 0 !important;
    }

    .el-dialog__body {
      padding: 0 !important;
    }
    .el-dialog__headerbtn .el-dialog__close {
      font-size: 24px;
    }

    .tip-form {
      //height: 544px;
      height: 348px;
      background: url("@/assets/intelligentInterview/dialogBg.png");
      border-radius: 8px;

      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      img {
        width: 50px;
        height: 67px;
      }
      .name {
        margin-top: 18px;
        font-weight: 500;
        font-size: 20px;
        color: #333333;
      }
      .tip {
        margin-top: 18px;
        font-weight: 400;
        font-size: 16px;
        color: #555555;
      }

      .btnBox {
        margin-top: 52px;
        .el-button {
          width: 168px;
          height: 50px;
          background: #236ce0;
          border-radius: 5px;

          font-weight: 500;
          font-size: 18px;
          color: #ffffff;
        }
        .cancel {
          margin-right: 30px;
          border: 1px solid #9d9d9d;
          color: #333333;
          background: #fff;
        }
      }
    }
  }
}
</style>
