<template>
    <div class="enterpriseInterview-container">
        <div class="title">
            企业信息
            <img src="@/assets/intelligentInterview/shz.png" alt="" v-if="auditStatus == 0">
            <!-- 成功 -->
            <img src="@/assets/intelligentInterview/shcg.png" alt="" v-if="auditStatus == 1">
            <!-- 失败 -->
            <img src="@/assets/intelligentInterview/sb.png" alt="" v-if="auditStatus == 2">
        </div>
        <el-form
            ref="ruleForm"
            :model="ruleForm"
            label-width="80px"
            label-position="top"
            :rules="rules">
            <el-form-item label="企业名称" prop="companyName">
                <el-input
                    v-model="ruleForm.companyName"
                    :disabled="auditStatus == 1 || auditStatus == 0"></el-input>
            </el-form-item>
            <el-form-item label="统一社会信用代码" prop="companyCreditCode">
                <el-input
                    v-model="ruleForm.companyCreditCode"
                    maxlength="18"
                    show-word-limit
                    :disabled="auditStatus == 1 || auditStatus == 0"
                    ></el-input>
            </el-form-item>
            <el-form-item label="企业联系人" prop="companyContact">
                <el-input
                    v-model="ruleForm.companyContact"
                    disabled></el-input>
            </el-form-item>
            <el-form-item label="联系人电话号码" prop="companyTel">
                <el-input v-model="ruleForm.companyTel" disabled></el-input>
            </el-form-item>

            <el-form-item label="所属行业" prop="companyIndustry">
                <el-select
                    v-model="ruleForm.companyIndustry"
                    placeholder="请选择所属行业"
                    :disabled="auditStatus == 1 || auditStatus == 0">
                    <el-option
                        v-for="itemO in workTypeList"
                        :key="itemO.dictValue"
                        :label="itemO.dictLabel"
                        :value="itemO.dictValue"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item
                label="企业注册地址"
                prop="companyAddress2"
                :class="init.companyAddress ? 'placeholder' : 'colord28'">
                <el-cascader
                    :placeholder="init.companyAddress ? init.companyAddress : '请选择企业注册地址'"
                    v-model="ruleForm.companyAddress2"
                    :options="araeList"
                    :props="araeListOption"
                    filterable
                    @change="changeArea"
                    :disabled="auditStatus == 1 || auditStatus == 0"></el-cascader>
            </el-form-item>
            <el-form-item class="columns1 " label="营业执照上传" prop="busLicenseUrl">
                <div class="imgBox">
                    <!-- <img v-if="ruleForm.busLicenseUrl" :src="ruleForm.busLicenseUrl" class="imageUrl"> -->
                    <!-- :disabled="((!auditStatus && auditStatus != 0) || auditStatus == 1) ? true : false "   -->
                    <el-image 
                        v-if="ruleForm.busLicenseUrl" 
                        :src="ruleForm.busLicenseUrl" 
                        class="imageUrl"
                        :preview-src-list="[ruleForm.busLicenseUrl]">
                    </el-image>
                    <el-upload
                        v-if="(!auditStatus && auditStatus != 0) || auditStatus == 2"
                        class="avatar-uploader"
                        list-type="picture-card"
                        action="#"
                        :headers="headers"
                        :http-request="uploadFile"
                        :file-list="fileList"
                        :show-file-list="false"
                        :on-preview="previewUpload"
                        accept=".jpeg,.png,.jpg,.bmp,.gif">
                        <!-- :show-file-list="false"  -->
                        <i class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </div>
            </el-form-item>
            <!-- 修改信息 审核中 -->
            <!--  auditStatus 用工单位审核状态(0，未审核,1，审核通过,2，审核不通过) -->
            <!-- {{auditStatus == 0 ? '审核中' :auditStatus == 1 : '审核通过'}} -->
            <div class="btn w168" @click="saveFn" v-if="!auditStatus && auditStatus != 0">提交审核</div>
            <div class="btn w168 btn2" v-if="auditStatus == 0">审核中</div>
            <div class="btn w168" @click="saveFn('edit')" v-if="auditStatus == 2">修改审核</div>
            <div class="btn pl20" v-if="auditStatus == 1 && userInfo.isActLegalHelper == 0" @click="openLegal">开通权益守护AI助手</div>
            <div class="btn pl20 btnDisAble" v-if="auditStatus == 1 && userInfo.isActLegalHelper ==1">已开通权益守护AI助手</div>
        </el-form>
    </div>
</template>

<script>
import * as $api from '@/api/personalCenter'
import { handleTree } from '@/utils/StringUtils'
import store from '@/store'
import axios from 'axios'
export default {
    name: 'enterpriseInterview',
    components: {},
    data() {
        var checkPhone = (rule, value, callback) => { // 手机号验证
            if (!value) {
                return callback(new Error('手机号不能为空'));
            } else {
                const reg = /^1[3456789]\d{9}$/
                if (reg.test(value)) {
                    callback();
                } else {
                    return callback(new Error('请输入正确的手机号'));
                }
            }
        };
        var checkCompanyCreditCode = (rule, value, callback) => { // 手机号验证
            if (!value) {
                return callback(new Error('统一社会信用代码不能为空'));
            } else {
                const reg = /^[0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}$/
                if (reg.test(value)) {
                    callback();
                } else {
                    return callback(new Error('请输入正确的统一社会信用代码'));
                }
            }
        };

        return {
            ruleForm: {
                companyName: '',
                companyContact: '',
                companyCreditCode: '',
                companyTel: '',
                companyAddress: '',
                companyAddress2: '',
                busLicenseUrl: '',   // 营业执照
            },

            rules: {
                companyName: { required: true, message: '请输入企业名称', trigger: 'change' },
                companyContact: { required: true, message: '请输入负责人', trigger: 'change' },
                companyCreditCode: [
                    { required: true, message: '请输入统一社会信用代码', trigger: 'change' },
                    { validator: checkCompanyCreditCode, trigger: 'change' }
                ],
                companyTel:   [
                    { required: true, message: '请输入负责人联系电话', trigger: 'change' },
                    { validator: checkPhone, trigger: 'blur' }
                ],
                companyAddress2: { required: true, message: '请选择企业注册地址', trigger: 'change' },
                busLicenseUrl: { required: true, message: '请上传营业执照', trigger: 'change' },
                companyIndustry: { required: true, message: '请选择所属行业', trigger: 'change' },
            },
            araeList: [],
            workTypeList: [],
            araeListOption: {
                value: "areaName",
                label: "areaName",
                children: "children",
            },
            workTypeListOPtion: {
                value: "id",
                label: "dictLabel",
                children: "children",
            },
            fileList: [],
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            imageUrl: '',
            araeListTree: [],
            dialogVisible: false,
            init: {
                companyAddress: '',
            },
            auditStatus: null,
            isOpenLegal: false,

            isStatus: 0,
            titleImg: '',
            busLicenseUrl: '',
            userInfo: {}
        }
    },
    created() {
        this.getInitData()
        this.getAreaList()  // 地区
        this.getInit()  // 列表
    },
    methods: {
        changeArea(value) {
            // let obj = []
        },
        saveFn(type) {
            //
            this.$refs.ruleForm.validate((valid) => {
                if(valid) {
                    if( this.ruleForm.companyAddress2 instanceof Array ) {
                        this.ruleForm.companyAddress = this.ruleForm.companyAddress2.length > 0 ? this.ruleForm.companyAddress2.join('') : ''
                    } else if(this.ruleForm.companyAddress2) {
                        this.ruleForm.companyAddress =  this.ruleForm.companyAddress2
                    }
                    if (type == 'edit') {
                        // this.ruleForm.id =
                        $api.editCompanyInfoAPI(this.ruleForm).then(res => {
                            let { code, msg, data } = res
                            if (code == 200) {
                                this.getInit()  // 列表
                                this.$refs.ruleForm.clearValidate();
                            } else {
                                this.$message.error(msg)
                            }
                        })

                    } else {
                        delete this.ruleForm.id
                        $api.companyCheckInForWebAPI(this.ruleForm).then(res => {
                            let { code, msg, data } = res
                            if (code == 200) {
                                this.getInit()  // 列表
                                this.$refs.ruleForm.clearValidate();
                            } else {
                                this.$message.error(msg)
                            }
                        })
                    }
                }
            })
        },
        getInit(arr) {
            $api.getCompanyInfoAPI().then(res => {
                let { code, msg, data } = res
                if (code == 200) {
                    this.userInfo = this.$store.state.user.userInfo
                    this.ruleForm = data.rows.length ? data.rows[0] : this.ruleForm
                    this.auditStatus = data.rows.length ? data.rows[0].auditStatus : null
                    this.init.companyAddress = this.ruleForm.companyAddress   // 用于回显
                    this.ruleForm.companyAddress2 = this.ruleForm.companyAddress
                    this.ruleForm.companyContact =  this.userInfo.nickName
                    this.ruleForm.companyTel =  this.userInfo.phonenumber
                    // this.cancleRuleForm()
                    this.$nextTick(() => {
                        this.$refs.ruleForm.clearValidate();
                    })

                }
            })
        },
        cancleRuleForm() {
            this.$nextTick(()=>{  // 清除校验
                this.$refs.ruleForm.clearValidate();
            })
        },
        // 开通法律助手
        openLegal() {
            $api.grantPerOfLegalAsstAPI().then(res => {
                let { code, msg, data } = res
                if (code == 200) {
                    this.$message.success(msg)
                    this.isOpenLegal = false
                    this.getUserInfo()
                } else {
                    this.$message.error(msg)
                }
            })
        },
        async getUserInfo() {   // 刷新页面
            let params = {}
            $api.getUserInfoAPI(params).then(res => {
                if (res.code == 200) {
                    store.commit('SET_USER', res.data)
                    this.getInit()
                }
            })
        },
        // 文件上传之前
        previewUpload() {
            if(this.auditStatus == 0) {  // 审核中
                return this.$message.warning('审核状态中,不可切换图片')
            }
        },
        // 文件上传
        uploadFile(params) {
            if(this.auditStatus == 0 || this.auditStatus == 1 ) {  // 审核中   审核成功
               return this.$message.warning('审核状态中,不可切换图片')
            }
            let formData = new FormData();
            formData.append("busLicense", params.file);
            const token = store.getters.token;
            axios.post('/api/crm/web/companyInfo/uploadBusLicense', formData, {
                // 因为我们上传了图片,因此需要单独执行请求头的Content-Type
                headers: {
                    // 表示上传的是文件,而不是普通的表单数据
                    'Content-Type': 'multipart/form-data',
                    'Authorization': token
                }
            }).then(res => {
                if (res.data.code == 200) {
                    this.$message.success('上传成功')
                    this.ruleForm.busLicenseUrl = res.data.imgUrl
                } else {
                    this.$message.error(res.data.msg)
                }
            })
        },

        // 获取地区
        getAreaList() {
            $api.getAreaListAPI({}).then(res => {
                let { code, msg, data } = res
                if (code == 200) {
                    this.araeListTree = data.rows
                    this.araeList = handleTree(data.rows)
                } else {
                    this.$message.error(msg)
                }
            })
        },
        getInitData() {
            // 行业
            $api.dictTypeAPI({ dictType: 'crm_company_industry' }).then(res => {
                let { code, msg, data } = res
                if (code == 200) {
                    this.workTypeList = data
                } else {
                    this.$message.error(msg)
                }
            })

        },


    },
}
</script>

<style scoped lang="scss">
.enterpriseInterview-container {
    min-height: 820px;

    .title {
        font-weight: bold;
        font-size: 22px;
        color: #333333;
        margin-bottom: 34px;

        display: flex;
        align-items: center;

        img {
            margin-left: 12px;
            width: 106px;
            height: 36px;
        }
    }
}

.btn {
    margin-top: 70px;
    // width: 168px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    background: #236CE0;
    border-radius: 5px;

    // font-weight: 500;
    font-weight: bold;
    font-size: 18px;
    color: #FFFFFF;
    cursor: pointer;

    &.w168 {
        width: 168px;
    }

    &.pl20 {
        padding: 0 20px;
        width: max-content;
    }
}

::v-deep.el-form {
    display: grid;
    grid-template-columns: 406px 406px;
    grid-column-gap: 84px;
    margin-left: 63px;
    .placeholder {
        .el-input__inner::placeholder {
            color: #333!important;
        }
    }
    .colord28 {
        .el-input__inner::placeholder {
            color: #4575C2!important;
        }
    }
    .columns1 {
        grid-column: 1 / 3;
        /* 第一个元素独占第一列 */
    }

    .el-form-item__label {
        line-height: normal;
        font-size: 16px;
        line-height: 22px !important;
        color: #555;
    }

    .el-cascader,
    .el-input {
        width: 100%;
        color: #333;

        .el-input__inner {
            height: 56px !important;
            line-height: 56px !important;
            border: 1px solid #BEBEBE;
        }
    }

    .el-form-item.is-required:not(.is-no-asterisk) .el-form-item__label::after {
        content: '*';
        color: #FF0000;
        margin-left: 4px;
    }

    .el-form-item.is-required:not(.is-no-asterisk) .el-form-item__label::before {
        display: none;
    }
    .el-input.is-exceed .el-input__suffix .el-input__count {
        color: #BEBEBE;
    }

    .el-select {
        width: 100%;

        // min-width: 300px;
        input::placeholder,
        .el-select__caret {
            color: #4575C2;
        }
    }

}

.imgBox {
    display: flex;

    .imageUrl {
        width: 96px;
        height: 96px;
        margin-right: 20px;
    }
}

::v-deep .avatar-uploader {
    width: 96px;
    height: 96px;
    border-radius: 4px;
    border: 1px solid #BEBEBE;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .el-upload {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        border: 1px solid #BEBEBE;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}

.avatar-uploader-icon {
    font-size: 14px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}

.avatar {
    width: 96px;
    height: 96px;
    display: block;
}

::v-deep .el-input__inner::placeholder {
    color: #333;
}

.btn2 {
    background: #B4B4B4;
    color: #fff;
    cursor: not-allowed;
}
.btnDisAble {
    cursor: not-allowed;
}
</style>
