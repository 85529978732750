<!-- eslint-disable vue/no-mutating-props -->
<template>
    <div>
        <el-dialog
            custom-class="publishDialog"
            :title="title"
            :visible.sync="dialogFormVisible"
            :close-on-click-modal="false"
            @close="closeDialogFormVisible">
            <el-form
                ref="ruleForm"
                class="ruleForm"
                :model="ruleForm"
                label-width="80px"
                label-position="top"
                v-loading="loading"
                :rules="rules">
                <div class="formItemBox">
                    <el-form-item label="工期（整数天）" prop="proTimeLimit">
                      <el-input
                          maxlength="4"
                          show-word-limit
                          type="text"
                          v-model="ruleForm.proTimeLimit"
                          @change="changeProTimeLimit"
                          placeholder="请输入需要工期"></el-input>
                    </el-form-item>

                    <el-form-item label="岗位类型" prop="gwlx" :class="init.workTypesName ? 'placeholder' : 'colord28'">
                        <el-cascader
                                :placeholder="init.workTypesName ? init.workTypesName : '请选择岗位类型'"
                                v-model="ruleForm.gwlx"
                                :options="workTypeList"
                                :props="workTypeListOPtion"
                                filterable
                                @change="changeWorkType"
                                ref="cascader"
                                collapse-tags
                                class="placeholder"></el-cascader>
                    </el-form-item>

                    <el-form-item label="需要人数" prop="employeesNum">
                        <el-input
                            maxlength="4"
                            show-word-limit
                            type="number"
                            :min="0"
                            v-model="ruleForm.employeesNum"
                            placeholder="请输入需要人数"></el-input>
                            <!-- oninput="value=value.replace(/[^\d]/g,'')" -->
                    </el-form-item>
                    <el-form-item label="工作所在地地址" prop="proArea" :class="init.proArea ? 'placeholder' : 'colord28'">
                        <el-cascader
                            :placeholder="init.proArea ? init.proArea : '工作所在地地址'"
                            v-model="ruleForm.proArea"
                            :options="araeList"
                            :props="araeListOPtion"
                            class="placeholder"
                            filterable
                            @change="changeArea"
                            :separator="''"></el-cascader>
                    </el-form-item>

                    <el-form-item label="薪资待遇" prop="money" class="moneyFormItem" >
                        <el-input
                            :class="['moneyInput', moneyRed ? 'redBorder' : '' ]"
                            v-model="moneyInput"
                            @focus="moneyFocus"
                            @input="moneyInputFn"
                            :readonly="true"></el-input>
                        <div class="moneyRed" v-if="moneyRed">请选择期望薪资</div>
                        <div class="moneyRedIcon">*</div>
                        <div class="editMoney" v-if="showEditMoney">
                            <div>
                                <el-input
                                    v-model="minMoney"
                                    placeholder="请输入最小薪资"
                                    type="number"
                                    :min="0"></el-input>
                                <div class="moneyRed" v-if="minMoneyRed">{{ minMoneyText  }}</div>
                            </div>
                            <span>至</span>
                            <div>
                                <el-input
                                    v-model="maxMoney"
                                    placeholder="请输入最大薪资"
                                    type="number"
                                    :min="0"></el-input>
                                <div class="moneyRed" v-if="maxMoneyRed">{{ maxMoneyText  }}</div>
                            </div>
                        </div>
                        <div class="moneyBox" v-show="moneyVisible">
                            <div class="parentList">
                                <div :class="['parentList-item', moneyParentIndex == index ? 'active' : '']"
                                    v-for="(item, index) in moneyListTab" :key="index"
                                    @click="changeParentTitleMoney(item, index)">
                                    {{ item.dictLabel }}
                                </div>
                            </div>
                            <div class="childList">
                                <div class="childList-item" v-for="(itemo, indexo) in moneyList" :key="indexo"
                                    @click="selectMoney(itemo)">
                                    <div>{{ itemo.dictLabel }}</div>
                                </div>
                            </div>
                        </div>
                    </el-form-item>
                    <el-form-item label="岗位有效期" prop="postDeadLineTime">
                        <el-date-picker
                            placeholder="请选择岗位有效期"
                            v-model="ruleForm.postDeadLineTime"
                            type="date"
                            :picker-options="forbiddenTime"
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd"
                            @input="$forceUpdate()"></el-date-picker>
                    </el-form-item>
                    <el-form-item label="性别要求" prop="sexRequire">
                        <el-select v-model="ruleForm.sexRequire" placeholder="请选择性别要求">
                            <el-option v-for="itemO in sexSelectList" :key="itemO.dictValue" :label="itemO.dictLabel"
                                :value="itemO.dictValue"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="求职者测评方式" prop="examType">
                      <el-select v-model="ruleForm.examType" placeholder="请选择求职者测评方式">
                        <el-option v-for="itemO in examTypeSelectList" :key="itemO.dictValue" :label="itemO.dictLabel"
                                   :value="itemO.dictValue"></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="年龄要求" prop="ageRequire" class="moneyFormItem">
                        <el-select
                            v-model="ruleForm.ageRequire"
                            placeholder="请选择年龄要求"
                            @change="changeAge"
                            clearable>
                            <el-option
                                v-for="itemO in ageRequireList"
                                :key="itemO.dictValue"
                                :label="itemO.dictLabel"
                                :value="itemO.dictValue"></el-option>
                        </el-select>
                        <div class="editMoney" v-if="showEditAge">
                            <div>
                                <el-input
                                    v-model="minAge"
                                    placeholder="请输入最小年龄"
                                    type="text"
                                    :min="0"
                                    oninput="value=value.replace(/^(0+)|[^\d]+/g,'')"></el-input>
                                <div  class="moneyRed" v-if="minAgeRed">{{ minAgeText }}</div>
                            </div>
                            <span>至</span>
                            <div>
                                <el-input
                                    v-model="maxAge"
                                    placeholder="请输入最大年龄"
                                    type="text"
                                    :min="0"
                                    oninput="value=value.replace(/^(0+)|[^\d]+/g,'')"></el-input>
                                <div  class="moneyRed" v-if="maxAgeRed">{{ maxAgeText }}</div>
                            </div>
                        </div>
                    </el-form-item>
                    <el-form-item
                        label="福利待遇"
                        prop="fringeBenefits"
                        :class="init.fringeBenefits ? 'placeholder' : 'colord28'">
                        <el-select
                                :placeholder="init.fringeBenefits ? init.fringeBenefits : '请选择福利待遇'"
                                v-model="ruleForm.fringeBenefits"
                                multiple
                                @change="changeFringeBenefits">
                            <el-option
                                v-for="itemO in fringeBenefitsList"
                                :key="itemO.dictValue"
                                :label="itemO.dictLabel"
                                :value="itemO.dictValue"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="持证情况" prop="hasCert">
                        <el-select
                            v-model="ruleForm.hasCert"
                            placeholder="请选择持证情况"
                            @change="hasCertChange"
                            @input="$forceUpdate()">
                            <el-option
                                v-for="itemO in hasCertList"
                                :key="itemO.dictValue"
                                :label="itemO.dictLabel"
                                :value="itemO.dictValue"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="工作内容" prop="proDesc">
                        <el-input
                            class="columns1"
                            type="textarea"
                            v-model="ruleForm.proDesc"
                            resize="vertical"
                            maxlength="2000"
                            show-word-limit
                            rows="4"
                            placeholder="请输入对该岗位的需求描述，如：具体负责什么工作、技能要求等。"></el-input>
                    </el-form-item>
                </div>
                <div class="checkBox">
                    <el-checkbox v-model="configChecked">已阅读并同意</el-checkbox>
                    <span class="configText" @click="readConfig">《招聘协议》</span>
                </div>

                <div class="btn-box">
                    <span v-if="!this.cache" class="cancle" @click="closeDialogFormVisible">取消</span>
                    <span  @click="saveFn" >发布</span>
                    <!-- v-no-more-click -->
                </div>
            </el-form>
        </el-dialog>
        <!-- 招聘协议 -->
        <!-- <el-dialog
            custom-class="configDialog"
            :visible.sync="showConfig"
            :center="true"
            @close="configCheckedFn">
            <div class="config" v-html="config"></div>
            <div class="btn-box">
                <span :class="[isConfirmTxt ? '' : 'cancle']" @click="configCheckedFn">确定 {{ confirmTxt }} </span>
            </div>
        </el-dialog> -->
        <el-dialog
            top="5vh"
            :visible.sync="showConfig"
            width="40%"
            custom-class="configDialog"
            @close="configCheckedFn">
            <div style="width: 100%;height: 75vh;overflow: auto">
                <div v-html="config"></div>
                <!-- <div class="btn-box">
                    <span :class="[isConfirmTxt ? '' : 'cancle']" @click="configCheckedFn">确定 {{ confirmTxt }} </span>
                </div> -->
            </div>
        </el-dialog>
    </div>
</template>
<script>
import {myPositionForm} from './ruleForm'
import * as $api from '@/api/personalCenter'
import {handleTree} from '@/utils/StringUtils'
import moment from 'moment'

export default {
    name: 'commonForm',
    components: {
        // showConfig
    },
    data() {
        return {
            checked: false,
            rules: {
                examType: {
                  required: true,
                  trigger: 'change',
                  message: '请选择测评类型'
                },
                proTimeLimit: { required: true, trigger: 'change', message: '请设置工期时间' },
                gwlx: { required: true, trigger: 'change', message: '请选择岗位类型' },
                employeesNum: [
                    { required: true, trigger: 'blur', message: '请输入需要人数' },
                    //请输入数字
                    {
                        validator: this.validateNumeric,
                        trigger: ['blur', 'input'],
                        // message: '请输入有效的数字',
                    },
                ],
                proDesc: { required: true, trigger: ['change','blur'], message: '工作内容不能为空' },
                proArea: { required: true, trigger: 'change', message: '请选择工作所在地地址' },
                // money: { required: true, trigger: 'change', message: '请选择薪资待遇' },
                postDeadLineTime: { required: true, trigger: 'blur', message: '请选择岗位有效期' },
            },
            ruleForm: {
                // zgzsId: [],  // 资格证书
                gwlx: [],  // 想要干的工作
                ...Object.assign(myPositionForm)
            },
            certNamesOPtion: {
                value: "id",
                label: "dictName",
                children: "children",
            },
            araeListOPtion: {
                value: "areaName",
                label: "areaName",
                children: "children",
            },
            init: {
                workTypesName: '',   // 想干的工作
                proArea: '',  // 想工作的地方
                // certNames: '',      // 证书
                // trainArea: ''
                fringeBenefits: '', // 福利待遇
            },
            // 招聘类型
            postTypeList: [],


            // 薪资待遇
            moneyList: [],  // 薪资
            moneyVisible: false,
            moneyListTab: [],
            moneyParentIndex: 0,
            proUnit: '',
            proUnitName: '',
            moneyTitle: '',
            moneyInput: '',
            money: '', // 期望薪资
            showEditMoney: false,
            minMoney: '',
            maxMoney: '',
            moneyRed: false,
            minMoneyRed: false,
            maxMoneyRed: false,
            // 性别
            sexSelectList: [],
            examTypeSelectList: [],
            // 年龄
            showEditAge: false,
            minAge: '',
            maxAge: '',
            // AgeRed: false,
            minAgeRed: false,
            maxAgeRed: false,

            araeListNodeTree: [],
            araeList: [],  //

            // 岗位类型
            workTypeListNode: [],  // 工种
            workTypeList: [],
            workTypeListOPtion: {  // 工种
                value: "id",
                label: "dictName",
                children: "children",
            },
            ageRequireList: [],
            fringeBenefitsList: [],  // 福利待遇
            hasCertList: [
                { dictValue: '0', dictLabel: '有无都可' },
                { dictValue: '1', dictLabel: '需要' },
            ],
            dialogFormVisible: false,
            config: {}, // 招聘协议
            showConfig: false,
            configChecked: false,

            timer: null,//倒计时
            confirmTxt: 5,//倒计时时间5秒
            isConfirmTxt: false,
            forbiddenTime: {
                disabledDate(time) {
                    return time.getTime() < Date.now() - 8.64e7; // 不可选历史天，可选当前天，可选未来天
                }
            },
            min16: false,
            max60: false,
            isMaxBig: false,
            isMinBig: false,

            isMaxMoneyBig: false,
            isMinMoneyBig: false,
            maxMoneyText: '',
            minMoneyText: '',
            minAgeText: '',
            maxAgeText: '',
            loading: false,
            title: '发布新岗位',
            cache: null,
        };
    },
    watch: {
        minMoney(newValue, oldValue) {
            if (this.moneyInput == '自定义金额') {
                if (newValue) {
                    this.minMoneyRed = false
                    if (this.maxMoney && Number(newValue) > Number(this.maxMoney)) {
                        this.minMoneyRed = true
                        this.minMoneyText = '最小薪资应大于最大薪资'
                    } else {
                        this.minMoneyRed = false
                    }
                } else {
                    this.minMoneyRed = true
                    this.minMoneyText = '最小薪资不能为空'
                }

            }
        },
        maxMoney(newValue, oldValue) {
            if (this.moneyInput == '自定义金额') {
                if (newValue) {
                    this.maxMoneyRed = false
                    if (this.minMoney && Number(newValue) < Number(this.minMoney)) {
                        this.maxMoneyRed = true
                        this.maxMoneyText = '最大薪资应大于最小薪资'
                    } else {
                        this.minMoneyRed = false
                        this.maxMoneyRed = false
                    }
                } else {
                    this.maxMoneyRed = true
                    this.maxMoneyText = '最大薪资不能为空'
                }

            }
        },
        minAge(newValue, oldValue) {
            if (this.ruleForm.ageRequire == -1) {  //自定义年龄
                if (newValue) {
                    this.minAgeRed = false
                    if (Number(newValue) < 16 ) {
                        this.minAgeRed = true
                        this.minAgeText = '最小年龄应在16周岁及以上'
                    }
                    if (Number(this.minAge) > 60) {
                        this.minAgeRed = true
                        this.minAgeText = '最小年龄应在60周岁及以下'
                    }
                    if( (Number(newValue) > Number(this.maxAge)) && this.maxAge) {
                        this.minAgeRed = true
                        this.minAgeText = '最小年龄应小于等于最大年龄'
                    } else {
                        if (this.maxAge) {
                            this.maxAgeRed = false
                        }
                    }
                } else {
                    this.minAgeText = '最小年龄不能为空'
                    this.minAgeRed = true
                    // this.min16 = false
                    // this.isMinBig = false
                }
            }
        },
        maxAge(newValue, oldValue) {
            if (this.ruleForm.ageRequire == -1) {
                if (newValue) {
                    this.maxAgeRed = false
                    if (Number(newValue) > 60) {
                        // this.max60 = true
                        this.maxAgeRed = true
                        this.maxAgeText = '最大年龄应在60周岁及以下'
                    }
                    if (Number(newValue) < 16) {
                        this.maxAgeRed = true
                        this.maxAgeText = '最大年龄应在16周岁及以上'
                    }
                    if ((Number(newValue) < Number(this.minAge)) && this.minAge) {
                        this.maxAgeRed = true
                        this.maxAgeText = '最大年龄应大于等于最小年龄'
                    } else {
                        if (this.minAge) {
                            this.minAgeRed = false
                        }
                    }
                } else {
                    this.maxAgeRed = true
                    this.maxAgeText = '最大年龄不能为空'
                }
            }
        },
    },
    mounted(){
        // 薪资弹窗
        this.$nextTick(()=>{
            document.addEventListener('click',(e)=>{
                let moneyFormItem = document.getElementsByClassName('moneyFormItem')
                if(moneyFormItem && moneyFormItem.length){
                    let isSelf = moneyFormItem[0].contains(e.target)
                    if(!isSelf){
                        this.moneyVisible = false
                    }
                }
            })
        })
    },
    // 在组件生命周期结束时销毁
    beforeDestroy() {
      // TODO remove listener error
        window.removeEventListener('click', () => {}, true)
    },
    methods: {
        validateNumeric(rule, value, callback) {
            const regex = /^\d*$/; // 正则表达式，匹配纯数字
            const regex2 = /^.{0,4}$/
            if (!regex.test(value)) {
                return callback(new Error('请输入数字'));
            }
            if (!regex2.test(value)) {
                return callback(new Error('请填写具体数字，不能超过4位数'));
            }
            callback(); // 如果验证通过，调用callback()表示成功
        },
        closeDialogFormVisible() {
            this.dialogFormVisible = false
            this.moneyVisible = false
            this.moneyRed = false
            this.cache = null;
            this.cancleFn()
            this.$emit('flashData', '关闭')
        },
        // 暂用于智能岗位的更新
        updateCommonForm(data, title= '发布新岗位') {
          this.cache = JSON.parse(JSON.stringify(data));
          this.showCommonForm(data, title, true);
        },
        async showCommonForm(list,title = '发布新岗位', auto = false) {
            // const closeBtn = document.querySelector(".publishDialog .el-dialog__headerbtn");
            // if(closeBtn) {
            //   closeBtn.style.display = 'none'
            // }
            if(!auto) {
              this.cache = null;
              // if(closeBtn) {
              //   closeBtn.style.display = 'inherit';
              // }
            }
            // console.log(list);
            this.title = title
            this.loading = true;
            this.dialogFormVisible = true
            try {
                this.cancleFn()
                await this.getInitData()  // 字典
                await this.getAllWorkTypeDict()
                this.getAreaList()
                if(list) {   // 修改简历
                    this.initForm(list)   // 回显
                }
            } catch (e) {
              console.error(e);
            }
            this.loading = false;

            this.$nextTick(()=>{
                this.$refs.ruleForm.clearValidate();
                // this.$refs.ruleForm.resetFields();
            })
        },
        initForm(list) {
            for (let key in this.ruleForm) {
                this.ruleForm[key] = list[key]
            }
            this.ruleForm.id = list.id
            this.init.proArea = list.proArea
            this.init.fringeBenefits = list.fringeBenefits
            // if(list.proName) {  // 持证一级名称
            //     this.init.certNames = list.proName + ' / ' + list.certSecNames
            // }
            if(list.workTypeName) {  // 工作类型名称
                const firstLevel = this.getWorkTypeFirstLevelData(list.workType);
                this.init.workTypesName = firstLevel.dictName + ' / ' + list.workTypeName
                this.ruleForm.gwlx = [firstLevel.id, list.workType]
                this.ruleForm.workTypeName = list.workTypeName
            }
            if(list.fringeBenefits) {  // 福利待遇
                this.ruleForm.fringeBenefits = list.fringeBenefits.split(',')
            }
            // 兼容 jobDetail 的调用
            let proUnitName = list.proUnitName ?? "";
            if(this.moneyListTab.length > 0) {
              this.moneyListTab.forEach(item=>{
                if(list.proUnit === item.dictValue) {
                  proUnitName = item.dictLabel;
                }
              })
            }
            // 薪资回显
            if(list.minProSpend != '-1' && list.minProSpend !='-1') {
                this.moneyInput = list.minProSpend + ' ~ ' + list.maxProSpend + proUnitName
                this.money = list.minProSpend + '-' + list.maxProSpend
            } else if(list.minProSpend =='-1'){
                this.moneyInput = '面议'
                this.money = '面议'
            } else {
                this.moneyInput = ''
                this.money = ''
            }

            this.proUnit = list.proUnit

            this.ruleForm.ageRequire = list.ageRequire;
            if (!this.ruleForm.ageRequire.includes('-1') && this.ruleForm.ageRequire != '0-0') {
                if (this.ruleForm.ageRequire.indexOf('周岁') == -1) {  // 没单位
                    this.ruleForm.ageRequire = list.ageRequire + '周岁'
                }
            } else if(this.ruleForm.ageRequire == '0-0') {
                this.ruleForm.ageRequire = '0-0'
            } else {
                this.ruleForm.ageRequire = '';
            }
            this.ruleForm.postDeadLineTime =  moment(list.postDeadlineTime).format('YYYY-MM-DD')

            // console.log('回显11', this.ruleForm)
        },
        // 保存
        saveFn() {
            if(!this.moneyInput) {
                this.moneyRed = true   // 只是为了 和其他校验一样展示样式
            }
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    if (!this.configChecked) {
                        return this.$message.warning('请勾选协议')
                    }
                    if (!this.moneyInput) {
                       return this.$message.warning('请选择薪资待遇')
                    }

                    if (this.moneyInput == '自定义金额' && (!this.minMoney || !this.maxMoney)) {
                        if (!this.minMoney && !this.minMoney) {
                            this.minMoneyText = '最小薪资不能为空'
                            this.maxMoneyText = '最大薪资不能为空'
                        }
                        if (!this.minMoney) {
                            this.minMoneyRed = true
                            this.maxMoneyText = '最小薪资不能为空'
                        }
                        if (!this.maxMoney) {
                            this.maxMoneyRed = true
                            this.maxMoneyText = '最大薪资不能为空'
                        }
                        // return
                    }
                    if (this.ruleForm.ageRequire == -1 && (!this.minAge || !this.maxAge)) {
                        if (!this.minAge && !this.maxAge) {
                            this.minAgeText = '最小年龄不能为空'
                            this.maxAgeText = '最大年龄不能为空'
                        }
                        if (!this.minAge) {
                            this.minAgeRed = true
                            this.minAgeText = '最小年龄不能为空'
                        }
                        if (!this.maxAge) {
                            this.maxAgeRed = true
                            this.maxAgeText = '最大年龄不能为空'
                        }
                        // return
                    }
                    if(this.moneyInput == '自定义金额' && (this.maxMoneyRed || this.minMoneyRed || this.moneyRed)) {
                        return
                    }
                    if (this.ruleForm.ageRequire == -1 && (this.minAgeRed || this.maxAgeRed )) {
                        return
                    }

                    if (this.ruleForm.employeesNum == 0) {
                        return   this.$message.warning('人数不能输入0')
                    }
                    const that = this
                    let obj = Object.assign(myPositionForm)
                    for (let key in obj) {
                        obj[key] = that.ruleForm[key] || ''
                    }
                    if (that.ruleForm.proArea instanceof Array) {
                        obj.proArea = that.ruleForm.proArea.length > 0 ? that.ruleForm.proArea.join('') : ''
                    }
                    if (that.ruleForm.fringeBenefits instanceof Array) {
                        obj.fringeBenefits = that.ruleForm.fringeBenefits.length > 0 ? that.ruleForm.fringeBenefits.join(',') : ''
                    }
                    if( that.moneyInput == '面议') {
                            obj.minProSpend = '-1'
                            obj.maxProSpend = '-1'
                            obj.proUnit = ''
                    } else if (that.moneyInput == '自定义金额') {
                        obj.minProSpend = that.minMoney
                        obj.maxProSpend = that.maxMoney
                        obj.proUnit = that.proUnit
                    } else{
                        obj.minProSpend = that.money ? that.money.split('-')[0] : ''
                        obj.maxProSpend = that.money ? that.money.split('-')[1] : ''
                        if(that.money.indexOf('-')) {
                            obj.minProSpend = that.money ? that.money.split('-')[0] : ''
                            obj.maxProSpend = that.money ? that.money.split('-')[1] : ''
                        }
                        obj.proUnit = that.proUnit
                    }

                    // 如果是自定义年龄
                    if (obj.ageRequire == -1) {  // 自定义年龄
                        obj.ageRequire = that.minAge + '-' + that.maxAge
                    } else if (obj.ageRequire.indexOf('周岁') !=-1) { // 这种情况是  新增的时候选自定义,然后编辑的时候没有对年龄做修改,
                        obj.ageRequire = obj.ageRequire.substring(0,obj.ageRequire.indexOf('周'))
                    }

                    let userInfo = this.$store.state.user.userInfo
                    obj.contactPerson = userInfo.nickName
                    obj.contactMobile = userInfo.phonenumber
                    obj.employerContact = userInfo.nickName
                    obj.employerPhoneContact = userInfo.phonenumber
                    obj.infoType = userInfo.isAuthCompany
                    if(this.ruleForm.id) {
                        obj.id = this.ruleForm.id
                    }
                    let sendData = obj;
                    if(this.cache) {
                        sendData = {...JSON.parse(JSON.stringify(this.cache)), ...obj};
                        // console.log("岗位数据更新", sendData);
                    }
                    // this.loading = true
                    $api.workWantInfoPublish(sendData).then(res => {
                        this.loading = false
                        if (res.code == 200) {
                            // this.getInInfo()
                            this.dialogFormVisible = false
                            this.$message.success(res.msg)
                            this.cancleFn()
                            this.$emit('flashData', '刷新')
                        }
                    }).catch(error => {
                        this.dialogFormVisible = false
                        this.loading = false
                    })
                }
            })
        },
        cancleFn() {
            for (let key in this.ruleForm) {
                this.ruleForm[key] = ''
            }
            this.init.proArea = ''
            this.init.workTypesName = ''
            this.moneyParentIndex = 0,
            this.proUnit = '',
            this.moneyTitle = '',
            this.moneyInput = '',
            this.money = ''
            this.configChecked = false

            this.minMoney = ''
            this.maxMoney = ''
            this.moneyRed = false
            this.minMoneyRed = false
            this.maxMoneyRed = false
            this.showEditMoney = false


            this.minAge = ''
            this.maxAge = ''
            this.ageRed = false
            this.minAgeRed = false
            this.maxAgeRed = false
            this.showEditAge = false
            clearInterval(this.timer)
        },
        hasCertChange(value) {
        },
        changeFringeBenefits(value) {
        },
        moneyFocus() {
            this.moneyVisible = true
        },
        moneyInputFn(value) {
            if (value) {
                if (value != '自定义薪资') {
                    this.minMoney = ''
                    this.maxMoney = ''
                    this.minMoneyRed = false
                    this.maxMoneyRed = false
                    this.showEditMoney = false
                }
            } else {
                this.moneyRed = true
            }
        },
        getMoneyList() {
            $api.dictTypeAPI({ dictType: this.moneyListTab[this.moneyParentIndex].dictType }).then(res => {
                if (res.code == 200) {
                    this.moneyList = res.data
                }
            })
        },
        // 切换薪资待遇头部
        changeParentTitleMoney(item, index) {
            this.moneyParentIndex = index
            this.proUnit = item.dictValue
            this.getMoneyList()
        },
        selectMoney(item) {
            this.money = item.dictValue
            this.moneyInput = item.dictLabel
            this.moneyVisible = false
            if (this.moneyInput == '自定义金额') {
                this.showEditMoney = true
            } else {
                this.showEditMoney = false
                this.minMoney = ''
                this.maxMoney = ''
                this.minMoneyText = ''
                this.maxMoneyText = ''
                this.minMoneyRed = false
                this.minMoneyRed = false
            }
            this.moneyRed = false
        },
        // 修改年龄
        changeAge(value) {
            if (value == -1) { // '自定义年龄'
                this.showEditAge = true
            } else {
                this.showEditAge = false
                this.minAgeRed = false
                this.maxAgeRed = false
                this.minAgeText = ''
                this.maxAgeText = ''
                this.minAge = ''
                this.maxAge = ''
            }
        },
        // 获取选择框的数据
        async getInitData() {
          try {
            const res = await  $api.dictTypeAPI({ dictType: 'crm_sex_require,crm_age_type,crm_work_post_type,crm_fringe_benefits,crm_pro_unit,crm_exam_type' });
            const { code, msg, data } = res;
            if (code !== 200) {
              throw new Error(msg);
            }
            let user_sex = []
            let to_piece = []
            let pro_unit = []
            let crm_work_post_type = []
            let crm_fringe_benefits = []
            let crm_age_type = []
            let crm_exam_type = []
            // crm_work_post_type        招聘类型
            // crm_fringe_benefits        福利待遇
            // crm_age_type        年龄需求

            data.map(item => {
              if (item.dictType == 'crm_sex_require') {
                user_sex.push(item)
              }
              if (item.dictType == 'crm_exam_type') {
                crm_exam_type.push(item)
              }
              if (item.dictType == 'crm_pro_unit') {
                pro_unit.push(item)
              }
              if (item.dictType == 'crm_work_post_type') {
                crm_work_post_type.push(item)
              }
              if (item.dictType == 'crm_fringe_benefits') {
                crm_fringe_benefits.push(item)
              }
              if (item.dictType == 'crm_age_type') {
                crm_age_type.push(item)
              }
            })
            this.sexSelectList = user_sex
            this.moneyListTab = pro_unit
            this.moneyList = to_piece
            this.postTypeList = crm_work_post_type
              this.ageRequireList = crm_age_type
            console.log(' this.ageRequireList',  this.ageRequireList)
            this.fringeBenefitsList = crm_fringe_benefits
            this.examTypeSelectList = crm_exam_type

            this.postTypeList.map((item,index)=>{
              if(item.dictLabel == '未开启') {
                this.postTypeList.splice(index, 1)
              }
            })
            let arr = [
              { name: '元/件', dictType: 'crm_salary_type_to_piece' },
              { name: '元/时', dictType: 'crm_salary_type_to_hour' },
              { name: '元/平方', dictType: 'crm_salary_type_to_sq' },
              { name: '元/天', dictType: 'crm_salary_type_to_day' },
              { name: '元/月', dictType: 'crm_salary_type' },
            ]
            arr.forEach(item => {
              this.moneyListTab.forEach((item2, index) => {
                if (item.name == item2.dictLabel) {
                  item2.dictType = item.dictType
                }
                if (item2.dictLabel == '其他') {  // 不展示其他
                  this.moneyListTab.splice(index, 1)
                }
              })
            })
            this.proUnit = this.moneyListTab[0].dictValue
            this.getMoneyList()
          } catch (e) {
            this.$message.error(e.msg);
          }
        },
        //工期
        changeProTimeLimit(val) {
          //更改值之后重新进行表单校验
          this.ruleForm.proTimeLimit = val.replace(/^(0+)|\D+/g,'')
          this.$refs.ruleForm.validateField('proTimeLimit');
        },
        // 工种
        changeWorkType(value) {
            if (value) {
                this.ruleForm.proType = value[0]
                this.ruleForm.workType = value[1]
                this.workTypeListNode.forEach(item => {
                    if (item.id == value[1]) {
                        this.ruleForm.workTypeName = item.dictName
                        this.ruleForm.proName = '招:' + item.dictName
                    }
                    // if (item.id == value[0]) {
                    //     this.ruleForm.proName = item.dictName
                    // }
                })
            }
        },

        // 修改地区
        changeArea(value) {
        },
        // 获取工种
        async getAllWorkTypeDict() {
          try {
            const res = await  $api.getAllWorkTypeDictAPI({});
            let { code, msg, data } = res;
            if(code !== 200) {
              throw new Error(msg);
            }
            this.workTypeListNode = data.rows
            this.workTypeList = handleTree(JSON.parse(JSON.stringify(data.rows)))
          } catch (e) {
            this.$message.error(e.msg);
          }
        },
        // 获取地区
        getAreaList() {
            $api.getAreaListAPI({}).then(res => {
                let { code, msg, data } = res
                if (code == 200) {
                    this.araeListNodeTree = data.rows
                    this.araeList = handleTree(data.rows)
                } else {
                    this.$message.error(msg)
                }
            })
        },
        // 获取协议
        getCommonConfig() {
            let that = this
            $api.getCommonConfigAPI({ sourceType: 6 }).then(res => {
                let { code, msg, data } = res
                if (code == 200) {
                    that.config = data.content || {}
                    //提交报名按钮倒计时
                    that.timer = setInterval(() => {
                        if (that.confirmTxt == 0) {
                            that.confirmTxt = ""
                            that.isConfirmTxt = true
                            clearInterval(that.timer);
                        } else {
                            that.confirmTxt--
                        }
                    }, 1000);

                } else {
                    that.$message.error(msg)
                }
            })
        },
        readConfig() {
            this.showConfig = true
            this.getCommonConfig()
        },
        configCheckedFn() {
            // if (this.isConfirmTxt == false) {
            //     return false;
            // }
            this.configChecked = true
            this.showConfig = false
        },
        // 通过二级岗位 id 获取一级岗位信息
        getWorkTypeFirstLevelData(workTypeSecondId) {
            const data = this.workTypeListNode.find(item => item.id === workTypeSecondId);
            if(!data) {
                return null;
            }
            const parent = this.workTypeList.find(item => item.id === data.parentId);
            return parent ?? null;

        }

    },

}

</script>
<style lang="scss" scoped>
.columns1 {
    //grid-column: 1 / 2;
    grid-column-start: 1;
    grid-row-start: 5;
    grid-column-end: span 3;
    grid-row-end: span 1;
    /* 第一个元素独占第一列 */
}

.el-form-item__label {
    line-height: normal;
    font-size: 16px;
    line-height: 22px !important;
    color: #555;
}


.el-input {
    width: 406px;

    ::v-deep.el-input__inner {
        height: 56px !important;
        line-height: 56px !important;
        border: 1px solid #BEBEBE;
    }
}

.el-select {
    width: 406px;
}



.moneyFormItem {
    position: relative;

    .moneyRedIcon {
        color: #FF0000;
        position: absolute;
        top: -42px;
        left: 70px;
    }
    // 自定义金额
    .editMoney {
        // width: 406px;
        margin-top: 10px;
        // border: 1px solid red;
        display: flex;
        justify-content: space-between;
        .el-input {
            width: 150px!important;
        }
        .el-input__inner {
            height: 40px !important;
            line-height: 40px !important;
            border: 1px solid #BEBEBE;
            font-size: 16px;
        }
    }
}
// 薪资
::v-deep .moneyDialog {
    // border: 1px solid red;
    position: absolute;
    top: 10px;
    // left: 50%;
    .el-dialog__body {
        padding: 0 !important;
    }
    .el-dialog__header {
        padding: 0 !important;
        height: 0 !important;
        &::before {
            display: none!important;
        }
    }
}

.moneyBox {
    // display: flex;
    // position: absolute;
    position: absolute;
    // top: 140px;
    // left: 120%;
    top: 50px;
    z-index: 9999999;
    width: 400px;
    padding: 20px;
    background: #fff;
    box-shadow: 0px 2px 22px 0px rgba(0, 0, 0, 0.14);
    border-radius: 4px;

    .parentList {
        cursor: pointer;
        display: flex;
        justify-content: space-between;

        .parentList-item {
            font-size: 14px;

            // color: #1A67E2;
            &.active {
                font-weight: bold;
                color: #1A67E2;
            }
        }
    }
    .childList {

        .childList-item {
            cursor: pointer;
            font-size: 14px;
            line-height: 40px;
            height: 40px;

            &:hover {
                background: rgba(242, 243, 247, 1);
                color: #333;
                transform: translate(1.1);
            }
        }
    }
}

.moneyRed {
    color: #F56C6C;
    position: absolute;
    bottom: -30px;
    font-size: 12px;
    width: max-content;
}
::v-deep.redBorder {
    border-color: #F56C6C;
    .el-input__inner {
        border-color: #F56C6C!important;
    }
}

// 协议
.checkBox {
    text-align: center;
    margin-top: 43px;

    .el-checkbox__input.is-checked+.el-checkbox__label {
        color: #236CE0;
    }

    .el-checkbox__input.is-checked .el-checkbox__inner,
    .el-checkbox__input.is-indeterminate .el-checkbox__inner {
        color: #236CE0;
        background-color: #236CE0;
        border-color: #236CE0;
        // margin-top: 5px;
    }

    .configText {
        cursor: pointer;

        &:hover {
            color: #236CE0;
        }
    }

}

::v-deep .configDialog {
    max-height: 832px;
    overflow-y: auto;
    // 关闭按钮
    .el-dialog__headerbtn {
        right: 30px;
        top: 30px;

        .el-dialog__close {
            font-size: 28px;
            color: #979797;
        }
    }

    // 取消 发布按钮
    .btn-box {
        display: flex;
        justify-content: center;
        margin-top: 19px;
        margin-bottom: 48px;

        span {
            margin-right: 30px;
            width: 168px;
            height: 50px;
            text-align: center;
            line-height: 50px;
            background: #236CE0;
            border-radius: 5px;
            font-weight: 500;
            font-size: 18px;
            color: #FFFFFF;
            cursor: pointer;

            &.cancle {
                cursor: not-allowed;
                // border: 1px solid rgba(157, 157, 157, .8);
                // color: #333;
                // background: #fff;
            }
        }
    }
}

// 发布岗位
::v-deep .publishDialog {
    z-index: 99999!important;
    width: 1220px;
    max-height: 832px;
    overflow-y: auto;
    border-radius: 8px;
    // 居中
    margin: 0 !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

  // 隐藏滑动条
  -ms-overflow-style: none;  /* IE 10+ */
  scrollbar-width: none;  /* Firefox */

    .el-dialog__header {
        padding: 0 30px 0 40px !important;
        height: 92px;
        line-height: 92px;

        font-weight: 500;
        font-size: 18px;
        color: #333333;

        &::before {
            content: '';
            display: inline-block;
            vertical-align: baseline;
            width: 3px;
            height: 15px;
            background: #3076FF;
            margin: 4px 5px 0 0;
        }
    }

    // 关闭按钮
    .el-dialog__headerbtn {
        right: 30px;
        top: 30px;

        .el-dialog__close {
            font-size: 26px;
            color: #979797;
        }
    }

    .el-dialog__body {
        padding: 0 !important;
    }
}

// 隐藏滑动条
.publishDialog::-webkit-scrollbar {
  width: 0;  /* Chrome, Safari, and Opera */
}

// 弹窗
::v-deep.ruleForm {
    .placeholder {
        .el-input__inner::placeholder {
            color: #333 !important;
        }
    }
    .el-textarea {
        border-radius: 4px;
        //padding: 20px;
        .el-input__count {
          right: 20px;
          bottom: 2px;
           //padding: 2px!important;
           //background: transparent;
        }
    }
    .el-textarea__inner {
        min-height: 300px;
        border-radius: 0;
        // border: none;
        // border-radius: 0;
    }
    .colord28 {
        .el-input__inner::placeholder {
            color: #4575C2 !important;
        }
    }

    box-sizing: border-box;
    // max-height: 900px;
    overflow: auto;

    .formItemBox {

        display: grid;
        grid-template-columns: 328px 328px 328px;
        grid-template-rows: max-content;
        grid-column-gap: 58px;
        grid-row-gap: 25px;
        // width: 1220px;
        padding-left: 51px;

      .el-form-item {
        margin-bottom: 0;
      }

        .el-form-item:last-child{
            grid-column: 1 / 4;
            width: 100%;
        }

        .columns1 {
            grid-column: 1 / 2;
            /* 第一个元素独占第一列 */
          padding: 0;
        }

        .el-checkbox__inner {
            margin-top: -5px;
        }



    }


    // 取消 发布按钮
    .btn-box {
        display: flex;
        justify-content: center;
        margin-top: 19px;
        margin-bottom: 48px;

        span {
            margin-right: 30px;
            width: 168px;
            height: 50px;
            text-align: center;
            line-height: 50px;
            background: #236CE0;
            border-radius: 5px;
            font-weight: 500;
            font-size: 18px;
            color: #FFFFFF;
            cursor: pointer;

            &.cancle {
                border: 1px solid rgba(157, 157, 157, .8);
                color: #333;
                background: #fff;
            }
        }
    }

    .el-form-item.is-required:not(.is-no-asterisk) .el-form-item__label::after {
        content: '*';
        color: #FF0000;
        margin-left: 4px;
    }

    .el-form-item.is-required:not(.is-no-asterisk) .el-form-item__label::before {
        display: none;
    }

    .el-select__caret {
        color: #4575C2;
        margin-right: 4px;
    }

    .el-icon-arrow-up:before {
        content: '\e78f';
        color: #555;
    }

    .el-form-item {

        // margin-left: 58px;
    }

    .el-form-item__label {
        line-height: normal;
        font-size: 16px;
        line-height: 22px !important;
        color: #555;
    }

    .el-input {
        width: 328px;

        ::v-deep.el-input__inner {
            height: 56px !important;
            line-height: 56px !important;
            border: 1px solid #BEBEBE;
        }
    }

    .el-select {
        width: 328px;
    }
}

::v-deep .config {
    padding: 20px;
    line-height: 30px;
    font-size: 18px;

    .el-icon-close {
        font-size: 18px;
    }
}

::v-deep.el-dialog__wrapper {
    z-index: 99999;
}
</style>
