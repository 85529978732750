import request from '@/utils/request'

/**
 * get接口样例
 * @param params
 */
export function getApi(params) {
    return request({
        url: '/login',
        method: 'get',
        params
    },true,true)
}

/**
 * post接口样例
 * @param data
 */
export function postApi(data) {
    return request({
        url: '/login',
        method: 'post',
        data
    },true,true)
}


/**
 * 用户注册
 * @param data
 */
export function userRegisterApi(data) {
    return request({
        url: '/api/auth/register',
        method: 'post',
        data
    })
}

/**
 * 获取图形验证码
 */
export function loginCodeApi() {
    return request({
        url: '/api/code',
        method: 'get'
    })
}
