import { encrypt, decrypt, generateSignature } from '@/utils/cryptoUtil/cryptoUtils';
import axios from 'axios';
/**
 * 网络请求POST封装
 */
export function $ApiPost(url, data) {
    const token = 'eyJhbGciOiJIUzUxMiJ9.eyJ1c2VyX2lkIjoxLCJ1c2VyX2tleSI6ImFhMmNkNmM5LWIwZWUtNDkyYS1iNjA5LTU4NTFkOTA2ZmE2MyIsInVzZXJuYW1lIjoiYWRtaW4ifQ.At08YFmhIajPRNK11Hy2Go11ew_KgtJOijdt-dKOLqRwaqTumw8qL08nqEe5rMmahsyw1zLXO93O98jiAl4B_g';
    const timestamp = new Date().getTime().toString();
    const sign = generateSignature(timestamp);

    const frontData = {
        frontData: data
    }
    // 加密数据
    const encryptedData = encrypt(frontData);
    return axios.post(url, {
        timestamp: timestamp,
        sign: sign,
        token: token,
        data: encryptedData
    }, {
        headers: {
            'AppId': process.env.VUE_APP_APP_ID,
            'version': '1.0',
            'Authorization': `Bearer ${token}`,
        }
    })
        .then(response => {
            // 解密数据并json转化
            const decryptedData = decrypt(response.data.data);
            // console.log('解密后的数据:', decryptedData);

            // 检查是否可能是一个JSON字符串
            if (decryptedData.trim().startsWith('{') && decryptedData.trim().endsWith('}')) {
                try {
                    const data = JSON.parse(decryptedData);
                    return data;
                } catch (error) {
                    throw new Error('Decrypted data is not valid JSON.');
                }
            } else {
                return decryptedData;
                // throw new Error('Decrypted data is not valid JSON.');
            }
        })
        .catch(error => {
            console.error("网络请求或其他错误:", error);
        });

}

/**
 * 网络请求GET封装
 */
export function $ApiGet(url, params,token) {
    // const token = 'eyJhbGciOiJIUzUxMiJ9.eyJ1c2VyX2lkIjoxLCJ1c2VyX2tleSI6ImFhMmNkNmM5LWIwZWUtNDkyYS1iNjA5LTU4NTFkOTA2ZmE2MyIsInVzZXJuYW1lIjoiYWRtaW4ifQ.At08YFmhIajPRNK11Hy2Go11ew_KgtJOijdt-dKOLqRwaqTumw8qL08nqEe5rMmahsyw1zLXO93O98jiAl4B_g';
    const timestamp = new Date().getTime().toString();
    const sign = generateSignature(timestamp);
    // console.log('这是传的参数', params);

    return axios.get(url, {
        params: {
            ...params,
            timestamp,
            sign,
            token
        },
        headers: { // 正确位置设置请求头
            'AppId': process.env.VUE_APP_APP_ID,
            'version': '1.0',
            'Authorization': `Bearer ${token}`,
        }
    })
        .then(response => {
            const decryptedData = decrypt(response.data.data);
            // console.log('解密后的数据:', decryptedData);

            // 检查是否可能是一个JSON字符串
            if (decryptedData.trim().startsWith('{') && decryptedData.trim().endsWith('}')) {
                try {
                    const data = JSON.parse(decryptedData);
                    return data;
                } catch (error) {
                    console.error('解析JSON时出错:', error);
                    throw new Error('Decrypted data is not valid JSON.');
                }
            } else {
                return decryptedData;
                // throw new Error('Decrypted data is not valid JSON.');
            }
        })
        .catch(error => {
            console.error('Error:', error);
            throw error;
        });
}



