<template>
  <div class="container">
    <div class="container_left">
      <div class="case_box">
        <div class="case_box_newWork_btn" @click=publishDialog>
          <span>发布新岗位</span>
        </div>
        <div class="case_box_newChat_btn" @click="getNewChat">
          <img :src="btnIcon" alt=""/>
          <span>创建新会话</span>
        </div>
        <div class="case_box_chatHistory">
          <div class="case_box_chatHistory_divide"></div>
          <span class="case_box_chatHistory_content">历史询问</span>
        </div>
        <div class="case_box_chatHistory_list">
          <div
              class="case_box_chatHistory_item"
              v-for="(item, index) in HistoryLimit.slice(0, 5)"
              :key="index"
              @click="getMessageList(item.sessionBatch)"
          >
            <span>{{ item.modelSearch }}</span>
            <div class="case_box_chatHistory_item_divide"></div>
          </div>
          <div class="case_box_chatHistory_lastItem">保留最近5次对话</div>
        </div>
      </div>
    </div>
    <div class="container_right">
      <div class="chat_box-question--tips">
        <div class="tips_title">
          <div class="tips_title-left">
            <div class="tips_title-left--divide"></div>
            <span>猜你想问</span>
          </div>
          <div class="tips_title-right" @click="reloadTipsBtnList()">
            <img
                :src="reloadBtn"
                alt=""
                class="tips_title-right--btn icon-arrow"
            />
            <span>换一批</span>
          </div>
        </div>
        <div class="chat_box-question-tips--divide"></div>
        <div class="chat_box-question-tips--item">
          <div
              class="chat_box-question-tips--item-box"
              v-for="(item, index) in processedTipsList"
              :key="index"
              @click="postQuestion(item.dialogueContent)"
          >
            <el-tooltip
                class="item"
                effect="dark"
                :content="item.tooltipContent"
                placement="top"
                :disabled="item.tooltipDisabled"
            >
              <span>
                {{ item.displayText }}
              </span>
            </el-tooltip>
            <img :src="rightIcon" alt=""/>
          </div>
        </div>
      </div>
      <div class="chat_box--body">
        <chat-box
            ref="aichatBox"
            :chatMessageList="chatMessageList"
            :proUnitDictType="proUnitDictType"
            box-height="450"
            :serviceType="serviceType"
            @updateWorkDataList="handleUpdateWorkDataList"
            @autoPublish="startAutoPublish"
            @openPublishDialog="openPublishDialog"
        ></chat-box>
      </div>
      <post-message
          @update:isDoing="(value) => (isTyping = value)"
          @message-sent="handleMessage"
          :sessionBatch="sessionBatch"
          :serviceType="serviceType"
          :isDoing="isTyping"
      ></post-message>
    </div>

    <!-- 发布岗位弹窗 -->
    <commonForm ref="commonForm" @flashData="flashData"/>
  </div>
</template>
<script>
import chatBox from "@/components/aiChatBox";
import postMessage from "../../components/postMessage/index.vue";
import {filter} from "@/utils/filters";
import {
  postAiFindWorkerCommunicationAnalyzerApi,
  postAiFindWorkerListApi,
  postAiFindWorkerPresetQuestionApi
} from "@/api/aiFindWorker";
import {getHistoryListApi, getMessageListApi, getQuestionListApi, saveMessgaeApi,} from "@/api/com";
import commonForm from '@/views/personalCenter/components/commonForm';
import * as $api from "@/api/personalCenter";
import {AsyncQueue} from "@/utils/asyncUtils/AsyncQueue";
import {filterRelativeWorkerData} from "@/views/aiFindWorker/dataFilter";
import debounce from "lodash/debounce";

let asyncQueue = new AsyncQueue();

export default {
  name: "aiFindWorker",
  components: {
    chatBox,
    postMessage,
    commonForm
  },
  data() {
    return {
      userInput: "",
      chatMessageList: [
        /**
         * {
         *   showAIPublish?: boolean // 是否显示智能发布按钮
         *   workWantId?: string, // 岗位id
         *   text: string, // 需要呈现的文本
         *   list?: Array, // 匹配到的数据
         * }
         */
      ],
      rotFlag: true,
      rotTipFlag: true,
      rightIcon: require("@/assets/icon/aiFindWork/rightIcon.png"),
      addressIcon: require("@/assets/icon/aiFindWork/addressTips.png"),
      btnIcon: require("@/assets/icon/aiFindWork/add_btn_icon.png"),
      reloadBtn: require("@/assets/icon/aiFindWork/reload_btn.png"),
      questionHistoryList: [],
      questionTipsList: [],
      isTyping: false,
      history: [],
      pageNum: 0,
      historyPageNum: 0,
      sessionBatch: null, //会话批次,用来判断是新会话,还是旧会话,如果是新会话就不用传
      serviceType: "",
      replyContent: null,
      proUnitDictType: [],
      total: 0,
      mode: 0, // 区分对话模式（0-求职者主动寻找，1-AI预设问题）
      presetSummaryList: [],
      presetQuestionList: [],
      currentPresetQuestion: null,
      preQuesCompletedTips: "已为您智能生成了岗位信息。",
      workWantId: "",
      workerInfoListQueries: [],
      isNewSession: "0",
    };
  },
  computed: {
    HistoryLimit() {
      return this.questionHistoryList.map((item) => {
        const maxLength = 14; // 设置最大长度
        if (item.modelSearch.length > maxLength) {
          return {
            ...item,
            modelSearch: item.modelSearch.substring(0, maxLength) + "...",
          };
        }
        return item;
      });
    },
    processedTipsList() {
      return this.questionTipsList.map((item) => {
        const processedText = this.truncateText(item.dialogueContent, 16);
        return {
          ...item,
          displayText: processedText.text,
          tooltipContent: processedText.tooltip ? item.dialogueContent : "",
          tooltipDisabled: !processedText.tooltip, // 禁用工具提示，除非文本被截断
        };
      });
    },
  },
  mounted() {
    window.addEventListener('load', () => {
      const currentRoute = this.$router.currentRoute;
      if (currentRoute.query && Object.keys(currentRoute.query).length > 0) {
        this.$router.push({
          path: currentRoute.path, query: {
            serviceType: 1
          }
        });
      }
    });
    // this.run();
  },
  created() {
    if (this.$route.query.context) {
      this.queryContent = this.$route.query.context;
      this.postQuestion(this.queryContent);
    }
    // 检查路由查询参数中是否有 serviceType
    if (this.$route.query.serviceType) {
      this.serviceType = this.$route.query.serviceType;
    }
    asyncQueue = new AsyncQueue();
    this.getQuestionList();
    this.getHistoryList();
  },
  methods: {
    filter,
    //创建新对话
    getNewChat() {
      this.getHistoryList();
      this.chatMessageList = [];
      this.sessionBatch = null;
      this.currentPresetQuestion = null;
      this.workWantId = "";
      this.mode = 0;
      this.workerInfoListQueries = {};
      this.isNewSession = '0';
    },
    //限制字数，用...替代
    truncateText(text, maxLength) {
      if (text.length > maxLength) {
        return {text: text.substring(0, maxLength) + "...", tooltip: true};
      }
      return {text: text, tooltip: false};
    },
    //四个问题列表换一批
    refreshTips() {
      this.getQuestionList();
    },
    publishDialog() {
      // this.dialogFormVisible = true
      this.$refs.commonForm.showCommonForm()
    },
    async openPublishDialog(item) {
      const workWantId = item?.content?.workWantId ?? this.workWantId;
      try {
        const res = await $api.getCompanyWorkWantInfoAPI({ id: workWantId });
        const { code, msg, data } = res
        if (code !== 200) {
          throw new Error(msg);
        }
        if (data && data.delFlag === 2) {
          this.$message.warning('岗位已取消');
        } else {
          this.$refs.commonForm.updateCommonForm(data, '发布新岗位')
        }
      } catch (e) {
        console.error("获取岗位失败!", e);
        this.$message.error('获取岗位失败!')
      }
    },
    // 发布成功之后 我的岗位列表需要刷新  如果这里需要刷页面  可以在里面写方法
    flashData() {
      console.log('发布岗位成功')
    },
    // serviceType : 1-AI招工，2-AI找活，3-工作伙伴AI助手，4-权益守护AI助手，5-智能面试
    getQuestionList() {
      this.pageNum += 1;

      const data = {
        pageNum: this.pageNum,
        pageSize: 4,
        serviceType: "1",
      };

      if (this.pageNum === 1) {
        getQuestionListApi(data)
            .then((res) => {
              this.total = res.data.total;
              this.questionTipsList = res.data.rows;
            })
            .catch((error) => {
              console.error("错误信息:", error);
            });
      } else if (this.pageNum > 1 && this.total % this.pageNum*4 === 0) {
        getQuestionListApi(data)
            .then((res) => {
              this.questionTipsList = res.data.rows;
            })
            .catch((error) => {
              console.error("错误信息:", error);
            });
      } else if (this.total % this.pageNum*4 !== 0) {
        console.log('1111')
        this.pageNum = 0;
        getQuestionListApi(data)
            .then((res) => {
              this.questionTipsList = res.data.rows;
            })
            .catch((error) => {
              console.error("错误信息:", error);
            });
      }
    },
    transformMessages(historyMessages) {
      let messageId = 1;
      const transformMessage = (message) => {
        const result = [];
        // 处理用户消息
        if (message.modelSearch) {
          const content = message.modelSearch;
          result.push({
            id: messageId++, //分配ID递增
            type: "text",
            user: 'user',
            content: content ?? "",
          });
        }
        // 处理AI消息
        if (message.modelRecover) {
          let content = message.modelRecover;

          try {
            content = JSON.parse(content);
          } catch (e) {
            content = {
              text: "暂未找到合适的结果，您可尝试输入:工种类型、年龄、性别、工作地点等信息进行重试。"
            };
          }
          const item = {
            id: messageId++,
            type: "text",
            user: 'robot',
            content: content,
          }
          message.isUseGenJob === 1 ? result.unshift(item) : result.push(item);
        }
        return result;
      };
      return historyMessages
        .map(transformMessage)
        .reduce((acc, cur) => [...acc, ...cur], []) ?? [];
    },
    // 点击历史记录标题，获取历史消息
    async getMessageList(sessionBatch) {
      this.isTyping = false;
      this.sessionBatch = sessionBatch; //存储批次,发送消息需要带
      this.workerInfoListQueries = {};

      const data = {
        sessionBatch: sessionBatch,
        serviceType: "1",
      };
      try {
        this.chatMessageList = [];
        this.$refs.aichatBox.updateIsLoading();
        // 查询历史记录
        const res = await getMessageListApi(data);
        // 解析历史记录
        const list = this.transformMessages(res.data);
        this.chatMessageList = list;
        this.safeScrollBottom();
      } catch (e) {
        console.error("错误信息:", e);
      }
    },
    postQuestion(question) {
      this.mode = 0;
      const messageData = {
        message: question,
      };
      this.handleMessage(messageData);
    },
    handleUpdateWorkDataList: debounce(function(item){
      const index = this.chatMessageList.findIndex(msg => msg.id === item.id);
      if(index === -1) {
        console.error("当前消息未找到", item);
        return;
      }
      const userQuery= this.chatMessageList.slice(0, index).findLast(item => item.user === "user");
      if(!userQuery) {
        console.error("当前消息的用户提问未找到", item);
        return;
      }
      const dataQuery = {
        ...this.getOrCreateWorkInfoListQuery(item.id),
        keyword: userQuery.content,
      };

      // console.log(dataQuery);
      postAiFindWorkerListApi(dataQuery).then((res) => {
        // console.log(res);
        // console.log(JSON.stringify(res.data.rows.map(({id}) => id), null, 2));
        if (res.data.rows) {
          this.chatMessageList[index].content.list = res.data.rows;
        }
      });
    }, 500),
    //接收<post-message>组件用户发送的消息
    handleMessage(messageData) {
      this.isTyping = true;
      // localStorage.getItem('sensitiveWords')与message核验，如果有敏感词，则return 并提示有敏感词，并且做处理
      const sensitiveWords = JSON.parse(
          localStorage.getItem("sensitiveWords") || "[]"
      );
      let sensitiveMessage = messageData.message;
      let foundSensitiveWord = false;

      sensitiveWords.forEach((word) => {
        // 使用正则表达式确保匹配完整单词，避免部分匹配
        // const regex = new RegExp(`\\b${word}\\b`, "gi");
        // if (regex.test(sensitiveMessage)) {
        //   foundSensitiveWord = true;
        //   sensitiveMessage = sensitiveMessage.replace(regex, "***");
        // }

        const regex = new RegExp(`\\b${word}\\b`, "gi");
        let a = sensitiveMessage.indexOf(word);
        // 如果匹配到敏感词，则替换为***
        if (a>=0) {
          foundSensitiveWord = true;
          sensitiveMessage = sensitiveMessage.replace(regex, "***");
        }
      });

      if (foundSensitiveWord) {
        this.isTyping = false;
        this.$message.error("您的消息包含敏感词，请重新编辑后发送。");
        return;
      }

      // 动态分配新消息的 ID
      const newId = this.chatMessageList.length + 1;
      const userMessage = {
        id: newId,
        type: "text",
        user: "user",
        content: messageData.message,
      };
      // 将新消息添加到 chatMessageList
      this.chatMessageList.push(userMessage);
      // 强制 Vue 立即更新 DOM
      this.$nextTick(() => {
        this.safeScrollBottom();
      });

      // 添加空的robot消息作为加载占位符
      const loadingMessage = {
        id: this.getNewId(),
        type: "text",
        user: "robot",
        content: "",
        isLoading: true,
      };
      this.chatMessageList.push(loadingMessage);
      // 强制 Vue 立即更新 DOM
      this.$nextTick(() => {
        this.safeScrollBottom();
      });
      this.sendToAIAndGetReply(messageData.message, messageData.sessionBatch);
    },
    safeScrollBottom() {
      if (
          this.$refs.aichatBox &&
          typeof this.$refs.aichatBox.scrollToBottom === "function"
      ) {
        this.$refs.aichatBox.scrollToBottom();
      }
    },
    // 获取历史消息记录
    getHistoryList() {
      this.historyPageNum = 1;
      const data = {
        pageNum: this.historyPageNum,
        pageSize: 5,
        serviceType: "1",
      };
      getHistoryListApi(data)
        .then((res) => {
          this.questionHistoryList = res.data.rows;
        })
        .catch((error) => {
          console.error("错误信息:", error);
        });
    },
    //保存消息数据到后端
    saveHistory(saveData) {
      const data = {
        serviceType: "1",
        fileType: "0",
        modelSearch: saveData.userContent,
        modelRecover: saveData.robotContent,
        modelType: "1",
        isUseGenJob: this.mode === 1 ? 1 : 0, // 是否首次使用生成简历（0-否，1-是）
      };
      // 如果 sessionBatch 有值，则添加到请求数据中
      if (this.sessionBatch) {
        data.sessionBatch = this.sessionBatch;
      }
      const task = () => {
        return saveMessgaeApi(data)
          .then((res) => {
            // console.log("响应:", res);
            if (this.sessionBatch == null && res.data.sessionBatch) {
              this.sessionBatch = res.data.sessionBatch;
            }
            if(this.isNewSession === '0') {
              this.isNewSession = "1";
              this.getHistoryList();
            }
          })
          .catch((error) => {
            console.error("错误信息:", error);
          }).finally();
      };
      asyncQueue.enqueue(task);
    },
    startAutoPublish() {
      this.mode = 1;
      this.getAIFindWorkerPresetQuestion();
    },
    // 获取 AI 找活预设问题，用于发布新岗位
    async getAIFindWorkerPresetQuestion() {
      this.chatMessageList.push(this.createLoadingMessage());
      const getPresetQuestionData = (res) => {
        return res.data.rows[0]
      }
      let data;
      try {
        data = getPresetQuestionData(await postAiFindWorkerPresetQuestionApi());
      } catch (e) {
        console.error("获取AI招工预设问题失败", e);
        return;
      }

      // console.log("这是AI招工预设问题：", data['fir_level_list'].map(({preColumName, preColumProp})=>({preColumName, preColumProp})));
      // console.log(data);
      // 找到最后一个isLoading的robot消息，并更新它
      const lastLoadingMsgIndex = this.chatMessageList.findLastIndex((m) => m.user === "robot" && m.isLoading);
      if(lastLoadingMsgIndex === -1) {
        return;
      }
      // 获取总述句的预设问题列表
      this.presetSummaryList = data['fir_level_list']
      // 获取单字段的预设问题列表
      this.presetQuestionList = data['sec_level_list'];
      if(this.presetSummaryList.length > 0) {
        this.currentPresetQuestion = this.presetSummaryList[0];
      } else {
        this.currentPresetQuestion = this.presetQuestionList[0];
      }
      this.updateLoadingChatMessage(lastLoadingMsgIndex, {text: this.currentPresetQuestion.preQuContent});
      this.safeScrollBottom();
    },
    // 发送消息到AI并获取回复
    async sendToAIAndGetReply(userMessageContent, sessionBatch) {
      if(this.mode === 1) {
        // 用户处于预设问题情境下的回答处理
        await this.sendPresetToAIAndGetReply(userMessageContent, sessionBatch);
      } else {
        // 用户处于默认情境下的提问处理
        await this.sendQuestionToAIAndGetReply(userMessageContent, sessionBatch);
      }
    },
    // 发送预设问题的回答给服务器并获取回复
    async sendPresetToAIAndGetReply(userMessageContent, sessionBatch) {
      // 获取当前预设问题，并置空，为下一个预设问题做准备
      const currentPresetQuestion = this.currentPresetQuestion;
      this.currentPresetQuestion = null;
      const sendData = {
        record: userMessageContent,
        session_id: !sessionBatch ? this.sessionBatch : sessionBatch ? sessionBatch : "-1",
        useGenJob: 1, // 区分是否预设问题（1-是）
      }
      if(currentPresetQuestion && currentPresetQuestion.preQuLevel === '2') {
        sendData["field_dict"] = currentPresetQuestion.preQuType;
        const filter = ["招聘类型", "福利待遇", "持证情况", "工作内容"];
        if(filter.includes(currentPresetQuestion.preColumName)) {
          sendData["field_value"] = userMessageContent;
        }
      }
      // 找到最后一个isLoading的robot消息，并更新它
      const lastLoadingMsgIndex = this.chatMessageList.findIndex(
          (m) => m.user === "robot" && m.isLoading
      );
      try {
        const res = await postAiFindWorkerCommunicationAnalyzerApi(sendData);
        // console.log(`招工预设问题 ${currentPresetQuestion.preColumName} 回答反馈：`,res);
        if(res.code !== 200) {
          throw new Error(res.msg);
        }

        this.saveHistory({
          userContent: userMessageContent,
          robotContent: {text: currentPresetQuestion.preQuContent},
        });


        const data = res.data;
        // 如果用户还未完成必要问题的回答，将继续进行询问
        if(data.completed === false) {
          let requiredMissing = data.requiredMissing.length !== 0 ? data.requiredMissing : data.optionalMissing;
          if(requiredMissing.length === 0) {
            throw new Error("招工预设未完成，但已无需要询问事项！");
          }
          const required = requiredMissing[0];
          const presetQuestionFilter = this.presetQuestionList.filter((item) => item.preQuType === required);
          if(presetQuestionFilter.length === 0) {
            throw new Error("未找到需要询问的招工事项！" + required);
          }
          const presetQuestion = presetQuestionFilter[0];
          this.currentPresetQuestion = presetQuestion;
          // console.log("当前问题：", presetQuestion.preColumName);
          this.updateLoadingChatMessage(lastLoadingMsgIndex, {text: presetQuestion.preQuContent});
        } else {
          // 如果用户已完成必要问题的回答，显示跳转至岗位详情界面的按钮
          // console.log("招工预设问题回答完成", data);
          // 保存当前发布的岗位 id
          this.workWantId = data.workWantId;

          let content = {
            workWantId: data.workWantId,
            text: `${this.preQuesCompletedTips}`,
          };
          // 预设模式下，将此条信息归类为问题
          this.saveHistory({
            userContent: "",
            robotContent: content,
          });

          this.updateLoadingChatMessage(lastLoadingMsgIndex, content,{jumpPath: "1"});
          // 退出预设模式
          this.mode = 0;
        }
      } catch (e) {
        console.error(`招工预设问题 ${currentPresetQuestion.preColumName} 回答反馈失败：`, e);
        this.updateLoadingChatMessage(lastLoadingMsgIndex, {text: "无法连接服务器，请检查网络连接状态。"});
      } finally {
        this.isTyping = false;
      }
    },
    // 发送用户提问给服务器并获取回复
    async sendQuestionToAIAndGetReply(userMessageContent, sessionBatch) {
      const sendData = {
        pageNum: 1,
        pageSize: 6,
        keyword: userMessageContent,
      };

      let replyContent = {
        text: "暂未找到合适的结果，您可尝试输入:工种类型、年龄、性别、工作地点等信息进行重试。"
      };
      // 找到最后一个isLoading的robot消息，并更新它
      const lastLoadingMsgIndex = this.chatMessageList.findIndex(
          (m) => m.user === "robot" && m.isLoading
      );
      try {
        const res = await postAiFindWorkerListApi(sendData);
        // console.log("这是ai招工问答数据", res.data.rows);
        if(lastLoadingMsgIndex !== -1) {
          const item = this.chatMessageList[lastLoadingMsgIndex];
          this.workerInfoListQueries[item.id] = {
            pageNum: 1,
            pageSize: 6,
            total: res.data.total
          }
        }
        if (res.data.rows.length > 0) {
          replyContent = {
            text: res.isExactMatch === 1
                ? "已为您找到了一些相关的空闲工人，若您不满意可以"
                : "暂未找到合适的结果，为您推荐以下您可能感兴趣的工人，若您不满意可以",
            list: filterRelativeWorkerData(res.data.rows),
          }
        }
      } catch (e) {
        console.error("ai招工错误信息:", e);
      }
      if (lastLoadingMsgIndex !== -1) {
        replyContent['showAIPublish'] = true;
        this.saveHistory({
          userContent: userMessageContent,
          robotContent: replyContent,
        });
        this.updateLoadingChatMessage(lastLoadingMsgIndex, replyContent);
        this.safeScrollBottom();
      }
      this.isTyping = false;
    },
    updateLoadingChatMessage(pos, content, options) {
      options = options ? options : {};
      const updatedMessage = {
        ...this.chatMessageList[pos],
        content: JSON.parse(JSON.stringify(content)),
        isLoading: false, // 更新为非加载状态
        ...options, // 额外参数（例如职位路径）
      };
      this.$set(this.chatMessageList, pos, updatedMessage);
      if(this.mode === 0) {
        this.safeScrollBottom();
      }
    },
    getNewId() {
      return this.chatMessageList.length + 1;
    },
    createLoadingMessage(user = 'robot') {
      return {
        id: this.getNewId(),
        type: "text",
        user: user,
        content: [],// 空内容
        isLoading: true,
      };
    },
    reloadBtnList() {
      let element = document.querySelector(".case_box-work-title--right_btn");
      if (this.rotFlag) {
        element.classList.add("icon-arrow-rotate");
      } else {
        element.classList.remove("icon-arrow-rotate");
      }
      this.rotFlag = !this.rotFlag;
    },
    reloadTipsBtnList() {
      let element = document.querySelector(".tips_title-right--btn");
      if (this.rotTipFlag) {
        element.classList.add("icon-arrow-rotate");
      } else {
        element.classList.remove("icon-arrow-rotate");
      }
      this.rotTipFlag = !this.rotTipFlag;
      this.getQuestionList();
    },
    getOrCreateWorkInfoListQuery(id) {
      if(!this.workerInfoListQueries[id]) {
        this.workerInfoListQueries[id] = {
          pageNum: 1,
          pageSize: 6,
          total: 0
        }
      }
      const {total, pageSize} = this.workerInfoListQueries[id];

      let pageNum = this.workerInfoListQueries[id].pageNum + 1
      if(total > 0 && total < pageNum * pageSize) {
        pageNum = 1
      }
      this.workerInfoListQueries[id].pageNum = pageNum
      return {...this.workerInfoListQueries[id]};
    },
    run(){
      const testMap = {
        "一级第一个问题": "我要招五个在贵阳市的工地普工的人，为期30天, 工资500一天",
        "招聘类型": "灵活用工",
        "岗位类型": "普工",
        "工作地点": "贵州省贵阳市",
        "薪资待遇": "面议",
        "岗位截止日期": "1个月",
        "招聘人数": "5",
        "性别要求": "不限",
        "年龄要求": "不限",
        "福利待遇": "五险一金",
        "持证情况": "无要求",
        "工作内容": "自己看着办",
        "工期": "30",
      };
      const summary = "我要招五个在贵阳市干普工的人，为期30天";
      this.isTyping = false;
      setInterval(()=>{
        if(!this.mode === 1) {
          return;
        }
        if(this.isTyping || !this.currentPresetQuestion) {
          return;
        }
        this.isTyping = true;
        if(!this.currentPresetQuestion.preColumName) {
          this.handleMessage({
            message: summary
          });
          return;
        }
        this.handleMessage({
          message: testMap[this.currentPresetQuestion.preColumName]
        });
      }, 3000);
    }
  },
};
</script>
<style lang="scss" scoped>
.container {
  width: 1840px;
  display: flex;
  margin-top: 27px;

  .container_left {
    width: 360px;

    .case_box {
      width: 360px;
      /* height: 450px; */
      border-radius: 12px;
      margin-bottom: 20px;
      background-color: #ffffff;
      display: flex;
      flex-direction: column;
      align-items: center;

      .case_box_newWork_btn {
        width: 308px;
        height: 44px;
        cursor: pointer;
        margin-top: 40px;
        background: #52c4a4;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 16px;
          height: 16px;
        }

        span {
          margin-left: 3px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 16px;
          color: #ffffff;
        }
      }

      .case_box_newChat_btn {
        width: 308px;
        height: 44px;
        cursor: pointer;
        margin-top: 12px;
        background: linear-gradient(113deg, #60a4ff 0%, #1957ff 100%);
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 16px;
          height: 16px;
        }

        span {
          margin-left: 3px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 16px;
          color: #ffffff;
        }
      }

      .case_box_chatHistory_list {
        width: 308px;
        background: #f1f5ff;
        border-radius: 6px;
        margin-bottom: 13px;

        .case_box_chatHistory_item {
          height: 51px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          margin-left: 11px;
          margin-right: 11px;
          font-size: 14px;
          color: #333333;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-end;
          cursor: pointer;

          span {
            margin-left: 20px;
            margin-top: 13px;
            margin-bottom: 13px;
          }

          .case_box_chatHistory_item_divide {
            width: 286px;
            height: 1px;
            background-color: #979797;
            opacity: 0.22;
          }
        }

        .case_box_chatHistory_lastItem {
          width: 100%;
          margin-top: 12px;
          margin-bottom: 16px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 10px;
          color: #8d8d8d;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .case_box-work-title {
        width: 308px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .case_box-work-title--left {
          display: flex;
          align-items: center;
          margin-top: 18px;

          .case_box-work-title--left_divide {
            width: 3px;
            height: 15px;
            background: #3076ff;
          }

          span {
            margin-left: 5px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 18px;
            color: #333333;
            font-style: normal;
          }
        }

        .case_box-work-title--right {
          margin-top: 20px;
          display: flex;
          align-items: center;
          cursor: pointer;

          img {
            width: 12px;
            height: 12px;
            margin-right: 4px;
          }

          span {
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #333333;
            font-style: normal;
          }
        }
      }

      .case_box-work-list {
        width: 320px;
        margin-top: 16px;

        .case_box-work-list--item {
          width: 320px;
          height: 70px;
          display: flex;
          margin-bottom: 13px;
          justify-content: space-between;
          border-bottom: 1px solid #dde0e8;

          .case_box-work-list--item-left {
            .work-title {
              font-family: PingFangSC, PingFang SC;
              font-weight: bold;
              font-size: 14px;
              color: #555555;
            }

            .work-tag-box {
              display: flex;
              margin-top: 4px;

              .work-tag {
                margin-right: 3px;
                color: #ffffff;
                border-radius: 1px;
                padding: 2px 7px;
                background-color: #52c4a4;
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                font-size: 9px;
              }
            }

            .work-address {
              margin-top: 5px;

              img {
                width: 6px;
                height: 8px;
              }

              span {
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                font-size: 10px;
                color: #888888;
              }
            }
          }

          .case_box-work-list--item-right {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: space-between;

            .salary_box {
              margin-top: 2px;

              .salary_box-num {
                font-family: DINAlternate, DINAlternate;
                font-weight: bold;
                font-size: 14px;
                color: #555555;
              }

              .salary_box-unit {
                font-family: PingFangSC, PingFang SC;
                font-weight: 500;
                font-size: 8px;
                color: #555555;
              }
            }

            .record_btn {
              margin-bottom: 11px;
              display: flex;
              cursor: pointer;
              align-items: center;
              justify-content: center;
              width: 61px;
              height: 22px;
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              font-size: 10px;
              color: #3076ff;
              border-radius: 11px;
              border: 1px solid #3076ff;
            }
          }
        }
      }
    }

    .case_box_chatHistory {
      width: 308px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      margin-top: 40px;
      margin-bottom: 14px;
      font-size: 18px;
      color: #333333;
      display: flex;
      align-items: center;

      .case_box_chatHistory_divide {
        width: 3px;
        height: 15px;
        background: #3076ff;
        margin-right: 5px;
      }
    }
  }

  .container_right {
    width: 1440px;
    margin-bottom: 55px;
    margin-left: 26px;

    .chat_box-question--tips {
      width: 1440px;
      height: 166px;
      display: flex;
      flex-direction: column;
      align-items: center;
      background: #ffffff;
      border-radius: 12px;

      .tips_title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: calc(100% - 54px);
        margin-top: 20px;
        margin-left: 24px;
        margin-right: 30px;

        .tips_title-left {
          display: flex;
          align-items: center;

          .tips_title-left--divide {
            width: 3px;
            height: 15px;
            margin-right: 5px;
            background: #3076ff;
          }

          span {
            font-family: PingFangSC, PingFang SC;
            font-weight: bold;
            font-size: 18px;
            color: #333333;
          }
        }

        .tips_title-right {
          display: flex;
          align-items: center;
          cursor: pointer;

          img {
            width: 19px;
            height: 16px;
          }

          span {
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 17px;
            color: #333333;
          }
        }
      }

      .chat_box-question-tips--divide {
        width: 1386px;
        height: 1px;
        background-color: #979797;
        /* border: 1px solid #979797; */
        opacity: 0.2;
        margin-top: 16px;
      }

      .chat_box-question-tips--item {
        width: calc(100% - 54px);
        display: flex;
        justify-content: flex-start;

        .chat_box-question-tips--item-box {
          width: 328px;
          height: 60px;
          background: #f1f5ff;
          border-radius: 8px;
          margin-top: 21px;
          margin-right: 22px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          span {
            font-family: PingFangSC, PingFang SC;
            font-weight: bold;
            font-size: 16px;
            color: #333333;
          }

          img {
            width: 12px;
            height: 10px;
            margin-left: 4px;
          }
        }
      }
    }

    .chat_box--body {
      width: 100%;
      margin-top: 36px;
    }
  }
}

.icon-arrow {
  transition: all 0.3s ease-out;
}

.icon-arrow-rotate {
  transform: rotate(360deg);
}
</style>
