import request from '@/utils/request'

/**
 *  // AI招工对话问答
 * @param data
 */
export function postAiFindWorkerListApi(params) {
    // const url = `/api/crm/web/jobWant/aiFindWorker?pageNum=${data.pageNum}&pageSize=${data.pageSize}`;
    // return request.post(url, data, { shouldEncrypt: true, shouldDecrypt: true })
    return request.get('/api/crm/web/jobWant/aiFindWorker', { shouldEncrypt: false, shouldDecrypt: true, params })
}

/**
 * AI 招工预设问题
 */
export function postAiFindWorkerPresetQuestionApi() {
    const url = `/api/crm/web/jobWant/aiFindWorkerRetPreQu`;
    return request.post(url, {}, { shouldEncrypt: true, shouldDecrypt: true });
}

/**
 * AI 招工预设问题对话分析
 * 上传用户回答，返回是否完成预设问题的回答，并附带剩余需要回答的问题列表
 * @param data
 */
export function postAiFindWorkerCommunicationAnalyzerApi(data)  {
    const url = '/api/crm/web/workWantAnalyzer/dialogue';
    return request.post(url, data, { shouldEncrypt: true, shouldDecrypt: true });
}
