/**
 * 处理相关工人列表数据
 * @param list 工人数据列表
 */
export function filterRelativeWorkerData(list) {
    return list.map((item) => ({
        id: item.id,
        // 姓名
        userName: item.userName,
        // 性别
        sex: item.sex,
        // 求职岗位
        workTypesName: item.workTypesName,
        // 头像
        avatar: item.avatar,
    }));
}
