// rsaService.js
import JSEncrypt from 'jsencrypt';
import store from '@/store'

export default {
    /**
     * 使用公钥加密数据
     * @param {string} plaintext 待加密的明文
     * @returns {string} 加密后的密文
     */
    encryptWithPublicKey(plaintext) {
        const encryptor = new JSEncrypt();
        encryptor.setPublicKey(store.getters.RSAKey);
        return encryptor.encrypt(plaintext);
    }
};

