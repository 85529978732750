import Layout from "@/layout";

const hrStatuteRouter = {
  path: '/hrStatute',
  component: Layout,
  redirect: '/hrStatute/index',
  name: 'hrStatute',
  children: [
    {
      path: 'index',
      component: () => import('@/views/hrStatute/index'),
      name: 'hrStatuteIndex',
    }
  ]
}

export default hrStatuteRouter
