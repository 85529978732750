<template>
    <div class="main">
        <div class="main-topBox">
            <div class="top-title">已报名岗位</div>
            <work-card v-show="workList.length > 0" :workList="workList" class="card"></work-card>
            <no-data v-if="workList.length === 0"></no-data>
        </div>
        <div class="main-bottomBox">
            <div class="top-title">
              为您推荐
              <div class="hrChat_box_title--flushBtn"  @click="reloadBtnList()">
                <img :src="flushBtn" alt=""  class="hrChat_box_title--flushBtn-img icon-arrow">
                <span>换一换</span>
              </div>
            </div>
            <work-card :workList="suggestWorkList" class="card"></work-card>
        </div>
    </div>
</template>

<script>
import workCard from '@/components/personalCenter/workCard.vue'
import noData from '@/views/aiInterview/components/noData.vue'
import { jobApplyListAIApi  } from '@/api/aiInterView'
import {dictTypeApi, hotWorkListApi} from "@/api/aiFindWork";
// import titleBar from './components/titleBar.vue'
import { filter } from "@/utils/filters";
export default {
    name: 'leftIndex1',
    components: {
        workCard,
        noData
        // titleBar,
    },
    data() {
        return {
          rotFlag: true,
          flushBtn: require('@/assets/hr-question/right-header-refresh.png'),
          workList: [],
          proUnitDictType: [],
          suggestWorkList: [],
          hotWorkForm: {
            pageNum: 1,
            pageSize: 2
          },
        };
    },
    computed: {},
    mounted() {
      this.getDictTypeList()
      this.getJobApplyList()
      this.getSuggestWorkList()
    },
    methods: {
      filter,
      //获取字典
      getDictTypeList() {
        dictTypeApi({ dictType: "crm_pro_unit" }).then((res) => {
              this.proUnitDictType = res.data;
            }).catch(() => {

          });
      },
      reloadBtnList() {
        this.getSuggestWorkList()
        let element = document.querySelector('.hrChat_box_title--flushBtn-img');
        if (this.rotFlag) {
          element.classList.add('icon-arrow-rotate')
        } else {
          element.classList.remove('icon-arrow-rotate')
        }
        this.rotFlag = !this.rotFlag
      },
      // 获取已报名岗位
      getJobApplyList() {
          jobApplyListAIApi({ pageNum: 1, pageSize: 3, distMark: 1 }).then(res => {
          this.workList = res.data.rows.length === 0
              ? [] : res.data.rows.filter(item => !!item.companyWorkWant).map(item => item.companyWorkWant).filter(item => !!item.workTypeName).map(item => {
              let a = ''
                  if (item.postType == 1) {

                a = '长期岗位'
              }else if(item.postType == 2){
                a = '短期岗位'
              }else if(item.postType == 3){
                a = '兼职/代班'
              } else if(item.postType == 0) {
                a = '灵活用工'
              }
              return {
                name: item.workTypeName,
                money: item.minProSpend + '-' + item.maxProSpend,
                tagList: [a,item.employeesNum + '人'],
                km: 20,
                adress: item.proArea,
                id: item.id,
                unit: this.filter(this.proUnitDictType, item.proUnit),
                list: item,
                interviewFlag: item.interviewFlag,
                examType: item.examType,
                companyName: item.proCompanyName
              }
            }).slice(0,3)
        }).catch(() => {
        })
      },

      // 获取推荐岗位
      getSuggestWorkList(){
        hotWorkListApi(this.hotWorkForm).then(res=>{
          this.hotWorkForm.pageNum ++
          if(this.hotWorkForm.pageNum*2 > res.data.total){
            this.hotWorkForm.pageNum = 1
          }
          this.suggestWorkList = res.data.rows.length === 0
            ? []
            : res.data.rows.filter(item => !!item.workTypeName).map(item => {
              let a = ''
              if(item.postType == 1){
                a = '长期岗位'
              }else if(item.postType == 2){
                a = '短期岗位'
              }else if(item.postType == 3){
                a = '兼职/代班'
              } else if(item.postType == 0) {
                a = '灵活用工'
              }
              return {
                name: item.workTypeName,
                money: item.minProSpend + '-' + item.maxProSpend,
                tagList: [a,item.employeesNum + '人'],
                km: 20,
                btn: true,
                adress: item.proArea,
                unit: this.filter(this.proUnitDictType, item.proUnit),
                id: item.id,
                list: item,
                companyName: item.proCompanyName
              }
            })
        }).catch(()=> {

        })
      },
    }
}
</script>
<style lang='scss' scoped>
::v-deep.card {
  .list-item {
    margin-top: 15px;
    border-radius: 8px;
    border: 1px solid #DBDBDB;
  }
}
* {
    box-sizing: border-box;
}
.icon-arrow-rotate {
  transform: rotate(360deg);
}
.icon-arrow {
  transition: all .3s ease-out;
}

.main {
    &-topBox {
      min-height: 510px;
      padding: 19px 26px;
      background: #FFFFFF;
      box-shadow: 0px 2px 22px 0px rgba(0,0,0,0.14);
      border-radius: 12px;
    }
    &-bottomBox {
      margin-top: 24px;
      min-height: 400px;

      padding: 19px 26px;
      background: #FFFFFF;
      box-shadow: 0px 2px 22px 0px rgba(0,0,0,0.14);
      border-radius: 12px;
      overflow: auto;

    }

    .top-title {
      font-weight: 500;
      font-size: 18px;
      color: #333333;
      display: flex;
      align-items: center;
      .hrChat_box_title--flushBtn{
        cursor: pointer;
        transform: translateX(250px);
        .hrChat_box_title--flushBtn-img{

        }
        img{
          width: 15px;
          height: 12px;
        }
      }
      span{
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #333333;
      }
      &:before {
        content:'';
        display: inline-block;
        width: 3px;
        height: 15px;
        margin-right: 4px;
        background: #3076FF;

      }
    }

}
</style>
