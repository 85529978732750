<template>

  <div>
    <div
        @click="dialogFormVisible = true"
        @mouseleave="dialogFormVisible = false"
        @mouseenter="dialogFormVisible = true" 
        class="tip-form roleSelectDialog"
        v-if="dialogFormVisible">
        <!---->
      <div class="selectBox">
        <div
            :class="['selectBox-item', identity === item.identity ? 'active' : '']"
            v-for="(item, index) in list"
            :key="index"
            @click="tabClick(item)">
          <div>
            <div class="name">{{ item.name }}</div>
            <div class="tip">{{ item.tip }}</div>
          </div>
          <img :src="item.img" alt="">
        </div>
      </div>

      <div class="center" @click="GoPersonCenter">个人中心</div>
      <div class="line"></div>
      <div class="login-out" @click="LoginOut">退出登录</div>

    </div>
    <ifWorkRule @switchSuccess="switchIdentity" ref="ifWorkRule"/>
    <LawIsRole @switchSuccess="switchIdentity" ref="lawIsRole"/>
  </div>

</template>

<script>
import ifWorkRule from "@/components/personalCenter/ifWorkRule.vue"
import LawIsRole from "@/components/personalCenter/lawIsRole.vue";
import store from "@/store";
import {setToken} from "@/utils/auth";
import {updateUserIdentityApi} from "@/api/user";
import * as $api from '@/api/personalCenter'
export default {
  data() {
    return {
      dialogFormVisible: false,
      list: [
        {
          name: '我要招人',
          tip: '企业HR、老板',
          img: require('@/assets/intelligentInterview/hr.png'),
          path: '',
          identity: '2'
        },
        {
          name: '我要找活',
          tip: '工人、员工',
          img: require('@/assets/intelligentInterview/work.png'),
          path: '',
          identity: '1'
        },
      ],
      activeIndex: 0,
      identity: '1',
    }
  },
  components: {
    LawIsRole,
    ifWorkRule
  },
  created() {
    this.identity = store.state.user.identity;
    store.watch(() => store.state.user.identity, (newValue, oldValue) => {
      this.identity = newValue
    })
  },
  mounted() {
    this.$nextTick(() => {
      document.addEventListener('click', (e) => {
        let roleSelectDialog = document.getElementsByClassName('roleSelectDialog')
        if (roleSelectDialog && roleSelectDialog.length) {
          let isSelf = roleSelectDialog[0].contains(e.target)
          if (!isSelf) {
            this.dialogFormVisible = false
          }
        }
      })
    })
  },
  // 在组件生命周期结束时销毁
  beforeDestroy() {
    document.removeEventListener('click', () => {
    });
  },
  methods: {
    showDialog() {
      this.dialogFormVisible = true
    },
    closeDialog() {
      this.dialogFormVisible = false
    },
    // 去 个人中心
    GoPersonCenter() {
      // this.$router.push({ path: '/personalCenter', query: { name: '个人信息' } });
      if (this.identity == 2) {   // hr
        this.$router.push({path: '/personalCenter/indexHr', query: {name: '个人信息'}});
      } else {  // 工人
        this.$router.push({path: '/personalCenter/index', query: {name: '个人信息'}});
      }
      this.dialogFormVisible = false
    },
    tabClick(item) {
      if(item.identity === this.identity) {
        return;
      }
      this.dialogFormVisible = false
      const nowRoute = this.$route.path;
      if (item.identity === '1' && nowRoute === '/hrStatute/index') {
        // TODO ??
        this.$refs.lawIsRole.showDialog();
        return;
      }
      // 个人中心界面
      if(nowRoute === "/personalCenter/index" || nowRoute === "/personalCenter/indexHr"){
        updateUserIdentityApi({identity: item.identity}).then(res => {
          this.switchIdentity(item.identity);
        }).catch((e) => {
          console.error("切换角色失败", e);
        });
        return;
      }
      this.$refs.ifWorkRule.showDialog();
    },
    switchIdentity(identity) {
      if(identity !== '1' && identity !== '2') {
        console.error("切换角色失败");
        return;
      }
      store.commit('SET_IDENTITY', identity);
      const nowRoute = this.$route.path;
      // AI 招工
      if(nowRoute === '/aiFindWorker/index' && identity === '1'){
        this.$router.push({
          path: '/aiFindWork/index',
        });
        return;
      }
      // AI 找活界面
      if (nowRoute==='/aiFindWork/index' && identity === '2'){
        this.$router.push({
          path: '/aiFindWorker/index',
        });
      }
    },
    LoginOut() {
      this.dialogFormVisible = false
        // 成功之后推出登录
        $api.logoutAPI({}).then(res => {
            if (res.code == 200) {
                this.$router.push('/home')
                store.commit('SET_TOKEN', null)
            }
        })
    },
  }
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}

.tip-form {
  box-sizing: border-box;
  // width: 338px;
  width: 345px;
  position: absolute;
  right: 20px;
  top: 85px;
  background: #fff;
  box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  transition: all 0.4s ease 0s;

  z-index: 1000;

  .selectBox {
    padding: 0 12px;
    margin-top: 17px;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;

    .selectBox-item {
      // width: 153px;
      padding: 10px 12px;
      background: #F1F5FF;
      border-radius: 6px;
      cursor: pointer;

      display: flex;
      justify-content: space-between;
      border: 1px solid #fff;
      transition: all 0.5s;

      &.active {
        border: 1px solid #3076FF;
        font-weight: bold;
      }

      &:hover {
        border: 1px solid #3076FF;
        font-weight: bold;
      }

      img {
        width: 44px;
        height: 44px;
        margin-left: 11px;
      }

      .name {
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        color: #333333;
      }

      .tip {
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
        color: #7F7F7F;
      }
    }
  }

  // 个人中心
  .center {
    font-weight: 500;
    font-size: 16px;
    color: #333333;
    line-height: 22px;
    padding: 21px 0 19px 23px;
    cursor: pointer;

    &:hover {
      // color:#3076FF;
      font-weight: bold;
    }
  }

  .line {
    // width: 275px;
    height: 1px;
    background: #EAEAEA;
  }

  .login-out {
    font-weight: 400;
    font-size: 16px;
    color: #EB4F3E;
    line-height: 22px;
    cursor: pointer;

    padding: 17px 0 21px 23px;
    // &:hover {
    //     // color:#3076FF;
    //     font-weight: bold;
    // }
  }
}
</style>
