<template>
  <div class="nodata-body">
    <img :src="noDataPic" alt="">
  </div>
</template>
<script>
export default {
  name: 'NoData',
  data() {
    return {
      noDataPic: require('@/assets/intelligentInterview/noData.png')
      }
    }
  }
</script>
<style lang="scss" scoped>
.nodata-body{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  img{
    width: 276px;
    height: 276px;
  }
}
</style>
