<template>
    <div class="leftIndex2-main">
        <titleBar name="历史面试" @GoMore="HistoryGoMore" />
        <div class="vedioList" v-if="vedioList.length>0">
          <vedioItem v-for="(item, index) in vedioList" :key="index" :item="item" />
        </div>
        <no-data  v-if="vedioList.length === 0"></no-data>
    </div>
</template>
<script>
import vedioItem from '@/components/personalCenter/vedioItem.vue'
import titleBar from './titleBar.vue';
import noData from '@/views/aiInterview/components/noData.vue'
import store from '@/store'
import * as $api from '@/api/personalCenter'
export default {
    components: {
        titleBar,
        vedioItem,
        noData
    },
    data() {
        return {
            vedioList: [],
            identity: ''
        };
    },


    computed: {

    },
    created() {
        this.identity = store.state.user.identity
        store.watch(() => store.state.user.identity, (newValue, oldValue) => {
            this.identity = newValue
        })
    },
    mounted() {
        this.getInterViewHistory()
    },
    methods: {
        //获取历史面试记录
        getInterViewHistory() {
            $api.getInterviewAPI({ owner: store.state.user.identity == 2 ? 1 : 0 }).then(res => {
              if(res.data.rows.length > 12){
                this.vedioList = res.data.rows.slice(0, 12)
              }else {
                this.vedioList = res.data.rows
              }
            }).catch(() => {

            })
        },

        // // 历史面试 更多按钮
        HistoryGoMore() {
            // this.$router.push('/personalCenter/historyInterview?highIndex=3')
            if (this.identity == 2) {   // hr
                this.$router.push({ path: '/personalCenter/indexHr', query: { name: '历史面试', } });
            } else {  // 工人
                this.$router.push({ path: '/personalCenter/index', query: { name: '历史面试', } });
            }
        },

    }
}
</script>
<style lang='scss' scoped>
* {
    box-sizing: border-box;
}

.leftIndex2-main {
    background: #FFFFFF;
    box-shadow: 0px 2px 22px 0px rgba(0, 0, 0, 0.14);
    border-radius: 12px;

    padding: 19px 26px;

    .vedioList {
        width: 100%;
        margin-top: 21px;
        display: grid;
        grid-template-columns: 190px 190px;
        grid-row-gap: 14px;
        justify-content: space-between;

    }
}
</style>
