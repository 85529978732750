import request from '@/utils/request'

/**
 * post接口样例
 * @param data
 */
export function postApi(params) {
    return request.get('/api/crm/web/jobWant/aiFindWorker', { shouldEncrypt: false, shouldDecrypt: true, params })

    // return request.post('/api/crm/web/jobWant/aiFindWorker', data,{shouldEncrypt:true,shouldDecrypt:true})
}

/**
 * 首页AI找活统计数据
 * @param data
 */
export function getAiFindWorkDataApi(data) {
    return request.post('/api/crm/web/index/homePageAIFindJobCount', data, { shouldEncrypt: true, shouldDecrypt: true })
}

/**
 * 获取公共配置信息
 * sourceType:
 * 类型（
 * 1-隐私政策，
 * 2-关于我们，
 * 3-联系我们，
 * 4-免责条例，
 * 5-用户协议，
 * 6-工匠行招工信息发布规则，
 * 7-接单协议，
 * 8-付款协议，
 * 9-敏感词,
 * 100-贵州精选词条）
 * @param params
 */
export function getCommonConfigApi(params) {
    return request.get('/api/crm/web/index/getCommonConfig',  { shouldEncrypt: false, shouldDecrypt: true,params })
}

/**
 * 法律助手随机预设问题列表
 * @param params
 */
export function questionRandomApi(params) {
    return request.get('/api/crm/web/index/getOneRandomLegalHelperDialogue', {shouldEncrypt:false,shouldDecrypt:true,params})
}

/**
 * 大模型预设问题列表
 * @param params
 */
export function questionListApi(params) {
    return request.get('/api/crm/web/index/modelDialogueList', {shouldEncrypt:false,shouldDecrypt:true,params})
}

/**
 * 获取面试人的信息
 * @param params
 */
export function getUser(data){
    return request.post(`/api/crm/terminal/interview/info`,data,{shouldEncrypt: true, shouldDecrypt: true})
}
