<template>
  <div class="bg" v-loading.fullscreen.lock="fullscreenLoading">
    <div class="mb40">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">总览</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: breadcrumb.path }">{{ breadcrumb.name }}</el-breadcrumb-item>
        <el-breadcrumb-item>岗位详情</el-breadcrumb-item>
      </el-breadcrumb>

      <div class="detail-container">
        <!-- 最顶部 基础信息 工作 多少钱一天等信息 -->
        <div class="top-row" v-if="list">
          <div class="left-info">
            <div class="flexCenter">
              <div class="tag" v-if="list.postTypeName">{{ list.postTypeName }}</div>
              <!-- <div class="name" v-if=" list.workTypeName">{{ list.workTypeName }}</div> -->
              <el-tooltip class="item" effect="dark" placement="top-end" v-if="!!list.workTypeName">
                <div slot="content">{{ list.workTypeName }}</div>
                <div class="name singeLine" style="max-width: 480px;">{{ list.workTypeName }}</div>
              </el-tooltip>
              <div class="money" v-if="list.minProSpend == -1">面议</div>
              <div class="money" v-else>{{ list.minProSpend + '~' + list.maxProSpend }} {{ list.proUnitName }}</div>
            </div>
            <div class="info">
              <span><i class="el-icon-s-custom"></i> 人数 ：{{ list.employeesNum }}人</span>
              <span><i class="el-icon-view"></i> 浏览：{{ list.browsedNum }}次</span>
              <span><i class="el-icon-location"></i> {{ list.proArea }}</span>
              <span><i class="el-icon-time"></i> {{ list.createTime }}</span>
            </div>
          </div>

          <!-- 右侧按钮 -->
          <div class="deleteBtn" v-if="false">
            <span @click="deleteFn">删除</span>
            <span class="edit" @click="editFn"> 修改编辑</span>
          </div>
        </div>

        <div class="line"></div>

        <div class="flexBetween personNumber" v-if="false">
          <span>已报名人数：{{ list.jobWantApplyList ? list.jobWantApplyList.length : 0 }}</span>
          <span class="btn" @click="GomyPositionList">查看报名列表</span>
        </div>
        <div class="line"></div>


        <div class="detail">
          <div class="tag">
            <span>{{ list.postTypeName }}</span>
            <span>{{ list.employeesNum }}人</span>
          </div>
          <div class="mb15" v-if="!!list.proCompanyName">
            <div class="name">公司名称：</div>
            <div class="info">{{ list.proCompanyName }}</div>
          </div>
          <div class="mb15" v-if="false">
            <div class="name">岗位职责：</div>
            <div class="info">{{ list.descr }}</div>
          </div>
          <div class="mb15 h360" v-if="list.proDesc">
            <div class="name">工作内容：</div>
            <div class="info">
              <div v-for="(item, index) in textToListByBreak(list.proDesc)" :key="index">
                {{ item }}
              </div>
            </div>
          </div>
          <div class="mb15 work-wrap ">
            <div class="name">工作地址：</div>
            <div class="map-wrap">
              <div class="adress"><i class="el-icon-location"></i> {{ list.proArea }}</div>
              <div class="map">
                <el-amap ref="centerMap" class="amap-box" :vid="'amapVue'" :zoom="mapConfig.zoom"
                         :zooms="mapConfig.zooms" :center="mapConfig.center" :plugin="plugin">
                  <!-- <el-amap-marker
                    v-for="(marker, index) in markers"
                    :position="marker.position"
                    :key="index"
                ></el-amap-marker> -->
                </el-amap>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

    <!--  右侧面试 -->
    <div class="ritght-content">
      <centerCon :default-question="defaultQuestion" :work-type-id="workTypes" :user-jobwant-id="userJobwantId"
                 :company-work-want-id="companyWorkWantId"/>
    </div>
  </div>
</template>
<script>
import * as $api from '@/api/personalCenter'
import {parseTime} from '@/utils/date'
import centerCon from '@/views/aiInterview/components/centerInterview.vue'
// import centerCon from '@/views/aiInterview/components/centerCon.vue'
export default {
  components: {
    centerCon,
  },
  data() {
    return {
      workTypes: '',
      userJobwantId: '',
      defaultQuestion: '',
      companyWorkWantId: '',
      breadcrumb: {
        name: '',
        path: ''
      },
      list: {},
      fullscreenLoading: true,
      mapConfig: {
        zoom: 15,    // 当前缩放层级
        zooms: [14, 18],    // 地图缩放层级范围
        center: [106.629997, 26.646999],    // 地图中心点
      },
      markers: [
        {
          position: [106.629997, 26.646999]
        }
      ],
      plugin: [
        {
          pName: 'Geolocation',
          events: {
            init(o) {
              // o 是高德地图定位插件实例
              // o.getCurrentPosition((status, result) => {
              //     console.log('status:', status)
              //     console.log('result:', result)
              //     if (result && result.position) {
              //         self.lng = result.position.lng;
              //         self.lat = result.position.lat;
              //         self.center = [self.lng, self.lat];
              //         self.loaded = true;
              //         self.$nextTick();
              //     }
              // });
            }
          }
        }
      ],
      identity: '',
    };
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      // 面包屑跳转
      let path = vm.$route.query.path
      vm.breadcrumb.path = path || from.fullPath
      if (from.name) {
        if (path) {
          sessionStorage.setItem('path', path)
        } else {
          sessionStorage.setItem('path', from.fullPath)
        }
      }
    })
  },
  destroyed() {
    sessionStorage.removeItem('path')
  },
  created() {
    this.getDictTypeAPI()
    this.identity = this.$store.state.user.identity
    this.$store.watch(() => this.$store.state.user.identity, (newValue, oldValue) => {
      this.identity = newValue
    })
  },
  mounted() {
    this.breadcrumb.name = this.$route.query.name
    // 刷新问题
    this.breadcrumb.path = sessionStorage.getItem('path') || ''
  },
  methods: {
    getInit(arr) {
      let obj = {}
      // this.fullscreenLoading = true
      $api.getCompanyWorkWantInfoAPI({id: this.$route.query.id, obj}).then(res => {
        let {code, msg, data} = res
        this.fullscreenLoading = false
        if (code == 200) {
          this.list = data
          this.defaultQuestion = this.list.workTypeName
          this.workTypes = this.list.workType
          this.userJobwantId = '' //简历ID
          this.companyWorkWantId = this.list.id //招聘信息ID
          this.list.createTime = this.list.createTime ? parseTime(this.list.createTime, '{y}-{m}-{d} {h}:{i}:{s}') : ''
          arr.map(item => {
            if (item.dictType == 'crm_work_post_type' && this.list.postType == item.dictValue) {  // 岗位类别
              // this.list.postType = item.dictLabel
              this.list.postTypeName = item.dictLabel
            }
            if (item.dictType == 'crm_pro_unit' && this.list.proUnit == item.dictValue) {  // 岗位类别
              // this.list.proUnit = item.dictLabel
              this.list.proUnitName = item.dictLabel
            }

          })

        } else {
          this.fullscreenLoading = false
          // this.$message.error(msg)
        }
      }).catch(error => {
        // this.$message.error(error.msg)
        this.fullscreenLoading = false
      })
    },
    getDictTypeAPI() {
      // 培训报名 性别 岗位等字典 rm_train_user_type
      $api.dictTypeAPI({dictType: 'crm_work_post_type,crm_pro_unit'}).then(res => {
        let {code, msg, data} = res
        if (code == 200) {
          this.getInit(data)
        } else {
          this.$message.error(msg)
        }
      }).catch(error => {
        this.fullscreenLoading = false
      })
    },
    // 查看报名列表
    GomyPositionList() {
      if (this.identity == 2) {   // hr
        this.$router.push({
          path: '/personalCenter/indexHr',
          query: {name: '我的岗位', isDetail: 1, companyWorkwantId: this.list.id}
        });
      } else {  // 工人
        this.$router.push({
          path: '/personalCenter/index',
          query: {name: '我的岗位', isDetail: 1, companyWorkwantId: this.list.id}
        });
      }
      // this.$router.push({ path: '/personalCenter', query: { name: '我的报名', companyWorkwantId: this.list.id } })
    },
    deleteFn() {

    },
    editFn() {

    },
    textToListByBreak(text) {
      return text.replace("\r\n", "\n").replace("\r", "\n").split('\n').filter(t => t.length > 0);
    },
  },
};
</script>
<style>

</style>
<style lang="scss" scoped>
/* 滚动条整体部分 */
::-webkit-scrollbar {
  width: 12px; /* 对垂直滚动条有效 */
  height: 12px; /* 对水平滚动条有效 */
}

/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 10px; /* 使滚动条滑块边角圆滑 */
  border: 2px solid transparent; /* 边框颜色，设置为透明 */
  background-clip: content-box; /* 让边框内缩一定的距离 */
}

.flexCenter {
  display: flex;
  justify-content: start;
  align-items: center;
}

.bg {
  height: 100%;
  width: 100%;
  min-height: 900px;
  margin-bottom: 40px;
  background: url('@/assets/intelligentInterview/detailBg.png');
  // background: red;

  display: flex;
  justify-content: center;

  .el-breadcrumb {
    // margin: 30px 0 12px;
    height: 64px;
    line-height: 64px;
  }

//   overflow: hidden;
}

.h360 {
  height: 260px;
  min-height: 200px;
  overflow-y: auto;
}

// 左侧
.detail-container {
  height: calc(100% - 64px);
  // width: 1260px;
  // height: 901px;
  box-sizing: border-box;
  background: #FFFFFF;
  border-radius: 12px;
  padding: 28px 21px 0;
  position: relative;
  min-width: 734px;
  .top-row {
    width: 100%;
    font-weight: 400;
    font-size: 14px;
    color: #999999;
    padding: 0 21px;
    // display: flex;
    // justify-content: space-between;
    .left-info {
        min-width: 560px;
    }
    .deleteBtn {
      display: flex;

      span {
        margin-left: 18px;
        padding: 0 28px;
        height: 42px;
        line-height: 42px;
        border-radius: 8px;
        border: 1px solid #E42525;
        font-size: 16px;
        color: #E42525;
        cursor: pointer;

      }

      .edit {
        color: #3076FF;
        border-color: #3076FF;
      }
    }

    .name {
      max-width: 700px;
      font-weight: 500;
      font-size: 22px;
      color: #333333;
      margin-right: 20px;
    }

    .money {
      font-weight: 500;
      font-size: 22px;
      color: #1A67E2;
    }

    .tag {
      margin-right: 10px;
      padding: 0 8px;
      height: 26px;
      line-height: 26px;
      background: #3076FF;
      border-radius: 0px 13px 13px 0px;
      color: #fff;
    }

    .info {
      margin-top: 13px;
      padding-bottom: 11px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      letter-spacing: 1px;

      span {
        // margin-right: 60px;
      }
    }
  }

  .line {
    height: 1px;
    border-top: 1px solid rgba(151, 151, 151, 0.2);
  }

  // 已报名人数
  .personNumber {
    padding: 26px 21px 28px;
    @extend .flexBetween;
    font-weight: 500;
    font-size: 18px;
    color: #333333;

    .btn {
      height: 42px;
      line-height: 42px;
      background: #52C4A4;
      border-radius: 21px;
      padding: 0 25px;
      font-weight: 400;
      font-size: 16px;
      color: #FFFFFF;
      cursor: pointer;
    }
  }

  // 岗位职责 工作内容等信息
  .detail {
    padding: 21px 21px 10px 21px;

    .tag {
      display: flex;
      margin-bottom: 21px;

      span {
        margin-right: 6px;
        height: 26px;
        line-height: 26px;
        padding: 0 8px;
        border-radius: 2px;
        color: #fff;
        font-size: 14px;
        background: #52C4A4;

        &:first-child {
          background: #0B2467;
        }
      }
    }

    .name {
      margin-bottom: 7px;
      font-weight: 500;
      font-size: 18px;
      color: #333333;
      line-height: 25px;
    }

    .info {
      width: 688px;
      font-size: 16px;
      color: #606060;
      line-height: 24px;
      word-wrap: break-word;
      overflow-wrap: break-word;
    }

    .mb15 {
      margin-bottom: 15px;
    }
    .work-wrap {
        
      position: absolute;
      bottom: 0;
      right: 10px;
    //   width: 100%;
    }
    // 工作地址
    .map-wrap {
      border: 1px solid #DEDEDE;
      border-radius: 10px;
      width: 734px;
      .adress {
        padding-left: 13px;
        height: 48px;
        line-height: 48px;
        border-bottom: 1px solid #DEDEDE;
        font-size: 16px;
        color: #606060;
      }

      .map {
        height: 260px;
      }
    }

  }


  .flex {
    display: flex;
  }

  .flexBetween {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  * {
    box-sizing: border-box;
  }

  .mb40 {
    margin-bottom: 40px;
    height: auto;
    // background: #FFFFFF;
    // border-radius: 12px;
    // padding: 28px 21px 0;
    // border: 1px solid red;
  }

}

// 视频面试
.ritght-content {
  width: 40%;
  display: flex;
  margin-top: 60px;
  margin-left: 40px;
  position: relative;
  height: 100%;
}

::v-deep.map {
  border-radius: 0 0 10px 10px;
  overflow: hidden;
}
</style>
