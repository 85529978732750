import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)


import homeRouter from "./modules/home";
import aiFindWorkRouter from "./modules/aiFindWork";
import aiFindWorkerRouter from "./modules/aiFindWorker";
import aiInterviewRouter from "./modules/aiInterview";
import hrQuestionRouter from "./modules/hrQuestion";
import hrStatute from "./modules/hrStatute";
import personalCenter from "./modules/personalCenter";
import testRouter from "@/router/modules/test";

export const constantRouterMap = [
  homeRouter,
  aiFindWorkRouter,
  aiFindWorkerRouter,
  aiInterviewRouter,
  hrQuestionRouter,
  hrStatute,
  personalCenter,
  testRouter,
  {
    path: '/',
    redirect: '/home'
  },
  // 404-page wait complete !!!
  /*{
    path: '*',
    redirect: '/404'
  }*/
]

const originalPush = Router.prototype.push
const originalReplace = Router.prototype.replace
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
Router.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err)
}

const createRouter = ()=> new Router({
  mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRouterMap
})

const router = createRouter()
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router
