<template>
  <div class="chat_box" ref="chatContainer">
    <div class="loading-container" v-show="isLoading">
      <img
          src="../../assets/common/video-loading.gif"
          alt="Loading..."
      />
      <span
      >内容加载中...</span
      >
    </div>
    <div class="chat_message_item">
      <!-- 第一个消息设置欢迎语 -->
      <div class="chat_message_item_box robot_box">
        <div class="chat_message_item_box_avatar">
          <img :src="robotAvatar" alt=""/>
        </div>
        <div class="chat_message_item_box--message robot_message_box">
          <div class="chat_message_item_box_right_content">
            <div class="chat_message_item_box_right_content_text">
              欢迎您使用工匠AI大模型
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
        class="chat_message_item"
        v-for="(item, index) in chatMessageList"
        :key="index"
    >
      <div class="chat_message_item_box robot_box" v-if="item.user === 'robot'">
        <div class="chat_message_item_box_avatar">
          <img :src="robotAvatar" alt=""/>
        </div>
        <div class="chat_message_item_box--message robot_message_box">
          <div class="chat_message_item_box_right_content">
            <!-- 工人AI找活 serviceType：2-->
            <div
                class="chat_message_item_box_right_content_text"
                style="white-space: pre-wrap"
                v-if="serviceType == '2'"
            >
              <img
                  src="../../assets/common/video-loading.gif"
                  alt="Loading..."
                  v-show="item.isLoading"
              />
              <span class="span2" > {{text(item)}} </span>
              <div
                  class="resume_btn"
                  v-if="isShowResume(item)"
                  @click="goToAIResume(item)">
                查看简历
              </div>
              <div
                  class="work-TT"
                  v-if="hasList(item)"
              >
                <div
                    class="work-tt"
                    v-for="(item2, index2) in sliceList(item, 3)"
                    :key="index2"
                >
                  <div class="work-tt-t" >
                    <!-- <span class="typeName" >
                      {{ formatWorkTypeName(item2.workTypeName) }}
                    </span> -->
                    <el-tooltip class="item" effect="dark" placement="top-end">
                        <div slot="content">{{ item2.workTypeName }}</div>
                        <div class="singeLine typeName" style="max-width: 150px;">{{item2.workTypeName}}</div>
                    </el-tooltip>
                    <span class="ProSpend" v-if="item2.minProSpend != '-1'&& item2.maxProSpend !='-1'">
                      {{ item2.minProSpend }}-{{item2.maxProSpend}}
                      <span class="ProSpend2" style="">
                        {{ item2.proUnit ? filter(proUnitDictType, item2.proUnit) : "" }}
                      </span>
                    </span>
                    <span class="ProSpend" v-if="item2.minProSpend=='-1' || item2.maxProSpend=='-1'">
                      面议
                    </span>
                  </div>
                  <div
                      class="work-tt-t2"
                  >
                    <div
                        v-if="item2.postType == 1"
                        class="tt-t2"
                    >
                      <span
                      >长期岗位</span
                      >
                    </div>
                    <div
                        v-if="item2.postType == 2"
                        class="tt-t2"
                    >
                      <span
                      >短期岗位</span
                      >
                    </div>
                    <div
                        v-if="item2.postType == 3"
                        class="tt-t2"
                    >
                      <span
                      >兼职/代班</span
                      >
                    </div>
                    <div
                        class="tt-t3"
                    >
                      <span
                      >{{ item2.employeesNum }}人</span
                      >
                    </div>
                  </div>
                  <div
                      class="work-tt-t3"
                      v-if="!!item2.proCompanyName"
                  >
                    <img src="@/assets/common/company.png" alt="" style="width: 14px; height: 14px;"/>
<!--                    <span
                        class="t3-span"
                    >20km</span
                    >-->
                    <span>{{
                        item2.proCompanyName
                      }}</span>
                  </div>
                  <div
                      class="work-tt-t4"
                      @click="goDetail(item2)">
                    <span>去报名</span
                    >
                  </div>
                </div>
              </div>
            </div>

            <!-- 老板AI招工 serviceType：1 -->
            <div
                class="chat_message_item_box_right_content_text"
                style="white-space: pre-wrap"
                v-if="serviceType == '1'"
            >
              <div
                  style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                "
              >
                <div class="findWorker">
                  <img
                      src="../../assets/common/video-loading.gif"
                      alt="Loading..."
                      v-show="item.isLoading"
                  />
                  <span class="span2" > {{text(item)}} </span>
                  <div v-if="isShowAIPublish(item)" class="auto_publish_btn"  @click="goToAutoPublish">
                    智能发岗
                  </div>
                  <div
                      class="resume_btn"
                      v-if="isShowPublishBtn(item)"
                      @click="goToAIPost(item)">
                    发布岗位
                  </div>
                </div>
                <div
                    class="findWorker2"
                    v-if="hasList(item)"
                    @click="(e) => updateWorkData(item, e)"
                >
                  <img
                      src="../../assets/icon/aiFindWork/reload_btn.png"
                      class="chat_box_tips_title-right--btn icon-arrow"
                  />
                  <span>换一批</span>
                </div>
              </div>
              <div
                  class="findWorker3"
                  v-if="hasList(item)"
              >
                <div
                    class="findWorker3-row"
                    v-for="(item3, index3) in sliceList(item, 6)"
                    :key="index3"
                >
                  <div
                      class="row1"
                  >
                    <img
                        src="../../assets/icon/aiFindWork/aiAvatar.png"
                    />
                    <div class="row1-1-flex">
                      <div
                          class="row1-1"
                      >
                        <div>
                          <span
                              class="row1-span1"
                          >{{ item3.userName }}</span
                          >
                        </div>
                        <div
                            class="row1-2"
                        >
                          <div
                              class="row1-2-1"
                          >
                            <span class="row1-span2">{{
                                getGenderLabel(item3.sex)
                              }}</span>
                          </div>
                          <div
                              class="row1-2-2"
                              v-if="!!item3.workTypesName"
                          >
                            <!-- <span class="row1-span2">{{
                                formatWorkTypeName(item3.workTypesName)
                              }}</span> -->
                            <el-tooltip class="item" effect="dark" placement="top-end">
                                <div slot="content">{{ item3.workTypesName }}</div>
                                <div class="singeLine row1-span2" style="max-width: 70px;">{{item3.workTypesName }}</div>
                            </el-tooltip>
                          </div>
                        </div>
                      </div>
                      <div class="row2" @click="goConnection(item3)">
                        <span>去联系</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="chat_message_item_box user_box" v-if="item.user === 'user'">
        <div class="chat_message_item_box--message user_message_box">
          <div class="chat_message_item_box_right_content">
            <div class="chat_message_item_box_right_content_text">
              {{ item.content }}
            </div>
          </div>
        </div>
        <div class="chat_message_item_box_avatar">
          <img :src="userAvatar" alt=""/>
        </div>
      </div>
    </div>

    <!-- 去联系 -->
    <scanCodePay ref="scanCodePay"/>
    <callDialog ref="callDialog"/>
  </div>
</template>
<script>
import debounce from "lodash/debounce";
import store from "@/store";
import {filter} from "@/utils/filters";
import {dictTypeApi} from "@/api/aiFindWork";
import * as $api from "@/api/personalCenter";

export default {
  name: "chatBox",
  props: {
    chatMessageList: {
      type: Array,
      default: () => {
        return [
          {
            type: "text", //text:文字信息;voice：语音信息;image：图片信息;video：视频信息;file：文件信息;workList：工作流信息;workerList：求职人员信息;
            user: "robot", //robot:机器人;user:用户;
            content: "你好，我是小智，有什么可以帮助你的吗？", //内容
          },
        ];
      },
    },
    proUnitDictType: {
      type: Array,
      default: () => {
        return [];
      },
    },
    boxHeight: {
      type: String,
      default: "500",
    },
    serviceType: {
      type: String,
      default: "",
    },
  },
  components: {},
  // watch: {
  //   chatMessageList() {
  //     console.log('有变化');
  //     this.scrollToBottom();
  //   },
  // },
  data() {
    return {
      robotAvatar: require("@/assets/icon/aiFindWork/aiAvatar.png"),
      userAvatar: "",
      userDefaultAvatar: require("@/assets/icon/home/avatar.png"),
      speed: 50,
      isLoading: true,
      genders: [],
      hrImg: require("@/assets/intelligentInterview/hr.png"),
      workImg: require("@/assets/intelligentInterview/work.png"),
      rotTipFlag: false
    };
  },
  created() {
    this.scrollToBottom();
    // 测试简历 dialog
    // setTimeout(()=>{
    //   console.log("goToAIResume");
    //   this.goToAIResume();
    // }, 5000)
  },
  mounted() {
    this.userAvatar = store.state.user.userInfo.avatar
        ? store.state.user.userInfo.avatar
        : this.userDefaultAvatar;
    this.fetchGenderDictionary();
    //设定聊天框高度
    let element = document.querySelector(".chat_box");
    element.style.height = this.boxHeight + "px";
  },
  methods: {
    text(item) {
      return item.isLoading
        ? "正在思考"
        : (item.content.text
          ? item.content.text
          : "暂未找到合适的结果，您可尝试输入:工种类型、年龄、性别、工作地点等信息进行重试。");
    },
    isShowResume(item) {
      return !item.isLoading && !!item?.content?.viewResume;
    },
    isShowAIPublish(item) {
      return !item.isLoading && !!item?.content?.showAIPublish;
    },
    hasList(item) {
      return !item.isLoading && !!item?.content?.list;
    },
    sliceList(item, count) {
      return (item?.content?.list ?? []).slice(0, count);
    },
    isShowPublishBtn(item) {
      return !item.isLoading && !!item?.content?.workWantId;
    },
    async goToAIResume() {
      this.$emit("openAIResumeDialog")
    },
    goToAutoPublish() {
      this.$emit('autoPublish');
    },
    goToAIPost(item) {
      this.$emit('openPublishDialog', item);
    },
    getInit(item) {
        let obj = {}
        this.$router.push({
          path: '/personalCenter/jobDetails',
          query: {
            name: 'AI找活',
            id: item.id,
            companyWorkWantId: item.id
          }
        })
    },
    goDetail(data) {
      if (store.state.user.identity == 1) {
        this.getInit(data)
      } else {
        this.$alert('请先将身份切换为我要找活', '提示', {
          confirmButtonText: '确定',
          callback: action => {
          }
        });
      }
    },
    filter,
    updateWorkData(item, e) {
      let element = e.target.parentElement?.querySelector('.chat_box_tips_title-right--btn') ?? e.target;
      // console.log(element)
      if (this.rotTipFlag) {
        element.classList.add("icon-arrow-rotate");
      } else {
        element.classList.remove("icon-arrow-rotate");
      }
      this.rotTipFlag = !this.rotTipFlag;
      // 找到用户消息作为Keyword
      this.$emit("updateWorkDataList", item);
    },
    // 联系老板
    goConnection(detail) {
      // 获取电话号码
      let item = detail;
      let userInfo = store.state.user.userInfo;
      $api.getPhoneAPI({id: item.id}).then((res) => {
        let {data, success} = res;
        if (data && success) {
          // 订单类型，1:招工电话,2:工人电话,3:抢单,4:充值,5:购买会员,6:充值钱包,7:ai大模型使用招工,8:ai大模型使用工匠,9:悬赏招工,10:企业会员权益
          let obj = {
            phone: data,
            userName: item.userName,
            avatar: item.avatar
                ? item.avatar
                : item.sex == "男"
                    ? this.workImg
                    : this.hrImg,
          };
          this.$refs.callDialog.showDialog(obj);
        } else {
          let obj = {
            id: item.id,
            goodsId: item.id,
            orderType: this.identity == 1 ? 1 : 2, // 这个页面跳过去 写死
            phone: data,
            userName: item.userName,
            avatar: item.avatar
                ? item.avatar
                : item.sex == "男"
                    ? this.workImg
                    : this.hrImg,
            sex: item.sex,
          };
          this.$refs.scanCodePay.showDialog(obj);
        }
      });
    },
    fetchGenderDictionary() {
      dictTypeApi({dictType: "sys_user_sex"})
          .then((res) => {
            this.genders = res.data; // Assuming res.data contains the array of gender objects
            // console.log("这是性别数据", this.genders);
          })
          .catch((error) => {
            console.error("Error fetching gender dictionary:", error);
          });
    },
    getGenderLabel(sex) {
      const gender = this.genders.find((g) => g.dictValue === sex);
      return gender ? gender.dictLabel : "未知"; // Return '未知' if no matching dictValue
    },
    formatWorkTypeName(text) {
      if (text && typeof text === "string") {
        let t = text;
        const index = t.indexOf(",");
        if (index !== -1) {
          t = t.substring(0, index) + "...";
        }
        // 只显示不超过三个职业
        const splits = t.split("/");
        if(splits.length > 3) {
          t = splits.slice(0, 3).join("/");
        }
        return t;
      }
      return "不限"; // Return a default string if text is undefined or not a string
    },
    setOverflowHidden() {
      this.$refs.chatContainer.style.overflow = "hidden";
    },
    // 设置 overflow 属性为 auto
    setOverflowAuto() {
      this.$refs.chatContainer.style.overflow = "auto";
    },
    updateIsLoading() {
      // console.log("loadingloading");
      this.isLoading = true;
      this.setOverflowHidden();
    },
    // 使用lodash的debounce函数
    scrollToBottom: debounce(function () {
      // this.isLoading = true;
      this.$nextTick(() => {
        if (this.$refs.chatContainer) {
          const container = this.$refs.chatContainer;
          if (container.scrollHeight > 0) {
            container.scrollTop = container.scrollHeight;
            this.isLoading = false;
            // 加载完成后恢复滚动
            this.setOverflowAuto();
          }
        }
      });
    }, 400),
    //设定聊天框高度
    updateElementHeight(height) {
      this.$nextTick(()=>{
        let element = document.querySelector(".chat_box");
        element.style.height = height + "px";
      });
    },
  },
};
</script>
<style lang="scss" scoped>
::-webkit-scrollbar {
  height: 0;
  width: 0;
  color: transparent;
}

.resume_btn {
  cursor: pointer;
  width: 100px;
  height: 30px;
  margin-top: 5px;
  border-radius: 20px;
  border: 1px solid #3076ff;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #3076ff;
}

.auto_publish_btn {
  cursor: pointer;
  width: 60px;
  height: 20px;
  display: inline-flex;
  border-radius: 11px;
  border: 1px solid #3076ff;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: #3076ff;
}

.chat_box {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  position: relative;

  .loading-container {
    z-index: 999;
    width: 255px;
    height: 136px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 1);

    img {
      width: 40px;
      height: 40px
    }

    span {
      font-weight: 550;
      font-size: 18px;
      color: #333333;
    }
  }

  .chat_message_item {
    display: flex;

    .robot_box {
      display: flex;
      justify-content: flex-start;
    }

    .user_box {
      display: flex;
      justify-content: flex-end;
    }

    .chat_message_item_box {
      width: 100%;
      display: flex;
      margin-top: 22px;

      .chat_message_item_box_avatar {
        img {
          width: 44px;
          height: 44px;
          border-radius: 50%;
        }
      }

      .chat_message_item_box--message {
        max-width: 1200px;
        width: max-content;
        padding: 15px 19px;
      }
    }

    .robot_message_box {
      background: #ffffff;
      border-radius: 5px;
      margin-left: 14px;
      color: #333333;
      font-size: 14px;

      .chat_message_item_box_right_content {
        .chat_message_item_box_right_content_text {
          img {
            width: 20px;
            height: 20px;
            vertical-align: middle;
          }

          span {
            color: #333333;
            font-size: 14px;
          }

          .span2 {
            color: #333333;
            font-size: 14px;
          }

          .findWorker {
            width: max-content;

            span {
              color: #333333;
              font-size: 14px;
            }
          }

          .findWorker2 {
            display: flex;
            align-items: center;
            flex-direction: row;
            cursor: pointer;

            img {
              width: 15px;
              height: 12px;
              margin-right: 4px;
            }

            span {
              font-size: 14px;
              color: #333333;
            }
          }

          .findWorker3 {
            display: grid;
            grid-template-columns: auto auto auto;
            grid-row-gap: 8px;
            grid-column-gap: 8px;
            width: max-content;
            margin-left: 12px;
            margin-top: 12px;

            .findWorker3-row {
              width: auto;
              height: 60px;
              background: #f1f5ff;
              border-radius: 5px;

              .row1 {
                display: grid;
                height: 100%;
                grid-template-columns: 45px auto;
                grid-column-gap: 8px;
                align-items: center;
                position: relative;
                .row1-1-flex {
                  display: flex;
                  justify-content: space-between;
                }

                .row1-1 {
                  display: flex;
                  flex-direction: column;
                  margin-right: 30px;

                  .row1-span1 {
                    font-size: 14px;
                    color: #555555;
                    font-weight: 500;
                  }

                  .row1-2 {
                    display: flex;
                    flex-direction: row;
                    margin-top: 6px;

                    .row1-2-1 {
                      background: #52c4a4;
                      border-radius: 1px;
                      padding: 3px 6px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      margin-right: 6px;

                      .row1-span2 {
                        font-size: 9px;
                        color: #ffffff;
                      }
                    }
                    .row1-2-2 {
                      background: #52c4a4;
                      border-radius: 1px;
                      padding: 3px 6px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      .row1-span2 {
                        font-size: 9px;
                        color: #ffffff;
                      }
                    }
                  }
                }

                img {
                  width: 35px;
                  height: 35px;
                  border-radius: 50%;
                  margin: 0 10px;
                }


              }

              .row2 {
                display: flex;
                max-width: 52px;
                max-height: 22px;
                place-self: center;
                justify-content: center;
                align-items: center;
                border-radius: 15px;
                border: 1px solid #555555;
                padding: 4px 14px;
                margin-right: 10px;
                cursor: pointer;

                span {
                  font-size: 10px;
                  color: #555555;
                  cursor: pointer;
                }
              }
            }
          }

          .work-TT {
            display: flex;
            flex-direction: row;
            margin-top: 10px;

            .work-tt {
              position: relative;
              width: fit-content;
              max-width: 380px;
            //   height: 82px;
              height: 92px;
              background: #f1f5ff;
              border-radius: 5px;
              margin-right: 10px;
              .work-tt-t {
                display: flex;
                justify-content: space-between;
                flex-direction: row;
                white-space: nowrap;

                .typeName {
                  color: #555555;
                  font-size: 14px;
                  margin: 8px 20px 0 8px;
                }

                .ProSpend {
                  color: #555555;
                  font-size: 14px;
                  min-width: 90px;
                  text-align: end;
                  margin: 8px 8px 0 20px;
                }

                .ProSpend2 {
                  color: #555555;
                  font-size: 8px;
                }
              }

              .work-tt-t2 {
                display: flex;
                flex-direction: row;
                margin: 5px 0 0 8px;

                .tt-t2 {
                  background: #0b2467;
                  border-radius: 1px;
                  margin-right: 8px;
                  display: flex;
                  justify-content: center;
                  align-items: center;

                  span {
                    padding: 3px 6px;
                    color: #ffffff;
                    font-size: 9px;
                  }
                }

                .tt-t3 {
                  background: #52c4a4;
                  border-radius: 1px;
                  display: flex;
                  justify-content: center;
                  align-items: center;

                  span {
                    padding: 3px 6px;
                    color: #ffffff;
                    font-size: 9px;
                  }
                }
              }

              .work-tt-t3 {
                display: flex;
                flex-direction: row;
                margin: 5px 0 0 8px;
                align-items: center;
                width: 130px;

                i {
                  margin-right: 2px;
                }

                .t3-span {
                  color: #888888;
                  font-size: 10px;
                  margin-right: 5px;
                }

                span {
                  color: #888888;
                  font-size: 10px;
                }
              }

              .work-tt-t4 {
                cursor: pointer;
                position: absolute;
                bottom: 10px;
                right: 10px;
                width: 61px;
                height: 22px;
                border-radius: 11px;
                border: 1px solid #3076ff;
                display: flex;
                justify-content: center;
                align-items: center;

                span {
                  font-size: 10px;
                  color: #3076ff;
                  cursor: pointer;
                }
              }

            }
          }

        }
      }

      .dashline {
        border-top: 1px dashed rgba(151, 151, 151, 0.2);
        width: auto;
        margin-top: 8px;
      }

      .copy {
        margin-top: 10px;
        width: auto;
        display: flex;
        /* align-items: center; */
        /* justify-content: space-between; */
        flex-direction: column;

        .content {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 82px;
          height: 35px;
          border-radius: 7px;
          border: 1px solid #bababa;
          font-size: 14px;
          color: #333333;
          line-height: 20px;
          cursor: pointer;
        }

        .tip {
          margin-top: 10px;
          font-size: 12px;
          color: #949494;
          line-height: 17px;
        }
      }
    }

    .user_message_box {
      word-break: break-word;
      background: #3076ff;
      color: #ffffff;
      border-radius: 5px;
      margin-right: 14px;
      font-size: 14px;
    }
  }
}
.icon-arrow {
  transition: all 0.3s ease-out;
}
.icon-arrow-rotate {
  transform: rotate(360deg);
}
</style>
