import { getToken, getRSAKey } from '@/utils/auth'

const state = {
  token: '',
  RSAKey: '',
  loginTimes: 0,
  identity: '1',
  nickName: '',
  userLocation: {
    latitude: '',
    longitude: ''
  },
  userInfo: {},
  code:'',
  workTypeName: '' ,//工作岗位
  type:'',
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_RSAKEY: (state, RSAKey) => {
    state.RSAKey = RSAKey
  },
  SET_LOGINTIMES: (state, loginTimes) => {
    state.loginTimes = loginTimes
  },
  SET_IDENTITY: (state, identity) => {
    state.identity = identity
  },
  SET_LOCATION: (state, userLocation) => {
    state.userLocation = userLocation
  },
  SET_USER: (state, userInfo) => {
    state.userInfo = userInfo
  },
  SET_NICKNAME: (state, nickName) => {
    state.nickName = nickName
  },
  SET_CODE: (state, code) => {
    state.code = code
  },
  SET_TYPE: (state, type) => {
    state.type = type
  },
  SET_WORK_TYPE_NAME: (state, workTypeName) => {
    state.workTypeName = workTypeName
  }
}

export default {
  state,
  mutations
}
