import request from '@/utils/request'
let shouldEncrypt = true
let shouldDecrypt = true
let baseUrl = '/api'
// 获取人格测评量表
export function getEvalExamListAPI(params) {
    return request.get(baseUrl + '/crm/web/eval/getEvalExamList', { shouldEncrypt: shouldEncrypt, shouldDecrypt: shouldDecrypt, params })
}

export function getEvalExamQuAndOpByExamIdAPI(params) {
    return request.get(baseUrl + '/crm/web/eval/getEvalExamQuAndOpByExamId', { shouldEncrypt: shouldEncrypt, shouldDecrypt: shouldDecrypt, params })
}
export function subExamAnswersAPI(data) {
    return request.post(baseUrl + '/crm/web/eval/subExamAnswers', data, { shouldEncrypt: shouldEncrypt, shouldDecrypt: shouldEncrypt }, true)
}

// 查看测评结果接口
export function checkEvalRatingsAPI(data) {
    return request.post(baseUrl + '/crm/web/eval/checkEvalRatings', data, { shouldEncrypt: shouldEncrypt, shouldDecrypt: shouldEncrypt }, true)
}

export function payForExamAPI(data) {
    return request.post(baseUrl + '/crm/webOrder/payForExam', data, { shouldEncrypt: shouldEncrypt, shouldDecrypt: false }, true)
}

export function getEvalRatingsListAPI(data) {
    let params = {
        pageSize: data.pageSize, // 每页条数
        pageNum: data.pageNum, // 当前页数
    }
    return request.get(baseUrl + '/crm/web/eval/getEvalRatingsList/' + data.examId, { shouldEncrypt: shouldEncrypt, shouldDecrypt: shouldDecrypt, params })
}
export function queryIsExistsBuyByUserAPI(data) {
    let params = {
        examId: data.id
    }
    return request.get(baseUrl + '/crm/web/eval/queryIsExistsBuyByUser/' + data.examId, { shouldEncrypt: shouldEncrypt, shouldDecrypt: shouldDecrypt, params })
}
/**
 * 是否有人才测评
 * @param data
 * data: { interviewCode:string }
 * */
export function getInterviewExam(data) {
    return request.post(baseUrl + '/crm/terminal/interview/exam', data, { shouldEncrypt: shouldEncrypt, shouldDecrypt: shouldDecrypt }, true)
}

