<template>
  <div class="record-page">
    <div class="camera_box" id="camera_box">
<!--      <el-amap
          ref="centerMap"
          class="amap-box"
          :vid="'amapVue'"
          :zoom="mapConfig.zoom"
          :zooms="mapConfig.zooms"
          :center="mapConfig.center"
          :plugin="plugin"
      >
        &lt;!&ndash; 标记 &ndash;&gt;
        <el-amap-marker
            v-for="(marker, index) in markers"
            :position="marker.position"
            :key="index"
        ></el-amap-marker>
      </el-amap>-->
    <DraggableCamera :drag-box-area="dragBoxArea"/>
    </div>
    <div class="record_box">
    </div>
    <span @click="startRecording">
      开始
    </span>
    <span @click="stopRecording">
      停止
    </span>

    <cantTo />
  </div>

</template>

<script>
import DraggableCamera from '@/components/userCamera/index.vue'
import cantTo from '@/components/personalCenter/cantTo.vue'
import RecordRTC from 'recordrtc'
import { setToken } from '@/utils/auth'
import axios from 'axios'
import {postApi} from '@/api/test'
export default {
  name: 'screenRecord',
  components: {
    DraggableCamera
    // cantTo
  },
  data () {
    return {
      markers: [
        {
        position: [106.629997, 26.646999]
        }
      ],

      mapConfig: {
        zoom: 15,    // 当前缩放层级
        zooms: [14, 18],    // 地图缩放层级范围
        center: [106.629997, 26.646999],    // 地图中心点
      },
      video: null,
      videoStart: false,
      recorder: null,
      plugin: [
        {
          pName: 'Geolocation',
          events: {
            init(o) {
              // o 是高德地图定位插件实例
              o.getCurrentPosition((status, result) => {
                if (result && result.position) {
                  self.lng = result.position.lng;
                  self.lat = result.position.lat;
                  self.center = [self.lng, self.lat];
                  self.loaded = true;
                  self.$nextTick();
                }
              });
            }
          }
        }
      ],
      dragBoxArea: {
        left: 0,
        top: 0,
        right: 0,
        bottom: 0
      },
      apiTestForm: {

      },
      displayMediaStream: null,
      chunks : [],
      recordingUrl: null,
    }
  },
  created () {
    if (!navigator.getDisplayMedia && !navigator.mediaDevices.getDisplayMedia) {
      const error = 'Your browser does NOT support the getDisplayMedia API.'
      throw new Error(error)
    }
  },
  mounted () {
    //this.loginTest()
    this.video = document.querySelector('video')
    this.getDragArea()
    //this.apiTest()
  },
  methods: {
    async startRecording() {
      try {
        this.displayMediaStream = await navigator.mediaDevices.getDisplayMedia({
          video: {
            cursor: 'always', // 可选：是否包含鼠标指针
            displaySurface: 'browser', // 只录制浏览器区域
            logicalSurface: true, // 使用逻辑表面，提高录制质量（仅Chrome 97+）
          },
        });
        // 使用MediaRecorder开始录制
        this.recorder = new MediaRecorder(this.displayMediaStream, { mimeType: 'video/webm' });
        this.recorder.ondataavailable = this.handleDataAvailable.bind(this);
        this.recorder.start(); // 开始录制
      } catch (error) {
        console.error('用户取消录制:', error);
      }
    },
    async stopRecording() {
      this.recorder.stop();
      // 等待所有数据块收集完成
      await new Promise(resolve => {
        this.recorder.onstop = resolve;
      });
      // 合并Blob数据为一个完整视频文件
      const recordingBlob = new Blob(this.chunks, { type: 'video/webm' });

      this.uploadFile(recordingBlob)

      // 生成Object URL，用于预览或上传
      //this.recordingUrl = URL.createObjectURL(recordingBlob);
      // 清空数据块列表
      this.chunks = [];
    },
    uploadFile(blob) {
      const formData = new FormData()
      formData.append('file', blob,'recording.webm')
      axios.post('/api/file/upload/files',formData,{
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(res=> {
        //console.log('上传结果',res)
      }).catch(err=> {
        //console.log('上传错误',err)
      })
    },
    handleDataAvailable(event) {
      if (event.data.size > 0) {
        this.chunks.push(event.data);
      }
    },
    apiTest(){
      postApi({keyword:'帮我找一个前端工程师'}).then(res=> {
        // console.log('返回参数',res)
      }).catch(err=> {
        console.log('错误',err)
      })
    },
    loginTest() {
      this.$showLoginModal(true)
    },

    //获取摄像头父组件位置-计算摄像头元素区域可拖动范围
    getDragArea () {
      const dragBox = document.querySelector('.camera_box')
      const dragBoxLeft = dragBox.offsetLeft
      const dragBoxTop = dragBox.offsetTop
      const dragBoxWidth = dragBox.clientWidth
      const dragBoxHeight = dragBox.clientHeight
      this.dragBoxArea = {
        left: dragBoxLeft,
        top: dragBoxTop,
        right: dragBoxWidth,
        bottom: dragBoxHeight
      }
    },
    // 调用获取显示媒体
    invokeGetDisplayMedia (success, error) {
      const displaymediastreamconstraints = {
        video: {
          displaySurface: 'browser', // monitor, window, application, browser
          logicalSurface: false,
          cursor: 'always' // never, always, motion
        }
      }
      // above constraints are NOT supported YET
      // that's why overridnig them

      // displaymediastreamconstraints = {
      //   video: true
      // }
      if (navigator.mediaDevices.getDisplayMedia) {
        // console.log(navigator.mediaDevices.getDisplayMedia(displaymediastreamconstraints))
        navigator.mediaDevices.getDisplayMedia(displaymediastreamconstraints).then(success).catch(error)
      } else {
        navigator.getDisplayMedia(displaymediastreamconstraints).then(success).catch(error)
      }
    },
    // 捕获屏幕
    captureScreen (callback) {
      // console.log('0')
      this.invokeGetDisplayMedia(
          screen => {
            this.addStreamStopListener(screen, () => {
              //
            })
            callback(screen)
          },
          function (error) {
            // console.error(error)
            alert('Unable to capture your screen. Please check console logs.\n' + error)
          }
      )
    },
    addStreamStopListener (stream, callback) {
      stream.addEventListener(
          'ended',
          function () {
            callback()
            callback = function () {}
          },
          false
      )
      stream.addEventListener(
          'inactive',
          function () {
            callback()
            callback = function () {}
          },
          false
      )
      stream.getTracks().forEach(track => {
        track.addEventListener(
            'ended',
            () => {
              this.stopRecording()
              callback()
              callback = function () {}
            },
            false
        )
        track.addEventListener(
            'inactive',
            function () {
              callback()
              callback = function () {}
            },
            false
        )
      })
    },
    /*startRecording () {
      this.captureScreen(screen => {
        this.video.srcObject = screen
        this.recorder = RecordRTC(screen, {
          type: 'video'
        })
        this.recorder.startRecording()
        // release screen on stopRecording
        this.recorder.screen = screen
        this.videoStart = true
      })
    },
    stopRecordingCallback () {
      this.video.src = this.video.srcObject = null
      this.video.src = URL.createObjectURL(this.recorder.getBlob())
      // 如果需要下载录屏文件可加上下面代码
      const url = URL.createObjectURL(this.recorder.getBlob())
      const a = document.createElement('a')
      document.body.appendChild(a)
      a.style.display = 'none'
      a.href = url
      a.download = new Date() + '.mp4'
      a.click()
      window.URL.revokeObjectURL(url)
      // 以上是下载所需代码
      this.recorder.screen.stop()
      this.recorder.destroy()
      this.recorder = null
      this.videoStart = false
    },
    stopRecording () {
      this.recorder.stopRecording(this.stopRecordingCallback)
    }*/
  }
}
</script>

<style lang="scss" scoped>
.record-page{
  height: 800px;
  position: relative;
  display: flex;
  flex-direction: column;
  .camera_box{
    width: 600px;
    height: 600px;
    background-color: #1957FF;
    position: absolute;
  }
  .record_box{
    width: 800px;
    height: 800px;
    background-color: #FFFFFF;
  }
}
</style>
