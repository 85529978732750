<template>
    <div class="topic-container" v-if="list.length > 0">
         <div class="title-wrap">
             <div class="name">面试详情</div>
             <div class="btn" @click="handleChange">
               <span class="">{{isExpanded ? '收起' : '展开'}}</span>
               <span :class="[isExpanded ? 'isExpandedIcon' : 'icon','el-icon-arrow-up']"></span>
             </div>
         </div>
         <div :class="[ isExpanded ? 'expanded': 'shrink', 'list-wrap']">
             <template v-for="(item,index) in list" >
               <div class="list-item" :key="index" v-if="item.user">
                 <div class="question" >
                     <span class="label">问题：</span>
                     <span class="content">{{item.robot.content}}</span>
                   </div>
                   <div class="question answer" style="margin-top: 25px;">
                     <span class="label">回答：</span>
                     <span class="content">{{ item.user.content}}</span>
                   </div>
               </div>
             </template>
         </div>
    </div>
 </template>
 
 <script>
 export default {
    components: {},
    data () {
       return {
         list: [],
         isExpanded: true, // 展开  收起
       };
    },
 
   methods: {
       initInfo(detail) {
            console.log('detail', detail)
            if (detail.list && detail.list[0].dialogueRecord) {
                    this.list = JSON.parse(detail.list[0].dialogueRecord)
            } 
            console.log('答题 ',  this.list )
     },
     handleChange() {
       this.isExpanded = !this.isExpanded
     }
    }
 }
 </script>
 <style lang='scss' scoped>
 .topic-container {
     margin-top: 20px;
     // max-height: 552px;
     background: #fff;
     border-radius: 10px;
     // overflow-y: auto;
     // overflow: hidden;
     padding-bottom: 20px;
     max-width: 1500px;
     .title-wrap {
         padding: 30px 27px;
         display: flex;
         justify-content: space-between;
         .icon {
           transform: rotate(180deg);
           transition: all 0.5s;
         }
         .isExpandedIcon {  // 旋转
           transform: rotate(360deg);
         }
         .name {
             margin-right: 8px;
             font-weight: 500;
             font-size: 16px;
             color: #303133;
             display: flex;
             align-items: center;
 
             &::before {
                 content: "";
                 width: 2px;
                 height: 13px;
                 margin-top: 2px;
                 margin-right: 8px;
                 background: #3076FF;
             }
         }
         .btn {
           padding: 0 20px;
           height: 30px;
           line-height: 30px;
           background: rgba(0, 0, 0, 0.3);
           border-radius: 15px;
           cursor: pointer;
           color: #fff;
         }
     }
     .list-wrap {
         padding: 0 40px;
         display: grid;
         grid-template-columns: 1fr 1fr;
         grid-column-gap: 25px;
         grid-row-gap: 20px;
         transition: all 0.5s ease-in-out;
         &.expanded {
           max-height: 2000px;/* 足够显示全部内容的最大高度 */
           opacity: 1
         }
         &.shrink {
           // transform: translate(1.1);
           max-height: 0;/* 足够显示全部内容的最大高度 */
           opacity: 0
         }
         .list-item {
             border-radius: 12px;
             border: 1px dashed #333333;
             padding: 20px;
             .question  {
               display: flex;
               .label {
                 width: max-content;
                 flex-shrink: 0;
                 margin-left: 10px;
               }
               .content {
                 font-weight: 400;
                 font-size: 14px;
                 color: #303133;
               }
             }
             .answer {
               background: #2768D8;
               border-radius: 8px;
               color: #fff;
               padding: 10px;
               .content {
                 color: #fff;
               }
             }
         }
     }
 }
 </style>
 