<template>
  <div class="chat_box" ref="chatContainer">
    <div class="loading-container" v-show="isLoading">
      <img
          src="../../assets/common/video-loading.gif"
          alt="Loading..."
      />
      <span>内容加载中...</span>
    </div>
    <div class="chat_message_item">
      <!-- 第一个消息设置欢迎语 -->
      <div class="chat_message_item_box robot_box">
        <div class="chat_message_item_box_avatar">
          <img :src="robotAvatar" alt=""/>
        </div>
        <div class="chat_message_item_box--message robot_message_box">
          <div class="chat_message_item_box_right_content">
            <div class="chat_message_item_box_right_content_text">
              欢迎您使用工匠AI大模型
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
        class="chat_message_item"
        v-for="(item, index) in chatMessageList"
        :key="index"
    >
      <div class="chat_message_item_box robot_box" v-if="item.user === 'robot'">
        <div class="chat_message_item_box_avatar">
          <img :src="robotAvatar" alt=""/>
        </div>
        <div class="chat_message_item_box--message robot_message_box">
          <div class="chat_message_item_box_right_content">
            <div
                class="chat_message_item_box_right_content_text"
                style="white-space: pre-wrap"
            >
              <img
                  src="../../assets/common/video-loading.gif"
                  alt="Loading..."
                  style="width: 100px; height: 100px;"
                  v-if="item.isLoading"
              />
              <span
                  v-show="item.isLoading"
                  style=""
              >正在思考</span
              >
              {{ item.displayContent || item.content }}
            </div>
            <div class="successTip" v-if="item.displayContent !='抱歉，无法获取AI的回复。' && !item.isLoading">
              <img
                  src="../../assets/common/finished-icon.png"
                  alt=""
                  class="successImg"
              />
              <span
                  class="successSpan"
              >创作完成，共计{{ item.total }}字</span
              >
            </div>
            <div class="dashline" style=""></div>
            <div class="copy" v-show="!item.isLoading">
              <div class="content" @click="copyText(item.content)">
                <span>复制全文</span>
              </div>
              <div class="tip">
                <span
                >以上内容为工匠 AI
                  大模型生成，不代表开发者立场，请勿删除和修改本标记</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="chat_message_item_box user_box" v-if="item.user === 'user'">
        <div class="chat_message_item_box--message user_message_box">
          <div class="chat_message_item_box_right_content">
            <div class="chat_message_item_box_right_content_text">
              {{ item.content }}
            </div>
          </div>
        </div>
        <div class="chat_message_item_box_avatar">
          <img :src="userAvatar" alt=""/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import debounce from "lodash/debounce";
import throttle from "lodash/throttle";
import store from "@/store";

export default {
  name: "chatBox",
  props: {
    chatMessageList: {
      type: Array,
      default: () => {
        return [
          {
            type: "text", //text:文字信息;voice：语音信息;image：图片信息;video：视频信息;file：文件信息;workList：工作流信息;workerList：求职人员信息;
            user: "robot", //robot:机器人;user:用户;
            content: "你好，我是小智，有什么可以帮助你的吗？", //内容
          },
        ];
      },
    },
    boxHeight: {
      type: String,
      default: "500",
    },
  },
  components: {},
  data() {
    return {
      userAvatar: "",
      userDefaultAvatar: require("@/assets/icon/home/avatar.png"),
      robotAvatar: require("@/assets/icon/aiFindWork/aiAvatar.png"),
      speed: 150,
      isLoading: true,
      userScrolling: false,
    };
  },
  created() {
    this.scrollToBottom();
  },
  mounted() {
    const container = this.$refs.chatContainer;
    if (container) {
      container.addEventListener("scroll", () => {
        const isAtBottom =
            container.scrollTop + container.clientHeight >=
            container.scrollHeight - 100; // 容忍度10px
        this.userScrolling = !isAtBottom;
      });
    }
    this.userAvatar = store.state.user.userInfo.avatar ? store.state.user.userInfo.avatar : this.userDefaultAvatar;
    //设定聊天框高度
    let element = document.querySelector(".chat_box");
    element.style.height = this.boxHeight + "px";

    // 延迟时间
      this.chatMessageList.filter(item => item.user === 'robot').forEach((msg, index) => {
      setTimeout(() => {
        this.animateMessage(msg);
      }, index * this.speed);
    });
  },
  beforeDestroy() {
    const container = this.$refs.chatContainer;
    if (container) {
      container.removeEventListener("scroll", this.handleScroll);
    }
  },

  methods: {
    setOverflowHidden() {
      this.$refs.chatContainer.style.overflow = "hidden";
    },
    // 设置 overflow 属性为 auto
    setOverflowAuto() {
      this.$refs.chatContainer.style.overflow = "auto";
    },
    updateIsLoading() {
      this.isLoading = true;
      this.setOverflowHidden();
    },
    // 使用lodash的debounce函数
    // scrollToBottom: debounce(function () {
    //   this.isLoading = true;
    //   this.$nextTick(() => {
    //     if (this.$refs.chatContainer) {
    //       const container = this.$refs.chatContainer;
    //       if (container.scrollHeight > 0) {
    //         container.scrollTop = container.scrollHeight;
    //         this.isLoading = false;
    //         // 加载完成后恢复滚动
    //         this.setOverflowAuto();
    //       }
    //     }
    //   });
    // }, 100),
    scrollToBottom: throttle(function () {
      if (!this.userScrolling) {
        this.$nextTick(() => {
          const container = this.$refs.chatContainer;
          if (container && container.scrollHeight > 0) {
            container.scrollTop = container.scrollHeight;
            this.isLoading = false;
            this.setOverflowAuto();
          }
        });
      }
    }, 200),

    animateMessage(message) {
      let fullContent = message.content;
      let i = 0;
      const lengthToTriggerScroll = 30; // 每50个字符触发一次滚动

      const step = () => {
        if (i < fullContent.length) {
          message.displayContent += fullContent.charAt(i++);
          if (
              i % lengthToTriggerScroll === 0 ||
              fullContent.charAt(i - 1) === "\n"
          ) {
            this.scrollToBottom();
          }
          this.$nextTick(() => {
            setTimeout(step, this.speed);
          });
        } else {
            console.log("1111111111",message.displayContent)
          this.$set(message, "content", message.displayContent);
          this.$emit("typing-completed");
          this.scrollToBottom();
        }
      };
      step();
    },
    copyText(text) {
      if (navigator.clipboard) {
        navigator.clipboard
            .writeText(text)
            .then(() => {
              this.$message({
                message: "文本已复制到剪贴板",
                type: "success",
                duration: 1500,
                customClass: 'zZindex'
              });
            })
            .catch((err) => {
              console.error("复制文本失败:", err);
            });
      } else {
        console.error("浏览器不支持剪贴板");
      }
    },
  },
};
//
</script>
<style lang="scss" scoped>
::-webkit-scrollbar {
  height: 0;
  width: 0;
  color: transparent;
}

.chat_box {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  position: relative;

  .loading-container {
    z-index: 999;
    width: 255px;
    height: 136px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 1);

    img {
      width: 40px;
      height: 40px;
    }

    span {
      font-weight: 550;
      font-size: 18px;
      color: #333333;
    }
  }

  .chat_message_item {
    display: flex;

    .robot_box {
      display: flex;
      justify-content: flex-start;
    }

    .user_box {
      display: flex;
      justify-content: flex-end;
    }

    .chat_message_item_box {
      width: 100%;
      display: flex;
      margin-top: 22px;

      .chat_message_item_box_avatar {
        img {
          width: 44px;
          height: 44px;
          border-radius: 50%;
        }
      }

      .chat_message_item_box--message {
        max-width: 600px;
        padding: 15px 19px;
      }
    }

    .robot_message_box {
      background: #ffffff;
      border-radius: 5px;
      margin-left: 14px;
      color: #333333;
      font-size: 16px;

      .chat_message_item_box_right_content {
        .chat_message_item_box_right_content_text {
          img {
            width: 20px;
            height: 20px;
            vertical-align: middle;
          }

          span {
            color: #333333;
            font-size: 14px;
          }
        }
      }

      .successTip {
        margin-top: 13px;
        display: flex;
        align-items: center;

        .successImg {
          width: 14px;
          height: 14px
        }

        .successSpan {
          font-size: 12px;
          color: #949494;
          margin-left: 8px;
          line-height: 20px;
        }
      }

      .dashline {
        border-top: 1px dashed rgba(151, 151, 151, 0.2);
        width: auto;
        margin-top: 8px;
      }

      .copy {
        margin-top: 10px;
        width: auto;
        display: flex;
        /* align-items: center; */
        /* justify-content: space-between; */
        flex-direction: column;

        .content {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 82px;
          height: 35px;
          border-radius: 7px;
          border: 1px solid #bababa;
          font-size: 14px;
          color: #333333;
          line-height: 20px;
          cursor: pointer;
        }

        .tip {
          margin-top: 10px;
          font-size: 12px;
          color: #949494;
          line-height: 17px;
        }
      }
    }

    .user_message_box {
      background: #3076ff;
      color: #ffffff;
      border-radius: 5px;
      margin-right: 14px;
      font-size: 16px;
    }
  }
}
</style>
