<template>
  <!--更换手机号弹窗-->
  <el-dialog
    class="dialog-phone"
    :visible.sync="dialogFormVisible"
    :center="true"
    @close="cancelFn"
    :close-on-click-modal="false"
  >
    <div class="tip-form">
      <img src="@/assets/intelligentInterview/wranning.png" alt="" />
      <div class="name" >注销账号</div>
      <div class="tip">请确认是否注销账号</div>
      <div class="tip">账号注销后，将会删除账号的全部数据，</div>
      <div class="tip">无法找回，请谨慎操作</div>
      <div class="btnBox">
        <el-button class="cancel" type="info" @click="confirmFn">确认注销</el-button>
        <el-button type="primary" @click="cancelFn">还是算了</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import * as $api from '@/api/personalCenter'
import store from "@/store";
export default {
  name: "tipDialog",
  data() {
    return {
      dialogFormVisible: false,
    };
  },
  created() {
  },
    methods: {
        cancelFn() { 
            this.dialogFormVisible = false;
            this.$emit('isOutFn')
        },
        show() {
            this.dialogFormVisible = true;
        },
        confirmFn() { 
            $api.logoffAPI({}).then(res => {
                if (res.code == 200) {
                    this.$router.push('/home')
                    store.commit('SET_TOKEN', null)
                    this.$forceUpdate()
                }
            })
        },
  },
};
</script>

<style lang="scss" scoped>
// 更换手机弹窗
.dialog-phone {
  ::v-deep .el-dialog {
    width: 532px;
    border-radius: 8px;
    // 居中
    margin: 0 !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .el-dialog__header {
      padding: 0 !important;
    }

    .el-dialog__body {
      padding: 0 !important;
    }
    .el-dialog__headerbtn .el-dialog__close {
      font-size: 24px;
    }

    .tip-form {
      padding: 53px 45px;
      background: #fff;
      border-radius: 8px;

      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      img {
        width: 56px;
        height: 56px;
      }
      .name {
        margin: 18px 0;
        font-weight: 500;
        font-size: 20px;
        color: #333333;
      }
      .tip {
        font-weight: 400;
        font-size: 16px;
        color: #333333;
        line-height: 25px;
      }

      .btnBox {
        margin-top: 46px;
        .el-button {
          width: 168px;
          height: 50px;
          background: #236ce0;
          border-radius: 5px;

          font-weight: 500;
          font-size: 18px;
          color: #ffffff;
        }
        .cancel {
          margin-right: 30px;
          border: 1px solid #9D9D9D;
          color: #333333;
          background: #fff;
        }
      }
    }
  }
}
</style>
