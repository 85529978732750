import Layout from "@/layout";

const personalCenterRouter = {
  path: '/personalCenter',
  component: Layout,
  // redirect: '/personalCenter/index',
  name: 'personalCenter',
  children: [
    {
      path: 'index',  // 工人
      component: () => import('@/views/personalCenter/index'),
      name: 'personalCenterIndex',
      meta: {
        // noBottomBar : true,
      },
      children: [
        
      ]
    },
    {
      path: 'indexHr',
      component: () => import('@/views/personalCenter/indexHr'),
      name: 'personalCenterIndexHr',
      meta: {
        // noBottomBar : true,
      },
      children: [
        
      ]
    },
    // {
    //   path: 'myRegistration', // 我的报名
    //   component: () => import('@/views/personalCenter/myRegistration'),
    //   name: 'myRegistration',
    // },
    // {
    //   path: 'myPosition', // 我的岗位
    //   component: () => import('@/views/personalCenter/myPosition'),
    //   name: 'myPosition',
    // },
    // {
    //   path: 'myPositionList', // 我的岗位列表
    //   component: () => import('@/views/personalCenter/myPositionList'),
    //   name: 'myPositionList',
    //   meta: {
    //     highIndex: 'myPosition'
    //   }
    // },
    {
      path: 'jobDetails', // 岗位详情
      component: () => import('@/views/personalCenter/jobDetails'),
      name: 'jobDetails',
      meta: {
        // noBottomBar : true,
        highIndex: 'myPosition'
      }
    },
    {
      path: 'jobDetailsAI', // 岗位详情
      component: () => import('@/views/personalCenter/jobDetailsAI'),
      name: 'jobDetailsAI',
      meta: {
        // noBottomBar : true,
        highIndex: 'myPosition'
      }
    },
    // {
    //   path: 'enterpriseInformation', // 企业信息
    //   component: () => import('@/views/personalCenter/enterpriseInformation'),
    //   name: 'enterpriseInformation',
    //   meta: {
    //     highIndex: 'enterpriseInformation'   //个人中心 左侧导航栏高亮   又从不同页面跳进来
    //   }
    // },
    // {
    //   path: 'historyInterview', // 历史面试
    //   component: () => import('@/views/personalCenter/historyInterview'),
    //   name: 'historyInterview',
    //   meta: {
    //     noBottomBar : true,
    //     highIndex: 'historyInterview'
    //   }
    // },
    // {
    //   path: 'historyInterviewDetail', // 历史面试详情
    //   component: () => import('@/views/personalCenter/historyInterviewDetail'),
    //   name: 'historyInterviewDetail',
    //   meta: {
    //     noBottomBar : true,
    //     highIndex: 'historyInterview'
    //   }
    // },
    // {
    //   path: 'myResume', // 我的简历
    //   component: () => import('@/views/personalCenter/myResume'),
    //   name: 'myResume',
    //   meta: {
    //     highIndex: 'myResume'
    //   }
    // },
   
  ]
}

export default personalCenterRouter
