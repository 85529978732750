<template>
    <div class="resumeTemplateRight-container">
        <div class="container" id="ResumeTemplate">
            <div class="left-wrap">  
                <div class="left-header">
                    <div class="avater">
                        <div class="inside-border">
                            <el-image v-if="showData && showData.avatar" :src="showData.avatar">
                            </el-image>
                        </div>
                    </div>
                    <div class="name">{{ showData.userName  }}</div>
                    <div class="sex" >{{ showData.sexName  }}</div>
                </div>

                <div class="base-info">
                    <div class="title">
                        <img src="@/assets/resumeTemplateDownload/base.png" alt="">
                        <span class="label">基本信息</span>
                    </div>
                    <div class="info">
                        <p class="info-item">
                            <span>出生年月：</span>
                            <span>{{ showData.birthDate }}</span>
                        </p>
                        <p class="info-item" >
                            <span>文化程度：</span>
                            <span>{{ showData.eduLevelName  }}</span>
                        </p>
                        <p class="info-item">
                            <span>毕业学校：</span>
                            <span>{{ showData.graduate }}</span>
                        </p>
                        <p class="info-item" v-if="showData.skill">
                            <span>技能：</span>
                            <span>{{ showData.skill }}</span>
                        </p>
                        <p class="info-item">
                            <span>技能证书：</span>
                            <span v-if="showData.certNames">{{ showData.certNames }}<span
                                    v-if="showData.certNames">-</span> {{ showData.certSecNames }}</span>
                            <span v-else>暂无</span>
                        </p>
                    </div>
                </div>
                <div class="base-info">
                    <div class="title">
                        <img src="@/assets/resumeTemplateDownload/bao.png" alt="">
                        <span class="label">求职意向</span>
                    </div>
                    <div class="info">
                        <p class="info-item">
                            <span>期望岗位：</span>
                            <span v-if="showData.typeName">{{ showData.typeName + '/' + showData.workTypesName }}</span>
                        </p>
                        <p class="info-item">
                            <span>期望薪资：</span>
                            <span
                                v-if="showData.minWorkSalarys && showData.minWorkSalarys != -1 && showData.maxWorkSalarys != -1">{{
                                    showData.minWorkSalarys + '-' + showData.maxWorkSalarys }} {{ showData.workSalarysUnitName }}</span>
                            <span v-else>面议</span>
                        </p>
                        <p class="info-item">
                            <span>期望工作地：</span>
                            <span>{{ showData.workPlaceWant }}</span>
                        </p>
                        <p class="info-item"
                            v-if="showData.csUserJobWantExtend && showData.csUserJobWantExtend.otherBenefitsRequired">
                            <span>其他要求：</span>
                            <span>{{ showData.csUserJobWantExtend.otherBenefitsRequired }}</span>
                        </p>
                    </div>
                </div>
            </div>
            <div class="right-wrap">
                <div class="right-header">
                    <div class="name">我的个人简历</div>
                    <div class="subName">我,期待一个自我展示的舞台</div>
                </div>
                <div class="work-wrap" v-if="showData.csJobExperiences && showData.csJobExperiences.length > 0">
                    <div class="title">
                        <img src="@/assets/resumeTemplateDownload/work.png" alt="">
                        <span class="label">工作经历</span>
                    </div>
                    <div class="content">
                        <div class="content-item"  v-for="(item, index) in showData.csJobExperiences" :key="index">
                            <div class="name">
                                <span>{{ item.entryTime }}<span style="margin: 0 8px;">至</span> {{ item.exitTime}} </span>
                                <span>{{ item.workplace}}</span> 
                            </div>
                            <div class="tag">{{item.job}}</div>
                            <div class="info">{{item.jobContent}}</div>
                        </div>
                    </div>
                </div>
                <div class="desc-wrap">
                    <div class="title">
                        <img src="@/assets/resumeTemplateDownload/desc.png" alt="">
                        <span class="label">自我介绍</span>
                    </div>
                    <div class="content">
                        {{showData.descr}}
                    </div>
                </div>
            </div>
        </div>
        <el-button class="downLoading-btn" @click="exportPic"><i class="el-icon-download"></i> 点击下载简历模板二</el-button>
    </div>
</template>

<script>
import html2Canvas from 'html2canvas';
import moment from 'moment';
export default {
    name: 'resumeTemplateRight',
    components: {},
    data() {
        return {
            showData: {}
        };
    },

    methods: {
        getInfo(showData) {
            this.showData = showData
            this.showData.birthDate = this.showData.birthDate ? moment(this.showData.birthDate).format("YYYY-MM-DD") : ''
        },
        // 导出简历
        exportPic() {
            this.downloading = true
            html2Canvas(document.querySelector("#ResumeTemplate"), {
                allowTaint: true,  //开启跨域
                useCORS: true,
            }).then(canvas => {
                let dataURL = canvas.toDataURL("image/png");
                if (dataURL !== "") {
                    this.downloading = false
                    var a = document.createElement('a')
                    a.download = "个人简历模板二";
                    a.href = dataURL;
                    a.click()
                }
            }).catch(() => {
                this.downloading = false
            })
        },

    }
}
</script>
<style lang='scss' scoped>
.resumeTemplateRight-container {
    position: relative;
    width: 100%;
    background: #fff;
    .downLoading-btn {
        position: absolute;
        // bottom: 0;
        margin-top: 50px;
        left: 50%;
        transform: translateX(-50%);
        color: #333;
        font-size: 18px;
    }
}
::v-deep.container {
    display: flex;
    font-family: PingFangSC, PingFang SC;
    height: 100%;
    .left-wrap {
        flex-shrink: 0;
        width: 260px;
        background: url('@/assets/resumeTemplateDownload/leftBG.png') no-repeat;
        background-size: 100% 100%;
        .left-header {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-top: 50px;
            .avater {
                border: 1px solid #FFFFFF;
                width: 132px;
                height: 132px;
                border-radius: 50%;

                .inside-border {
                    border: 1px solid #EFC497;
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                    box-sizing: border-box;
                }

                .el-image {
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                }
            }
            .name {
                margin-top: 20px;
                font-weight: 500;
                font-size: 34px;
                color: #E3C89D;
            }
            .sex {
                font-weight: 500;
                font-size: 20px;
                color: #E3C89D;
            }
        }
        // 基本信息
        .base-info {
            padding: 0 37px 0 27px;
            .title {
                display: flex;
                align-items: center;
                border-bottom: 1px solid  #E3C89D;
                padding-bottom: 2px;
                margin: 44px 0 18px;
                img {
                    width: 16px;
                    height: 16px;
                    margin-right: 6px;
                }
                .label {
                    font-weight: 500;
                    font-size: 18px;
                    color: #E3C89D;
                }
            }
            .info {
                p {
                    padding: 0;
                }
                .info-item {
                    margin-bottom: 10px;
                    font-weight: 400;
                    font-size: 14px;
                    color: #E3C89D;
                    .label {
                        width: max-content;
                    }
                }
            }
        }
    }

    //  右侧
    .right-wrap {
        flex: 1;
        .right-header {
            height: 114px;
            background: #ECEEEF;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .name {
                font-weight: 500;
                font-size: 32px;
                color: #333333;
            }
            .subName {
                margin-top: 5px;
                font-weight: 500;
                font-size: 14px;
                color: #333333;
            }
        }
        // 工作经历
        .work-wrap {
            padding: 17px 22px;
                .title {
                    display: flex;
                    align-items: center;
                    border-bottom: 1px solid  #333;
                    padding-bottom: 2px;
                    img {
                        width: 16px;
                        height: 16px;
                        margin-right: 6px;
                    }
                    .label {
                        font-weight: 500;
                        font-size: 16px;
                        color: #333333;
                    }
                }
                .content {
                  .content-item {
                    .name {
                        margin-top: 13px;
                        display: flex;
                        justify-content: space-between;
                        flex-wrap: wrap;
                        align-items: center;
                        font-weight: 500;
                        font-size: 12px;
                        color: #333333;
                    }
                    .tag {
                        width: max-content;
                        margin-top: 5px;
                        height: 20px;
                        line-height: 20px;
                        padding: 0 6px;
                        font-weight: 500;
                        font-size: 12px;
                        color: #E3C89D;
                        background: #313131;
                    }
                    .info {
                        margin-top: 8px;
                        font-weight: 400;
                        font-size: 12px;
                        color: #333333;
                        line-height: 15px;
                    }
                  }
                }
        }
        // 个人描述
        .desc-wrap {
            padding: 17px 22px;
                .title {
                    display: flex;
                    align-items: center;
                    border-bottom: 1px solid  #333;
                    padding-bottom: 2px;
                    img {
                        width: 16px;
                        height: 16px;
                        margin-right: 6px;
                    }
                    .label {
                        font-weight: 500;
                        font-size: 16px;
                        color: #333333;
                    }
                }
                .content {
                    margin-top: 16px;
                    font-weight: 400;
                    font-size: 12px;
                    color: #333333;
                    line-height: 15px;
                }
        }
    }

}
</style>