<template>
  <!-- <v-scale-screen width="1920" bodyOverflowHidden="false" fullScreen="false" style="background: linear-gradient(180deg, #DDEDFE 0%, #ECF1FA 100%)"> -->

  <div class="home_container">
    <!-- 左边容器 -->
    <div class="left-container">
      <!-- 左上- 人力资源领域知识问答-->
      <div class="left-1">
        <div class="left-1-header">
          <img
              class="left-1-logo"
              src="../../assets/common/blue-border.png"
              alt=""
          />
          <img
              class="left-1-title"
              src="../../assets/common/left-1-title.png"
              alt=""
          />
        </div>
        <div class="left-1-box">
          <hr-chat></hr-chat>
        </div>
      </div>

      <!-- 左下-AI找活 -->
      <div class="left-2">
        <div class="left-2-header">
<!--          <img-->
<!--              class="left-2-logo"-->
<!--              src="../../assets/common/blue-border.png"-->
<!--              alt=""-->
<!--          />-->
          <img
              class="left-2-logo"
              src="../../assets/common/left-2-title.png"
              alt=""
          />
        </div>
        <div class="left-2-box">
          <ai-find-work :work-data="aiFindWorkData"></ai-find-work>
        </div>
      </div>
    </div>

    <!-- 中间-ai数字人 -->
    <div class="ai-center">
      <div class="ai-box" @click="togglePlayPause">
        <!--        <img :src="userImage" alt="" />-->
        <video
            ref="backgroundVideo"
            :src="videoSrc"
            playsinline
            @loadedmetadata="onVideoMetadataLoaded"

            autoplay
        ></video>
        <!-- @click="togglePlayPause" -->
        <!-- @click.stop -->
        <!-- @ended="playEnd()" -->
        <div v-if="boxShow" class="hot" @click="playShow()"></div>
      </div>
      <!-- 中央的底部-智能面试 -->
      <div class="center-bottom">
        <div class="center-bottom-header">
          <img
              class="center-bottom-logo"
              src="../../assets/common/blue-border.png"
              alt=""
          />
          <img
              class="center-bottom-title"
              src="../../assets/common/center-bottom-title.png"
              alt=""
          />
        </div>
        <div class="center-bottom-box">
          <el-row>
            <el-col v-show="span == 8" :span="span">
              <div class="bottom-btn" @click="interViewLink(2)">
                <img
                    src="../../assets/common/home-my-resume.png"
                    class="button"
                />
              </div>
            </el-col>
            <el-col :span="span">
              <div class="bottom-btn" @click="interViewLink(1)">
                <img src="../../assets/common/home-moni.png" class="button"/>
              </div>
            </el-col>
            <el-col :span="span">
              <div class="bottom-btn" @click="interViewLink(3)">
                <img
                    src="../../assets/common/home-history.png"
                    class="button"
                />
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>

    <!-- 右边容器 -->
    <div class="right-container">
      <!-- 右上-权益守护AI助手 -->
      <div class="right-1">
        <div class="right-1-header">
          <img
              class="right-1-logo"
              src="../../assets/common/blue-border.png"
              alt=""
          />
          <img
              class="right-1-title"
              src="../../assets/common/right-1-title.png"
              alt=""
          />
        </div>
        <div class="right-1-box">
          <div class="question-box">
            <div class="question-box-content">
              <div class="header">
                <div>
                  <span
                      class="caini"
                  >猜你想问</span
                  >
                </div>
                <div style="cursor: pointer" @click="getLawQuestionList()">
                  <img
                      src="../../assets/icon/aiFindWork/reload_btn.png"
                      alt=""
                  />
                  <span class="refresh">换一批</span>
                </div>
              </div>
              <div class="line"></div>
              <!-- 猜你想问 -->
              <div class="question-list-box">
                <!-- 左侧数据 -->
                <div class="question-list">
                  <div
                      class="q1"
                      v-for="(item, index) in leftColumn"
                      :key="index"
                  >
                    <el-tooltip
                        class="item"
                        effect="dark"
                        :content="item.dialogueContent"
                        placement="left"
                    >
                      <span @click="linkToDetail(item.dialogueContent)">{{
                          item.dialogueContent
                        }}</span>
                    </el-tooltip>
                  </div>
                </div>
                <!-- 竖线分隔符 -->
                <div class="divider"></div>
                <!-- 右侧数据 -->
                <div class="question-list">
                  <div
                      v-for="(item, index) in rightColumn"
                      :key="index"
                      class="q1"
                  >
                    <el-tooltip
                        class="item"
                        effect="dark"
                        :content="item.dialogueContent"
                        placement="left"
                    >
                      <span @click="linkToDetail(item.dialogueContent)">{{
                          item.dialogueContent
                        }}</span>
                    </el-tooltip>
                  </div>
                </div>
              </div>

              <!-- 对话 -->
              <div class="chat-box">
                <!-- ai消息气泡 -->
                <div class="robot-chat">
                  <span>您好,我是您的权益守护AI助手</span>
                </div>
                <div class="robot-chat">
                  <span>有什么可以帮到您?</span>
                </div>

                <!-- 用户消息气泡 -->
                <div class="user-chat">
                  <span>{{ randomQuestion }}</span>
                </div>
                <div class="robot-chat">
                  <span>正在思考...</span>
                </div>
                <!-- 问问题 -->
                <div class="postMessage-box">
                  <input
                      v-model="lawQuestion"
                      @keypress.enter.prevent="postMessage()"
                      type="text"
                      class="input-field"
                      placeholder="有什么问题尽管问我~"
                  />
                  <img
                      @click="postMessage()"
                      src="../../assets/common/home-video-icon.png"
                      alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 右下-AI招工 -->
      <div class="right-2">
        <div class="right-2-header">
<!--          <img-->
<!--              class="r1"-->
<!--              src="../../assets/common/blue-border.png"-->
<!--              alt=""-->
<!--          />-->
          <img
              class="r1"
              src="../../assets/common/right-2-title.png"
              alt=""
          />
        </div>
        <div class="right-2-box">
          <!-- 对话 -->
          <div class="chat-box">
            <!-- ai消息气泡 -->
            <div class="robot-chat-box">
              <div class="robot-img">
                <img
                    src="../../assets/icon/aiFindWork/aiAvatar.png"
                    alt=""
                />
              </div>
              <div class="robot-chat">
                <span class="rotspan"
                      style="font-size: 14px"
                >我是您的专属智能招工助手~</span
                >
              </div>
            </div>

            <!-- 用户消息气泡 -->
            <div class="user-chat-box">
              <div
                  class="user-chat"
                  style="cursor: pointer"
                  @click="linkNormal"
              >
                <img
                    class="u1"
                    src="../../assets/common/video-play.png"
                    alt=""
                />
                <img
                    class="u2"
                    src="../../assets/common/video-user-icon.png"
                    alt=""
                />
              </div>
              <div class="user-img">
                <img
                    src="../../assets/icon/aiFindWork/aiAvatar.png"
                    alt=""
                />
              </div>
            </div>

            <div class="robot-chat-box">
              <div class="robot-img">
                <img
                    src="../../assets/icon/aiFindWork/aiAvatar.png"
                    alt=""
                />
              </div>
              <div class="robot-chat">
                <span class="span1" style=""
                >根据您的需求描述，当前已为您找到30名空闲工人，<span class="span2" @click="linkNormal"
                >请点击查看</span
                ><img
                    class="rob1-img"
                    src="../../assets/common/goto-ai-worker.png"
                /></span>
              </div>
            </div>

            <!-- 问问题 -->
            <div class="postMessage-box">
              <input
                  v-model="workQuestion"
                  @keypress.enter.prevent="postWorkMessage()"
                  type="text"
                  class="input-field"
                  placeholder="有什么问题尽管问我~"
              />
              <img
                  @click="postWorkMessage()"
                  src="../../assets/common/home-video-icon.png"
                  alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <cantTo ref="cantTo"/>
    <change-rule ref="changeRule"/>
  </div>
  <!-- </v-scale-screen> -->
</template>
<script>
import {getCompanyInfoAPI} from "@/api/personalCenter";
import {updateUserIdentityApi, userInfoApi} from "@/api/user";
import store from "@/store";
import {MESSAGE_TYPE} from "vue-baberrage";
import AiFindWork from "@/views/home/components/aifindWork.vue";
import HrChat from "@/views/home/components/hrChat.vue";
import {
  questionListApi,
  getAiFindWorkDataApi,
  questionRandomApi,
} from "@/api/home";
import {$ApiGet} from "@/utils/common";
// import { getDistances } from "@/utils/distance/index"
// import VScaleScreen from '../../../node_modules/v-scale-screen'
export default {
  name: "firstHome",
  components: {
    // VScaleScreen
    AiFindWork,
    HrChat,
  },
  data() {
    return {
      span: 8,
      autoplayEnabled: true, // 假设默认开启自动点击功能
      autoClickTimer: null,
      boxShow: true,
      videoSrc: require("@/assets/video/video.mp4"),
      randomQuestion: "如何申请商标？商标侵权怎么办？",
      userImage: require("@/assets/intelligentInterview/user.png"),
      arrayList: [
        {
          id: 1,
          msg: "这里是问题这里是问题这里是问题这里是问题？",
          time: 5,
          type: MESSAGE_TYPE.NORMAL,
          extraWidth: 200,
        },
        {
          id: 2,
          msg: "这里是问题2？",
          time: 5,
          type: MESSAGE_TYPE.NORMAL,
          extraWidth: 200,
        },
        {
          id: 3,
          msg: "这里是问题3？",
          time: 5,
          type: MESSAGE_TYPE.NORMAL,
          extraWidth: 200,
        },
      ],
      barrageList: [],
      LawQuestionList: [
        "中国女职工劳动保护权益",
        "如何确定工伤认定办法",
        "如何保护公司商业机密",
        "最低工资规定",
        "职工工作时间的规定",
        "企业民主管理规定",
      ],
      aiFindWorkData: {},
      lawQuestion: "",
      workQuestion: "",
      leftColumn: [],
      rightColumn: [],
      lawForm: {
        pageSize: 6,
        pageNum: 1,
        serviceType: 4,
      },
      token: "",
      userInfo: {}
    };
    },
    methods: {
    handleButtonClick() {
      // 按钮点击时的常规处理逻辑
      //this.$refs.backgroundVideo.muted = false;
    },

    //获取法律助手随机问题
    getRandomQuestion() {
      questionRandomApi({})
          .then((res) => {
            this.randomQuestion = res.data.dialogueContent;
          })
          .catch(() => {
          });
    },
    playShow() {
      this.$refs.backgroundVideo.play();
      this.boxShow = false;
    },
    playEnd() {
        // console.log("播放完成");
      console.log('playEnd',)
      this.$refs.backgroundVideo.load();
    },
    interViewLink(num) {
      if (num === 1) {
        if (store.state.user.token) {
          this.$router.push({
            path: "/aiInterview/index",
          });
        } else {
          this.$showLoginModal(true);
        }
      } else if (num === 2) {
        if (store.state.user.token) {
          this.$router.push({
            path: "/personalCenter/index",
            query: {
              name: "我的简历",
            },
          });
        } else {
          this.$showLoginModal(true);
        }
      } else if (num === 3) {
        if (store.state.user.token) {
          this.$router.push({
            path: "/personalCenter/index",
            query: {
              name: "历史面试",
            },
          });
        } else {
          this.$showLoginModal(true);
        }
      }
    },
    /**
     * 打开切换角色提示的窗口
     * @param success 切换成功回调
     */
    showChangeRole(success) {
      this.$refs.changeRule.showDialog(success);
    },
    postWorkMessage() {
      if (!this.$store.state.user.token) {
        this.$showLoginModal(true);
        return;
      }
      const success = () => {
        this.$router.push({
          path: "/aiFindWorker/index?serviceType=1",
          query: {
            context: this.workQuestion,
          },
        });
      }
      this.$store.state.user.identity === '1' ? this.showChangeRole(success) : success();
    },
    linkNormal() {
      const success = () => {
        this.$router.push({
          path: "/aiFindWorker/index?serviceType=1",
        });
      }
      this.$store.state.user.identity === '1' ? this.showChangeRole(success) : success();
    },
    showLawTip() {
      this.$refs.cantTo.showDialog();
    },
    lookIsLawGoTo() {
    },
    postMessage() {
      if (!this.$store.state.user.token) {
        this.$showLoginModal(true);
        return;
      }
      this.linkToDetail(this.lawQuestion)
    },
    async linkToDetail(question) {
      if(store.state.user.identity === '1') {
        try {
          const res = await this.$refs.changeRule.showDialog();
          if(!res) {
            return;
          }
        } catch(e) {
          return;
        }
      }
      userInfoApi({}).then((res) => {
        // console.log(res.data);
        this.userInfo = res.data;
        if(!this.userInfo) {
          return;
        }
        const jumpTo = () => {
          const route = {
            path: "/hrStatute/index?serviceType=4"
          };
          if(question) {
            route.query = {
              context: question,
            }
          }
          this.$router.push(route);
        }
        if(this.userInfo.legalAsstPerFlag === 1) {
          //1为所有通过,直接跳转
          jumpTo();
        } else if(this.userInfo.legalAsstPerFlag === 0 && this.userInfo.jobRoles == '0') {
          //是hr,个人信息没填,先跳到个人信息去
          this.showLawTip();
        } else {
          //是hr,个人信息也填了法律,则判断企业信息是否审核通过
          getCompanyInfoAPI({}).then((companyRes) => {
            // console.log("这是企业信息", companyRes);
            const companyStatus = companyRes.data.rows != null && companyRes.data.rows[0] ? companyRes.data.rows[0].auditStatus === "1" : false;
            if(!companyStatus) {
              this.showLawTip();
              return;
            }
            jumpTo();
          }).catch((companyError) => {
          });
        }
      }).catch(e =>{

      })
    },
    //权益守护AI助手预设问题
    getLawQuestionList() {
      questionListApi(this.lawForm)
          .then((res) => {
            //将数组res.data.rows中的数据通过filter方法按照下标过滤，是单数的加入到this.leftColumn，偶数的加入到this.rightColumn
            this.leftColumn = res.data.rows.filter((item, index) => {
              return index % 2 === 0;
            });
            this.rightColumn = res.data.rows.filter((item, index) => {
              return index % 2 !== 0;
            });
            if (this.lawForm.pageNum * this.lawForm.pageSize < res.data.total) {
              this.lawForm.pageNum++;
            } else {
              this.lawForm.pageNum = 1;
            }
          })
          .catch(() => {
          });
    },

    //检查并更新敏感词
    checkAndUpdateSensitiveWords() {
      this.token = store.state.user.token;
      const lastUpdated = localStorage.getItem("sensitiveWordsLastUpdated");
      const tenDays = 10 * 24 * 60 * 60 * 1000; // 10天的毫秒数
      // 检查是否存在时间戳，或者时间戳是否超过10天
      if (!lastUpdated || Date.now() - lastUpdated > tenDays) {
        this.getSensitiveWordsList(this.token);
      } else {
        return;
      }
    },
    //获取敏感词数据
    getSensitiveWordsList(token) {
      $ApiGet("/api/crm/web/index/getSensitiveWords", {}, token)
          .then((res) => {
            // console.log("敏感词数据:", res);
            const sensitiveWords = res.split(" "); // 将字符串分割成数组
            // console.log(sensitiveWords);
            localStorage.setItem(
                "sensitiveWords",
                JSON.stringify(sensitiveWords)
            );
            localStorage.setItem(
                "sensitiveWordsLastUpdated",
                Date.now().toString()
            ); // 更新时间戳
          })
          .catch((error) => {
            console.error("错误信息:", error);
          });
    },
    //获取AI找活统计数据
    getAiFindWorkData() {
      const data = {
        proArea: "贵阳",
        distMark: "1",
      };
      getAiFindWorkDataApi(data)
          .then((res) => {
            this.aiFindWorkData = res.data;
          })
          .catch((error) => {
            console.error("错误信息:", error);
          });
    },
    //获取AI招工统计数据
    // getAiWorkerData() {
    //   $ApiPost('/api/crm/web/index/homePageAIEmployWorkerCount', {
    //   }).then(res => {
    //     console.log("AI招工统计数据:", res);
    //   }).catch(error => {
    //     console.error("错误信息:", error);
    //   });
    // },
    pauseDanmu() {
      this.danmuPaused = true; // 暂停动画
    },
    resumeDanmu() {
      this.danmuPaused = false; // 恢复动画
    },
    // video防止抖动
    onVideoMetadataLoaded(event) {
      const videoElement = event.target;
      videoElement.style.transform = 'scale(1)';
    //   videoElement.style.width = `${videoElement.videoWidth}px`;
    //   videoElement.style.height = `${videoElement.videoHeight}px`;
    },
    stopVideoOnOutsideClick(event) {
      // 检查点击的目标是否不是视频元素
      if (!this.$refs.backgroundVideo.contains(event.target)) {
        this.$refs.backgroundVideo.pause();
      }
    },
    // 点击视频 播放或暂停
    togglePlayPause() {
        const videoElement = this.$refs.backgroundVideo;
      if (videoElement.paused) {
        videoElement.play(); // 如果当前是暂停状态，则开始播放
      } else {
        videoElement.pause(); // 如果当前正在播放，则暂停
      }
    }
  },
  created() {
    this.checkAndUpdateSensitiveWords();
    this.getAiFindWorkData();
    // this.getAiWorkerData();
  },
  mounted() {
    // 假设在组件挂载后立即启动延时自动点击
    //this.scheduleAutoClick(2000); // 设置延时为2秒
    // getDistances('30.572961','104.066301');

    if (store.state.user.identity == 1) {
      this.span = 8;
    } else if (store.state.user.identity == 2) {
      this.span = 12;
    }
    store.watch(
        () => store.state.user.identity,
        (newValue, oldValue) => {
          this.span = newValue === '1' ? 8 : 12;
        }
    );
    this.getLawQuestionList();
    this.getRandomQuestion();
    this.$nextTick(() => {
        // this.$refs.backgroundVideo.play();
    })
    // 添加点击事件监听器
    document.addEventListener('click', this.stopVideoOnOutsideClick);
  },
  beforeDestroy() {
    // 组件卸载时清除定时器，避免内存泄漏
    clearTimeout(this.autoClickTimer);
    this.$refs.backgroundVideo.load();  // 销毁数字人
    // 移除点击事件监听器
    document.removeEventListener('click', this.stopVideoOnOutsideClick);
  },
};
</script>

<style lang="scss" scoped>
.home_container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .left-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .left-1 {
      margin: 30px 0 0 41px;

      .left-1-header {
        width: 460px;
        height: 50px;
        position: relative;

        .left-1-logo {
          width: 498px;
          height: 50px;
        }

        .left-1-title {
          width: 464px;
          height: 30px;
          position: absolute;
          top: 10px;
          left: 25px;
        }
      }

      .left-1-box {
        margin-top: 10px;
        width: 498px;
        height: 400px;
        background: #ffffff;
        border-radius: 16px;
        border: 1px solid #b5cbdf;
      }
    }

    .left-2 {
      margin: 10px 0 20px 41px;

      .left-2-header {
        width: 498px;
        height: 50px;
        position: relative;

        .left-2-logo {
          width: 498px;
          height: 50px;
        }

        .left-2-title {
          width: 464px;
          height: 30px;
          position: absolute;
          top: 10px;
          left: 25px;
        }
      }

      .left-2-box {
        margin-top: 10px;
        width: 498px;
        height: 406px;
        background: #ffffff;
        border-radius: 16px;
        border: 1px solid #b5cbdf;
      }
    }
  }

  .ai-center {
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 25px 0 20px 0;
    // border: 1px solid red;
    .ai-box {
      width: 736px;
      height: 701px;
      border-radius: 16px;
      background: url("@/assets/video/homeViewPic.png");
      background-size: 100% 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      position: relative;
      transform: translateY(40px);

      video {
        width: 390px;
        transform: translateZ(0);
      }

      .hot {
        width: 50px;
        height: 50px;
        position: absolute;
        right: 0;
        bottom: 0;
      }

      img {
        cursor: pointer;
        width: 837px;
        height: 784px;
      }
    }

    .center-bottom {
      margin-top: 10px;

      .center-bottom-header {
        width: 786px;
        height: 50px;
        position: relative;

        .center-bottom-logo {
          //width: 786px;
          height: 60px;
        }

        .center-bottom-title {
          width: 742px;
          height: 30px;
          position: absolute;
          top: 15px;
          left: 25px;
        }
      }

      .center-bottom-box {
        margin-top: 20px;
        width: 786px;
        height: 142px;
        background: #ffffff;
        border-radius: 16px;
        border: 1px solid #b5cbdf;

        .bottom-btn {
          cursor: pointer;
          height: 70px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 35px 22px 0 22px;
          background: url("../../assets/common/home-bg-button.png") no-repeat;
          background-size: 210px 70px;
          background-position: center;

          .button {
            width: 90px;
            height: 20px;
            margin: 0 5px 5px 0;
          }
        }
      }
    }
  }

  .right-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px;

    .right-1 {
      margin: 15px 41px 0 0;

      .right-1-header {
        width: 498px;
        height: 50px;
        position: relative;

        .right-1-logo {
          width: 498px;
          height: 50px;
        }

        .right-1-title {
          width: 464px;
          height: 30px;
          position: absolute;
          top: 10px;
          left: 25px;
        }
      }

      .right-1-box {
        margin-top: 10px;
        width: 498px;
        height: 500px;
        background: #ffffff;
        border-radius: 16px;
        border: 1px solid #b5cbdf;

        .question-box {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 14px;

          .question-box-content {
            width: 463px;
            height: 226px;
            background: #ebf4fd;
            border-radius: 12px;

            .header {
              margin: 15px 20px;
              display: flex;
              justify-content: space-between;
              align-items: center;

              .caini {
                font-weight: 550;
                font-size: 18px;
                color: #333333;
              }

              img {
                width: 15px;
                height: 12px;
              }

              .refresh {
                font-size: 14px;
                color: #333333;
              }
            }

            .line {
              width: auto;
              height: 1px;
              margin: 0 15px 0 15px;
              background-color: rgba(151, 151, 151, 0.24);
            }

            .question-list-box {
              display: flex;
              flex-direction: row;
              margin-left: 10px;
              min-height: 176px;

              .question-list {
                margin: 0 20px;
                display: flex;
                flex-direction: column;
                width: 39%;

                .q1 {
                  margin-top: 25px;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
                }

                & span {
                  cursor: pointer;
                  font-size: 16px;
                  color: #333333;
                }

                & span:hover {
                  color: #285dab;
                }
              }

              .divider {
                margin: 15px 10px 0 10px;
                background-color: rgba(151, 151, 151, 0.24);
                width: 1px;
                height: 80px;
              }
            }

            .chat-box {
              margin: 5px 10px 0 10px;
              display: flex;
              flex-direction: column;

              .robot-chat {
                align-self: flex-start;
                /* 使元素对齐到容器的起始位置 */
                margin-top: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                width: max-content;
                /* 自适应内容宽度 */
                height: 34px;
                background: #ebf4fd;
                border-radius: 20px;

                & span {
                  font-size: 14px;
                  color: #285dab;
                  padding: 10px 12px;
                }
              }

              .user-chat {
                align-self: flex-end;
                /* 使元素对齐到容器的结束位置 */
                margin-top: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                width: max-content;
                /* 自适应内容宽度 */
                height: 34px;
                background: #3076ff;
                border-radius: 20px;

                & span {
                  font-size: 14px;
                  color: #ffffff;
                  padding: 10px 12px;
                }
              }

              .postMessage-box {
                display: flex;
                flex-direction: row;
                align-items: center;
                // margin-top: 5px;

                .input-field {
                  margin: 14px 10px 0 0;
                  background: #f2f4f8;
                  border-radius: 24px;
                  width: 382px;
                  height: 40px;
                  border-style: none;
                  padding-left: 16px;
                  font-weight: 400;
                  font-size: 14px;
                  flex-grow: 1;
                }

                img {
                  width: 54px;
                  height: 54px;
                  padding-top: 10px;
                  cursor: pointer;
                }

                .input-field::placeholder {
                  font-weight: 400;
                  font-size: 14px;
                  color: #969696;
                  line-height: 20px;
                  text-align: left;
                  font-style: normal;
                }
              }
            }
          }
        }
      }
    }

    .right-2 {
      margin: 10px 41px 20px 0;

      .right-2-header {
        width: 498px;
        height: 50px;
        position: relative;

        .r1 {
          width: 498px;
          height: 50px;
        }

        .r2 {
          width: 464px;
          height: 30px;
          position: absolute;
          top: 10px;
          left: 25px;
        }
      }

      .right-2-box {
        margin-top: 10px;
        width: 498px;
        height: 315px;
        background: #ffffff;
        border-radius: 16px;
        border: 1px solid #b5cbdf;

        .chat-box {
          margin: 20px 10px 20px 10px;
          display: flex;
          flex-direction: column;

          .robot-chat-box {
            display: flex;
            flex-direction: row;
            align-self: flex-start;
            max-width: 330px;
            margin-top: 10px;

            .robot-chat {
              /* 使元素对齐到容器的起始位置 */
              margin-top: 12px;
              display: flex;
              justify-content: center;
              align-items: center;

              /* 自适应内容宽度 */
              height: auto;
              background: #ebf4fd;
              border-radius: 2px 10px 10px 10px;

              .rotspan {
                padding: 10px 12px;
              }

              .span2 {
                color: #1957ff;
                cursor: pointer;
              }

              .span1 {
                font-size: 14px;
                color: #285dab;
                padding: 10px 4px 10px 12px;
              }

              .rob1-img {
                width: 12px;
                height: 8px;
                cursor: pointer;
              }
            }

            .robot-img {
              margin: 10px 6px 0 0;

              & img {
                width: 26px;
                height: 26px;
                border: 1px solid #aabedb;
                border-radius: 50%;
              }
            }
          }

          .user-chat-box {
            display: flex;
            flex-direction: row;
            align-self: flex-end;
            max-width: 200px;

            .user-chat {
              /* 使元素对齐到容器的结束位置 */
              margin-top: 12px;
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: row;
              width: max-content;
              /* 自适应内容宽度 */
              height: 34px;
              background: #3076ff;
              border-radius: 10px 2px 10px 10px;

              & span {
                font-size: 14px;
                color: #ffffff;
                padding: 10px 12px;
              }

              .u1 {
                width: 22px;
                height: 22px;
                padding: 0 8px;
                cursor: pointer;
              }

              .u2 {
                width: 81px;
                height: 16px;
                padding-right: 20px;
              }
            }

            .user-img {
              margin: 10px 0 0 6px;

              & img {
                width: 26px;
                height: 26px;
                border: 1px solid #aabedb;
                border-radius: 50%;
              }
            }
          }

          .postMessage-box {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 25px 15px 0 10px;

            .input-field {
              margin: 14px 10px 0 0;
              background: #f2f4f8;
              border-radius: 24px;
              width: 382px;
              height: 40px;
              border-style: none;
              padding-left: 16px;
              font-weight: 400;
              font-size: 14px;
              flex-grow: 1;
            }

            img {
              width: 54px;
              height: 54px;
              padding-top: 10px;
              cursor: pointer;
            }

            .input-field::placeholder {
              font-weight: 400;
              font-size: 14px;
              color: #969696;
              line-height: 20px;
              text-align: left;
              font-style: normal;
            }
          }
        }
      }
    }
  }
}
</style>
