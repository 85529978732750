<template>
    <div class="titleBar">
        <span class="name">{{ name }}</span>
        <!-- <img src="@/assets/resumeTemplateDownload/line.png" alt=""> -->
        <div class="line"></div>
    </div>
</template>

<script>
export default {
    name: 'titleBar',
    components: {},
    props: {
        name : {
            type: String,
            default: ''
        }
    },
    data() {
        return {

        };
    },

    methods: {}
}
</script>
<style lang='scss' scoped>
.titleBar {
    display: flex;
    align-items: center;
    .name {
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 18px;
        color: #1E356D;
        margin-right: 15px;
        position: relative;
        width: max-content;
        &::before {
            content: '';
            position: absolute;
            bottom: -3px;
            width: 100%;
            height: 5px;
            background: #52E8DF;
            border-radius: 3px;
        }
    }
    img {

        // flex: 1;

    }
    .line {
        flex: 1;
        height: 1px;
        background: #1E356D;
        position: relative;
        &::after {
            content: '';
            position: absolute;
            right: -6px;
            top: -3px;   

            // display: inline-block;
            width: 6px;
            height: 6px;
            border: 1px solid #1E356D;
            border-radius: 50%;
            box-sizing: border-box;
        }
    }
}

</style>