<template>
    <div class="rightIndex2-main">
        <div class="right-topBox">
            <div class="title">
                <div class="name">{{ companyName }}</div>
                <div class="btn" @click="EditEnterPrise">编辑企业信息</div>
            </div>
            <div class="line"></div>
            <div class="info">
                <div class="info-item" v-for="(itemInfo, indexInfo) in infoList" :key="indexInfo">
                    <span class="color99">{{ itemInfo.name }}</span>
                    <span class="overflowline2">{{ itemInfo.info }}</span>
                </div>
            </div>
        </div>
        <div class="right-bottomBox">
            <titleBar name="我的岗位" @GoMore="GoToMyRegistration" />
            <workCard v-if="workList.length !== 0" class="mt18" :workList="workList"/>
            <no-data  v-if="workList.length === 0"></no-data>
        </div>
    </div>
</template>

<script>
import titleBar from './titleBar.vue'
import noData from '@/views/aiInterview/components/noData.vue'
import workCard from '@/components/personalCenter/workCard.vue'
import { filter} from '@/utils/filters'
import {getHRCompanyApi, hrSendWorkApi} from '@/api/aiInterView'
import {dictTypeApi} from "@/api/aiFindWork";
import  store from '@/store'
export default {
    name: 'rightIndex2',
    components: {
        titleBar,
        workCard,
        noData
    },
    data() {
        return {
          infoList: [],
          workList: [],
          companyIndustry: [],
          unit: [],
          companyName: '',
          identity: ''
        };
    },
  mounted() {
    this.getDictTypeList()
    this.getUnitDictTypeList()
    this.getHrCompany()
    this.getWorkList()
  },
  created() {
    this.identity = store.state.user.identity
    store.watch(() => store.state.user.identity, (newValue, oldValue) => {
        this.identity = newValue
    })
  },
  methods: {
      getWorkList(){
        hrSendWorkApi({pageNum:1,pageSize:2}).then(res=> {
          if(res.data.rows.length !== 0){
            this.workList = res.data.rows.length === 0 ? [] : res.data.rows.map(item => {
              let a = ''
              if(item.postType == 1){
                a = '长期岗位'
              }else if(item.postType == 2){
                a = '短期岗位'
              }else if(item.postType == 3){
                a = '兼职/代班'
              } else if(item.postType == 0){
                a = '灵活用工'
              }
              return {
                name: item.proName,
                money: item.minProSpend + '-' + item.maxProSpend,
                tagList: [a,item.employeesNum + '人'],
                km: 20,
                btn: true,
                adress: item.proArea,
                unit: filter(this.unit, item.proUnit),
                id: item.id,
                list: item,
                companyName: item.proCompanyName
              }
          })
          }else {
            this.workList = []
          }
        }).catch(()=>{

        })
      },
      getDictTypeList(){
        dictTypeApi({ dictType: 'crm_company_industry' }).then(res=> {
          this.companyIndustry = res.data
        }).catch(() => {
        })
      },
      getUnitDictTypeList(){
        dictTypeApi({ dictType: 'crm_pro_unit' }).then(res=> {
          this.unit = res.data
        }).catch(() => {
        })
      },
      filter,
      getHrCompany(){
        getHRCompanyApi({}).then(res=> {
          if(res.data.rows.length !== 0){
            let a = {}
            Object.assign(a,res.data.rows[0])
            this.companyName = a.companyName
            this.infoList = [
                { name: '企业名称', info: a.companyName, },
                { name: '统一社会信用代码', info: a.companyCreditCode ? a.companyCreditCode : '暂无', },
                { name: '负责人', info: a.companyContact, },
                { name: '负责人联系电话', info: a.companyTel, },
                { name: '所属行业', info: filter(this.companyIndustry,a.companyIndustry)},
                { name: '企业地址', info: a.companyAddress, },
                ]
          }else {
            this.companyName = '暂未填写企业信息'
            this.infoList = [
              { name: '企业名称', info: '暂未填写', },
              { name: '统一社会信用代码', info: '暂未填写', },
              { name: '负责人', info: '暂未填写', },
              { name: '负责人联系电话', info: '暂未填写', },
              { name: '所属行业', info: '暂未填写'},
              { name: '企业地址', info: '暂未填写', },
            ]
          }
        }).catch(()=> {

        })
      },
        // 我的岗位  更多按钮
        GoToMyRegistration() {
          // this.$router.push('/personalCenter/myPosition?highIndex=5')
          if(this.identity == 2) {   // hr
              this.$router.push({ path: '/personalCenter/indexHr', query: { name: '我的岗位', } });
          } else {  // 工人
              this.$router.push({ path: '/personalCenter/index', query: { name: '我的岗位', } });
          }
        },
        // 编辑企业信息
        EditEnterPrise() {
            // this.$router.push('/personalCenter/enterpriseInformation?highIndex=4')
            if(this.identity == 2) {   // hr
              this.$router.push({ path: '/personalCenter/indexHr', query: { name: '企业信息',  } });
            } else {  // 工人
                this.$router.push({ path: '/personalCenter/index', query: { name: '企业信息', } });
            }
        },
    }
}
</script>
<style lang='scss' scoped>
* {
    box-sizing: border-box;
}
.rightIndex2-main {
    .right-topBox {
        box-shadow: 0px 2px 22px 0px rgba(0, 0, 0, 0.14);
        border-radius: 12px;
        position: relative;
        display: flex;
        flex-direction: column;
        .title {
            height: 126px;
            padding-left: 33px;
            background: linear-gradient(180deg, #CBDFFF 0%, #FFFFFF 100%);
            border-radius: 12px 12px 0px 0px;

            .name {
                margin-top: 30px;
                font-weight: 500;
                font-size: 20px;
                color: #010101;
                line-height: 28px;
            }

            .btn {
                display: inline-block;
                margin-top: 10px;
                padding: 0 10px;
                font-weight: 400;
                font-size: 14px;
                color: #555555;
                line-height: 28px;
                text-align: center;
                border-radius: 4px;
                border: 1px solid #555555;
                cursor: pointer;
            }
        }

        .line {
            margin: 0 13px;
            height: 1px;
            background: rgba(151, 151, 151, 0.2)
        }

        .info {
            flex: 1;
            padding: 22px 33px;
            background: #fff;
            box-sizing: border-box;
            border-radius: 0 0 12px 12px;

            .info-item {
                margin-bottom: 20px;
                font-weight: 400;
                font-size: 16px;
                color: #333;

                display: flex;
                align-items: center;

                .color99 {
                    color: #999999;
                    margin-right: 23px;
                }
            }
        }

    }

    ::v-deep .right-bottomBox {
        margin-top: 24px;
        box-shadow: 0px 2px 22px 0px rgba(0, 0, 0, 0.14);
        border-radius: 12px;
        background: #fff;

        padding: 19px 26px;
        overflow: hidden;

        .mt18 {
            margin-top: 18px;
        }

        .list-item {
            margin-bottom: 15px;
            border: 1px solid #DBDBDB;
        }


    }
}
</style>
