<template>
    <div class="type9Personality-container">
        <titleBar name="9型人格测试结果报告"/>
        <div class="content-wrap">
            <div class="grid-cloumn">
                <div class="test-wrap">
                    <div class="name">你的9型人格分析是：</div>
                    <div class="type"> {{  detailInfo.enneagramName }}</div>
                    <div class="info"> {{ detailInfo.enneagramDesc }} </div>
                </div>
                <div class="beIntested-wrap">
                    <span class="name">可能感兴趣的工作</span>
                    <div class="tag" v-if="detailInfo.willInterestWork && detailInfo.willInterestWork.length > 0">
                        <span 
                            class="tag-item" 
                            v-for="(item, index) in detailInfo.willInterestWork" 
                            :key="index">{{item}}</span>
                    </div>
                </div>
            </div>
            <baseContent name="性格特征" style="margin-top: 38px" :detailInfo="detailInfo.characterTrait"/>
            <baseContent name="个人发展建议" style="margin-top: 57px" :detailInfo="detailInfo.personalDevelopAdvice"/>
        </div>
    </div>
</template>

<script>
import baseContent from './base-content.vue'
import titleBar from './titleBar.vue'
import * as $api from '@/api/personalCenter'
export default {
    components: {
        baseContent,
        titleBar
    },
    data() {
        return {
            mbtiTypeData: [],
            detailInfo: {}
        };
    },
    created() {
        this.getDicType();
    },
    methods: {
        initInfo(detailInfo) {
            this.detailInfo = detailInfo
         },
        getDicType() { 
            $api.dictTypeAPI({ dictType: 'crm_mbti_type,crm_enneagram_type,crm_cartel_type,crm_cartel_child_type' }).then(res => {
                if (res.code == 200) {
                    console.log(9999, res.data)
                    this.mbtiTypeData = res.data
                }
            })
        }
    }
}
</script>
<style lang='scss' scoped>
* {
    box-sizing: border-box;
}

.type9Personality-container {
    margin-top: 38px;
    // padding: 26px 24px;
    background: #FFFFFF;
    border-radius: 12px;
    font-family: PingFangSC, PingFang SC;
    .content-wrap {
        margin-top: 38px;
        padding: 0 23px;
    }
    .grid-cloumn {
        display: grid;
        grid-template-columns: auto 600px;
        grid-column-gap: 67px;
        // margin-top: 36px;
          // MBTI类型是：
          .test-wrap {
            .name {
                font-weight: 600;
                font-size: 22px;
                color: #333333;
            }

            .type {
                font-weight: 600;
                font-size: 55px;
                line-height: 77px;
                margin-top: 10px;
                color: #1A67E2;
                display: flex;
                align-items: center;

                .type-tag {
                    font-weight: 500;
                    font-size: 22px;
                    color: #FFFFFF;
                    margin-left: 14px;
                    padding: 0 12px;
                    height: 43px;
                    line-height: 43px;
                    background: #1A67E2;
                    border-radius: 4px;
                }
            }

            .info {
                margin-top: 10px;
                font-weight: 400;
                font-size: 16px;
                color: #333333;
            }
        }
        // 维度数据对比
        .beIntested-wrap {
            border: 1px dashed #a09d9d;
            box-sizing: border-box;
            position: relative;
            border-radius: 12px;
            .name {
                position: absolute;
                top: -15px;
                left: 50%;
                transform: translateX(-50%);
                padding: 0 20px;
                background: #fff;
            }

           .tag {
                padding: 50px 46px 0;
                display: flex;
                flex-wrap: wrap;
                // justify-content: ;

                .tag-item {
                    margin-right: 15px;
                    margin-bottom: 32px;
                    height: 30px;
                    line-height: 30px;
                    padding: 0 10px;
                    background: #C7DDFF;
                    border-radius: 2px;

                    font-weight: 400;
                    font-size: 16px;
                    color: #1A67E2;
                }
           }
        }
    }
}
</style>