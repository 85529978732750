<template>
	<div class="list">
		<div class="list-item" v-for="(item, index) in list" :key="index" @click="parentDetail(item)">
			<div class="top-content">
				<div class="title flexBetweenCenter">
					<!-- <span class="singeLine w160">{{ item.workTypeName }}</span> -->
                    <el-tooltip effect="dark" placement="top-end">
                        <div slot="content">
                            <span>{{ item.workTypeName }}</span>
                        </div>
                        <span class="singeLine" style="width: 55%;">{{ item.workTypeName }}</span>
                    </el-tooltip>

                    <el-tooltip effect="dark" placement="top-end">
                        <div slot="content">
                            <span class="color7e2" v-if="item.minProSpend == -1">面议</span>
                            <span class="color7e2" v-else>{{ item.minProSpend + "~" + item.maxProSpend}}{{ unitFilter(item.proUnit) }}</span>
                        </div>
                        <div class="singeLine" style="width: 45%; text-align: right;">
                            <span class="color7e2" v-if="item.minProSpend == -1">面议</span>
                            <span class="color7e2" v-else>{{ item.minProSpend + "~" + item.maxProSpend}}{{ unitFilter(item.proUnit) }}</span>
                        </div>
                    </el-tooltip>
					
				</div>
				<div class="tag">
					<span class="tag-item" v-if="item.postType">
						{{postTypeFilter(item.postType)}}
					</span>
					<span class="tag-item">{{ item.employeesNum }}人</span>
				</div>
				<div class="adress flexAlignCenter">
					<i class="el-icon-location-outline"></i>
					<!-- <span class="pl6">{{ item.km }}km</span> -->
					<span class="pl10 singeLine">{{ item.proArea || '无'}}</span>
				</div>
				<div v-if="!isShowBtn" class="pt10">
					<!-- 不显示查看按钮时  底部的间距时20px 显示时是10px -->
				</div>
				<div v-if="isShowBtn" class="bottom-content flexBetweenCenter">
					<span>已报名人数：<span>{{ item.ybmrs }}</span>
					</span>
					<span class="btn" @click="GoDetail">查看报名列表</span>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import * as $api from "@/api/personalCenter";
export default {
	name: "commonCard",
	components: {},
	props: {
		list: {
			type: Array,
			default: () => { },
		},
		isShowBtn: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			dictTypeList: [], // 单位
			postTypeDicList: [], // postType
		};
	},
	computed: {
		// 使用计算属性
		unitFilter() {
			// 单位
			return function (val) {
				// 对计算属性进行传参
				let obj = this.dictTypeList.find((item) => item.dictValue == val);
				return obj ? obj.dictLabel : "";
			};
		},
		postTypeFilter() {
			return function (val) {
				// 对计算属性进行传参  岗位类别（0-未开启，1-长期岗位，2-短期岗位，3-兼职/代班）
				let obj = this.postTypeDicList.find((item) => item.dictValue == val);
				return obj ? obj.dictLabel : "";
			};
		},
	},
	mounted() {
		this.getInitData();
	},
	methods: {
		GoDetail() {
			this.$router.push("/personalCenter/myPositionList");
		},
		parentDetail(item) {
			// this.$emit('parentDetail')
			this.$router.push({
				path: "/personalCenter/jobDetails",
				query: { name: "我的报名", id: item.id, companyWorkwantId: item.id },
			});
		},
		getInitData() {
			// 培训报名 性别 岗位等字典 rm_train_user_type
			$api
				.dictTypeAPI({ dictType: "crm_work_post_type,crm_pro_unit" })
				.then((res) => {
					let { code, msg, data } = res;
					if (code == 200) {
						if (data && data.length) {
							let crm_work_post_type = [];
							let crm_pro_unit = [];
							data.map((item) => {
								if (item.dictType == "crm_work_post_type") {
									crm_work_post_type.push(item);
								}
								if (item.dictType == "crm_pro_unit") {
									crm_pro_unit.push(item);
								}
							});
							this.postTypeDicList = crm_work_post_type;
							this.dictTypeList = crm_pro_unit;
						}
					} else {
						this.$message.error(msg);
					}
				});
		},
	},
};
</script>

<style lang="scss" scoped>
.flexBetweenCenter {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.flexAlignCenter {
	display: flex;
	align-items: center;
}

.pl6 {
	padding-left: 6px;
}

.pl10 {
	padding-left: 10px;
}

.pt10 {
	padding-top: 10px;
}

.list {
	display: grid;
	grid-template-columns: 384px 384px 384px;
	// grid-template-columns: 1fr 1fr 1fr;
	grid-template-rows: max-content;
	grid-gap: 22px;

	.list-item {
		background: #ffffff;
		border-radius: 8px;
		border: 1px solid #dbdbdb;

		font-size: 14px;
		font-weight: 400;
		cursor: pointer;

		.top-content {
			padding: 15px 22px 10px 18px;

			.title {
				font-size: 18px;
				// font-weight: bold;
				font-weight: 500;
				color: #555;
				line-height: 28px;

				.color7e2 {
					color: #1a67e2;
					// letter-spacing: 2px;
				}
			}

			.tag {
				margin-top: 11px;
				display: flex;

				.tag-item {
					margin-right: 10px;
					background: #52c4a4;
					border-radius: 2px;
					color: #fff;
					// padding: 4px 9px;
					height: 26px;
					line-height: 26px;
					padding: 0 10px;

					&:first-child {
						background: #0b2467;
					}
				}
			}

			.adress {
				margin-top: 14px;
				color: #888;

				img {
					width: 9px;
					height: 12px;
				}
				.el-icon-location-outline {
					margin-top: 2px;
				}
			}
		}

		.bottom-content {
			height: 58px;
			line-height: 58px;
			padding: 0 22px;

			border-top: 1px solid rgba(151, 151, 151, 0.2);
			color: #888;
			font-size: 16px;

			.btn {
				padding: 0 11px;
				height: 34px;
				line-height: 34px;
				border-radius: 7px;
				border: 1px solid #9d9d9d;
				cursor: pointer;
				color: #333;
				font-size: 14px;
			}
		}
	}
}

</style>
