<template>
    <div class="title">{{name}}</div>
</template>

<script>
export default {
    components: {},
    props: {
        name: {
            type: String,
            default: ''
        }
    },
   data () {
      return {

      };
   },

   methods: {}
}
</script>
<style lang='scss' scoped>
    .title {
        font-weight: 500;
        font-size: 18px;
        color: #333333;

        &::before {
            content: '';
            display: inline-block;
            width: 3px;
            height: 15px;
            background: #3076FF;
            margin-right: 8px;
        }
    }
</style>