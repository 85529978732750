<template>
    <div class="errorTip">
        <i class="el-icon-warning-outline"></i>
        <span>面试者测评异常,未查到测评记录</span>
    </div>
</template>

<script>
export default {
    name: 'errorTip',
    components: {},
    data() {
        return {

        };
    },

    methods: {}
}
</script>
<style lang='scss' scoped>
.errorTip {
    color: #E7303E;
    font-size: 18px;
    padding: 20px;
    text-align: center;
    background: #FBE7EA;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    margin-top: 20px;
    .el-icon-warning-outline {
        margin-right: 10px;
    }
}
</style>
