<template>
    <div class="info-wrap">
        <div class="base-title" v-if="name">{{ name }}</div>
        <div class="tag" v-if="tagList.length > 0">
            <span class="tag-item" v-for="(item, index) in tagList" :key="index">{{item}}</span>
            <!-- <span class="tag-item">热情</span>
            <span class="tag-item">同理心</span>
            <span class="tag-item">关键词</span> -->
        </div>
        <div class="info"> {{ detailInfo }} </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        name: {
            type: String,
            default: ''
        },
        detailInfo: {
            type: String,
            default: ''
        },
        tagList: {
            type: Array,
            default: ()=>[]
        }
    },
    data() {
        return {

        };
    },

    methods: {}
}
</script>
<style lang='scss' scoped>
.info-wrap {
    .base-title {
        font-weight: 600;
        font-size: 18px;
        color: #333333;
    }

    .tag {
        margin-top: 15px;
        display: flex;
        flex-wrap: wrap;

        .tag-item {
            height: 26px;
            line-height: 26px;
            padding: 0 8px;
            margin-right: 10px;
            background: #C7DDFF;
            border-radius: 2px;
            font-weight: 400;
            font-size: 14px;
            color: #1A67E2;
        }
    }

    .info {
        margin-top: 15px;
        font-weight: 400;
        font-size: 16px;
        color: #333333;
    }
}
</style>