<template>
  <div class="intelligentInterview">
    <div class="left-content">
      <leftIndex1 ref="leftIndex1" v-if="userIdentity"/>   <!-- 第一种状态 左侧 是  已报名岗位  为您推荐 -->
      <leftIndex2 ref="leftIndex2" v-if="!userIdentity"/>   <!-- 第二种状态 左侧 是只有历史面试 -->
      <!-- 第三中状态 -->
      <div class="left-content-main3" v-if="false">
        <titleBar name="热门岗位" :noMoreBtn="false"/>
        <work-card :workList="workList" class="card"></work-card>
      </div>
    </div>
    <div class="center-content">
      <centerCon
          ref="centerCon"
          @flushEmitData="flush"
          :need-flush="true"
          :default-question="defaultQuestion"
          :work-type-id="workTypes"
          :user-jobwant-id="userJobwantId"/>
    </div>
    <div class="right-content">
      <rightIndex1 ref="rightIndex1" @customEvent="sendWorkType" v-if="userIdentity" /> <!-- 第一种状态 右侧 是 个人简历介绍和历史面试 -->
      <rightIndex2 ref="rightIndex2" v-if="!userIdentity"/> <!-- 第二种状态 右侧 是 公司简介和我的岗位 -->
    </div>

  </div>
</template>


<script>
import rightIndex1 from './components/rightIndex1.vue';
import leftIndex1 from './components/leftIndex1.vue'

import leftIndex2 from './components/leftIndex2.vue'
import rightIndex2 from './components/rightIndex2.vue'

import workCard from '@/components/personalCenter/workCard.vue'
import titleBar from './components/titleBar.vue'
// import centerCon from './components/centerCon.vue'
import centerCon from '@/views/aiInterview/components/centerInterview.vue'
import store from '@/store'
import user from "@/store/modules/user";
export default {
  name: 'intelligentInterview',
  components: {
    leftIndex1,
    rightIndex1,
    leftIndex2,
    rightIndex2,
    workCard,
    titleBar,
    centerCon
  },
  data() {
      return {
        defaultQuestion: '人力资源',
        workTypes: '',
        userJobwantId: '',
        workList: [],
        userIdentity: null, // true:求职者 false:招聘者
      }
  },
  created() {
    this.userIdentity = store.state.user.identity == 1 ? true : false  //1: 求职者 2:招聘者
    store.watch(() => store.state.user.identity, (newValue, oldValue) => {
      this.userIdentity = newValue == 1 ? true : false  //1: 求职者 2:招聘者
    })
  },
  methods: {
    flush() {
      if(this.userIdentity){
        //求职者
        this.$refs.rightIndex1.getInterViewHistory()
      }else {
        //招聘者
        this.$refs.leftIndex2.getInterViewHistory()
      }
    },
    sendWorkType(eventData) {
      this.defaultQuestion = eventData.workTypeName
      this.workTypes = eventData.workTypes
      this.userJobwantId = eventData.userJobwantId
    },
  }
}
</script>


<style lang="scss" scoped>

* {
  box-sizing: border-box;
}
::v-deep.card {
  .list-item {
    margin-top: 15px;
    border-radius: 8px;
    border: 1px solid #DBDBDB;
  }
}
.intelligentInterview {

  //width: 1980px;
//   background: url('@/assets/intelligentInterview/bg.png');
  padding: 31px 60px;
  display: flex;
  .left-content {
    width: 446px;
    &-main3 {
      background: #FFFFFF;
      box-shadow: 0px 2px 22px 0px rgba(0,0,0,0.14);
      border-radius: 12px;
      padding: 19px 26px;
    }
  }
  .right-content {
    width: 446px;
  }
  .center-content {
    // border: 1px solid red;
    flex: 1;
    margin: 0 36px;
    min-width: 784px;
    position: relative;

    // box-shadow: 0px 2px 22px 0px rgba(0,0,0,0.14);
    // border-radius: 8px;
    // border: 1px solid #FFFFFF;
  }
}

</style>
