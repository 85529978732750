<template>
  <!-- 历史面试 工人 最右侧卡片 -->
  <div>
    <div class="list-item" v-if="workList">
      <div class="top-content">
        <div class="title flexBetweenCenter">
          <!-- <span class="singeLine w200">{{ workList.workTypeName }}</span> -->
          <el-tooltip effect="dark" placement="top-end">
            <div slot="content">
                <span>{{ workList.workTypeName }}</span>
            </div>
            <span class="singeLine w200">{{ workList.workTypeName}}</span>
        </el-tooltip>
          <span class="color7e2" v-if="workList.minProSpend == -1">面议</span>
          <span class="color7e2" v-else>{{ Number(workList.minProSpend) + '~' + Number(workList.maxProSpend) }} {{
              workList.proUnit
            }}</span>
        </div>

        <div class="tag">
          <span class="tag-item">{{ workList.postType }}</span>
          <span class="tag-item">{{ workList.employeesNum }}人</span>
        </div>
        <div class="btn-wrap">
            <!-- <span class="btn" @click="goConnection">去联系</span> -->
            <span class="btn" @click="showDetail">岗位详情</span>
            <span class="btn" @click="seeResume(detail)">查看简历</span>
        </div>
        <div class="adress flexAlignCenter">
          <i class="el-icon-location-outline"></i>
          <!-- <span class="pl6">{{ workList.km }}km</span> -->
          <span class="pl10">{{ workList.proArea || '暂无'}}</span>
        </div>
        <!-- 插槽  自定义 -->
      </div>
    </div>

    <!-- 去联系 -->
    <scanCodePay ref="scanCodePay"/>
    <callDialog ref="callDialog" />

    <!-- 简历 -->
    <ResumeTemplate ref="ResumeTemplate" />

    <!-- 岗位详情 -->
    <dialogWorkType ref="dialogWorkType"/>
  </div>
</template>
<script>
import * as $api from '@/api/personalCenter'
import dialogWorkType from '@/views/personalCenter/interviewRecord/components/dialogWorkType'
import {getResumeApi} from "@/api/aiInterView";
export default {
    components: {
        dialogWorkType
    },
    props: {
        workList: {
        type: Object,
        default: () => {}
        },
        detail: {
            type: Object,
            default: () => {}
        },
        needReviewBtn: {   // 开始面试按钮
        type: Boolean,
        default: false
        }
  },
  data() {
    return {
      distance: 0,
      dictTypeList: [],
      postTypeDicList: [],
    };
  },
  computed: {	// 使用计算属性
    unitFilter() {   // 单位
      return function (val) {		// 对计算属性进行传参
        let obj = this.dictTypeList.find(item => item.dictValue == val)
        return obj ? obj.dictLabel : ''
      }
    },
    postTypeFilter() {
      return function (val) {		// 对计算属性进行传参  岗位类别（0-未开启，1-长期岗位，2-短期岗位，3-兼职/代班）
        let obj = this.postTypeDicList.find(item => item.dictValue == val)
        return obj ? obj.dictLabel : ''
      }
    }
  },
  mounted() {
    this.getInitData()
  },
  methods: {
    GoDetail() {
      // this.$router.push({ path: '/personalCenter/jobDetails', query: { name: '我的岗位', id: this.workList.id}})
    },
    // 开始面试
    startReview(item) {
      // this.$router.push({ path: '/personalCenter/jobDetails', query: { name: '开始面试', id: item.id}})
      },

    getInitData() {
      // 培训报名 性别 岗位等字典 rm_train_user_type
      $api.dictTypeAPI({dictType: 'crm_work_post_type,crm_pro_unit'}).then(res => {
        let {code, msg, data} = res
        if (code == 200) {
          if (data && data.length) {
            let crm_work_post_type = []
            let crm_pro_unit = []
            data.map(item => {
              if (item.dictType == 'crm_work_post_type') {
                crm_work_post_type.push(item)
              }
              if (item.dictType == 'crm_pro_unit') {
                crm_pro_unit.push(item)
              }
            })
            this.postTypeDicList = crm_work_post_type
            this.dictTypeList = crm_pro_unit

          }
        } else {
          this.$message.error(msg)
        }
      })
    },
    // 查看简历
    seeResume() {

        // 拉取简历
      getResumeApi().then(res => {   // 判断是否有简历  有才能开始面试
        let { code, msg, data } = res
        if (code == 200) {
          console.log(data)
          let userJonWantId = data.id
          if (!userJonWantId) {
            return this.$message.warning('没有简历')
          }
          this.$nextTick(() => {  // userJonWantId   传参格式 obj = { userJonWantId : xxxx }
            let obj = {
              userJonWantId: userJonWantId
            }
            this.$refs.ResumeTemplate.getDetail(obj)
          })
        }
      })
      },
    // 详情
    showDetail() {
        console.log('this.detail', this.detail)
        this.$refs.dialogWorkType.show(this.detail)
    },
    // 去联系
    goConnection() {
            // this.$refs.scanCodePay.showDialog()
            // 获取电话号码
            // detail.empJobWantVO
            let item = this.detail
            if (!item.empJobWantVO || !item.empJobWantVO.id) {
                return this.$message.warning('暂无empJobWantVO的id')
            }
            $api.getPhoneAPI({id: item.empJobWantVO.id}).then(res=>{
                let {data, success} = res
                if(data && success) {
                    // 订单类型，1:招工电话,2:工人电话,3:抢单,4:充值,5:购买会员,6:充值钱包,7:ai大模型使用招工,8:ai大模型使用工匠,9:悬赏招工,10:企业会员权益
                    let obj = {
                        phone: data,
                        userName: item.empJobWantVO.userName,
                        avatar: item.empJobWantVO.avatar ? item.empJobWantVO.avatar :  item.empJobWantVO.sex == '男' ? this.workImg : this.hrImg
                    }
                    this.$refs.callDialog.showDialog(obj)
                } else {
                    let obj = {
                        id: item.empJobWantVO.id,
                        goodsId: item.empJobWantVO.id,
                        orderType: this.identity == 1 ? 1 : 2,    // 这个页面跳过去 写死
                        phone: data,
                        userName: item.empJobWantVO.userName,
                        avatar: item.empJobWantVO.avatar ? item.empJobWantVO.avatar :  item.empJobWantVO.sex == '男' ? this.workImg : this.hrImg,
                        sex: item.empJobWantVO.sex,
                    }
                    this.$refs.scanCodePay.showDialog(obj)
                }

            })
        },
  },
};
</script>

<style lang="scss" scoped>
.btn-wrap {
    display: flex;
    flex-direction: column;

    position: absolute;
    right: 20px;
    bottom: 10px;
    .btn {
          margin-top: 10px;
          width: 82px;
          height: 30px;
          text-align: center;
          line-height: 30px;
          background: #3076FF;
          border-radius: 3px;

          font-weight: 400;
          font-size: 14px;
          color: #FFFFFF;

          cursor: pointer;
    }
}
.list-item {
  background: #ffffff;
  border-radius: 8px;

  font-size: 14px;
  font-weight: 400;
  min-width: 384px;

  // width: 384px;

  cursor: pointer;

  .top-content {
    padding: 15px 22px 20px 18px;
    position: relative;

    .title {
      font-size: 18px;
      font-weight: 500;
      color: #555;
      line-height: 28px;

      .color7e2 {
        color: #1a67e2;
        // letter-spacing: 2px;
      }
    }

    .tag {
      margin-top: 11px;
      display: flex;

      .tag-item {
        margin-right: 10px;
        background: #52c4a4;
        border-radius: 2px;
        color: #fff;
        // padding: 4px 9px;
        height: 26px;
        line-height: 26px;
        padding: 0 10px;

        &:first-child {
          background: #0b2467;
        }
      }
    }

    .adress {
      margin-top: 14px;
      color: #888;

      img {
        width: 9px;
        height: 12px;
      }
    }

    .startReview-btn {
      width: 94px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      border-radius: 7px;
      border: 1px solid #3076FF;
      cursor: pointer;
      color: #3076FF;
      font-size: 14px;
      position: absolute;
      right: 22px;
      bottom: 20px;
    }
  }

  .bottom-content {
    height: 58px;
    line-height: 58px;
    padding: 0 22px;

    border-top: 1px solid rgba(151, 151, 151, 0.2);
    color: #888;
    font-size: 16px;

    .btn {
      padding: 0 11px;
      height: 34px;
      line-height: 34px;
      border-radius: 7px;
      border: 1px solid #9d9d9d;
      cursor: pointer;
      color: #333;
      font-size: 14px;
    }
  }

  * {
    box-sizing: border-box;
  }

  .pl6 {
    padding-left: 6px;
  }

  .pl10 {
    padding-left: 10px;
  }

  .pt10 {
    padding-top: 10px;
  }
}

.flexBaseline {
  display: flex;
  align-items: baseline;
}

.flexBetweenCenter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flexAlignCenter {
  display: flex;
  align-items: center;
}
.w200 {
  width: 150px;
}

</style>
