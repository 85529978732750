// import request from '@/utils/request'
import request from '@/utils/request'
let baseUrl = '/api'
export function getUserInfoAPI(params) {// 用户信息
    return request.get(baseUrl + '/crm/web/user/ai/userInfo', { shouldEncrypt: true, shouldDecrypt: true, params })
}
export function updateWebUserInfoAPI(data) { // 修改个人信息
    return request.post(baseUrl + '/crm/web/user/ai/updateWebUserInfo', data, { shouldEncrypt: true, shouldDecrypt: true })
}
export function sendCaptchaAPI(data) {   // 获取手机号code
    return request.post(baseUrl + '/crm/web/user/sendCaptcha', data, { shouldEncrypt: true, shouldDecrypt: true })
}
// 修改头像
export function avatarAPI(data) {
    return request.post(baseUrl + '/crm/web/user/avatar', data, { shouldEncrypt: false, shouldDecrypt: true })
}
export function changePhoneNumberAPI(data) {  // 修改手机号
    return request.post(baseUrl + '/crm/web/user/changePhoneNumber', data, { shouldEncrypt: true, shouldDecrypt: true })
}
export function codeAPI(params) {   // 1-5 等的图像code
    return request.get(baseUrl + '/code', { shouldEncrypt: false, shouldDecrypt: true, params })
}
export function logoutAPI(params) {
    return request.delete(baseUrl + '/auth/logout', { shouldEncrypt: false, shouldDecrypt: false, params })
}
export function logoffAPI(params) {
    // return request.post(baseUrl + '/auth/logoff', { shouldEncrypt: false, shouldDecrypt: false, params })
    return request.post(baseUrl + '/auth/logoff', params, { shouldEncrypt: false, shouldDecrypt: false })
}export function dictTypeAPI(params) {  // 字典获取性别 等字典
    return request.get(baseUrl + '/crm/web/index/type/dictType', { shouldEncrypt: false, shouldDecrypt: false, params })
}

export function getAreaListAPI(params) {  // 所有区域
    return request.get(baseUrl + '/crm/web/index/getAreaList/list', { shouldEncrypt: false, shouldDecrypt: true, params })
}

// 我的简历
export function getUserJobWantAPI(params) {
    return request.get(baseUrl + '/crm/web/jobWant/getUserJobWant', { shouldEncrypt: true, shouldDecrypt: true, params })
}
export function getUserJobWantInfoAPI(params) {
    let obj = {}
    return request.get(baseUrl + '/crm/web/jobWant/getUserJobWantInfo/' + params.id, { shouldEncrypt: false, shouldDecrypt: false, obj })
}
export function getAllCertDictAPI(params) {    // 查询所有持证列表
    return request.get(baseUrl + '/crm/web/index/getAllCertDict/list', { shouldEncrypt: false, shouldDecrypt: true, params })
}
export function getAllWorkTypeDictAPI(params) {  // 查询所有工种列表
    return request.get(baseUrl + '/crm/web/index/getAllWorkTypeDict/list', { shouldEncrypt: false, shouldDecrypt: true, params })
}
// export function jobWantUploadAPI(data) {  // web发布简历
//     return request.post(baseUrl + '/crm/web/jobWant/jobWantUpload', data,{shouldEncrypt:true,shouldDecrypt:false})
// }
export function jobWantUploadAPI(data) {   // web发布简历
    return request.post(baseUrl + '/crm/web/jobWant/jobWantUpload', data, { shouldEncrypt: true, shouldDecrypt: false })
}



// 我的岗位
export function workWantAPI(params) {
    return request.get(baseUrl + '/crm/web/user/own/workWant/list', { shouldEncrypt: false, shouldDecrypt: true, params })
}
// 查询求职申请列表
export function jobWantApplyAPI(params) {
    return request.get(baseUrl + '/crm/jobWantApply/ai/list', { shouldEncrypt: true, shouldDecrypt: true, params })
}
export function jobApplyAPI(params) {
    return request.get(baseUrl + '/crm/web/user/ai/own/jobApply/list', { shouldEncrypt: false, shouldDecrypt: true, params })
}

export function workWantInfoPublish(data) {  // web招工岗位发布
    return request.post(baseUrl + '/crm/web/workWant/workWantInfoPublish', data, { shouldEncrypt: true, shouldDecrypt: true })
}


// 获取招聘信息详情
export function getCompanyWorkWantInfoAPI(params) {
    let obj = {}
    return request.get(baseUrl + '/crm/web/workWant/getCompanyWorkWantInfo/' + params.id + '/1', { shouldEncrypt: false, shouldDecrypt: true, obj })
}
// 求职简历获取联系电话
export function getPhoneAPI(params) {
    let obj = {}
    return request.get(baseUrl + '/crm/web/jobWant/getPhone/' + params.id, { shouldEncrypt: false, shouldDecrypt: true, obj })
}



// 企业信息
// 据用户查询企业信息
export function getCompanyInfoAPI(params) {
    return request.get(baseUrl + '/crm/web/companyInfo/getCompanyInfo', { shouldEncrypt: false, shouldDecrypt: true, params })
}
// 企业入驻-上传营业执照
export function uploadBusLicenseAPI(data) {
    return request.post(baseUrl + '/crm/web/companyInfo/uploadBusLicense', data, { shouldEncrypt: true, shouldDecrypt: false })
}
// 提交审核 企业入驻-web
export function companyCheckInForWebAPI(data) {
    return request.post(baseUrl + '/crm/web/companyInfo/companyCheckInForWeb', data, { shouldEncrypt: true, shouldDecrypt: true })
}
// 开通权益守护AI助手权限
export function grantPerOfLegalAsstAPI(data) {
    return request.post(baseUrl + '/crm/web/companyInfo/grantPerOfLegalAsst', data, { shouldEncrypt: true, shouldDecrypt: false })
}
// 修改企业
export function editCompanyInfoAPI(data) {
    return request.put(baseUrl + '/crm/web/companyInfo', data, { shouldEncrypt: true, shouldDecrypt: false }, true)
}


// 历史面试
export function getInterviewAPI(data) {
    return request.post(baseUrl + '/crm/web/interview/list', data, { shouldEncrypt: true, shouldDecrypt: true }, true)
}
// 面试记录
export function getInterviewV1API(data) {
    return request.post(baseUrl + '/crm/web/interview/v1/list', data, { shouldEncrypt: true, shouldDecrypt: true }, true)
}
// 面试详情
export function getInterviewIdAPI(params) {
    let obj = {}
    return request.get(baseUrl + '/crm/web/interview/' + params.id, { shouldEncrypt: false, shouldDecrypt: true, obj })
}
export function getInterviewIdV1API(params) {
    let obj = {}
    return request.get(baseUrl + '/crm/web/interview/v1/' + params.id, { shouldEncrypt: false, shouldDecrypt: true, obj })
}
// 历史  视频回放
export function interviewAPI(params) {
    let obj = {}
    return request.get(baseUrl + '/crm/web/interview/' + params.companyWorkwantId + '/' + params.applyCustomerId, { shouldEncrypt: false, shouldDecrypt: true, obj })
}

// 支付接口
// 微信JS支付
export function payJSOrderAPI(data) {
    return request.post(baseUrl + '/crm/webOrder/payJSOrder', data, { shouldEncrypt: true, shouldDecrypt: true }, true)
}

// 微信网页支付
export function payNativeOrderAPI(data) {
    return request.post(baseUrl + '/crm/webOrder/payNativeOrder', data, { shouldEncrypt: true, shouldDecrypt: false }, true)
}
// 判断是否购买手机号
export function payForPhoneNumberAPI(data) {
    return request.post(baseUrl + '/crm/webOrder/payForPhoneNumber', data, { shouldEncrypt: true, shouldDecrypt: false }, false)
}
// 查询支付订单
export function payOrderAPI(data) {
    return request.post(baseUrl + '/crm/webOrder/payOrder', data, { shouldEncrypt: true, shouldDecrypt: true }, true)
}
// 申请岗位
export function getJobWantApplyAPI(data) {
    return request.post(baseUrl + '/crm/web/workWant/ai/jobWantApply', data, { shouldEncrypt: true, shouldDecrypt: false }, true)
}

export function getPhoneBossAPI(params) {
    let obj = {}
    return request.get(baseUrl + '/crm/web/workWant/getPhone/' + params.id, { shouldEncrypt: false, shouldDecrypt: true, obj })
}

export function getExistAPI(params) {
    let obj = {}
    // {id}/{userId}
    return request.get(baseUrl + '/crm/web/interview/exist/' + params.id + '/' + params.userId, { shouldEncrypt: false, shouldDecrypt: false, obj })
}

export function deleteCompanyWorkwantAPI(data) {
    return request.post(baseUrl + '/crm/web/workWant/deleteCompanyWorkwant', data, { shouldEncrypt: true, shouldDecrypt: false }, true)
}

export function getCommonConfigAPI(params) {
    return request.get(baseUrl + '/crm/web/index/getCommonConfig', { shouldEncrypt: true, shouldDecrypt: true, params })
}
// 取消支付订单
export function webOrderCancelAPI(data) {
    return request.post(baseUrl + '/crm/webOrder/cancel', data, { shouldEncrypt: true, shouldDecrypt: false })
}