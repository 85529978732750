<template>
  <!--更换手机号弹窗-->
  <el-dialog class="dialog-phone" :visible.sync="dialogFormVisible" :center="true" :show-close="false"
             :close-on-click-modal="false">
    <div class="tip-form">
      <img src="@/assets/intelligentInterview/tip.png" alt="">
      <div class="name" v-show="this.nowIdentity=='2'">是否将角色切换至“我要找活”？</div>
      <div class="tip" v-show="this.nowIdentity=='2'">您当前角色为“我要招人“，该功能需切换至“我要找活”</div>
      <div class="name" v-show="this.nowIdentity=='1'">是否将角色切换至“我要招人”？</div>
      <div class="tip" v-show="this.nowIdentity=='1'">您当前角色为“我要找活“，该功能需切换至“我要招人”</div>
      <div class="btnBox">
        <el-button class="cancle" type="info" @click="cancleFn">取消</el-button>
        <el-button type="primary" @click="GoToEdit">确认切换</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import {updateUserIdentityApi} from "@/api/user";
import store from "@/store";

export default {
  name: 'tipDialog',
  components: {},
  props: {
    // isShowTip: {
    //     type: Boolean,
    //     default: false
    // }
  },
  data() {
    return {
      dialogFormVisible: false,
      nowIdentity: '',
      ifAiQustion: ''
    }
  },
  created() {
    this.nowIdentity = store.state.user.identity
    store.watch(() => store.state.user.identity, (newValue, oldValue) => {
      this.nowIdentity = newValue
    })
  },
  methods: {
    showDialog(question) {
      if (question) {
        this.ifAiQustion = question;
      }
      this.dialogFormVisible = true
    },
    cancleFn2() {
      this.dialogFormVisible = false;
    },
    cancleFn() {
        // this.$router.push({
        //   path: "/home/index",
        // });
      this.dialogFormVisible = false;
    },
    GoToEdit() {
      let updateStatus;
      if (this.nowIdentity === '2') {
        updateStatus = '1';
      } else {
        updateStatus = '2';
      }
      updateUserIdentityApi({identity: updateStatus}).then(res => {
        if (res.success) {
          store.commit('SET_IDENTITY', updateStatus)
          this.$message({
            message: "切换成功",
            type: "success",
            customClass: 'zZindex',
            duration: 1500,
          });
          this.$emit("switchSuccess", updateStatus);
          this.$router.push({ path: "/home/index" });
          this.dialogFormVisible = false;
        } else {
          this.$message({
            message: "切换失败",
            type: "error",
            center: true,
            duration: 1500,
            customClass: 'zZindex',
          });
          this.$router.push({
            path: "/home/index",
          });
        }
      }).catch((error) => {
        this.$message({
          message: "切换失败",
          type: "error",
          center: true,
          duration: 1500,
        });
        this.$router.push({
          path: "/home/index",
        });
      })
    },
  }
}
</script>

<style lang="scss" scoped>
// 更换手机弹窗
.dialog-phone {
  ::v-deep .el-dialog {
    width: 532px;
    border-radius: 8px;
    // 居中
    margin: 0 !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .el-dialog__header {
      padding: 0 !important;
    }

    .el-dialog__body {
      padding: 0 !important;
    }

    .el-dialog__headerbtn .el-dialog__close {
      font-size: 24px;
    }

    .tip-form {
      height: 544px;
      height: 348px;
      background: url('@/assets/intelligentInterview/dialogBg.png');
      border-radius: 8px;

      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      img {
        width: 50px;
        height: 67px;
      }

      .name {
        margin-top: 18px;
        font-weight: 500;
        font-size: 20px;
        color: #333333;
      }

      .tip {
        margin-top: 18px;
        font-weight: 400;
        font-size: 16px;
        color: #555555;
      }

      .btnBox {
        margin-top: 52px;

        .el-button {
          width: 168px;
          height: 50px;
          background: #236CE0;
          border-radius: 5px;

          font-weight: 500;
          font-size: 18px;
          color: #FFFFFF;
        }

        .cancle {
          margin-right: 30px;
          border: 1px solid #9D9D9D;
          color: #333333;
          background: #fff;
        }
      }

    }
  }
}
</style>
