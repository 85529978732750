<template>
    <div class="carte-content-container">
        <div class="title">卡特尔16人格测试结果报告</div>
        <div class="grid-cloumn" v-for="(item, index) in detailInfo" :key="index">
            <div class="test-wrap">
                <div class="name">你的16型人格分析是：</div>
                <div class="type">{{ item.cartelName }} <span class="type-tag">得分：{{ item.goalScore + '分' || '暂无'}}</span> </div>
                <div class="info">
                    {{ item.basicDesc || '暂无'}}
                </div>
            </div>
            <div class="score-wrap">
                <span class="name">得分标准</span>
                <slot></slot>
                <div class="info" style="margin-bottom: 22px;">
                    {{ item.lowScoreDesc }}
                </div>
                <div class="info">
                    {{ item.highScoreDesc }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            detailInfo: {}
        };
    },

    methods: {
        initInfo(detailInfo) {
            this.detailInfo = detailInfo
        },
    }
}
</script>
<style lang='scss' scoped>
* {
    box-sizing: border-box;
}
.carte-content-container {
    background: #FFFFFF;
    border-radius: 12px;
    font-family: PingFangSC, PingFang SC;
    margin-top: 38px;
    min-height: 500px;
    padding: 20px;
    .title {
        font-weight: 500;
        
        font-size: 18px;    
        color: #333333;

        &::before {
            content: '';
            display: inline-block;
            width: 3px;
            height: 15px;
            background: #3076FF;
            margin-right: 8px;
        }
    }

    .grid-cloumn {
        display: grid;
        grid-template-columns: auto 600px;
        grid-column-gap: 67px;
        margin-top: 36px;
        padding:0 23px 25px 23px;
        border-bottom: 1px solid #EAEAEA;
          // MBTI类型是：
          .test-wrap {
            .name {
                font-weight: 600;
                font-size: 22px;
                color: #333333;
            }

            .type {
                font-weight: 600;
                font-size: 55px;
                line-height: 77px;
                margin-top: 10px;
                color: #1A67E2;
                display: flex;
                align-items: center;

                .type-tag {
                    font-weight: 500;
                    font-size: 22px;
                    color: #FFFFFF;
                    margin-left: 14px;
                    padding: 0 12px;
                    height: 43px;
                    line-height: 43px;
                    background: #1A67E2;
                    border-radius: 4px;
                }
            }

            .info {
                margin-top: 10px;
                font-weight: 400;
                font-size: 16px;
                color: #333333;
            }
        }
        // 维度数据对比
        .score-wrap {
            border: 1px dashed #a09d9d;
            box-sizing: border-box;
            position: relative;
            border-radius: 12px;
            padding: 30px;
            font-weight: 400;
            font-size: 16px;
            color: #333333;
            .name {
                position: absolute;
                top: -15px;
                left: 50%;
                transform: translateX(-50%);
                padding: 0 20px;
                background: #fff;
            }
        }
    }
}
</style>