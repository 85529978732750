<template>
  <div class="date_box-body">
    <div class="data_box-select">
      <div class="data_box-address--btn">
        <img :src="addressTips" alt=""/>
        <span>贵州省</span>
      </div>

      <div class="postMessage-box">
        <input
            v-model="workQuestion"
            @keypress.enter.prevent="postMessage()"
            type="text"
            class="input-field"
            placeholder="搜工作：“招塔吊司机”"
        />
        <img
            @click="postMessage()"
            src="../../../assets/common/home-video-icon.png"
            alt=""
        />
      </div>
    </div>
    <div @click="linkNormal" class="data_box_animation--body">
      <div class="animation_box">
        <div class="box_item1 center">
          <div class="box_item2 center">
            <div class="box_item3 center">
              <div class="box_item4 center">
                <div class="box_emit center"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="date_box_date--area">
      <div @click="linkNormal" class="date_box_date--date--common">
        <div>
          <img :src="workPic" alt=""/>
        </div>
        <div class="date_box--caps">
          <span class="date_box--num">{{
              (workData.workWantTotal ? workData.workWantTotal : 0) | formatNumber(0)
            }}</span>
          <span class="date_box--title">岗位数量（总）</span>
        </div>
        <div class="date_box--caps">
          <span class="date_box--num">
            {{
              (workData.workWantInCount ? workData.workWantInCount : 0) | formatNumber(0)
            }}
          </span>
          <span class="date_box--title">正在招聘数</span>
        </div>
      </div>
      <div @click="linkNormal" class="date_box_date--date--common">
        <div>
          <img :src="workerPic" alt=""/>
        </div>
        <div class="date_box--caps">
          <span class="date_box--num">{{
              (workData.userJobTotal ? workData.userJobTotal : 0) | formatNumber(0)
            }}</span>
          <span class="date_box--title">用户总数</span>
        </div>
        <div class="date_box--caps">
          <span class="date_box--num">{{
              (workData.userJobCount ? workData.userJobCount : 0) | formatNumber(0)
            }}</span>
          <span class="date_box--title">月活用户总数</span>
        </div>
      </div>
    </div>
    <change-rule ref="changeRule"/>
  </div>
</template>
<script>
import store from "@/store";

export default {
  name: "aifindWork",
  props: {
    workData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  filters: {
    formatNumber(value, decimalPlaces = 2) {
      // 确保传入的是数字类型
      let num = Number(value);

      // 根据数值大小选择合适的单位（千、百万）
      const units = ['万', '亿'];
      let unitIndex = -1;
      if (num >= 10000) {
        while (num >= 10000 && unitIndex < units.length - 1) {
          num /= 10000;
          unitIndex++;
        }
      } else {
        return value;
      }

      // 格式化数值，保留指定的小数位数
      const formattedNum = num.toFixed(decimalPlaces);

      return `${formattedNum} ${units[unitIndex]}`;
    },
  },
  data() {
    return {
      workPic: require("@/assets/icon/home/work.png"),
      workerPic: require("@/assets/icon/home/worker.png"),
      addressTips: require("@/assets/icon/home/addressTips.png"),
      workQuestion: ""
    };
  },
  methods: {
    /**
     * 打开切换角色提示的窗口
     * @param success 切换成功回调
     */
    showChangeRole(success) {
      this.$refs.changeRule.showDialog(success);
    },
    // 用户发送消息
    postMessage() {
      if (!this.$store.state.user.token) {
        this.$showLoginModal(true);
        return;
      }

      const success = () => {
        this.$router.push({
          path: '/aiFindWork/index?serviceType=2',
          query: {
            context: this.workQuestion
          }
        });
      }
      this.$store.state.user.identity === '2' ? this.showChangeRole(success) : success();
    },
    linkNormal() {
      const success = () => {
        this.$router.push({
          path: '/aiFindWork/index?serviceType=2',
        });
      }
      this.$store.state.user.identity === '2' ? this.showChangeRole(success) : success();
    },
  }
};
</script>
<style lang="scss" scoped>
@keyframes degAnimation {
  0% {
    transform: rotate(0deg) translate(-50%, -50%);
  }

  100% {
    transform: rotate(360deg) translate(-50%, -50%);
  }
}

.date_box-body {
  width: 498px;
  //height: 340px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .data_box-select {
    display: flex;
    margin-top: 18px;

    .data_box-address--btn {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 86px;
      height: 47px;
      background: linear-gradient(180deg, #4695ff 0%, #1856ff 100%);
      border-radius: 24px;

      img {
        width: 11px;
        height: 14px;
      }

      span {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 17px;
        color: #ffffff;
        margin-left: 4px;
      }
    }

    .postMessage-box {
      display: flex;
      flex-direction: row;
      align-items: center;

      .input-field {
        margin: 0 10px 2px 8px;
        background: #f2f4f8;
        border-radius: 24px;
        width: 280px;
        height: 40px;
        border-style: none;
        padding-left: 16px;
        font-weight: 400;
        font-size: 14px;
        flex-grow: 1;
      }

      .input-field::placeholder {
        font-weight: 400;
        font-size: 14px;
        color: #969696;
        line-height: 20px;
        text-align: left;
        font-style: normal;
      }

      img {
        width: 54px;
        height: 54px;
        cursor: pointer;
      }
    }
  }

  .data_box_animation--body {
    width: 470px;
    height: 206px;
    background: #d8d8d8;
    border-radius: 8px;
    border: 1px solid #ffffff;
    margin-top: 18px;
    background: url("@/assets/icon/home/mapBack.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;

    .center {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .animation_box {
      width: 183px;
      height: 183px;

      .box_item1 {
        width: 183px;
        height: 183px;
        background: radial-gradient(
                49% 49% at 50% 50%,
                rgba(255, 255, 255, 0.11) 0%,
                rgba(156, 190, 255, 0.51) 100%
        );
        opacity: 0.49;
        border-radius: 50%;

        .box_item2 {
          width: 149px;
          height: 149px;
          background: radial-gradient(
                  49% 49% at 50% 50%,
                  rgba(255, 255, 255, 0.11) 0%,
                  rgba(114, 169, 239, 0.51) 100%
          );
          opacity: 0.74;
          border-radius: 50%;

          .box_item3 {
            width: 83px;
            height: 83px;
            background: radial-gradient(
                    0% 49% at 50% 50%,
                    rgba(255, 255, 255, 0.11) 0%,
                    rgba(96, 168, 241, 0.51) 100%
            );
            opacity: 0.86;
            border-radius: 50%;

            .box_item4 {
              width: 11px;
              height: 11px;
              background: #2a76e9;
              border: 2px solid #ffffff;
              border-radius: 50%;

              .box_emit {
                width: 183px;
                height: 183px;
                transform-origin: left top;
                background: conic-gradient(
                        from 90deg at 50% 50%,
                        rgba(140, 140, 140, 0) 96%,
                        rgba(0, 0, 0, 0) 91%,
                        #428eff 91%
                );
                opacity: 0.75;
                border-radius: 50%;
                animation: degAnimation 3s linear infinite;
              }
            }
          }
        }
      }
    }
  }

  .date_box_date--area {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 16px;

    .date_box_date--date--common {
      width: 228px;
      height: 78px;
      border-radius: 8px;
      border: 1px solid #dbdbdb;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      cursor: pointer;

      img {
        padding: 4px;
        width: 30px;
        height: 30px;
        margin-left: 7px;
      }

      .date_box--caps {
        display: flex;
        flex-direction: column;
        margin-left: 12px;

        .date_box--num {
          font-family: DINAlternate, DINAlternate;
          font-weight: bold;
          font-size: 20px;
          color: #333333;
        }

        .date_box--title {
          margin-top: 2px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 10px;
          width: 80px;
          color: #868686;
        }
      }
    }

    .date_box_date--date--common:nth-child(2) {
      margin-left: 8px;
    }
  }
}
</style>
