import Cookies from 'js-cookie'

//token存入cookie时的key
const TokenKey = 'token'
//RSA公钥Key
const RSAKeyName = 'RSAKey'

export function getToken() {
    return Cookies.get(TokenKey)
}

export function setToken(token) {

    return Cookies.set(TokenKey, token)
}

export function removeToken() {
    return Cookies.remove(TokenKey)
}

export function getRSAKey() {
    return Cookies.get(RSAKeyName)
}

export function setRSAKey(RSAKey) {
    return Cookies.set(RSAKeyName, RSAKey)
}

export function removeRSAKey() {
    return Cookies.remove(RSAKeyName)
}
