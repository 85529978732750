<template>
    <div class="interviewRecord-container"  v-loading="loading" >
        <div class="main">
            <el-table
                :data="tableData"
                style="width: 100%"
                :header-cell-style="{ background:'#F9FBFE',color: '#333' }">
                <el-table-column prop="userName" label="用户昵称" align="center" :show-overflow-tooltip="true"/>
                <el-table-column prop="mobile" label="手机号" align="center"  :show-overflow-tooltip="true"/>
                <el-table-column prop="examName" label="测试名称" align="center"  :show-overflow-tooltip="true"/>
                <!-- <el-table-column prop="ratingResult" label="测试结果" align="center"/> -->
                <!-- <el-table-column prop="interviewAppraise" label="关键词" align="center"/> -->
                <el-table-column prop="ratingTime" label="测试时间" align="center" width="160"/>
                <el-table-column prop="date" label="测试报告" align="center">
                    <template slot-scope="scope">
                        <el-button type="text" style="color: #1A67E2;" @click="GOToResult(scope.row)">点击查看</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="paginationBox">
                <el-pagination
                    v-if="page.total > queryParams.pageSize"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :page-size="queryParams.pageSize"
                    layout="total, prev, pager, next"
                    :total="page.total">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import * as $api from '@/api/myTest'
import moment from 'moment'
export default {
    name: 'resultTable',
    components: {},
    data() {
        return {
            tableData: [],
            page: {
                currentPage: 1,
                total: 0, // 工作总记录数
            }, // 分页
            queryParams: {
                pageSize: 10, // 每页条数
                pageNum: 1, // 当前页数
            },
            timer: [],
            loading: true,
        };
    },
    created() {
        this.getInit()
    },
    methods: {
        filterSex(val) {
            let obj = this.sexList.find(item => item.dictValue == val)
            return obj ? obj.dictLabel : ''
        },
        getInit() {
            this.loading = true
            let obj = {
                examId: this.$route.query.id,
                ...this.queryParams
            }
            $api.getEvalRatingsListAPI(obj).then(res => {
                let { code, msg, data } = res
                this.loading = false
                if (code == 200) {
                    this.tableData = data.rows || []
                    if (this.tableData.length > 0) {
                        this.tableData.forEach(item => {
                            if (item.ratingTime) {
                                item.ratingTime = moment(item.ratingTime).format("YYYY-MM-DD HH:mm:ss")
                            }
                        })
                    }
                    this.page.total = data.total || 0
                } else {
                    // this.$message.error(msg)
                }
            }).catch(error => {
                this.loading = false
                // this.$message.error(error.msg)
            })
        },
        GOToResult(item) {
            localStorage.setItem('isQueryList', 0)
            this.$emit('reflash')
            // this.$router.push({
            //     path: '/personalCenter/index',
            //     query: {
            //         name: '职业测评',
            //         isDetail: 1,   // 在 index 或者indexHr 来判断显示的组件显示的是列表还是详情页面
            //         id: item.id,
            //         examType: item.examType,
            //     }
            // })
        },
        handleSizeChange(val) {
            this.queryParams.pageSize = val
            this.getInit()
        },
        handleCurrentChange(val) {
            this.queryParams.pageNum = val
            this.getInit()
        },
        
    }
}
</script>
<style lang='scss' scoped>
::v-deep.interviewRecord-container {
    min-height: 822px;
    width: 100%;
    position: relative;
    .queryForm-Box {
        margin-top: 24px;
        .el-input__inner {
            height: 42px;
            // border-radius: 4px;
            &::placeholder {
                color: #555;
            }
        }
        .el-date-editor {
            height: 42px;
            line-height: 42px;
            .el-range-input {
                &::placeholder {
                    color: #555;
                }
            }
            .el-range-separator {
                height: 42px;
                line-height: 42px;
            }
            .el-range__icon {
                color: #555;
            }
        }
        .el-range-editor {
            height: 42px;
            line-height: 42px;
            .el-input__inner {
                padding: 0!important;
            }
        }

    }
    .main {
        margin-top: 22px;
        .avater-name {
            display: flex;
            justify-content: center;
            align-items: center;
            max-width: 160px;
            .avater {
                width: 32px;
                height: 32px;
                border-radius: 50%;
                margin-right: 10px;
            }
        }
        .el-table {
            .column-tag {
                padding: 12px;
                background: #EAF5EA;
                border-radius: 4px;
                border: 1px solid #52C4A4;

                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                font-size: 16px;
                color: #333333;
                .tag-item {
                    line-height: 22px;
                    &::after {
                        content: '|';
                        display: inline-block;
                        font-size: 12px;
                        margin: 5px;
                    }
                    &:last-child {
                        &::after {
                            display: none;
                        }
                    }
                }
            }
        }

    }
    .paginationBox {
        // margin-top: 20px;
        text-align: right;
        position: absolute;
        bottom: 0;
        width: 100%;
        .el-pagination {
            .el-pager{
                // height: 42px;
                li.active {
                    background: rgba(46,105,245,0.1);
                    border-radius: 3px;
                }
            }
        }

    }
    .flexCenter {
        display: flex;
        align-items: center;
    }
}
.overflowline1 {
    overflow: hidden; /*自动隐藏文字*/
    text-overflow: ellipsis;/*文字隐藏后添加省略号*/
    white-space: nowrap;/*强制不换行*/
    // width: 160px;/*不允许出现半汉字截断*/
}
</style>
