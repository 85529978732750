<template>
  <div class="bottom-container">

    <span>工匠行科技有限公司&nbsp;|&nbsp;招聘求职咨询/反馈电话：0851-85610106</span>
    <span>黔ICP备19008459号 贵公网安备52010302003531</span>
    <div class="link_box">
      <span class="span_href" style="color: #5499f3" @click="commonData(2)">关于我们</span>
      <div class="link_divider"></div>
      <span class="span_href" style="color: #5499f3" @click="commonData(3)">联系我们</span>
      <div class="link_divider"></div>
      <span class="span_href" style="color: #5499f3" @click="commonData(1)">隐私协议</span>
      <div class="link_divider"></div>
      <span class="span_href" style="color: #5499f3" @click="commonData(5)">用户协议</span>
      <div class="link_divider"></div>
      <span class="span_href" style="color: #5499f3" @click="commonData(6)">招聘协议</span>
    </div>
    <el-dialog
        top="5vh"
        :visible.sync="dialogVisible"
        width="40%">
      <div style="width: 100%;height: 75vh;overflow: auto">
        <div v-html="vHtmlContent"></div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {getCommonConfigApi} from "@/api/home";

export default {
  name: 'bottomBar',
  data () {
    return {
      dialogVisible: false,
      vHtmlContent: '',
    }
  },
  mounted() {
  },
  methods: {
    /**    类型（
    1-隐私政策，
    2-关于我们，
    3-联系我们，
    4-免责条例，
    5-用户协议，
    6-工匠行招工信息发布规则，
    7-接单协议，
    8-付款协议，
    9-敏感词,
    100-贵州精选词条）*/
    commonData(type) {
      getCommonConfigApi({sourceType: type}).then(res=> {
        this.vHtmlContent = res.data.content
        this.dialogVisible = true
      }).catch(()=> {
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.bottom-container {
  /* position: fixed;
  bottom: 0; */
  width: 100%;
  height: 121px;
  background-color: #1e2134;
  display: flex;
  font-size: 14px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: aliceblue;
  letter-spacing: 1px;
  padding-top: 17px;
  padding-bottom: 17px;
  .link_box{
    display: flex;
    align-items: center;
    .link_divider{
      width: 1px;
      height: 12px;
      background-color: #389EFF;
      margin: 0 20px;
    }
  }
  .span_href{
    cursor: pointer;
  }
}
.bottom-container span{
  font-size: 14px;
  line-height: 27px;
}
</style>
