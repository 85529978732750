<template>
    <div>
        <div class="myPosition-container" v-if="!showDetail">
            <div class="title">我的岗位</div>
            <div class="btn" @click="publishDialog">发布新岗位</div>
            <div class="list" v-if="list.length">
                <div class="list-item" v-for="(item, index) in list" :key="index" @click="parentDetail(item)">
                    <div class="top-content">
                        <div class="name flexBetweenCenter">
                            <!-- <span>{{ item.workTypeName }}</span> -->
                            <el-tooltip effect="dark" placement="top-end">
                                <div slot="content">
                                    <span>{{ item.workTypeName }}</span>
                                </div>
                                <div class="name singeLine" style="width: 55%;">
                                    <span>{{ item.workTypeName }}</span>
                                </div>
                            </el-tooltip>
                            <el-tooltip effect="dark" placement="top-end">
                                <div slot="content">
                                    <span class="color7e2" v-if="item.minProSpend == -1">面议</span>
                                    <span class="color7e2" v-else>{{ item.minProSpend + '~' + item.maxProSpend }}{{   unitFilter(item.proUnit) }}</span>
                                </div>
                                <div class="singeLine" style="width: 45%; text-align: right;">
                                    <span class="color7e2" v-if="item.minProSpend == -1">面议</span>
                                    <span class="color7e2" v-else>{{ item.minProSpend + '~' + item.maxProSpend }}{{   unitFilter(item.proUnit) }}</span>
                                </div>
                            </el-tooltip>
                           
                        </div>
                        <div class="adress flexBetweenCenter">
                            <div class="flexAlignCenter">
                                <i class="el-icon-s-custom"></i>
                                <span>已报名人数：<span>{{ item.jobWantApplyList ? item.jobWantApplyList.length : 0 }}</span>
                                </span>
                            </div>
                            <span
                                class="look-btn"
                                v-if="item.jobWantApplyList && item.jobWantApplyList.length" 
                                @click.stop="GomyPositionList(item)">查看报名列表</span>
                                <!-- <span class="look-btn" v-else>暂无报名列表</span> -->
                        </div>
                    </div>
                    <div class="bottom-content singeLine">
                        <i class="el-icon-location-outline"></i>
                        <!-- <span class="pl6">{{ item.km }}km</span> -->
                        <span class="pl10 ">{{ item.proArea  }}</span>
                    </div>
                </div>
            </div>
            <div class="paginationBox">
                <el-pagination
                    v-if="page.total > page.pageSize"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="page.currentPage"
                    :page-size="page.pageSize"
                    background
                    layout="total,prev, pager, next"
                    :total="page.total">
                </el-pagination>
            </div>
            <el-empty v-if="list.length == 0" description="暂无数据"></el-empty>

            <!-- 发布岗位弹窗 -->
            <commonForm ref="commonForm" @flashData="flashData" />
        </div>
        <!-- 我的岗位列表 -->
        <myPositionList v-if="showDetail" @detailBack="detailBack" />
    </div>
</template>

<script>
import commonForm from './commonForm.vue';
import myPositionList from './myPositionList.vue'
import * as $api from '@/api/personalCenter'
let _this = this
export default {
    name: 'myPosition',
    components: {
        commonForm,
        myPositionList
    },
    data() {
        return {
            showDetail: false,   // 展示岗位列表
            list: [],
            // dialogFormVisible: true,
            form: {
                name: '',
                region: ''
            },

            formList: [
                { name: '招聘类型', type: 'select', props: 'name', placeholder: '请选择', require: true, },
                { name: '岗位类型', type: 'select', props: 'sex', placeholder: '请选择', require: true, selectList: [{ label: '女', value: 1, }, { label: '男', value: 2, }] },
                { name: '需要人数', type: 'select', props: 'date', placeholder: '请选择', require: true, },
                { name: '工作所在地详细地址', type: 'select', props: 'job', placeholder: '请选择', require: true, selectList: [{ label: '女', value: 1, }, { label: '男', value: 2, }] },
                { name: '薪资待遇', type: 'select', props: 'money', placeholder: '请选择', require: true, selectList: [{ label: '女', value: 1, }, { label: '男', value: 2, }] },
                { name: '岗位有效期', type: 'date', props: 'where', placeholder: '请选择', require: true, },
                { name: '性别要求', type: 'select', props: 'where', placeholder: '请选择', selectList: [{ label: '女', value: 1, }, { label: '男', value: 2, }] },
                { name: '年龄要求', type: 'select', props: 'where', placeholder: '请选择', selectList: [{ label: '女', value: 1, }, { label: '男', value: 2, }] },
                { name: '福利待遇', type: 'select', props: 'where', placeholder: '请选择', selectList: [{ label: '女', value: 1, }, { label: '男', value: 2, }] },
                { name: '持证情况', type: 'select', props: 'where', placeholder: '请选择', selectList: [{ label: '女', value: 1, }, { label: '男', value: 2, }] },
                { name: '工作内容', type: 'textarea', props: 'where', placeholder: '请输入对该岗位的需求描述，如：具体负责什么工作、技能要求等。' },
            ],
            ruleForm: {

            },
            checked: false, // 协议
            dictTypeList: [],
            fullscreenLoading: false,
            page: {
                currentPage: 1,
                pageSize: 12, // 每页条数
                pageNum: 1, // 当前页数
                total: 0, // 工作总记录数
            }, // 分页
            identity: ''
        }
    },
    watch: {
        // $route.path == this.$route.path
        '$route.query': function (newVal, oldVal) {
            this.showDetail = this.$route.query.isDetail || false
            this.page.currentPage = sessionStorage.getItem('myPositionPage') ? Number(sessionStorage.getItem('myPositionPage')) : 1
        },
        'page.pageNum': {
            handler() {
                // this.getMyRecruitment();
            }
        },
    },
    destroyed() {   // 不能清除
        sessionStorage.removeItem('myPositionPage')
    },
    mounted() {
        this.showDetail = this.$route.query.isDetail || false
    },
    created() {
        this.getInitData()
        this.identity = this.$store.state.user.identity
        this.$store.watch(() => this.$store.state.user.identity, (newValue, oldValue) => {
            this.identity = newValue
            // this.getDictTypeAPI()
        })
    },
    computed: {	// 使用计算属性
        unitFilter () {   // 单位
            return function (val) {		// 对计算属性进行传参
                let obj = this.dictTypeList.find(item=>item.dictValue == val)
                return obj ? obj.dictLabel : ''
            }
        }
    },
    methods: {
        handleSizeChange(val) {
            this.page.pageSize = val
            this.getInitData()
        },
        handleCurrentChange(val) {
            this.page.pageNum = val
            this.getInitData()
        },
        getpagination(){
            document.getElementsByClassName("el-pagination__jump")[0].childNodes[0].nodeValue = "跳至";
            document.getElementsByClassName("el-pagination__total")[0].childNodes[0].nodeValue = `总共${this.total}条 每页显示`;
        },
        flashData(data) {
            if (data != '关闭') {
                this.getInit()
            }
        },
        // 获取选择框的数据
        getInitData() {
            this.fullscreenLoading = true
            // 培训报名 性别 岗位等字典 rm_train_user_type
            $api.dictTypeAPI({ dictType: 'crm_pro_unit' }).then(res => {
                let { code, msg, data } = res
                if (code == 200) {
                    this.dictTypeList = data
                    this.getInit()  // 列表
                } else {
                    this.$message.error(msg)
                }
            })

        },
        getInit() {
            this.fullscreenLoading = true
            const param = {
                pageNum: this.page.pageNum,
                pageSize: this.page.pageSize,
                // isImmediateRecruitment: '0'
            }
            $api.workWantAPI(param).then(res => {
                this.fullscreenLoading = false
                let { code, msg, data } = res
                // debugger
                if (code == 200) {
                    this.list = data.rows || []
                    this.page.total = data.total || 0
                }
            }).catch(error => {
                // this.$message.error(error.msg)
                this.fullscreenLoading = false
            })
        },
        publishDialog() {
            this.$refs.commonForm.showCommonForm()
        },
        // 查看报名列表
        GomyPositionList(item) {
            sessionStorage.setItem('myPositionPage', this.page.pageNum)
            if(this.identity == 2) {   // hr
                this.$router.push({ path: '/personalCenter/indexHr', query: { name: '我的岗位', isDetail: 1, companyWorkwantId: item.id } });
            } else {  // 工人
                this.$router.push({ path: '/personalCenter/index', query: { name: '我的岗位', isDetail: 1, companyWorkwantId: item.id } });
            }
        },
        detailBack() {
            this.showDetail = false
        },
        parentDetail(item) {
            sessionStorage.setItem('myPositionPage', this.page.pageNum)
            this.$router.push({ path: '/personalCenter/jobDetails', query: { name: '我的岗位', id: item.id, companyWorkwantId: item.id} })
         },
        saveFnParent(form) {
            // saveFnParent
        },
        publishFn() { },
        cancleFn() { }
    },
}
</script>

<style scoped lang="scss">
.myPosition-container {
    position: relative;
    min-height: 822px;

    .title {
        font-weight: bold;
        font-size: 22px;
        color: #333333;
        margin-bottom: 40px;
    }


}

// 列表
.list {
    display: grid;
    // grid-template-columns: 1fr 1fr 1fr;
    grid-template-columns: 384px 384px 384px;
    grid-template-rows: max-content;
    grid-column-gap: 22px;
    grid-row-gap: 20px;
    padding-bottom: 20px;
    .list-item {
        background: #FFFFFF;
        border-radius: 8px;
        border: 1px solid #DBDBDB;

        font-size: 14px;
        font-weight: 400;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        .top-content {
            padding: 15px 22px 10px 18px;
            flex: 1;
            .name {
                font-size: 18px;
                font-weight: 500;
                // font-weight: bold;
                color: #555;
                line-height: 28px;

                .color7e2 {
                    color: #1A67E2;
                    // letter-spacing: 2px;
                }
            }

            .look-btn {
                padding: 0 11px;
                height: 34px;
                line-height: 34px;
                background: #3076FF;
                border-radius: 7px;
                border-radius: 6px;
                color: #fff;
                font-size: 14px;
            }

            .adress {
                margin-top: 14px;
                color: #888;

                .el-icon-s-custom {
                    color: #1A67E2;
                    margin-right: 4px;
                }
            }
        }

        .bottom-content {
            height: 58px;
            line-height: 58px;
            padding: 0 22px;

            border-top: 1px solid rgba(151, 151, 151, .2);
            color: #888;
            font-size: 16px;
            .el-icon-location-outline {
                margin-top: 7px;
            }
        }

    }

    .pl6 {
        padding-left: 6px;
    }

    .pl10 {
        // width: 200px;
        padding-left: 10px;
        overflow: hidden;
    }

    .pt10 {
        padding-top: 10px;
    }
}

// 发布岗位按钮
.btn {
    width: 174px;
    height: 44px;
    line-height: 44px;
    text-align: center;
    background: #52C4A4;
    border-radius: 6px;

    font-weight: 500;
    font-size: 16px;
    color: #FFFFFF;
    cursor: pointer;

    position: absolute;
    right: 32px;
    top: 0;

}
.paginationBox {
    position: absolute;
    bottom: -20px;
    width: 100%;
    text-align: right;
    ::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
        background-color: #0066FF;
    }
}
.el-empty {
    min-height: 720px;
}
</style>
