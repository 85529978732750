import { render, staticRenderFns } from "./personalInformation.vue?vue&type=template&id=2b1f6797&scoped=true"
import script from "./personalInformation.vue?vue&type=script&lang=js"
export * from "./personalInformation.vue?vue&type=script&lang=js"
import style0 from "./personalInformation.vue?vue&type=style&index=0&id=2b1f6797&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b1f6797",
  null
  
)

export default component.exports