import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import store from '@/store'
import LoginModal from "@/views/login/index.vue";
import scanCodePay from "@/components/personalCenter/scanCodePay.vue";
import scanCodePayTest from "@/components/personalCenter/scanCodePayTest.vue";
import resumeTipDialog from "@/components/personalCenter/resumeTipDialog.vue";
import callDialog from "@/components/personalCenter/callDialog.vue";
import changeRule from "@/components/personalCenter/changeRule.vue";
import lawIsRole from "@/components/personalCenter/lawIsRole.vue";
import ifWorkRule from "@/components/personalCenter/ifWorkRule.vue";
import cantTo from '@/components/personalCenter/cantTo.vue'
import testCard from '@/components/myTest/test-card.vue'
import ResumeTemplate from "@/components/personalCenter/ResumeTemplate.vue";
// 代码是从svn上面迁移过来初始化的, 细节有疑惑,可以到svn查看具体是谁写的功能,咨询对方当时想法
import VueAMap from "vue-amap";
import 'element-ui/lib/theme-chalk/index.css';
import { vueBaberrage } from 'vue-baberrage'
import '../src/utils/rem';//引入rem.js

// import moment from 'moment'
Vue.directive('noMoreClick', {
  inserted(el, binding) {
    el.addEventListener('click', e => {
      el.classList.add('is-disabled')
      el.disabled = true
      setTimeout(() => {
        el.disabled = false
        el.classList.remove('is-disabled')
      }, 2000)//我这里设置的是2000毫秒也就是2秒
    })
  }
})


Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.use(vueBaberrage)
Vue.use(VueAMap)

ElementUI.Dialog.props.lockScroll.default = false;    // 因为dialog点击会动了一下

Vue.component('LoginModal', LoginModal);
Vue.component('scanCodePay', scanCodePay);   // 扫码付款弹窗  使用  <scanCodePay ref="scanCodePay"/> this.$refs.scanCodePay.showDialog()展示弹窗
Vue.component('scanCodePayTest', scanCodePayTest);   // 扫码付款弹窗
Vue.component('resumeTipDialog', resumeTipDialog);   // 未写简历提示 弹窗  使用方法同上扫码弹窗
Vue.component('callDialog', callDialog);   // 打电话弹窗提示 弹窗   使用方法同上扫码弹窗
Vue.component('changeRule', changeRule);   // 角色
Vue.component('cantTo', cantTo);   // 法律助手弹窗
Vue.component('lawIsRole', lawIsRole);   // 法律助手弹窗
Vue.component('ifWorkRule', ifWorkRule);
Vue.component('testCard', testCard);   // 性格测试
Vue.component('ResumeTemplate', ResumeTemplate);   // 简历
// 定义一个全局过滤器实现日期格式化
// Vue.filter('datefmt', function (input, 'YYYY-MM-DD') {
//   return moment.unix(input).format('YYYY-MM-DD')
// })

VueAMap.initAMapApiLoader({
  key: '10a2aba57769381106233dd73cc703a2',
  plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor', 'AMap.Geolocation', 'AMap.DistrictSearch', 'AMap.setPointToCenter'],
  uiVersion: '1.0',
  v: '1.4.15'
})
Vue.prototype.currentModals = [];
Vue.prototype.$showLoginModal = function (initialShow = false) {
  // 检查 `currentModals` 中是否已包含 "loginModal"，表示已有相同弹窗正在展示
  if (this.currentModals.includes('loginModal')) {
    return;
  } else {
    this.currentModals.push('loginModal');
  }
  const vm = new Vue({
    components: { LoginModal },
    data() {
      return { showModal: initialShow };
    },
    render(h) {
      return h(LoginModal, {
        props: {
          show: this.showModal,
        },
        on: {
          'update:show': (value) => {
            this.showModal = value;
            // 根据 `showModal` 的值更新当前弹窗状态
            this.currentModals.splice(this.currentModals.indexOf('loginModal'), 1);
          },
        },
      });
    },
  }).$mount();

  document.body.appendChild(vm.$el);

  return {
    close: () => {
      vm.showModal = false;
    },
  };
};

Vue.use(ElementUI)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

// 兼容性
if(!Object.hasOwn) {
  Object.hasOwn = (obj, key) => Object.prototype.hasOwnProperty.call(obj, key);
}
