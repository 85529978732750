import Layout from "@/layout";

const homeRouter = {
  path: '/home',
  redirect: '/home/index',
  component: Layout,
  name: 'Home',
  children: [
    {
      path: 'index',
      component: () => import('@/views/home/index'),
      name: 'HomeIndex',
    }
  ]
}

export default homeRouter
