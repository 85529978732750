import Layout from "@/layout";

const hrQuestionRouter = {
  path: '/hrQuestion',
  component: Layout,
  redirect: '/hrQuestion/index',
  name: 'hrQuestion',
  children: [
    {
      path: 'index',
      component: () => import('@/views/hrQuestion/index'),
      name: 'hrQuestionIndex',
    }
  ]
}

export default hrQuestionRouter
