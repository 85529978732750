<template>
  <div class="container">
    <div class="container_left">
      <div class="case_box">
        <div class="case_box_newChat_btn" @click="getNewChat">
          <img :src="btnIcon" alt="" />
          <span>创建新会话</span>
        </div>
        <div class="case_box_chatHistory">
          <div class="case_box_chatHistory_divide"></div>
          <span class="case_box_chatHistory_content">历史询问</span>
        </div>
        <div class="case_box_chatHistory_list">
          <div
            class="case_box_chatHistory_item"
            v-for="(item, index) in HistoryLimit.slice(0, 5)"
            :key="index"
            @click="getMessageList(item.sessionBatch)"
          >
            <span>{{ item.modelSearch }}</span>
            <div class="case_box_chatHistory_item_divide"></div>
          </div>
          <div class="case_box_chatHistory_lastItem">保留最近5次对话</div>
        </div>
      </div>
      <div class="case_box">
        <div class="case_box-work-title">
          <div class="case_box-work-title--left">
            <div class="case_box-work-title--left_divide"></div>
            <span>热门岗位</span>
          </div>
          <div class="case_box-work-title--right" @click="reloadBtnList()">
            <img
              :src="reloadBtn"
              alt=""
              class="case_box-work-title--right_btn icon-arrow"
            />
            <span>换一换</span>
          </div>
        </div>
        <div class="case_box-work-list">
          <div
            class="case_box-work-list--item"
            v-for="(item, index) in hotWorkList.data"
            :key="index"
          >
            <div class="case_box-work-list--item-left">
              <!-- <span class="work-title">{{ formatWorkTypeName(item.workTypeName) }}</span> -->
              <el-tooltip class="item" effect="dark" placement="top-end">
                <div slot="content">{{ item.workTypeName }}</div>
                <div class="singeLine work-title" style="max-width: 190px;">{{item.workTypeName }}</div>
              </el-tooltip>
              <div class="work-tag-box">
                <div
                  class="work-tag"
                  v-if="item.postType == 0"
                  style="background-color: #0b2467"
                >
                  灵活用工
                </div><div
                  class="work-tag"
                  v-if="item.postType == 1"
                  style="background-color: #0b2467"
                >
                  长期岗位
                </div>
                <div
                  class="work-tag"
                  v-if="item.postType == 2"
                  style="background-color: #0b2467"
                >
                  短期岗位
                </div>
                <div
                  class="work-tag"
                  v-if="item.postType == 3"
                  style="background-color: #0b2467"
                >
                  兼职/代班
                </div>
                <div class="work-tag">{{ item.employeesNum }}人</div>
              </div>
              <el-tooltip class="item" effect="dark" placement="top">
                <div slot="content" >{{ item.proCompanyName }}</div>
                <div class="work-address" v-if="!!item.proCompanyName">
                  <img src="@/assets/common/company.png" alt="" style="width: 14px; height: 14px;"/>
                  <span class="proArea-span">{{ formatCompanyName(item.proCompanyName) }}</span>
                </div>
              </el-tooltip>
            </div>
            <div class="case_box-work-list--item-right">
              <div class="salary_box">
                <span class="salary_box-num" v-if="item.minProSpend != '-1'">
                  {{ item.minProSpend }}-{{ item.maxProSpend }}
                </span>
                <span class="salary_box-num" v-if="item.minProSpend == '-1'">
                  面议
                </span>
                <span class="salary_box-unit" v-if="item.minProSpend != '-1'">{{
                  item.proUnit ? filter(proUnitDictType, item.proUnit) : ""
                }}</span>
              </div>
              <div class="record_btn" @click="getWork(item)">去报名</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container_right">
      <div class="chat_box-question--tips" v-show="isShowTips">
        <div class="tips_title">
          <div class="tips_title-left">
            <div class="tips_title-left--divide"></div>
            <span>猜你想问</span>
          </div>
          <div class="tips_title-right" @click="reloadTipsBtnList()">
            <img
              :src="reloadBtn"
              alt=""
              class="tips_title-right--btn icon-arrow"
            />
            <span>换一批</span>
          </div>
        </div>
        <div class="chat_box-question-tips--divide"></div>
        <div class="chat_box-question-tips--item">
          <div
            class="chat_box-question-tips--item-box"
            v-for="(item, index) in processedTipsList"
            :key="index"
            @click="postQuestion(item.dialogueContent)"
          >
            <el-tooltip
              class="item"
              effect="dark"
              :content="item.tooltipContent"
              placement="top"
              :disabled="item.tooltipDisabled"
            >
              <span>
                {{ item.displayText }}
              </span>
            </el-tooltip>
            <img :src="rightIcon" alt="" />
          </div>
        </div>
      </div>
      <div class="chat_box--body">
        <chat-box
          ref="aichatBox"
          :chatMessageList="chatMessageList"
          :proUnitDictType="proUnitDictType"
          box-height="616"
          :serviceType="serviceType"
          @openAIResumeDialog="openAIResumeDialog"
        ></chat-box>
      </div>
      <post-message
        ref="postMessage"
        @update:isDoing="(value) => (isTyping = value)"
        @message-sent="handleMessage"
        :sessionBatch="sessionBatch"
        :serviceType="serviceType"
        :isDoing="isTyping"
      >
      </post-message>
    </div>
    <!-- 发布岗位弹窗 -->
    <AIResume
        ref="aiResume"
        @openResumeTemplateDialog="openResumeTemplateDialog"
    ></AIResume>
    <resume-download-dialog
        ref="resumeDownloadDialog"
    ></resume-download-dialog>
  </div>
</template>
<script>
import chatBox from "@/components/aiChatBox";
import postMessage from "../../components/postMessage/index.vue";
import store from '@/store'
import {
  dictTypeApi,
  hotWorkListApi,
  postAiFindWorkCommunicationAnalyzerApi,
  postAiFindWorkListApi,
  postAiFindWorkPresetQuestionApi,
} from "@/api/aiFindWork";
import {filter} from "@/utils/filters";
import {getHistoryListApi, getMessageListApi, getQuestionListApi, saveMessgaeApi,} from "@/api/com";
import {getResumeApi} from "@/api/aiInterView";
import AIResume from "@/views/personalCenter/components/aiFindWork/AIResume.vue";
import resumeDownloadDialog from "@/views/personalCenter/components/aiFindWork/ResumeTemplateDownload.vue";
import {jobWantUploadAPI} from "@/api/personalCenter";
import {AsyncQueue} from "@/utils/asyncUtils/AsyncQueue";
import {filterRelativePostData} from "@/views/aiFindWork/dataFilter";

let asyncQueue = new AsyncQueue();

function sleep(time) {
  return new Promise(resolve => setTimeout(resolve, time));
}

export default {
  name: "aiFindWork",
  components: {
    chatBox,
    postMessage,
    AIResume,
    resumeDownloadDialog,
  },
  watch: {
    mode(newVal, oldVal) {
      if(newVal === 0) {
        this.$refs.aichatBox.updateElementHeight("450");
      } else {
        this.$refs.aichatBox.updateElementHeight("616");
      }
    },
    chatMessageList(newVal, oldVal) {
      if(this.mode === 0) {
        this.$refs.aichatBox.updateElementHeight("450");
      } else {
        this.$refs.aichatBox.updateElementHeight("616");
      }
    },
  },
  computed: {
    HistoryLimit() {
      return this.questionHistoryList.map((item) => {
        const maxLength = 14; // 设置最大长度
        if (item.modelSearch.length > maxLength) {
          return {
            ...item,
            modelSearch: item.modelSearch.substring(0, maxLength) + "...",
          };
        }
        return item;
      });
    },
    processedTipsList() {
      return this.questionTipsList.map((item) => {
        const processedText = this.truncateText(item.dialogueContent, 16);
        return {
          ...item,
          displayText: processedText.text,
          tooltipContent: processedText.tooltip ? item.dialogueContent : "",
          tooltipDisabled: !processedText.tooltip, // 禁用工具提示，除非文本被截断
        };
      });
    },
    isShowTips() {
      return this.mode === 0;
    }
  },
  data() {
    return {
      userInput: "",
      chatMessageList: [
        /**
         * {
         *   ViewResume: boolean, // 是否呈现查看简历按钮
         *   text: string, // 需要呈现的文本
         *   list: Array, // 匹配到的数据
         * }
         */
      ],
      rotFlag: true,
      rotTipFlag: true,
      rightIcon: require("@/assets/icon/aiFindWork/rightIcon.png"),
      addressIcon: require("@/assets/icon/aiFindWork/addressTips.png"),
      btnIcon: require("@/assets/icon/aiFindWork/add_btn_icon.png"),
      reloadBtn: require("@/assets/icon/aiFindWork/reload_btn.png"),
      questionHistoryList: [],
      questionTipsList: [],
      isTyping: false,
      hotWorkListForm: {
        pageNum: 1,
        pageSize: 3,
      },
      history: [],
      pageNum: 0,
      historyPageNum: 0,
      sessionBatch: null, //会话批次,用来判断是新会话,还是旧会话,如果是新会话就不用传
      presetSessionBatch: null, // 预设问题会话批次
      isNewSession: "0", //是否新会话（0-新会话，1-历史会话）
      serviceType: "",
      replyContent: null,
      hotWorkList: {
        data: [],
        total: 0,
      },
      proUnitDictType: [],
      total:0,
      mode: 1, // 区分对话模式（0-求职者主动寻找，1-AI预设问题）
      presetSummaryList: [],
      presetQuestionList: [], // 预设问题列表
      currentPresetQuestion: null, // 当前正在询问的预设问题
      preQuesCompletedTips: "已为您智能生成了简历。", // 预设问题问答结束，智能简历已生成提示
    };
  },
  mounted() {
    this.getHostWorkList();
    this.getDictTypeList();
    window.addEventListener('load',()=> {
      const currentRoute = this.$router.currentRoute;
      if (currentRoute.query && Object.keys(currentRoute.query).length > 0) {
        this.$router.push({ path: currentRoute.path, query: {
            serviceType:2
          }});
      }
    });
    // this.run();
  },
  created() {
    // 检查路由查询参数中是否有 serviceType
    if (this.$route.query.serviceType) {
      this.serviceType = this.$route.query.serviceType;
    }
    asyncQueue = new AsyncQueue();
    this.getQuestionList();
    this.getHistoryList();
    this.getResumeToSwitchMode();
  },
  methods: {
    openResumeTemplateDialog(data){
      this.$refs.resumeDownloadDialog.showDialog(data);
    },
    filter,
    getWork(item) {
      if(store.state.user.identity == 1){
        this.getInit(item)
      }else {
        this.$alert('请先将身份切换为我要找活', '提示', {
          confirmButtonText: '确定',
          callback: action => {
          }
        });
      }
    },
    getInit(item) {
      this.$router.push({
        path: '/personalCenter/jobDetails',
        query: {
          name: 'AI找活',
          id: item.id,
          companyWorkWantId: item.id
        }
      })
        /*$api.getCompanyWorkWantInfoAPI({ id: item.id, obj }).then(res => {
          let { code, msg, data } = res
          if (code == 200) {
            this.$router.push({
                path: '/personalCenter/jobDetails',
                query: {
                  name: 'AI找活',
                  id: data.id,
                  companyWorkWantId: data.id
                }
            })
          }
        }).catch(error => {  // 601 岗位不存在
            this.$message.warning(error.msg)
        })*/
    },
    //限制字数，用...替代
    truncateText(text, maxLength) {
      if (text.length > maxLength) {
        return { text: text.substring(0, maxLength) + "...", tooltip: true };
      }
      return { text: text, tooltip: false };
    },
    //获取字典
    getDictTypeList() {
      dictTypeApi({ dictType: "crm_pro_unit" })
        .then((res) => {
          this.proUnitDictType = res.data;
        })
        .catch(() => {});
    },
    //获取热门岗位列表
    getHostWorkList() {
      hotWorkListApi(this.hotWorkListForm)
        .then((res) => {
          this.hotWorkList.data = res.data.rows.filter(item => !!item.workTypeName);
          this.hotWorkList.total = res.data.total;
          if (
            this.hotWorkListForm.pageNum * this.hotWorkListForm.pageSize <
            this.hotWorkList.total
          ) {
            this.hotWorkListForm.pageNum++;
          } else {
            this.hotWorkListForm.pageNum = 1;
          }
        })
        .catch(() => {});
    },
    //创建新对话
    getNewChat() {
      this.getHistoryList();
      this.isNewSession = "0";
      this.chatMessageList = [];
      this.sessionBatch = null;
      this.isTyping = false;
    },
    //四个问题列表换一批
    refreshTips() {
      this.getQuestionList();
    },
    // serviceType : 1-AI招工，2-AI找活，3-工作伙伴AI助手，4-权益守护AI助手，5-智能面试
    getQuestionList() {
      this.pageNum += 1;

      const data = {
        pageNum: this.pageNum,
        pageSize: 4,
        serviceType: "2",
      };

      if (this.pageNum === 1) {
        getQuestionListApi(data)
            .then((res) => {
              this.total = res.data.total;
              this.questionTipsList = res.data.rows;
            })
            .catch((error) => {
              console.error("错误信息:", error);
            });
      } else if (this.pageNum > 1 && this.total % this.pageNum*4 === 0) {
        getQuestionListApi(data)
            .then((res) => {
              this.questionTipsList = res.data.rows;
            })
            .catch((error) => {
              console.error("错误信息:", error);
            });
      } else if (this.total % this.pageNum*4 !== 0) {
        // console.log('1111')
        this.pageNum = 0;
        getQuestionListApi(data)
            .then((res) => {
              this.questionTipsList = res.data.rows;
            })
            .catch((error) => {
              console.error("错误信息:", error);
            });
      }
    },
    transformMessages(historyMessages) {
      // console.log("history message", historyMessages);
      let messageId = 1;

      // 将消息转化成消息列表项
      const transformMessage = (message) => {
        const result = [];

        // 处理用户消息
        if (message.modelSearch) {
          const content = message.modelSearch;
          result.push({
            id: messageId++, //分配ID递增
            type: "text",
            user: 'user',
            content: content,
          });
        }

        // 处理AI消息
        if (message.modelRecover) {
          let content = message.modelRecover;
          try {
            content = JSON.parse(content);
          } catch (e) {
            content = {
              text: "暂未找到合适的结果，您可尝试输入:工种类型、年龄、性别、工作地点等信息进行重试。"
            };
          }
          const item = {
            id: messageId++,
            type: "text",
            user: 'robot',
            content: content,
          };
          message.isUseGenJob === 1 ? result.unshift(item) : result.push(item);
        }
        return result;
      }
      return historyMessages
        .map(transformMessage)
        .reduce((acc, cur) => [...acc, ...cur], []) ?? [];
    },
    // 点击历史记录标题，获取历史消息
    async getMessageList(sessionBatch) {
      this.isTyping = false;
      this.sessionBatch = sessionBatch; //存储批次,发送消息需要带
      this.isNewSession = "1"; //设置成历史会话

      const data = {
        sessionBatch: sessionBatch,
        serviceType: "2",
      };
      try {
        this.chatMessageList = [];
        this.$refs.aichatBox.updateIsLoading();
        // 查询历史记录
        const res = await getMessageListApi(data);
        // 解析历史记录
        const messageList = this.transformMessages(res.data);
        // console.log("消息解析:", messageList);
        this.chatMessageList = messageList;
        this.safeScrollBottom();
      } catch(err) {
        console.error("错误信息:", err);
      }
      this.isTyping = false;
    },
    postQuestion(question) {
      if (this.isTyping) return

      const messageData = {
        message: question,
      };
      this.handleMessage(messageData);
    },
    //接收<post-message>组件用户发送的消息
    async handleMessage(messageData) {
      this.isTyping = true;
      // localStorage.getItem('sensitiveWords')与message核验，如果有敏感词，则return 并提示有敏感词，并且做处理
      const sensitiveWords = JSON.parse(
        localStorage.getItem("sensitiveWords") || "[]"
      );
      let sensitiveMessage = messageData.message;
      let foundSensitiveWord = false;

      sensitiveWords.forEach((word) => {
        // 使用正则表达式匹配完整单词
        // const regex = new RegExp(`\\b${word}\\b`, "gi");
        // if (regex.test(sensitiveMessage)) {
        //   foundSensitiveWord = true;
        //   sensitiveMessage = sensitiveMessage.replace(regex, "***");
        // }

        const regex = new RegExp(`\\b${word}\\b`, "gi");
        let a = sensitiveMessage.indexOf(word);
        // 如果匹配到敏感词，则替换为***
        if (a>=0) {
          foundSensitiveWord = true;
          sensitiveMessage = sensitiveMessage.replace(regex, "***");
        }

      });

      if (foundSensitiveWord) {
        this.isTyping = false;
        this.$message.error("您的消息包含敏感词，请重新编辑后发送。");
        return;
      }

      // 动态分配新消息的 ID
      const userMessage = {
        id: this.getNewId(),
        type: "text",
        user: "user",
        content: messageData.message,
      };
      // 将新消息添加到 chatMessageList
      this.chatMessageList.push(userMessage);
      // 强制 Vue 立即更新 DOM
      this.safeScrollBottom();

      // 优化显示效果
      await sleep(400);

      // 添加空的robot消息作为加载占位符
      this.chatMessageList.push(this.createLoadingMessage());
      // 强制 Vue 立即更新 DOM
      this.safeScrollBottom();

      await this.sendToAIAndGetReply(messageData.message, messageData.sessionBatch);
    },
    safeScrollBottom() {
      if (
        this.$refs.aichatBox &&
        typeof this.$refs.aichatBox.scrollToBottom === "function"
      ) {
        this.$refs.aichatBox.scrollToBottom();
      }
    },
    // 获取历史消息记录
    getHistoryList() {
      this.historyPageNum = 1;
      const data = {
        pageNum: this.historyPageNum,
        pageSize: 5,
        serviceType: "2",
      };
      getHistoryListApi(data)
        .then((res) => {
          // console.log("获取左侧历史标题记录:", res);
          this.questionHistoryList = res.data.rows;
          const presetCommunication = this.questionHistoryList.find((history) => history.isUseGenJob === 1);
          if(presetCommunication) {
            this.presetSessionBatch = presetCommunication.sessionBatch;
            presetCommunication.modelSearch = "智能简历问答";
          }
          // console.log("历史消息列表：", this.questionHistoryList);
        })
        .catch((error) => {
          console.error("错误信息:", error);
        });
    },
    //保存消息数据到后端
    saveHistory(saveData) {
      // console.log("saveData11111", saveData);

      const data = {
        serviceType: "2",
        fileType: "0",
        modelSearch: saveData.userContent,
        modelRecover: saveData.robotContent,
        modelType: "1",
        isUseGenJob: this.mode === 1 ? 1 : 0, // 是否是预设问题（0-否，1-是）
      };

      // 如果是预设问题模式，是则统一 sessionBatch
      if (this.mode === 1) {
        if(this.presetSessionBatch) {
          data.sessionBatch = this.presetSessionBatch;
        }
      } else {
        if (this.sessionBatch) {
          data.sessionBatch = this.sessionBatch;
        }
      }

      // console.log(JSON.stringify(data))
      const task = () => {
        return saveMessgaeApi(data)
            .then((res) => {
              // console.log("保存数据后的响应:", res);
              // 保存服务器回传的 sessionBatch
              // 首次保存历史记录时，由服务器生成 sessionBatch 并回传
              if(this.mode === 1) {
                if (this.presetSessionBatch == null && res.data.sessionBatch) {
                  this.presetSessionBatch = res.data.sessionBatch;
                }
              } else {
                if (this.sessionBatch == null && res.data.sessionBatch) {
                  this.sessionBatch = res.data.sessionBatch;
                }
              }
              if(this.isNewSession === '0') {
                this.isNewSession = "1";
                this.getHistoryList();
              }
            })
            .catch((error) => {
              console.error("错误信息:", error);
            }).finally();
      }
      asyncQueue.enqueue(task);
    },
    async openAIResumeDialog() {
      try {
        const res = await getResumeApi();
        const resume = res.data;
        if(!resume.id) {
          console.error("chat box 简历未生成!");
          return;
        }
        // console.log(resume);
        await this.$refs.aiResume.showAIResumeForm(resume);
      } catch (e) {
        console.error("chat box 获取简历失败!", e)
        this.$message.error("获取简历失败!");
      }
    },
    // 获取个人简历
    getResumeToSwitchMode() {
      getResumeApi().then(res => {
        // console.log("获取个人简历", res)
        // 如果简历不为空，切换至求职者主动提问模式
        if(Object.hasOwn(res.data, "id")) {
          this.mode = 0;
        }
        if (this.$route.query.context) {
          this.queryContent = this.$route.query.context;
          this.postQuestion(this.queryContent);
        }
        if(this.mode === 1) {
          // 如果简历为空，执行预设问题，补充简历
          this.getAIFindWorkPresetQuestion();
        }
      }).catch((e) => {
        console.error("获取个人简历", e)
      });
    },
    // 获取AI 找活预设问题
    getAIFindWorkPresetQuestion() {
      this.chatMessageList.push(this.createLoadingMessage());

      postAiFindWorkPresetQuestionApi()
        .then((res) => {
          // console.log("这是AI找活预设问题：", res);
          // 找到最后一个isLoading的robot消息，并更新它
          const lastLoadingMsgIndex = this.chatMessageList.findLastIndex((m) => m.user === "robot" && m.isLoading);
          if(lastLoadingMsgIndex === -1) {
            return;
          }
          const getPresetQuestionData = (response) => {
            return response.data.rows[0];
          }
          const data = getPresetQuestionData(res);
          // 获取总述句的预设问题列表
          this.presetSummaryList = data['fir_level_list'];
          // 获取单字段的预设问题列表
          this.presetQuestionList = data['sec_level_list'];

          // console.log(JSON.stringify(data, null, 2));

          this.currentPresetQuestion = this.presetSummaryList[0] || this.presetQuestionList[0];
          // 服务器在生成简历时出现问题，前端处理
          if(!this.currentPresetQuestion) {
            this.sendPresetToAIAndGetReply("", this.sessionBatch, false);
            return;
          }
          this.updateLoadingChatMessage(lastLoadingMsgIndex, {text: this.currentPresetQuestion.preQuContent});
        })
        .catch((e) => {
          console.error("获取AI找活预设问题失败", e);
        });
    },
    // 发送消息到AI并获取回复
    async sendToAIAndGetReply(userMessageContent, sessionBatch, history = true) {
      if(this.mode === 1) {
        // 用户处于预设问题情境下的回答处理
        await this.sendPresetToAIAndGetReply(userMessageContent, sessionBatch, history);
      } else {
        // 用户处于默认情境下的提问处理
        await this.sendQuestionToAIAndGetReply(userMessageContent, sessionBatch);
      }
    },
    // 发送预设问题的回答给服务器并获取回复
    async sendPresetToAIAndGetReply(userMessageContent, sessionBatch, history = true) {
      const lastLoadingMsgIndex = this.chatMessageList.findIndex(
          (m) => m.user === "robot" && m.isLoading
      );
      if(!this.currentPresetQuestion) {
        this.updateLoadingChatMessage(lastLoadingMsgIndex, {text: "请先完成简历填写。"});
        return;
      }
      // 获取当前预设问题，并置空，为下一个预设问题做准备
      const currentPresetQuestion = this.currentPresetQuestion;
      this.currentPresetQuestion = null;

      const sendData = {
        record: userMessageContent,
        session_id: !sessionBatch ? this.sessionBatch : sessionBatch ? sessionBatch : "-1",
        useGenJob: 1, // 区分是否预设问题（1-是）
      }
      if(currentPresetQuestion && currentPresetQuestion.preQuLevel === '2') {
        sendData["field_dict"] = currentPresetQuestion.preQuType;
        const filter = ['姓名', '带头人/领班/组长', '培训报名', '资格证名称', '培训地点', '培训时间', '自我介绍'];
        if(filter.includes(currentPresetQuestion.preColumName)) {
          sendData["field_value"] = userMessageContent;
        }
      }
      try{
        const res = await postAiFindWorkCommunicationAnalyzerApi(sendData);
        if(res.code !== 200) {
          throw new Error(res.msg);
        }
        // console.log("找活预设问题回答反馈：",res);
        if(history) {
          this.saveHistory({
            userContent: userMessageContent,
            robotContent: { text: currentPresetQuestion.preQuContent },
          });
        }


        const data = res.data;
        // 如果用户还未完成必要问题的回答，将继续进行询问
        if(data.completed === false) {
          let requiredMissing = data.requiredMissing.length !== 0 ? data.requiredMissing : data.optionalMissing;
          if(requiredMissing.length === 0) {
            throw new Error("预设未完成，但已无需要询问事项！");
          }
          const required = requiredMissing[0];
          const presetQuestionFilter = this.presetQuestionList.filter((item) => item.preQuType === required);
          if(presetQuestionFilter.length === 0) {
            throw new Error("未找到需要询问的招工事项！" + required);
          }
          const presetQuestion = presetQuestionFilter[0];
          this.currentPresetQuestion = presetQuestion;
          this.updateLoadingChatMessage(lastLoadingMsgIndex, {text: presetQuestion.preQuContent});
          this.focusPostInput();
        } else {
          // 如果用户已完成必要问题的回答，显示跳转至简历修改界面的按钮
          // console.log("找活预设问题回答完成");

          let content = {
            viewResume: true,
            text: `${this.preQuesCompletedTips}`,
          };
          this.saveHistory({
            userContent: "",
            robotContent: content,
          });

          this.updateLoadingChatMessage(lastLoadingMsgIndex, content,{jumpPath: "1"});

          const workWantList = filterRelativePostData(data.matchWorkWant);

          // 根据简历生成查询列表，数据由服务器提供
          const index = this.chatMessageList.push(this.createLoadingMessage()) - 1;
          this.replyContent = workWantList;

          // isExactMatch 1:正确匹配 0:匹配失败的兜底数据
          content = {
            text: res.isExactMatch === 1 ? "已为您找到了一些相关岗位" : "暂未找到合适的结果，为您推荐以下您可能感兴趣的岗位",
            list: workWantList,
          };

          this.saveHistory({
            userContent: "",
            robotContent: content,
          });
          this.updateLoadingChatMessage(index, content);
          this.safeScrollBottom();
          // 退出预设模式
          this.mode = 0;
        }
      } catch (e) {
        console.error("预设问题回答反馈失败", e);
        this.updateLoadingChatMessage(lastLoadingMsgIndex, {text: "无法连接服务器，请检查网络连接状态。"});
      } finally {
        this.isTyping = false;
      }
    },
    // 发送用户提问给服务器并获取回复
    async sendQuestionToAIAndGetReply(userMessageContent, sessionBatch){
      const sendData = {
        pageNum: 1,
        pageSize: 3,
        keyword: userMessageContent,
      };

      let replyContent = {
        text: "暂未找到合适的结果，您可尝试输入:工种类型、年龄、性别、工作地点等信息进行重试。",
      }
      try {
        const res = await postAiFindWorkListApi(sendData);
        // console.log("AI 找活数据", res);
        if (res.data.rows.length > 0) {
          replyContent = {
            text: res.isExactMatch === 1 ? "已为您找到了一些相关岗位" : "暂未找到合适的结果，为您推荐以下您可能感兴趣的岗位",
            list: filterRelativePostData(res.data.rows),
          }
        }
      } catch (e) {
        console.error("找活查找失败", e);
      }
      // 找到最后一个isLoading的robot消息，并更新它
      const lastLoadingMessageIndex = this.chatMessageList.findIndex(
          (m) => m.user === "robot" && m.isLoading
      );
      if (lastLoadingMessageIndex !== -1) {
        this.saveHistory({
          userContent: userMessageContent,
          robotContent: replyContent,
        });
        this.updateLoadingChatMessage(lastLoadingMessageIndex, replyContent);
      }
      this.isTyping = false;
    },
    updateLoadingChatMessage(pos, content, options) {
      options = options ? options : {};
      const updatedMessage = {
        ...this.chatMessageList[pos],
        content: JSON.parse(JSON.stringify(content)),
        isLoading: false, // 更新为非加载状态
        ...options, // 额外参数（例如简历id）
      };
      this.$set(
          this.chatMessageList,
          pos,
          updatedMessage
      );
      if(this.mode === 0) {
        this.safeScrollBottom();
      }
    },
    getNewId() {
      return this.chatMessageList.length + 1;
    },
    createLoadingMessage(user = 'robot') {
      return {
        id: this.getNewId(),
        type: "text",
        user: user,
        workDataList: [], // 空内容, deprecated
        content: [],// 空内容
        isLoading: true,
      };
    },
    reloadBtnList() {
      this.getHostWorkList();
      let element = document.querySelector(".case_box-work-title--right_btn");
      if (this.rotFlag) {
        element.classList.add("icon-arrow-rotate");
      } else {
        element.classList.remove("icon-arrow-rotate");
      }
      this.rotFlag = !this.rotFlag;
    },
    reloadTipsBtnList() {
      let element = document.querySelector(".tips_title-right--btn");
      if (this.rotTipFlag) {
        element.classList.add("icon-arrow-rotate");
      } else {
        element.classList.remove("icon-arrow-rotate");
      }
        this.rotTipFlag = !this.rotTipFlag;
        this.getQuestionList()
    },
    focusPostInput() {
      this.$refs.postMessage.focusInput();
    },
    formatCompanyName(text) {
      if(text.length > 15) {
        return text.substring(0, 15) + '...'
      }
      return text
    },
    // 测试接口
    run() {
      const testMap = {
        '姓名': '刘一',
        '性别': '男性',
        '年龄': '25',
        '想要干的工作': '普工',
        '期望工作地': '贵州省贵阳市',
        '期望薪资': '面议',
        '带头人/领班/组长': '刘一',
        '培训报名':'无',
        '资格证名称': '无',
        '培训地点': '无',
        '培训时间': '无',
        '自我介绍': '无'
      };
      const testSummary = "我是刘一，男性，25，想找个普工，想在贵阳市工作，工资5000到6000一个月";
      this.isTyping = false;
      setInterval(()=>{
        if(this.isTyping || !this.currentPresetQuestion) {
          return;
        }
        this.isTyping = true;
        if(!this.currentPresetQuestion.preColumName) {
          this.postQuestion(testSummary);
          return;
        }
        this.postQuestion(testMap[this.currentPresetQuestion.preColumName]);
      }, 3000);
    },
    formatWorkTypeName(text) {
      if (text && typeof text === "string") {
        let t = text;
        const index = t.indexOf(",");
        if (index !== -1) {
          t = t.substring(0, index) + "...";
        }
        // 只显示不超过三个职业
        const splits = t.split("/");
        if(splits.length > 3) {
          t = splits.slice(0, 3).join("/");
        }
        return t;
      }
      return "不限"; // Return a default string if text is undefined or not a string
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  width: 1840px;
  display: flex;
  margin-top: 27px;

  .container_left {
    width: 360px;

    .case_box {
      width: 360px;
      /* height: 450px; */
      border-radius: 12px;
      margin-bottom: 20px;
      background-color: #ffffff;
      display: flex;
      flex-direction: column;
      align-items: center;

      .case_box_newChat_btn {
        width: 308px;
        height: 44px;
        cursor: pointer;
        margin-top: 47px;
        background: linear-gradient(113deg, #60a4ff 0%, #1957ff 100%);
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 16px;
          height: 16px;
        }

        span {
          margin-left: 3px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 16px;
          color: #ffffff;
        }
      }

      .case_box_newChat_btn:hover {
        background: linear-gradient(293deg, #60a4ff 0%, #1957ff 100%);
      }

      .case_box_chatHistory_list {
        width: 308px;
        background: #f1f5ff;
        border-radius: 6px;
        margin-bottom: 13px;

        .case_box_chatHistory_item {
          height: 51px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          margin-left: 11px;
          margin-right: 11px;
          font-size: 14px;
          color: #333333;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-end;
          cursor: pointer;

          span {
            margin-left: 20px;
            margin-top: 13px;
            margin-bottom: 13px;
          }

          .case_box_chatHistory_item_divide {
            width: 286px;
            height: 1px;
            background-color: #979797;
            opacity: 0.22;
          }
        }

        .case_box_chatHistory_lastItem {
          width: 100%;
          margin-top: 12px;
          margin-bottom: 16px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 10px;
          color: #8d8d8d;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .case_box-work-title {
        width: 308px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .case_box-work-title--left {
          display: flex;
          align-items: center;
          margin-top: 18px;

          .case_box-work-title--left_divide {
            width: 3px;
            height: 15px;
            background: #3076ff;
          }

          span {
            margin-left: 5px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 18px;
            color: #333333;
            font-style: normal;
          }
        }

        .case_box-work-title--right {
          margin-top: 20px;
          display: flex;
          align-items: center;
          cursor: pointer;

          img {
            width: 12px;
            height: 12px;
            margin-right: 4px;
          }

          span {
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #333333;
            font-style: normal;
          }
        }
      }

      .case_box-work-list {
        width: 320px;
        margin-top: 16px;

        .case_box-work-list--item {
          width: 320px;
          height: 70px;
          display: flex;
          margin-bottom: 13px;
          justify-content: space-between;
          border-bottom: 1px solid #dde0e8;

          .case_box-work-list--item-left {
            .work-title {
              font-family: PingFangSC, PingFang SC;
              font-weight: bold;
              font-size: 14px;
              color: #555555;
            }

            .work-tag-box {
              display: flex;
              margin-top: 4px;

              .work-tag {
                margin-right: 3px;
                color: #ffffff;
                border-radius: 1px;
                padding: 2px 7px;
                background-color: #52c4a4;
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                font-size: 9px;
              }
            }

            .work-address {
              margin-top: 5px;
              display: flex;
              align-items: end;
              img {
                width: 6px;
                height: 8px;
              }

              span {
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                font-size: 10px;
                color: #888888;
              }

              .proArea-span{
                margin-left: 4px;
              }
            }
          }

          .case_box-work-list--item-right {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: space-between;

            .salary_box {
              margin-top: 2px;

              .salary_box-num {
                font-family: DINAlternate, DINAlternate;
                font-weight: bold;
                font-size: 14px;
                color: #555555;
              }

              .salary_box-unit {
                font-family: PingFangSC, PingFang SC;
                font-weight: 500;
                font-size: 8px;
                color: #555555;
              }
            }

            .record_btn {
              margin-bottom: 11px;
              display: flex;
              cursor: pointer;
              align-items: center;
              justify-content: center;
              width: 61px;
              height: 22px;
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              font-size: 10px;
              color: #3076ff;
              border-radius: 11px;
              border: 1px solid #3076ff;
            }
          }
        }
      }
    }

    .case_box_chatHistory {
      width: 308px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      margin-top: 40px;
      margin-bottom: 14px;
      font-size: 18px;
      color: #333333;
      display: flex;
      align-items: center;

      .case_box_chatHistory_divide {
        width: 3px;
        height: 15px;
        background: #3076ff;
        margin-right: 5px;
      }
    }
  }

  .container_right {
    width: 1440px;
    margin-bottom: 55px;
    margin-left: 26px;

    .chat_box-question--tips {
      width: 1440px;
      height: 166px;
      display: flex;
      flex-direction: column;
      align-items: center;
      background: #ffffff;
      border-radius: 12px;

      .tips_title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: calc(100% - 54px);
        margin-top: 20px;
        margin-left: 24px;
        margin-right: 30px;

        .tips_title-left {
          display: flex;
          align-items: center;

          .tips_title-left--divide {
            width: 3px;
            height: 15px;
            margin-right: 5px;
            background: #3076ff;
          }

          span {
            font-family: PingFangSC, PingFang SC;
            font-weight: bold;
            font-size: 18px;
            color: #333333;
          }
        }

        .tips_title-right {
          display: flex;
          align-items: center;
          cursor: pointer;

          img {
            width: 19px;
            height: 16px;
          }

          span {
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 17px;
            color: #333333;
          }
        }
      }

      .chat_box-question-tips--divide {
        width: 1386px;
        height: 1px;
        background-color: #979797;
        /* border: 1px solid #979797; */
        opacity: 0.2;
        margin-top: 16px;
      }

      .chat_box-question-tips--item {
        width: calc(100% - 54px);
        display: flex;
        //justify-content: space-between;

        .chat_box-question-tips--item-box {
          width: 328px;
          height: 60px;
          background: #f1f5ff;
          border-radius: 8px;
          margin-top: 21px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          margin-right: 15px;

          span {
            font-family: PingFangSC, PingFang SC;
            font-weight: bold;
            font-size: 16px;
            color: #333333;
          }

          img {
            width: 12px;
            height: 10px;
            margin-left: 4px;
          }
        }
      }
    }

    .chat_box--body {
      width: 100%;
      margin-top: 36px;
    }
  }
}

.icon-arrow {
  transition: all 0.3s ease-out;
}

.icon-arrow-rotate {
  transform: rotate(360deg);
}
</style>
