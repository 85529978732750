import request from '@/utils/request'

/**
 * post接口样例
 * @param data
 */
export function postApi(params) {
    return request.get('/api/crm/web/jobWant/aiFindWorker', { shouldEncrypt: false, shouldDecrypt: true, params })
    // return request.post('/api/crm/web/jobWant/aiFindWorker', data,{shouldEncrypt:true,shouldDecrypt:true})
}

/**
 * 用户信息接口
 * @param params
 */
export function userInfoApi(params) {
    return request.get('/api/crm/web/user/ai/userInfo', {shouldEncrypt:false,shouldDecrypt:true,params})
}

/**
 * post接口样例
 * @param data
 */
export function updateUserIdentityApi(data) {
    return request.post('/api/crm/web/user/updateWebPosition', data,{shouldEncrypt:true,shouldDecrypt:false})
}
