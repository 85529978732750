import { getToken, getRSAKey } from '@/utils/auth'

const state = {
    answerList: [],
    currentIndex: 0,
    examId: '',  //量表id
    workWantId: '', //岗位id

    idsObj: {
        // workTypes: '', // 工种ID
        // userJobwantId: '', // 简历ID
        // companyWorkWantId: '', // 招聘表ID
        // proName: '', // 岗位名称
    },

    fixedBlobList: [] ,  //面试视频列表。 结构：{interviewCode: 面试人唯一标识, data: 面试视频}
    isFinished: false,  //完成面试记录上传
}

const mutations = {
    setAnswerList(state, payload) {
        state.answerList = payload;
    },
    setCurrentIndex(state, payload) {
        state.currentIndex = payload;
    },
    SET_EXAM_ID(state, payload) {
        state.examId = payload;
    },
    SET_WORK_WANT_ID(state, payload) {
        state.workWantId = payload;
    },
    SET_WORK_IDS(state, payload) {
        state.idsObj = payload;
    },
    SET_FIXED_BLOB_LIST(state, payload) {
        state.fixedBlobList = payload;
    },
    SET_IS_FINISHED(state, payload) {
        state.isFinished = payload;
    }
}

export default {
    state,
    mutations,
}
