<template>
    <div class="interviewRecordDetail-container" v-loading=" loading">
        <!-- 视频 -->
        <videoCard ref="videoCard" v-if="!loading"/>
        <!-- 面试题目 -->
        <topicList ref="topicList"/>
        <!-- 面试评分 -->
        <resultCard ref="resultCard" style="position: relative;">
            <!-- 没有付费 -->
            <div class="buy-wrap" v-show="isExistBuy.isExistBuy == false" >
                <div class="content">
                    <img src="@/assets/myTest/lock.png" alt="">
                    <div class="label" @click="payForExam">花费{{isExistBuy.examPrice}}即可查看所有测评结果</div>
                </div>
            </div>
        </resultCard>
        <!-- 去联系 -->
        <scanCodePay ref="scanCodePay" />
        <callDialog ref="callDialog" />
        <scanCodePayTest ref="scanCodePayTest" @paySuccessReflash="paySuccessReflash"/>
    </div>

</template>
<script>

import videoCard from '@/views/personalCenter/interviewRecord/components/video-card'
import resultCard from '@/views/personalCenter/interviewRecord/components/result-card'
import topicList from '@/views/personalCenter/interviewRecord/components/topic-list'
import * as $api from '@/api/personalCenter'
import { payForExamAPI } from '@/api/myTest'
import store from '@/store'
export default {
    name: 'historyInterviewDetail',
    components: {
        videoCard,
        resultCard,
        topicList
    },
    data() {
        return {
            detail: {},
            videoImg: require('@/assets/intelligentInterview/play.png'),
            hrImg: require('@/assets/intelligentInterview/hr.png'),
            workImg: require('@/assets/intelligentInterview/work.png'),
            workList: [],
            loading: true,
            fullscreenLoading: true,

            identity: '', // 1 工人 2hr
            examType: null,
            hasTestRecord: false,   // 是否有测评结果
            isExistBuy: {},
        }
    },
    created() {
        console.log('examId')
        this.identity = store.state.user.identity
        store.watch(() => store.state.user.identity, (newValue, oldValue) => {
            this.identity = newValue
            // this.getDictTypeAPI()
        })
        this.examType = this.$route.query.examType
        this.getDictTypeAPI()

    },
    destroyed() { 
        let ele = document.querySelector('.right-content')
        if (ele) {
            ele.style.background = '#fff'
            ele.style.padding = '32px 34px'
        }
    },
    mounted() {
        let ele = document.querySelector('.right-content')
        if (ele) {
            ele.style.background = 'transparent'
            ele.style.padding = 0
        }
    },
    methods: {
        goConnection() {
            // this.$refs.scanCodePay.showDialog()
            // 获取电话号码
            // detail.empJobWantVO
            let item = this.detail
            $api.getPhoneAPI({ id: item.empJobWantVO.id }).then(res => {
                let { data, success } = res
                if (data && success) {
                    // 订单类型，1:招工电话,2:工人电话,3:抢单,4:充值,5:购买会员,6:充值钱包,7:ai大模型使用招工,8:ai大模型使用工匠,9:悬赏招工,10:企业会员权益
                    let obj = {
                        phone: data,
                        userName: item.empJobWantVO.userName,
                        avatar: item.empJobWantVO.avatar ? item.empJobWantVO.avatar : item.empJobWantVO.sex == '男' ? this.workImg : this.hrImg
                    }
                    this.$refs.callDialog.showDialog(obj)
                } else {
                    let obj = {
                        id: item.empJobWantVO.id,
                        goodsId: item.empJobWantVO.id,
                        orderType: this.identity == 1 ? 1 : 2,    // 这个页面跳过去 写死
                        phone: data,
                        userName: item.empJobWantVO.userName,
                        avatar: item.empJobWantVO.avatar ? item.empJobWantVO.avatar : item.empJobWantVO.sex == '男' ? this.workImg : this.hrImg,
                        sex: item.empJobWantVO.sex,
                    }
                    this.$refs.scanCodePay.showDialog(obj)
                }

            })
        },
        getInit(arr) {
            this.loading = true
            if (this.$route.query.applyCustomerId) {
                $api.interviewAPI({ applyCustomerId: this.$route.query.applyCustomerId, companyWorkwantId: this.$route.query.companyWorkwantId }).then(res => {
                    let { code, msg, data } = res
                    this.commonFn(res, arr)
                }).catch(error => {
                    this.loading = false
                })
            } else {
                $api.getInterviewIdV1API({ id: this.$route.query.id }).then(res => {
                    let { code, msg, data } = res
                    this.commonFn(res, arr)
                }).catch(error => {
                    this.loading = false
                })
            }
        },
        // 支付
        payForExam() {
            payForExamAPI({ goodsId: this.detail.examId }).then(res1 => {
                 if (res1.code == 200) {
                     let { orderNo , price}  =  res1.data
                     this.$refs.scanCodePayTest.payMoney(orderNo,price)
                 }
             }).catch(error => {
                 // this.$message.error(error.msg)
             })
         },
        // 付钱之后 刷新列表
        paySuccessReflash() {
            this.getDictTypeAPI()
        },

        commonFn(res, arr) {
            let { code, msg, data } = res
            this.loading = false
            if (code == 200) {
                this.detail = res.data
                arr.map(item => {
                    if (this.identity == 1) {  // 工人
                        if (this.detail.companyWorkWantsVO) {
                            if (item.dictType == 'crm_work_post_type' && this.detail.companyWorkWantsVO.postType == item.dictValue) {  // 岗位类别
                                this.detail.companyWorkWantsVO.postType = item.dictLabel
                            }

                            if (item.dictType == 'sys_user_sex' && this.detail.companyWorkWantsVO.sexRequire == item.dictValue) {  // 性别
                                this.detail.companyWorkWantsVO.sexRequire = item.dictLabel
                            }
                            if (item.dictType == 'crm_pro_unit' && this.detail.companyWorkWantsVO.proUnit == item.dictValue) {  // 薪资单位
                                this.detail.companyWorkWantsVO.proUnit = item.dictLabel
                            }
                        }

                    } else {  // hr
                        if (this.detail.empJobWantVO) {
                            if (item.dictType == 'crm_pro_unit' && this.detail.empJobWantVO.workSalarysUnit == item.dictValue) {  // 薪资单位
                                this.detail.empJobWantVO.workSalarysUnit = item.dictLabel
                            }
                            if (item.dictType == 'crm_sex_require' && this.detail.empJobWantVO.sex == item.dictValue) {  // 性别
                                this.detail.empJobWantVO.sex = item.dictLabel
                            }
                        }
                    }
                    // empJobWantVO
                })
                this.$nextTick(() => {
                    this.$refs.videoCard.initInfo(this.detail, this.identity)
                    this.$refs.resultCard.initInfo(this.detail, this.identity)
                    this.$refs.topicList.initInfo(this.detail)
                    this.isExistBuy = {}
                    if (data && data.evalResult && data.evalResult.isExistBuy == false) {   // 没有付费 不能看测评结果
                        this.loading = false
                        this.isExistBuy = data.evalResult
                    }
                    if (this.identity == 1) {  // 工人 调整一下样式
                        let buy = document.querySelector('.buy-wrap')
                        console.log('buy', buy)
                        buy.style.height = '77%'
                        buy.style.top = '23%'
                    } 
                })
            } else {
                // this.$message.error(msg)
            }
        },
        getDictTypeAPI() {
            // 培训报名 性别 岗位等字典 rm_train_user_type
            $api.dictTypeAPI({ dictType: 'sys_user_sex,crm_work_post_type,crm_pro_unit,crm_sex_require' }).then(res => {
                let { code, msg, data } = res
                if (code == 200) {
                    this.getInit(data)
                } else {
                    this.$message.error(msg)
                }
            })
        },

    }
}
</script>

<style lang="scss" scoped>
.download {
    margin-left: 5px;
    cursor: pointer;
}

.interviewRecordDetail-container {
    min-height: 822px;
    // margin: 21px 0 21px 70px;
    display: flex;
    // justify-content: center;
    flex-direction: column;
    position: relative;
    .buy-wrap {
        width: 100%;
        height: 52%;
        background: rgba(0,0,0,0.16);
        backdrop-filter: blur(24px);
        position: absolute;
        top: 48.5%;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 22;
        border-radius: 8px;
        .content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 100%;
            .label {
                margin-top: 30px;
                font-size: 16px;
                padding: 0 20px;
                height: 50px;
                line-height: 50px;
                background: #2980F2;
                border-radius: 8px;
                font-weight: 500;
                color: #FFFFFF;
                cursor: pointer;
            }
            img {
                width: 70px;
                height: 80px;
            }
        }
    }
    .title {
        margin-bottom: 40px;
        font-weight: 500;
        font-size: 22px;
        color: #333333;
    }

    .right-title {
        font-weight: 500;
        font-size: 22px;
        color: #333333;
    }
}

.cursorPointer {
    cursor: pointer;
}

.pl9 {
    padding-left: 9px
}

.ml14 {
    margin-left: 14px;
}

.mb27 {
    margin-bottom: 27px;
}

::v-deep.personal-container .right-wrap {
    padding-bottom: 151px !important;
}

.overflowline2 {
    text-overflow: -o-ellipisis-lastline;
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
    word-break: break-all;
}

.el-avatar {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 100%;
        height: 100%;
    }
}

</style>
