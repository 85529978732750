<template>
  <div class="header">
    <div class="header-container">
      <div class="header-title">
        <img
            src="../../../assets/header/header-title.png"
        />
      </div>
      <div class="tab-container">
        <template v-for="tab in tabs">
            <div
                v-if="tab.identity == identity || tab.identity == ''"
                :key="tab.status"
                @click="selectTab(tab.status)"
                class="tab"
                :class="{
                    selected: currentTab === tab.status,
                    noselected: currentTab !== tab.status,
                }">
                <span>{{ tab.name }}</span>
                <img
                    class="slider-image"
                    src="../../../assets/header/header-tab-bottom.png"
                    v-show="currentTab === tab.status"
                />
            </div>
        </template>
        <img
            src="../../../assets/header/header-line.png"
        />
      </div>
      <div class="user-info" @mouseover="showPersonCenter" @click="showLogin">
        <img
            v-if="userInfo.nickName"
            :src="userInfo.avatar ? userInfo.avatar : defaultAvatar"
            alt="John"
        />
        <el-tooltip
            effect="dark"
            :content="userInfo.nickName"
            placement="top"
            :disabled="!displayNameInfo.isTruncated"
        >
          <div class="user-name">
            <!--          {{ userInfo.nickName ? userInfo.nickName : "登录" }}-->
            {{ displayNameInfo.displayName || "登录" }}
          </div>
        </el-tooltip>
      </div>
    </div>

    <!-- 点击头像的弹窗 -->
    <avatarDialog ref="avatarDialog" class="avatarDialog"/>
    <cantTo ref="cantTo"/>
    <change-rule ref="changeRule"/>
  </div>
</template>
<script>
import {getCompanyInfoAPI} from "@/api/personalCenter";
import avatarDialog from "./avatarDialog.vue";
import {mapState} from "vuex";
import user from "../../../store/modules/user";
import store from "@/store";
import {updateUserIdentityApi, userInfoApi} from "@/api/user";

export default {
  name: "topBar",
  components: {
    avatarDialog,
  },
  computed: {
    user() {
      return user;
    },
    displayNameInfo() {
      const nickName = this.userInfo.nickName;
      if (nickName && nickName.length > 4) {
        return {
          displayName: `${nickName.substring(0, 4)}...`,
          isTruncated: true
        };
      }
      return {
        displayName: nickName,
        isTruncated: false
      };
    }
  },
  data() {
    return {
      defaultAvatar: require("@/assets/icon/home/avatar.png"),
      userName: 0,
      currentTab: "1",
      tabs: [
        {
          name: "总览",
          status: "1",
          identity: '',
        },
        {
          name: "AI招聘",
          status: "6",
          identity: '2',
        },
        {
          name: "AI找工作",
          status: "5",
          identity: '1',
        },
        {
          name: "智能面试",
          status: "4",
          identity: '',
        },
        {
          name: "工作伙伴AI助手",
          status: "2",
          identity: '',
        },
        {
          name: "权益守护AI助手",
          status: "3",
          identity: '2',
        },
        {
          name: "职业测评",
          status: "7",
          identity: '1',
        },
        ],
        userInfo: {},
        identity: '2',   // 默认企业hr

    };
  },
    created() {
    if (this.$store.state.user.token) {
      this.getUserInfo();
    }
    this.$store.watch(
        () => this.$store.state.user.token,
        (newValue, oldValue) => {
          if (newValue) {
            this.getUserInfo();
          } else {
            this.userInfo = {};
          }
        }
    );
    this.$store.watch(
        () => this.$store.state.user.loginTimes,
        (newValue, oldValue) => {
          if (newValue) {
            this.$showLoginModal(true);
          }
        }
    );
    this.$store.watch(
        () => this.$store.state.user.userInfo,
        (newValue, oldValue) => {
          if (newValue) {
            this.userInfo.avatar = newValue.avatar;
            this.userInfo.nickName = newValue.nickName;
          } else {
            // this.userInfo = {}
          }
        }
    );
    this.identity = this.$store.state.user.identity
    this.$store.watch(
        () => this.$store.state.user.identity,
        (newValue, oldValue) => {
            if (newValue) {
                this.identity = newValue
            // this.$showLoginModal(true);
          }
        }
    );
  },
  methods: {
    getUserInfo() {
      userInfoApi()
          .then((res) => {
            this.userInfo = res.data;
            // console.log('用户信息',this.userInfo.avatar)
            if (!this.userInfo.avatar){
              this.userInfo.avatar = this.defaultAvatar;
            }
            // this.userInfo.avatar = this.userInfo.avatar | this.defaultAvatar;
            store.commit("SET_USER", this.userInfo);
          })
          .catch(() => {
          });
    },
    showLogin() {
      if (!this.$store.state.user.token) {
        this.$showLoginModal(true);
      }
    },
    showLawTip() {
      this.$refs.cantTo.showDialog();
      return;
    },
    /**
     * 打开切换角色提示的窗口
     * @param success 切换成功回调
     */
    showChangeRole(success) {
      this.$refs.changeRule.showDialog(success);
    },
    selectTab(status) {
      if (!this.$store.state.user.token && (status !== "1")) {
        this.$showLoginModal(true);
        return;
      }

      if (status == "1" || status == "2" || status == "4") {
        this.navigateTabs(status);
      }
      let companyStatus;

      if (status === "3" && store.state.user.identity === '1') {
        updateUserIdentityApi({identity: '2'}).then((res) => {
          if (res.success) {
            store.commit('SET_IDENTITY', '2');
            return userInfoApi({});
          } else {
            this.$message({
              message: "切换失败",
              type: "error",
              duration: 1500,
            });
            return Promise.reject(new Error('更新用户身份失败'));
          }
        }).then((userInfoRes) => {
          this.userInfo = userInfoRes.data;
          if (this.userInfo.legalAsstPerFlag === 1) {
            this.navigateTabs(status);
          } else if (this.userInfo.legalAsstPerFlag === 0 && this.userInfo.jobRoles == '0') {
            this.showLawTip();
            return;
          } else {
            return getCompanyInfoAPI();
          }
        }).then((companyInfoRes) => {
          const companyStatus = companyInfoRes.data.rows != null && companyInfoRes.data.rows[0] ? companyInfoRes.data.rows[0].auditStatus === "1" : false;
          if (!companyStatus) {
            this.showLawTip();
            return;
          } else {
            this.navigateTabs(status);
          }
        }).catch((error) => {
        });
      }
      // 点击法律助手判断
      if (status === "3" && store.state.user.identity === '2') {
        userInfoApi({}).then((res) => {
          this.userInfo = res.data;
          if (this.userInfo) {
            if (this.userInfo.legalAsstPerFlag === 1) {
              //1为所有通过,直接跳转
              this.navigateTabs(status);
            } else if (this.userInfo.legalAsstPerFlag === 0 && this.userInfo.jobRoles == '0') {
              //是hr,个人信息没填,先跳到个人信息去
              this.showLawTip();
              return;
            } else {
              //是hr,个人信息也填了法律,则判断企业信息是否审核通过
              getCompanyInfoAPI({}).then((companyRes) => {
                const companyStatus = companyRes.data.rows != null && companyRes.data.rows[0] ? companyRes.data.rows[0].auditStatus === "1" : false;
                if (!companyStatus) {
                  this.showLawTip();
                  return;
                } else {
                  this.navigateTabs(status);
                }
              }).catch((companyError) => {
              });
            }
          } else {
            return;
          }
        }).catch(() => {

        })
      }
      if (status === "7") {
            this.currentTab = 7;
            this.$router.push({path: '/personalCenter/index', query: { name: '职业测评',serviceType: 7  }})
        }

      let identity = '';
      identity = store.state.user.identity;
//点击AI找活
      if (status == "5") {
        //如果是老板，那就要打开切换弹窗
        const success = () => this.navigateTabs(status);
        identity === '2' ? this.showChangeRole(success) : success();
      }

//点击AI招工
      if (status == "6") {
        //如果是工人，那就要打开切换弹窗
        const success = () => this.navigateTabs(status);
        identity === '1' ? this.showChangeRole(success) : success();
      }

    },
    navigateTabs(status) {
      this.currentTab = status;
      let path;
      let serviceType;
      switch (status) {
        case "1":
          path = "/home/index";
          break;
        case "2":
          path = "/hrQuestion";
          serviceType = "3";
          break;
        case "3":
          path = "/hrStatute";
          serviceType = "4";
          break;
        case "4":
          path = "/aiInterview";
          serviceType = "5";
          break;
        case "5":
          path = "/aiFindWork";
          serviceType = "2";
          break;
        case "6":
          path = "/aiFindWorker";
          serviceType = "1";
              break;
        default:
          path = "/";
          break;
      }

      const fullPath = {
        path: path,
      };

      // 只有当 serviceType 存在时才添加到查询参数
      if (serviceType) {
        fullPath.query = {serviceType};
      }

      // 检查目标路由是否与当前路由相同，且当 serviceType 存在时是否相等
      if (
          this.$route.path !== path ||
          (serviceType && this.$route.query.serviceType !== serviceType)
      ) {
        this.$router.push(fullPath).catch((err) => {
          if (err.name !== "NavigationDuplicated") {
            throw err;
          }
        });
      }
    }
    ,
    setCurrentTabBasedOnRoute(path) {
      switch (path) {
        case "/home/index":
          this.currentTab = "1";
          break;
        case "/hrQuestion/index":
          this.currentTab = "2";
          break;
        case "/hrStatute/index":
          this.currentTab = "3";
          break;
        case "/aiInterview/index":
          this.currentTab = "4";
          break;
        case "/aiFindWork/index":
          this.currentTab = "5";
          break;
        case "/aiFindWorker/index":
          this.currentTab = "6";
           break;
        case "/personalCenter/index":
            this.currentTab = "7";
            break;
        default:
          this.currentTab = "1"; // 默认值
          break;
      }
    }
    ,
// 个人头像
    showPersonCenter() {
      if (store.state.user.token) {
        this.$refs.avatarDialog.showDialog();
      }
    }
    ,
    mouseClick() {
    }
    ,
  },
  watch: {
    "$route.path"(newPath) {
      this.setCurrentTabBasedOnRoute(newPath);
    }
    ,
  }
  ,
  mounted() {
    this.setCurrentTabBasedOnRoute(this.$route.path);
  }
  ,
}
;
</script>
<style lang="scss" scoped>
.header {
  width: 100%;
  height: 69px;
  position: relative;

  .header-container {
    background-color: #ffffff;
    position: relative;
    display: flex;
    justify-content: space-between;
    height: 69px;
    width: 100%;

    .header-title {
      // flex: 1;
      display: flex;
      align-items: center;

      & img {
        width: 700px;
        height: 100%;
      }
    }

    .tab-container {
      width: 60%;
      margin: 10px 20px 10px 0;
      padding-top: 10px;
      flex: 3;
      display: flex;
      align-items: center;
      flex-direction: row;
      position: relative;

      & img {
        position: absolute;
        bottom: -12px;
        right: 10%;
        width: 100%;
        height: 16px;
      }

      .tab {
        margin: 0 10px;
        position: relative;
        cursor: pointer;
        transition: background-color 0.3s, color 0.3s;

        span {
          padding: 0 34px;
          white-space: nowrap;
          font-family: PingFangSC, PingFang SC;
          font-size: 18px;
          line-height: 25px;
        }

        .slider-image {
          width: 99px;
          height: 18px;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: 80%;
        }
      }

      .selected {
        font-weight: 500;
        color: #1a67e2;
      }

      .noselected {
        color: #333333;
      }
    }

    .user-info {
      position: absolute;
      top: 20%;
      right: 4%;
      display: flex;
      flex-direction: row;
      cursor: pointer;

      & img {
        border: 2px solid #c8d8e9;
        margin-right: 3px;
        width: 38px;
        height: 38px;
        border-radius: 50%;
      }

      .user-name {
        margin-top: 8px;
      }
    }
  }
}
</style>
