<template>
<!-- <v-scale-screen width="1920" bodyOverflowHidden="false" fullScreen="false" style="background: linear-gradient(180deg, #DDEDFE 0%, #ECF1FA 100%)"> -->
    <div class="app-container">
      <topBar></topBar>
      <div class="content">
        <transition name="scale"  mode="out-in">
          <router-view :key="key"></router-view>
        </transition>
      </div>
      <bottomBar v-if="!$route.meta.noBottomBar"></bottomBar>
    </div>
  <!-- </v-scale-screen> -->
</template>

<script>
// import VScaleScreen from '../../node_modules/v-scale-screen'
import { getRSAKey,setRSAKey } from '@/utils/auth'
import bottomBar from "./components/bottomBar/index.vue";
import topBar from "./components/topBar/index.vue";
import axios from 'axios'
export default {
  name: "app",
  computed: {
    key() {
      return this.$route.path;
    }
  },
  components: {
    topBar,
    bottomBar,
    // VScaleScreen
  },
  data() {
    return {
    }
  },
  mounted() {
    this.RSAInit()
  },
  methods:{
    RSAInit() {
      axios.get('/api/auth/getPublicKey').then(res=> {
        if(res.data.code === 200){
          setRSAKey(res.data.data)
          this.$store.commit('SET_RSAKEY',res.data.data)
        }else {
          this.$message.warning('密钥获取失败')
        }
      }).catch(()=> {

      })
    },
  },
}
</script>
<style lang="scss" scoped>
@import "@/utils/style/index.css";
.scale-enter-active,
.scale-leave-active {
  transition: all 0.5s ease;
}


.scale-enter-from,
.scale-leave-to {
  opacity: 0;
  transform: scale(0.9);
}
.app-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: linear-gradient(180deg, #DDEDFE 0%, #ECF1FA 100%)
}
.content{
  display: flex;
  justify-content: center;
  /*  头部69 底部50 */
  min-height: calc(100% - 69px);
}


/* .h119 {   height: calc(100% - 119px);   }
.h69 {   height: calc(100% - 69px);   } */
</style>
