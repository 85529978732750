import Layout from "@/layout";

const aiInterviewRouter = {
  path: '/aiInterview',
  component: Layout,
  redirect: '/aiInterview/index',
  name: 'aiInterview',
  children: [
    {
      path: 'index',
      component: () => import('@/views/aiInterview/index'),
      name: 'aiInterviewIndex',
      meta: {
        // noBottomBar : true,
      }
    }
  ]
}

export default aiInterviewRouter
