<template>
  <div class="video-body">
    <video
        class="video-playData"
        ref="backgroundVideo"
        :src="videoSrc"
        loop
        muted
        playsinline
    ></video>
    <div class="video-muteOrPlay" @click="changePlay()">
      <img :src="play ? playVoice : muteVoice" alt="">
      <span>{{ play ? '外放' : '静音' }}</span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'videoBackground',
  props: {
    audioUrl: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      audioClass: null,
      play: true,
      muted: false,
      videoSrc: require("@/assets/video/video.mp4"),
      muteVoice: require('@/assets/icon/aiFindWork/muteVoice.png'),
      playVoice: require('@/assets/icon/aiFindWork/playVoice.png')
    }
  },
  methods: {
    changePlay() {
      this.play = !this.play
      this.muted = !this.muted
      if(this.audioClass){
        this.audioClass.muted = this.muted
      }
    },
    playStart(audioUrl){
      this.clearPlay()
      if(audioUrl){
        this.audioClass = new Audio(audioUrl);
        this.audioClass.muted = this.muted;
        this.audioClass.play();
        this.$refs.backgroundVideo.play()
        this.audioClass.addEventListener('ended', () => {
          this.$refs.backgroundVideo.pause()
          this.$refs.backgroundVideo.currentTime = 0;
        })
      }
    },
    clearPlay(){
      if(this.audioClass){
        this.audioClass.pause()
        this.audioClass = null
      }
      this.$refs.backgroundVideo.pause()
      this.$refs.backgroundVideo.currentTime = 0;
    }
  },
  beforeDestroy() {
    //销毁音视频
    if(this.audioClass){
      this.audioClass.pause()
      this.audioClass = null
    }else {
      this.audioClass = null
    }
    this.$refs.backgroundVideo.pause()
    this.$refs.backgroundVideo.currentTime = 0;
  }
}
</script>
<style lang="scss" scoped>
.video-body{
  width: 163px;
  height: 211px;
  position: absolute;
  right: 0;
  overflow: hidden;
  border-radius: 6px;
  transform: translateX(calc(100% + 52px)) translateY(calc( -50% + 50px));
  .video-playData{
    width: 163px;
    //transform: translateY(-30px);
  }
  .video-muteOrPlay{
    width: 80px;
    height: 40px;
    background: rgba(0,0,0,0.5);
    border-radius: 6px;
    position: absolute;
    left: 0;
    bottom: 0;
    transform: translateX(50%);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    img{
      width: 23px;
      height: 17px;
    }
    span{
      font-weight: 400;
      font-size: 18px;
      color: #FFFFFF;
    }
  }
}
</style>
