import Layout from "@/layout";

const aiFindWorkerRouter = {
  path: '/aiFindWorker',
  component: Layout,
  redirect: '/aiFindWorker/index',
  name: 'aiFindWorker',
  children: [
    {
      path: 'index',
      component: () => import('@/views/aiFindWorker/index'),
      name: 'aiFindWorkerIndex',
    }
  ]
}

export default aiFindWorkerRouter
