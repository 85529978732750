<template>
    <!--更换手机号弹窗-->
    <el-dialog
        class="dialog-phone"
        :visible.sync="dialogFormVisible"
        :center="true"
        :append-to-body="true">
        <div class="tip-form" v-if="userData">
            <img :src="userData.avatar" alt="">
            <div class="name singe-line ">{{userData.userName}}</div>
            <div class="phone">
                <!-- <i class="el-icon-phone"></i> -->
                <img src="@/assets/intelligentInterview/call.png" alt="">
                <span>{{userData.phone}}</span>
            </div>
            <el-button type="primary" @click="copyFn">复制手机号</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name: 'tipDialog',
    components: {
    },
    props: {
        // isShowTip: {
        //     type: Boolean,
        //     default: false
        // }
    },
    data() {
        return {
            dialogFormVisible: false,
            // phone: ''
            userData: {}
        }

    },
    mounted() {
    },
    methods: {
        showDialog(data) {
            this.userData = data
            this.dialogFormVisible = true
        },
        closeFn() {
            this.dialogFormVisible = false
        },
        async copyFn(){
            // navigator.clipboard.writeText(this.userData.phone).then(() => {
            //     this.$message.success('复制成功')
            // })
            // 解决测试环境复制兼容问题
            if (navigator.clipboard && window.isSecureContext) {
                // navigator clipboard 向剪贴板写文本
                this.$message({
                    message: "复制成功",
                    type: "success",
                    duration: 1500,
                    customClass: 'zZindex'
                });
                return navigator.clipboard.writeText(this.userData.phone)
            } else {
                // 创建text area
                const textArea = document.createElement('textarea')
                textArea.value = this.userData.phone
                // 使text area不在viewport，同时设置不可见
                document.body.appendChild(textArea)
                textArea.focus()
                textArea.select()
                this.$message({
                    message: "复制成功",
                    type: "success",
                    duration: 1500,
                    customClass: 'zZindex'
                });
                return new Promise((res, rej) => {
                    // 执行复制命令并移除文本框
                    document.execCommand('copy') ? res() : rej()
                    textArea.remove()
                })
            }
        },
    }
}
</script>

<style lang="scss" scoped>
// 更换手机弹窗
.dialog-phone {
    ::v-deep .el-dialog {
        width: 532px;
        border-radius: 8px;
        // 居中
        margin:0 !important;
        position:absolute;
        top:50%;
        left:50%;
        transform:translate(-50%,-50%);
        .el-dialog__header {
            padding: 0 !important;
        }

        .el-dialog__body {
            padding: 0 !important;
        }
        .el-dialog__headerbtn .el-dialog__close {
            font-size: 24px;
        }

       .tip-form {
            height: 544px;
            height: 348px;
            background: url('@/assets/intelligentInterview/dialogBg.png');
            border-radius: 8px;

            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            img {
                width: 72px;
                height: 72px;
                border-radius: 50%;
            }
            .name {
                max-width: 80%;
                margin-top: 14px;
                font-weight: 500;
                font-size: 20px;
                color: #333333;
            }
            .phone {
                margin-top: 10px;
                width: 190px;
                height: 46px;
                line-height: 46px;
                text-align: center;
                background: rgba(160, 182, 236,.1);
                border-radius: 4px;

                font-weight: 400;
                font-size: 20px;
                color: #555555;


                .el-icon-phone {
                    color: #236CE0;
                    font-size: 26px;
                    // margin-right: 6px;
                }
                img {
                    margin: 7px 6px 0 0;
                    width: 14px;
                    height: 17px;
                }
            }
            .el-button {
                margin-top: 38px;
                width: 238px;
                height: 50px;
                background: #236CE0;
                border-radius: 5px;

                font-weight: 500;
                font-size: 18px;
                color: #FFFFFF;
            }
       }
    }
}
.singe-line {
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
    white-space: nowrap;
  }
</style>
