<template>
    <div class="myTestResult-container" >
        <div class="back" @click="GoBack">
            <i class="el-icon-back"></i>
            <span>返回</span>
        </div>
        <!-- 没有付费 -->
        <div class="buy-wrap" v-if="isExistBuy.isExistBuy == false">
            <div class="content">
                <img src="@/assets/myTest/lock.png" alt="">
                <div class="label" @click="payForExam">花费{{isExistBuy.examPrice}}即可查看所有测评结果</div>
            </div>
        </div>

        <resultTable v-if="isQueryList == 1" ref="resultTable" @reflash="getInitData"/>
        <div v-else v-loading="loading">
            <!-- MBTI测试结果报告 -->
            <MBTITest v-if="examType == 1" ref="MBTITest"></MBTITest>
            <!-- 你的9型人格分析 -->
            <type9PersonalityTest v-if="examType == 2 " ref="type9PersonalityTest"></type9PersonalityTest>
            <!-- 卡特尔16人格测试结果报告 -->
            <cartContent ref="carteTest" v-if="examType == 3 "/>
            <scanCodePayTest ref="scanCodePayTest" @paySuccessReflash="paySuccessReflash"/>
        </div>
    </div>
</template>

<script>
import cartContent from '@/components/myTest/carte-content.vue'
import type9PersonalityTest from '@/components/myTest/type9PersonalityTest.vue'
import MBTITest from '@/components/myTest/MBTITest.vue'
import resultTable from './components/resultTable.vue'
import * as $api from '@/api/myTest'
export default {
    name: 'resultIndex',
    components: {
        cartContent,
        type9PersonalityTest,
        MBTITest,
        resultTable
    },
    data() {
        return {
            list: [
                {},
            ],
            examType: null, 
            isQueryList: 0,
            isExistBuy: {},
            loading: false,
        };
    },
    created() { 
        this.examType = Number(this.$route.query.examType)
        this.isQueryList = localStorage.getItem('isQueryList') || 0    // 1 显示列表 用来判断显示表格 还是详情页面

        if (this.isQueryList !=1) {
            this.getInitData()
        }
    },
    destroyed() { 
        localStorage.removeItem('isQueryList')
    },
    mounted() {
        let ele = document.querySelector('.carte-content-container');
        let cloumn = document.querySelector('.grid-cloumn');
        if (ele) {
            ele.style.padding = 0 
            ele.style.border = 0   
            ele.style.marginTop = '20px'   
            if (cloumn) {
                cloumn.style.borderWidth = 0
            }
        }
    },
    methods: {
        // 支付
        payForExam() {
            $api.payForExamAPI({ goodsId: this.$route.query.id }).then(res1 => {
                if (res1.code == 200) {
                    let { orderNo , price}  =  res1.data
                    this.$refs.scanCodePayTest.payMoney(orderNo,price)
                }
            }).catch(error => {
                // this.$message.error(error.msg)
            })
        },
        paySuccessReflash() {
            this.getInitData()
         },
        getInitData() { 
            this.loading = true
            this.isQueryList = localStorage.getItem('isQueryList')
            let frontData = {
                id: this.$route.query.id,
                examType: this.$route.query.examType,
            }
            this.$forceUpdate()
            $api.checkEvalRatingsAPI(frontData).then(res => {
                let { code, msg, data } = res
                this.loading = false
                if (code == 200) {
                    console.log(data)
                    this.isExistBuy = {}
                    if (data.isExistBuy == false) {   // 没有付费 不能看测评结果
                        this.isExistBuy = data
                        return 
                    } 
                    switch (this.examType) {
                        case 1:
                            var obj = data.result
                            if (obj.characterTrait && obj.characterTrait.indexOf('&') != -1) {
                                    var current = obj.characterTrait.split('&')
                                    obj.characterTrait = current[1]
                                    obj.characterTraitTagList = current[0].indexOf('、') != -1 ? current[0].split('、') : []
                            }
                                this.$nextTick(() => {
                                    this.$refs.MBTITest.initInfo(data.result)
                                })
                            break
                        case 2:
                                var willInterestWork = data.result.willInterestWork || {}
                                if (willInterestWork) {
                                    data.result.willInterestWork = willInterestWork.indexOf('/') != -1 ? willInterestWork.split('/') : [willInterestWork]
                            }
                            this.$nextTick(() => {
                                this.$refs.type9PersonalityTest.initInfo(data.result)          
                            })
                            break
                        case 3:
                                // 需要处理一下 树格式 再传给 组件
                                var arr = data.result   // switch 使用let eslint 校验不通过
                                arr.map(item => {
                                    if (item.characterTrait) {
                                        let currentData = item.characterTrait.indexOf('&') != -1 ? item.characterTrait.split('&') : ['暂无','暂无']
                                        item.lowScoreDesc  = currentData[1]
                                        item.highScoreDesc = currentData[0]
                                    } 
                                })
                                this.$nextTick(() => {
                                    this.$refs.carteTest.initInfo(data.result) 
                                })
                            break
                    }
                }
            }).catch(error => {
                this.loading = false
            })
        },
        GoBack() {
            if (this.identity == 2) {   // hr
                this.$router.push({ path: '/personalCenter/indexHr', query: { name: '职业测评', } });
            } else {  // 工人
                this.$router.push({ path: '/personalCenter/index', query: { name: '职业测评', } });
            }
        },
    }
}
</script>
<style lang='scss' scoped>
::v-deep.myTestResult-container {
    min-height: 822px;
    position: relative;
    font-family: PingFangSC, PingFang SC;
    .carte-content-container {
        margin-top: 20px!important;
    }
    .back {
        font-weight: 500;
        // font-weight: bold;
        font-size: 22px;
        color: #333333;
        line-height: 30px;
        cursor: pointer;
        // margin-bottom: 37px;

        .el-icon-back {
            font-size: 20px;
            font-weight: bold;
            margin-right: 9px;
        }
    }

    .progress {
        display: flex;
        align-items: center;
        margin-bottom: 40px;
        margin-top: 10px;

        .el-progress {
            flex: 1;
            margin: 0 18px;
        }
    }
    .buy-wrap {
        width: 106%;
        height: 84%;
        background: rgba(0,0,0,0.16);
        backdrop-filter: blur(24px);

        position: absolute;
        top: 20%;
        left: -3%;
        right: 0;
        bottom: 0;
        z-index: 22;
        border-radius: 8px;
        .content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 100%;
            .label {
                margin-top: 30px;
                font-size: 16px;
                padding: 0 20px;
                height: 50px;
                line-height: 50px;
                background: #2980F2;
                border-radius: 8px;
                font-weight: 500;
                color: #FFFFFF;
                cursor: pointer;
            }
            img {
                width: 70px;
                height: 80px;
            }
        }
    }

}
</style>