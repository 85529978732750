<template>
  <div
      class="draggable-box"
      :class="{ dragging: isDragging }"
      :style="{transition: isDragging ? 'none' : 'transform 0.3s'}"
      @mousedown="startDrag"
      @mouseup="endDrag"
      @mousemove="doDrag"
  >
    <!-- 盒子内容 -->
    <slot>
      <div class="camera_box-body">
        <video id="videoCamera" class="canvas" :width="videoWidth" :height="videoHeight" autoPlay></video>
        <canvas id="canvasCamera" class="canvas" :style="{display:'none'}" :width="videoWidth" :height="videoHeight"></canvas>
<!--        <div class="camera_box-tip">-->
<!--          <span>{{ nickName ? nickName : '游客' }}</span>-->
<!--        </div>-->
      </div>
    </slot>
  </div>
</template>

<script>
import '@/components/userCamera/index'
import store from "@/store";
export default {
  name: 'DraggableBox',
  props: {
    dragBoxArea: {
      type: Object,
      default: () => ({
        left: 0,
        top: 0,
        right: 0,
        bottom: 0
      })
    }
  },
  //计算属性
  computed: {
    nickName() {
      return store.state.user.nickName;
    }
  },
  data() {
    return {
      videoWidth: '100%',
      videoHeight: '100%',
      os: false,
      mediaRecorder: null,
      thisContext: null,
      isDragging: false,
      startX: 0,
      startY: 0,
      dragBoxLeft: 0,
      dragBoxTop: 0,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.getDragArea();
    })
    this.openCamera()
  },
  methods: {
    openCamera(){
      this.$nextTick(() => {
        const _this = this;
        this.os = false;//切换成关闭摄像头
        this.thisCancas = document.getElementById('canvasCamera');
        this.thisContext = this.thisCancas.getContext('2d');
        this.thisVideo = document.getElementById('videoCamera');
        // 旧版本浏览器可能根本不支持mediaDevices，我们首先设置一个空对象
        if (navigator.mediaDevices === undefined) {
          navigator.mediaDevices= {}
        }
        // 一些浏览器实现了部分mediaDevices，我们不能只分配一个对象
        // 使用getUserMedia，因为它会覆盖现有的属性。
        // 这里，如果缺少getUserMedia属性，就添加它。
        if (navigator.mediaDevices.getUserMedia === undefined) {
          navigator.mediaDevices.getUserMedia = function (constraints) {
            // 首先获取现存的getUserMedia(如果存在)
            let getUserMedia = navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.getUserMedia;
            // 有些浏览器不支持，会返回错误信息
            // 保持接口一致
            if (!getUserMedia) {
              return Promise.reject(new Error('getUserMedia is not implemented in this browser'))
            }
            // 否则，使用Promise将调用包装到旧的navigator.getUserMedia
            return new Promise(function (resolve, reject) {
              getUserMedia.call(navigator, constraints, resolve, reject)
            })
          }
        }
        const constraints = {
          audio: true,
          video: {width: _this.videoWidth, height: _this.videoHeight, transform: 'scaleX(-1)'}
        };

        navigator.mediaDevices.getUserMedia(constraints).then(function (stream) {
          //stream 是媒体流，包含了视频和音频。
          //stream.getAudioTracks() 获取音频轨道
          //stream.getVideoTracks() 获取视频轨道
          //新建一个MediaRecorder对象，并且传入一个视频轨道和多个音频轨道

          // 旧的浏览器可能没有srcObject
          if ('srcObject' in _this.thisVideo) {
            console.log('===new===')
            _this.thisVideo.srcObject = stream
          } else {
            console.log('===old===')
            // 避免在新的浏览器中使用它，因为它正在被弃用。
            _this.thisVideo.src = window.URL.createObjectURL(stream)
          }
          _this.thisVideo.onloadedmetadata = function (e) {
            _this.thisVideo.play()
          }
          console.log("thisVideo=========>",_this.thisVideo.srcObject)
        }).catch(err => {
          this.$message({
            message: '没有开启摄像头权限或浏览器版本不兼容',
            type: 'warning'
          });
        });
      });
    },

    getDragArea() {
    },
    startDrag(e) {
      this.isDragging = true;
      this.startX = e.clientX - this.dragBoxLeft;
      this.startY = e.clientY - this.dragBoxTop;
    },
    doDrag(e) {
      if (this.isDragging) {
        const x = e.clientX - this.startX;
        const y = e.clientY - this.startY;
        if(x>0 && x<(this.dragBoxArea.right-240) && y>0 && y<(this.dragBoxArea.bottom-200)){
          this.dragBoxLeft = x;
          this.dragBoxTop = y;
          this.$el.style.left = x + 'px';
          this.$el.style.top = y + 'px';
        }
      }
    },
    endDrag() {
      this.isDragging = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.draggable-box {
  position: absolute;
  right: 0;
  z-index: 1000;
  cursor: move;

  .camera_box-body{
    width: 100%;
    height: 100%;
    border-radius: 4px;
    background-color: #FFFFFF;
    .canvas{
      border-radius: 4px;
    }
    .camera_box-tip{
      height: 45px;
      border-radius: 4px;
      background-color: #FFFFFF;
      display: flex;
      align-items: center;
      span{
        font-family: PingFangSC, PingFang SC;
        font-size: 18px;
        font-weight: bold;
        color: #333333;
        margin-left: 11px;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
}

.dragging {
  cursor: grabbing;
}
</style>
