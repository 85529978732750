<template>
    <!-- 历史面试 工人 最右侧卡片 -->
    <div>
        <div class="list-item" v-for="(item, index) in workList" :key="index" @click="GoDetail(item)">
            <div class="top-content">
                <div class="title flexBetweenCenter">
                    <div>
                        <!-- <div class="hoverALL">{{ formatWorkTypeName(item.name) }}</div> -->
                        <!-- <span class="tip">{{item.name}}</span> -->
                        <el-tooltip class="item" effect="dark" placement="top-end" >
                            <div slot="content" >{{ item.name }}</div>
                            <div class="overflowline1">{{  item.name ? item.name : '暂无' }}</div>
                        </el-tooltip>
                    </div>
                    <el-tooltip class="item" effect="dark" placement="top-end" >
                        <div slot="content">
                            <span class="color7e2" v-if="item.list.minProSpend == -1">面议</span>
                            <span class="color7e2" v-else>{{ item.money }}{{ item.unit  }}</span>
                        </div>
                        <div class="overflowline1" style="text-align: right;">
                            <span class="color7e2" v-if="item.list.minProSpend == -1">面议</span>
                            <span class="color7e2" v-else>{{ item.money }}{{ item.unit  }}</span>
                        </div>
                    </el-tooltip>
                    <!-- <span class="color7e2" v-if="item.list.minProSpend == -1">面议</span>
                    <span class="color7e2" v-else>{{ item.money }}{{ item.unit  }}</span> -->
                </div>
                <div class="tag">
                    <span
                        class="tag-item"
                        v-for="(itemTag, indexTag) in item.tagList"
                        :key="indexTag">{{ itemTag }}</span>
                </div>
              <el-tooltip class="item" effect="dark" placement="top" >
                <div slot="content" >{{ item.companyName }}</div>
                <div class="company flexAlignCenter" v-if="!!item.companyName">
                    <img src="@/assets/common/company.png" alt="" style="width: 14px; height: 14px;"/>
                    <span class="pl10">{{ formatCompanyName(item.companyName) }}</span>
                </div>
              </el-tooltip>
              <div class="adress flexAlignCenter" v-if="!!item.adress">
                    <i class="el-icon-location-outline"></i>
                    <!-- <span class="pl6">{{ item.km }}km</span>-->
                    <span class="pl10">{{ item.adress || '无' }}</span>
                </div>

                <!-- 插槽  自定义 -->
                <div class="startReview-btn"  v-if="!item.btn">
                    <span @click.stop="startReview(item)" v-if="!item.interviewFlag">开始面试</span>
                    <span @click.stop="reviewPlay(item)" v-else>面试回放</span>
                </div>
                <!-- <div class="startReview-btn" @click="isRecommend(item)" v-if="isRecommend">去报名面试</div> -->
            </div>
        </div>
         <!-- 简历填写提示 -->
        <resumeTipDialog ref="resumeTipDialog"/>
    </div>
</template>


<script>
import store from '@/store'
import * as $api from "@/api/personalCenter";
export default {
    components: {
    },
    props: {
        workList: {
            type: Array,
            default: ()=>{}
        },
        needReviewBtn: {   // 开始面试按钮
            type: Boolean,
            default: false
        },
        // isRecommend: {
        //     type: Boolean,
        //     default: false
        // }
    },
    data() {
        return {
            identity: '',
        };
    },
    created() {
        this.identity = store.state.user.identity
        store.watch(() => store.state.user.identity, (newValue, oldValue) => {
            this.identity = newValue
        })
    },
    methods: {
        GoDetail(item) {
            this.getInit(item)
        },
        // 面试回放
        async reviewPlay(item) {
            let obj = {}
            let detail = await $api.getCompanyWorkWantInfoAPI({ id: item.id, obj })
            if (detail.code == 200 && detail.data && detail.data.delFlag == 2) {
                return this.$message.warning('岗位已取消')
            }

            let userInfo = this.$store.state.user.userInfo
            if(this.identity == 2) {   // hr
                this.$router.push(
                    { path: '/personalCenter/indexHr',
                     query: {
                        name: '历史面试' ,
                        name2: '面试回放' ,
                        isDetail: 1,
                        id: item.id ,
                        companyWorkwantId:  item.id,
                        applyCustomerId: userInfo.userId,
                        examType: item.examType
                    } });
            } else {  // 工人
                this.$router.push(
                    { path: '/personalCenter/index',
                     query: {
                        name: '历史面试' ,
                        name2: '面试回放' ,
                        isDetail: 1,
                        id: item.id ,
                        companyWorkwantId:  item.id,
                        applyCustomerId: userInfo.userId,
                        examType: item.examType
                } });
            }
        },
        // 开始面试
        async startReview(item) {
            let obj = {}
            let detail = await $api.getCompanyWorkWantInfoAPI({ id: item.id, obj })
            console.log('detail', detail)
            if (detail.code == 200 && detail.data && detail.data.delFlag == 2) {
                return this.$message.warning('岗位不存在')
            }
            $api.getUserJobWantAPI().then(res => {   // 判断是否有简历  有才能开始面试
                if (res.code == 200 && JSON.stringify(res.data) == "{}") { // 没有简历
                    this.$refs.resumeTipDialog.showDialog()
                } else {
                    this.$router.push({ path:'/personalCenter/jobDetailsAI', query:{
                        name: '智能面试',
                        id: item.list.id,
                        companyWorkWantId: item.list.id
                    }} )
                }
            }).catch(error => {
                // this.$refs.resumeTipDialog.showDialog()
            })
        },
        getInit(item) {
            let obj = {}
            $api.getCompanyWorkWantInfoAPI({ id: item.id, obj }).then(res => {
                let { code, msg, data } = res
                if (code == 200) {
                    this.$router.push({ path:'/personalCenter/jobDetails', query:{
                        name: '智能面试',
                        id: item.list.id,
                        companyWorkWantId: item.list.id
                    }} )
                }
            }).catch(error => {
                this.$message.warning(error.msg)
            })
        },
        // isRecommend(item) {
        //     this.$router.push({ path:'/personalCenter/jobDetails', query:{
        //         name: '智能面试',
        //         id: item.list.id,
        //         companyWorkWantId: item.list.id
        //     }} )
        // },

      formatCompanyName(text) {
          if(text.length > 12) {
            return text.substring(0, 12) + '...'
          }
          return text
      },

        formatWorkTypeName(text) {
          if (text && typeof text === "string") {
            let t = text;
            const index = t.indexOf(",");
            if (index !== -1) {
              t = t.substring(0, index) + "...";
            }
            // 只显示不超过三个职业
            const splits = t.split("/");
            if(splits.length > 1) {
              t = splits.slice(0, 1).join("/");
              t += "...";
            }
            return t;
          }
          return "不限"; // Return a default string if text is undefined or not a string
        },
    },
};
</script>

<style lang="scss" scoped>

.tip {
  visibility: hidden;
  max-width: 300px;
  width: max-content;
  background-color: #555;
  color: #fff;
  font-size: small;
  border-radius: 6px;
  padding: 5px 5px;
  position: absolute;
  bottom: 90%;
  left: 20%;
  margin-left: -60px; /* 宽度的一半 */
  opacity: 0;
  transition: opacity 0.3s;
  word-break: break-all;
}

/* 当鼠标悬停在包含提示的按钮上时显示提示 */
.workName {
  &:hover {
    .tip {
      visibility: visible;
      opacity: 0.8;
    }
  }
}

.list-item {
    background: #ffffff;
    border-radius: 8px;

    font-size: 14px;
    font-weight: 400;
    width: 384px;

    cursor: pointer;

    .top-content {
        padding: 15px 22px 20px 18px;
        position: relative;

        .title {
            font-size: 18px;
            font-weight: 500;
            color: #555;
            line-height: 28px;

            .color7e2 {
                color: #1a67e2;
                // letter-spacing: 2px;
            }
        }

        .tag {
            margin-top: 11px;
            display: flex;

            .tag-item {
                margin-right: 10px;
                background: #52c4a4;
                border-radius: 2px;
                color: #fff;
                // padding: 4px 9px;
                height: 26px;
                line-height: 26px;
                padding: 0 10px;

                &:first-child {
                    background: #0b2467;
                }
            }
        }

        .adress {
            margin-top: 6px;
            color: #888;

            img {
                width: 9px;
                height: 12px;
            }
        }
        .company {
            width: max-content;
            margin-top: 14px;
            color: #888;
            display: flex;
            // align-items: end;
            img {
                width: 9px;
                height: 12px;
            }
        }

        .startReview-btn {
            width: 94px;
            height: 36px;
            line-height: 36px;
            text-align: center;
            border-radius: 7px;
            border: 1px solid #3076FF;
            cursor: pointer;
            color: #3076FF;
            font-size: 14px;
            position: absolute;
            right: 22px;
            bottom: 20px;
        }
    }

    .bottom-content {
        height: 58px;
        line-height: 58px;
        padding: 0 22px;

        border-top: 1px solid rgba(151, 151, 151, 0.2);
        color: #888;
        font-size: 16px;

        .btn {
            padding: 0 11px;
            height: 34px;
            line-height: 34px;
            border-radius: 7px;
            border: 1px solid #9d9d9d;
            cursor: pointer;
            color: #333;
            font-size: 14px;
        }
    }

    * {
        box-sizing: border-box;
    }

    .pl6 {
        padding-left: 6px;
    }

    .pl10 {
      padding-left: 10px;
    }

    .pt10 {
        padding-top: 10px;
    }
}
.flexBaseline {
    display: flex;
    align-items: baseline;
}

.flexBetweenCenter {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.flexAlignCenter {
    display: flex;
    align-items: center;
}
.overflowline1 {
    overflow: hidden; /*自动隐藏文字*/
    text-overflow: ellipsis;/*文字隐藏后添加省略号*/
    white-space: nowrap;/*强制不换行*/
    width: 160px;/*不允许出现半汉字截断*/
}
.el-icon-location-outline {
    padding-top: 1px;
}
</style>
