<template>
    <div>
        <!--扫码支付 弹窗-->
        <el-dialog 
            @close="closeDialogFormVisible" 
            class="dialog-phone" 
            :visible.sync="dialogFormVisible" 
            :center="true"
            :close-on-click-modal="false">
            <div class="tip-form">
                <div class="name">获取手机号 微信扫一扫付款</div>
                <div class="money">{{ payInfo.price || 0 }}元 </div>
                <div class="scanBox">
                    <div ref="qrcode" id="qrcode" class="code-img"></div>
                    <div class="tip">
                        <img class="scan-img" src="@/assets/intelligentInterview/scan.png" alt="">
                        <span>打开手机微信扫一扫立即付款</span>
                    </div>
                </div>
                <!-- <div>
                    支付倒计时: 
                    <count-down :endTime="now + 1000 * 60 * 5"/>
                </div> -->
                <div class="btnBox">
                    <el-button 
                        v-no-more-click
                        class="cancle" 
                        type="info" 
                        @click="closeDialogFormVisible">取消支付</el-button>
                </div>
            </div>

        </el-dialog>
        <callDialog ref="callDialog"/>
    </div>
</template>

<script>
import * as $api from '@/api/personalCenter'
// import QRCode from 'qrcodejs2';
import QRCode from 'qrcodejs2'
import axios from 'axios'
import { encrypt, decrypt, generateSignature } from '@/utils/cryptoUtil/cryptoUtils';
// import countDown from './countDown.vue'
import callDialog from './callDialog' 
export default {
    name: 'scanCodePay',
    components: {
        // countDown
        callDialog,
    },
    data() {
        return {
            dialogFormVisible: false,
            payInfo: {},
            originUrl: '',
            timer: '',
            now: Date.now(),
            checkPay: false,
        }

    },
    mounted() {
    },
    destroyed() {
        clearInterval(this.timer)
    },
    methods: {
        closeDialogFormVisible() {
            this.dialogFormVisible = false
            clearInterval(this.timer)
        },
        getCoed(orderNo) {
            this.$nextTick(() => {
                this.$refs.qrcode.innerHTML = ""
                let qrcode = new QRCode('qrcode', {
                    width: 146,  // 宽
                    height: 150, // 高
                    text: this.originUrl, // 二维码内容,也可以直接放接口获取的数据
                    // render: 'canvas'     // 设置渲染方式（有两种方式 table和canvas，默认是canvas）
                    // background: '#f0f'   // 自定义颜色默认黑白
                    // foreground: '#ff0'
                })
                this.timer = setInterval(() => {
                    this.checkPaySuccess();
                }, 4000);
            })
        },
        showDialog(item, isCharacter) {
            // 1719613068199239681
            // item.goodsId
            if (isCharacter) {
                this.$emit('payOrder', item)
            } else {
                $api.payForPhoneNumberAPI({ goodsId: item.goodsId, orderType: item.orderType }).then(res1 => {
                    if (res1.code == 200) {
                        $api.payNativeOrderAPI({ orderNo: res1.orderNo }).then(res => {
                            this.payInfo = {
                                codeUrl: res.data.codeUrl,
                                id: item.id,
                                price: res1.price,
                                orderNo: this.orderNo,
                                avatar: item.avatar,
                                sex: item.sex,
                                userName: item.userName,
                                isBoss: item.isBoss || null
                            }
                            this.originUrl = res.data.codeUrl
                            this.dialogFormVisible = true
                            this.getCoed(this.orderNo)   // 二维码
                            // status 40 支付成功
                        })
                    }
                }).catch(error => {
                    this.$message.error(error.msg)
                })

            }

        },
        // 10-待支付，20-支付中，30-已关闭，40-已支付，50-支付失败
        checkPaySuccess() {
            if (this.orderNo) {
                $api.payOrderAPI({orderNo: this.orderNo}).then(res => {
                    if (res.code === 200) {
                        if (res.data && (typeof res.data) !== 'string' && res.data.status == 40) {
                            this.dialogFormVisible = false
                            this.$message({
                                message: "支付成功",
                                type: "success",
                                center: true,
                                customClass: 'zZindex'
                            });
                            this.checkPay = true
                            clearInterval(this.timer)
                            if (this.payInfo.isBoss) {  // 去联系老板
                                this.getPhoneBoss(this.payInfo)
                            } else {
                                this.getPhone(this.payInfo)
                            }
                        }
                    } else {
                        clearInterval(this.timer)
                    }
                }).catch(error => {
                    clearInterval(this.timer)
                })
            }
        },
        cancleFn() {
            $api.webOrderCancelAPI({ orderNo: this.payInfo.orderNo }).then(res => {
                let { data, success } = res
                clearInterval(this.timer)
                if (data && success) {
                    // 订单类型，1:招工电话,2:工人电话,3:抢单,4:充值,5:购买会员,6:充值钱包,7:ai大模型使用招工,8:ai大模型使用工匠,9:悬赏招工,10:企业会员权益
                    // this.$message.success('取消支付')
                    this.$message({
                        message: "取消支付",
                        type: "success",
                        center: true,
                        customClass: 'zZindex'
                    });
                    this.dialogFormVisible = false
                } else {
                    this.dialogFormVisible = false
                }
            }).catch(error => {
                clearInterval(this.timer)
                this.dialogFormVisible = false
            })
        },
        GoToPay() {
            this.checkPaySuccess()
        },
        getPhone(item) {
            // this.$refs.scanCodePay.showDialog()
            // 获取电话号码
            $api.getPhoneAPI({ id: this.payInfo.id }).then(res => {
                let { data, success } = res
                if (data && success) {
                    // 订单类型，1:招工电话,2:工人电话,3:抢单,4:充值,5:购买会员,6:充值钱包,7:ai大模型使用招工,8:ai大模型使用工匠,9:悬赏招工,10:企业会员权益
                    let obj = {
                        phone: data.phone || data,
                        userName: item.userName,
                        avatar: item.avatar
                    }
                    this.$refs.callDialog.showDialog(obj)
                }

            })
        },
        getPhoneBoss(item) {
            // this.$refs.scanCodePay.showDialog()
            // 获取电话号码
            $api.getPhoneBossAPI({ id: this.payInfo.id }).then(res => {
                let { data, success } = res
                if (data && success) {
                    // 订单类型，1:招工电话,2:工人电话,3:抢单,4:充值,5:购买会员,6:充值钱包,7:ai大模型使用招工,8:ai大模型使用工匠,9:悬赏招工,10:企业会员权益
                    let obj = {
                        phone: data.phone || data,
                        userName: item.userName,
                        avatar: item.avatar
                    }
                    this.$refs.callDialog.showDialog(obj)
                }

            })
        },
    }
}
</script>

<style lang="scss" scoped>
// 更换手机弹窗
.dialog-phone {
    ::v-deep .el-dialog {
        width: 532px;
        border-radius: 8px;
        // 居中
        margin: 0 !important;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        .el-dialog__header {
            padding: 0 !important;
        }

        .el-dialog__body {
            padding: 0 !important;
        }

        .el-dialog__headerbtn .el-dialog__close {
            font-size: 24px;
        }

        .tip-form {
            height: 544px;
            height: 455px;
            background: url('@/assets/intelligentInterview/dialogBg.png');
            border-radius: 8px;

            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;

            .name {
                margin-top: 33px;
                font-weight: 500;
                font-size: 20px;
                color: #333333;
            }

            .money {
                margin-top: 7px;
                font-weight: 600;
                font-size: 30px;
                line-height: 42px;
                color: #FE5F00;
            }

            // 二维码
            .scanBox {
                width: 175px;
                height: 224px;
                background: #FFFFFF;
                border: 1px solid #979797;

                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .code-img {
                    // width: 175px;
                    // height: 178px;
                    padding: 10px;
                }

                // 打开手机微信扫码提示
                .tip {
                    padding: 0 26px 12px 22px;
                    margin-top: 10px;
                    display: flex;
                    justify-content: center;

                    font-weight: 400;
                    font-size: 12px;
                    color: #333333;
                    line-height: 17px;

                    .scan-img {
                        width: 26px;
                        height: 24px;
                        margin-right: 17px;
                    }

                    span {
                        width: 84px;
                    }
                }

            }


            // 取消 已付款按钮
            .btnBox {
                margin-top: 24px;

                .el-button {
                    width: 168px;
                    height: 50px;
                    background: #236CE0;
                    border-radius: 5px;

                    font-weight: 500;
                    font-size: 18px;
                    color: #FFFFFF;
                }

                .cancle {
                    // margin-right: 30px;
                    border: 1px solid #9D9D9D;
                    color: #333333;
                    background: #fff;
                }
            }

        }
    }
}

#qrcode {
    width: 150px;
    height: 160px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
</style>
