import axios from 'axios'

//ai问答地址
const apiClient = axios.create({
    // baseURL: 'http://222.85.202.67:614'
});


export const req = async (method, url, data = null, config = {}) => {
    try {
        const response = await apiClient({
            method,
            url,
            data,
            ...config,
        });
        return response.data;
    } catch (error) {
        console.error('错误信息:', error.response || error.message || error);
        throw error;
    }
};

