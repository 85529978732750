<template>
    <div class="myTest-contaienr">
         <div class="card-content" v-for="(item, index) in tableData" :key="index">
             <!-- 是否存在测评（0-否，1-是） -->
                 <div class="test-status" v-if="item.isExistExam == 1">已测试</div>
                 <div class="title">
                     <img :src="item.icon" alt="">
                     <span>{{item.examTypeName}}</span>
                 </div>
                 <div class="flexBetween">
                     <ul class="info">
                         <li class="info-item" v-for="(itemRow, indexRow) in item.examDesc" :key="indexRow">{{ itemRow }}</li>
                     </ul>
                 <div class="btn-wrap">
                     <!--是否存在购买未使用记录（0-否，1-是）isExistBuyRecord; -->
                     <el-button class="btnGreen" @click="GOToResult(item)" v-if="item.isExistExam == 1">查看报告</el-button>
                     <!-- v-if="item.isExistExam == 0 && item.isExistBuyRecord == 1 " -->
                     <el-button class="btnGreen" @click="GOToTest(item)" >开始测试</el-button>
                     <!-- <el-button class="btnBule" @click="GOToTest(item)" v-if="item.isExistExam == 1 && item.isExistBuyRecord == 1 ">再测一次</el-button> -->
                     <!-- v-if="item.isExistExam != 0 && item.isExistBuyRecord !=0" -->
                     <!-- isExistExam 1 考试 0 没考过 -->
                     <!-- <el-button
                         @click="payForExam(item)"
                         class="btnOrange"
                         v-if="item.isExistExam == 0 || item.isExistBuyRecord !=1">{{item.examPrice}}元测试</el-button> -->
                 </div>
                 </div>
         </div>

         <scanCodePayTest ref="scanCodePayTest" @paySuccessReflash="paySuccessReflash"/>
    </div>
 </template>

 <script>
 import * as $api from '@/api/myTest'
 export default {
    name: 'myTestIndex',
    components: {},
    data () {
       return {
           tableData: [],
           goodsId: null,
       };
     },
     destroyed() {
         let ele = document.getElementsByClassName('right-content ')
         if (ele && ele.length > 0) {
             ele[0].style.backgroundColor = '#fff'
         }
     },
     mounted() {
         let ele = document.getElementsByClassName('right-content ')
         if (ele && ele.length > 0) {
             ele[0].style.backgroundColor = '#F2F3F6'
         }
         this.getInitData()
     },
     methods: {
         GOToResult(item) {
            localStorage.setItem('isQueryList', 1)   // 存储用来判断  进入result.vue  显示的是表格还是测试报告详情
            this.$router.push({
                path: '/personalCenter/index',
                query: {
                    name: '职业测评',
                    isDetail: 1,   // 在 index 或者indexHr 来判断显示的组件
                    id: item.id,
                    examType: item.examType
                }
            })
         },
         GOToTest(item) {
             this.$router.push({
                 path: '/personalCenter/index',
                 query: {
                     name: '职业测评',
                     isDetail: 2,
                     id: item.id,
                     examType: item.examType
                 }
             })
         },
         // 支付
         payForExam(item) {
             this.goodsId = item.id
             $api.payForExamAPI({ goodsId: this.goodsId }).then(res1 => {
                 if (res1.code == 200) {
                     let { orderNo , price}  =  res1.data
                     this.$refs.scanCodePayTest.payMoney(orderNo,price)
                 }
             }).catch(error => {
                 // this.$message.error(error.msg)
             })
         },
         paySuccessReflash() {
             this.getInitData()
          },
         getInitData() {
             $api.getEvalExamListAPI().then(res => {
                 let { code, msg, data } = res
                 if (code == 200) {
                     this.tableData = data.rows || []
                     if (this.tableData && this.tableData.length > 0) {
                         this.tableData.forEach(item => {
                             item.examDesc = item.examDesc.indexOf('/') != -1 ? item.examDesc.split('/') : [item.examDesc]
                             switch (item.examType) {
                                 case '1': // MBTI
                                     item.icon = require('@/assets/myTest/mbti.png')
                                     break;
                                 case '2':  // 9型
                                     item.icon = require('@/assets/myTest/nine.png')
                                     break;
                                 case '3':  // 卡特尔
                                     item.icon = require('@/assets/myTest/cart.png')
                                     break;
                             }
                         })
                     }
                 } else {
                     // this.$message.error(msg)
                 }
             }).catch(error=>{
                 // this.$message.error(error.msg)
             })
         },
    }
 }
 </script>
 <style lang='scss' scoped>
 ::v-deep.myTest-contaienr {
     min-height: 822px;
     .card-content {
         background: #FFFFFF;
         border-radius: 12px;
         padding: 40px 20px;
         display: flex;
         align-items: center;
         position: relative;

         margin-bottom: 24px;
         &:last-child {
             margin-bottom: 0;
         }
         .test-status {
             position: absolute;
             left: 0;
             top: 0;
             padding: 4px 14px;
             font-weight: 400;
             font-size: 14px;
             color: #1A67E2;
             background: #C7DDFF;
             border-radius: 12px 0px 12px 0px;
         }
         .title {
             font-weight: 600;
             font-size: 18px;
             color: #333333;
             display: flex;
             flex-direction: column;
             justify-content: center;
             align-items: center;
             img {
                 margin-bottom: 14px;
                 width: 50px;
                 height: 53px;
             }
         }
         .flexBetween {
             margin-left: 76px;
             flex: 1;
             display: flex;
             justify-content: space-between;
             align-items: center;
         }
         .btn-wrap {
             .btnGreen {
                 background: #52C4A4;
                 border-color: #52C4A4;
                 color: #fff;
             }
             .btnOrange {
                 color: #fff;
                 background: #FC4C00;
                 border-color: #FC4C00;
             }
         }
         .info {
             font-weight: 400;
             font-size: 16px;
             color: #333333;
             .info-item  {
                 // &::before {
                     margin-bottom: 10px;
                     &:last-child {
                         margin-bottom: 0;
                     }
                 //     content: '.'
                 // }
             }
         }
     }
 }
 </style>
