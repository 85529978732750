<template>
  <div class="hrChat">
    <div class="hrChat_box_title">
      <span>猜你想问</span>
      <!--      <div class="hrChat_box_title&#45;&#45;flushBtn"  @click="reloadBtnList()">
              <img :src="flushBtn" alt=""  class="hrChat_box_title&#45;&#45;flushBtn-img icon-arrow">
              <span>换一换</span>
            </div>-->
    </div>
    <div class="hrChat_box-divide"></div>
    <div class="hrChat_box_barrage">
      <div class="mask-left"></div>
      <div class="mask-right"></div>
      <div class="hrChat_box_barrage-content">
        <vue-danmaku
            ref="danmaku"
            v-model="barrageList"
            :loop="true"
            :channels="3"
            :isSuspend="true"
            :top="15"
            :right="12"
            :speeds="80"
            :randomChannel="true"
            use-slot
            class="danmuku">
          <template slot="dm" slot-scope="{danmu}">
            <div class="hrChat_box_barrage-item" @click="clickDanmu(danmu)">{{ danmu }}</div>
          </template>
        </vue-danmaku>
      </div>
    </div>
    <div class="hrChar_box-voice">
      <div class="mac-btn--back">
        <div class="mac-btn">
          <img :src="micPic" alt="">
        </div>
      </div>
      <div class="mac-btn--text" @click="linkToDetail">
        <span>输入您想问的问题</span>
        <span>点击开始智能问答</span>
      </div>
    </div>
  </div>
</template>
<script>
import vueDanmaku from 'vue-danmaku'
import {questionListApi} from "@/api/home";
import store from "@/store";
export default {
  name: 'hrChat',
  components: {
    vueDanmaku
  },
  data() {
    return {
      rotFlag: true,
      flushBtn: require('@/assets/hr-question/right-header-refresh.png'),
      micPic: require('@/assets/icon/home/mic.png'),
      questionForm: {
        pageSize: 50,
        pageNum: 1,
        serviceType: 3,
      },
      barrageList: [],
    }
  },
  mounted() {
    this.getDanmuList()
  },
  methods: {
    //跳转到问答助手
    linkToDetail() {
      if(store.state.user.token){
        this.$router.push({
          path: '/hrQuestion/index'
        })
      }else {
        this.$message({
          message: "请登录",
          type: "warning",
          duration: 1500,
          customClass: 'zZindex'
        });
        this.$showLoginModal(true);
      }
    },
    //获取弹幕列表
    getDanmuList() {
      questionListApi(this.questionForm).then(res => {
        this.barrageList = res.data.rows.map(item => {
          return item.dialogueContent
        })
        this.$refs.danmaku.play()
        if (this.questionForm.pageNum * this.questionForm.pageSize < res.data.total) {
          this.questionForm.pageNum++
        } else {
          this.questionForm.pageNum = 1
        }
      }).catch(() => {

      })
    },
    // 点击弹幕
    clickDanmu(danmu) {

      if(store.state.user.token){
        this.$router.push({
          path: '/hrQuestion/index',
          query: {
            dialogueContent: danmu
          }
        })
      }else {
        this.$message({
          message: "请登录",
          type: "warning",
          duration: 1500,
          customClass: 'zZindex'
        });
        this.$showLoginModal(true);
      }
    },
    reloadBtnList() {
      this.getDanmuList()
      let element = document.querySelector('.hrChat_box_title--flushBtn-img');
      if (this.rotFlag) {
        element.classList.add('icon-arrow-rotate')
      } else {
        element.classList.remove('icon-arrow-rotate')
      }
      this.rotFlag = !this.rotFlag
    }
  }
}
</script>
<style lang="scss" scoped>
.hrChat {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .hrChat_box_title {
    display: flex;
    height: 50px;
    justify-content: space-between;
    align-items: center;
    margin-left: 19px;
    margin-right: 17px;

    span {
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      font-size: 18px;
      color: #333333;
    }

    .hrChat_box_title--flushBtn {
      display: flex;
      align-items: center;
      cursor: pointer;

      .hrChat_box_title--flushBtn-img {

      }

      img {
        width: 15px;
        height: 12px;
      }

      span {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #333333;
      }
    }
  }

  .hrChat_box-divide {
    height: 2px;
    margin-left: 11px;
    margin-right: 10px;
    background: rgba(151, 151, 151, 0.24);
  }

  .hrChat_box_barrage {
    width: 100%;
    height: 206px;
    margin-top: 12px;
    position: relative;

    .mask-left {
      position: absolute;
      z-index: 99;
      width: 38px;
      height: 206px;
      left: 0;
      background: linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
    }

    .mask-right {
      position: absolute;
      width: 37px;
      height: 204px;
      z-index: 99;
      right: 0;
      background: linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
    }

    .hrChat_box_barrage-content {
      position: absolute;
      width: 100%;

      .danmuku {
        width: 100%;
        height: 206px;
      }
    }

    .hrChat_box_barrage-item {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 18px;
      color: #333333;
      padding: 15px 5px 15px 24px;
      background: #EBF4FD;
      border-radius: 27px;
      cursor: pointer;
    }
  }

  .hrChar_box-voice {
    background: url("@/assets/icon/home/voice-btn.png");
    background-size: 395px;
    height: 102px;
    width: 395px;
    margin-left: 53px;
    margin-top: 14px;
    cursor: pointer;
    display: flex;

    .mac-btn--back {
      margin-top: 8px;
      margin-left: 16px;
      width: 64px;
      height: 64px;
      background-color: #588fe7;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;

      .mac-btn {
        width: 58px;
        height: 58px;
        border-radius: 50%;
        background: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 29px;
          height: 34px;
        }
      }
    }

    .mac-btn--text {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: center;

      span:nth-child(1) {
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 22px;
        color: #FFFFFF;
        margin-top: 17px;
        text-shadow: 0px 2px 3px rgba(15, 58, 142, 0.5);
      }

      span:nth-child(2) {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: rgba(255, 255, 255, 0.76);
        letter-spacing: 2px;
      }
    }
  }
}

.icon-arrow {
  transition: all .3s ease-out;
}

.icon-arrow-rotate {
  transform: rotate(360deg);
}
</style>
