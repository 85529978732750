<template>
  <div class="myResume-container" ref="myResume">
    <div class="myResumeTitle">我的简历</div>
    <ResumeForm ref="resumeForm" class="resume_form" @goToResumeTemplateDialog="goToResumeTemplateDialog"></ResumeForm>
    <resume-download-dialog ref="resumeDownloadDialog"></resume-download-dialog>
  </div>
</template>

<script>
import resumeDownloadDialog from "@/views/personalCenter/components/aiFindWork/ResumeTemplateDownload.vue";
import ResumeForm from "@/views/personalCenter/components/aiFindWork/ResumeForm.vue";
import { getResumeApi } from "@/api/aiInterView";
import { throwOnResponseFailure } from "@/utils/request/error";

export default {
  name: "myResume",
  components: { ResumeForm, resumeDownloadDialog },
  data() {
    return {
      resumeData: {},
    };
  },
  created() {
    this.loadResumeData();
  },
  methods: {
    // 加载简历数据和初始化列表数据
    async loadResumeData() {
      try {
        const res = await getResumeApi();
        throwOnResponseFailure(res);
        if (!Object.hasOwn(res.data, "id")) {
          throw new Error("个人简历未生成!");
        }
        this.resumeData = res.data;
        this.$refs.resumeForm.initForm(res.data);
      } catch (e) {
        console.error("获取个人简历", e);
        this.$message.warning("个人简历未生成!");
        this.$refs.resumeForm.initForm({});
      }
    },
    // 打开简历模板页面
    async goToResumeTemplateDialog(data) {
      data && (await this.$refs.resumeDownloadDialog.showDialog(data));
    },
  },
};
</script>

<style scoped lang="scss">
// 个人简历
::v-deep .myResume-container {
  z-index: 99999 !important;
  width: 1220px;
  max-height: 832px;
  overflow-y: auto;
  border-radius: 8px;
  // 居中
  margin: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  // 隐藏滑动条
  -ms-overflow-style: none; /* IE 10+ */
  scrollbar-width: none; /* Firefox */

  .myResumeTitle {
    margin-bottom: 34px;
  }
}

// 隐藏滑动条
.myResume-container::-webkit-scrollbar {
  width: 0; /* Chrome, Safari, and Opera */
}

.resume_form {
  margin-top: 29px;
}
</style>
