<template>
    <!--更换手机号弹窗-->
    <el-dialog class="dialog-phone" :visible.sync="dialogFormVisible" :center="true">
        <div class="tip-form">
            <img src="@/assets/intelligentInterview/tip.png" alt="">
            <div class="name">您还未填写简历</div>
            <div class="tip">智能面试、AI找活需填写个人简历，才可正常使用</div>
            <div class="btnBox">
                <el-button class="cancle" type="info" @click="cancleFn">取消</el-button>
                <el-button type="primary" @click="GoToEdit">去填写</el-button>
            </div>
        </div>
    </el-dialog>
</template>

<script>
import store from '@/store'
export default {
    name: 'tipDialog',
    components: {
    },
    props: {
        // isShowTip: {
        //     type: Boolean,
        //     default: false
        // }
    },
    data() {
        return {
            dialogFormVisible: false,
            identity: '',
        }
        
    },
    created() {
        this.identity = store.state.user.identity
        store.watch(() => store.state.user.identity, (newValue, oldValue) => {
            this.identity = newValue
        })
    },
    mounted() {
    },
    methods: {
        showDialog() {
            // console.log('弹窗')
            this.dialogFormVisible = true
        },
        cancleFn() {
            this.dialogFormVisible = false
        },
        GoToEdit() {
            if(this.identity == 2) {   // hr
                this.$router.push({ path: '/personalCenter/indexHr', query: { name: '我的简历', } });
            } else {  // 工人
                this.$router.push({ path: '/personalCenter/index', query: { name: '我的简历', } });
            }
        },
    }
}
</script>

<style lang="scss" scoped>
// 更换手机弹窗
.dialog-phone {
    ::v-deep .el-dialog {
        width: 532px;
        border-radius: 8px;
        // 居中
        margin:0 !important;
        position:absolute;
        top:50%;
        left:50%;
        transform:translate(-50%,-50%);
        .el-dialog__header {
            padding: 0 !important;
        }

        .el-dialog__body {
            padding: 0 !important;
        }
        .el-dialog__headerbtn .el-dialog__close {
            font-size: 24px;
        }

       .tip-form {
            height: 544px;
            height: 348px;
            background: url('@/assets/intelligentInterview/dialogBg.png');
            border-radius: 8px;

            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            img {
                width: 50px;
                height: 67px;
            }
            .name {
                margin-top: 18px;
                font-weight: 500;
                font-size: 20px;
                color: #333333;
            }
            .tip {
                    margin-top: 18px;
                    font-weight: 400;
                    font-size: 16px;
                    color: #555555;
            }

            .btnBox {
                margin-top: 52px;
                .el-button {
                    width: 168px;
                    height: 50px;
                    background: #236CE0;
                    border-radius: 5px;

                    font-weight: 500;
                    font-size: 18px;
                    color: #FFFFFF;
                }
                .cancle {
                    margin-right: 30px;
                    border: 1px solid #9D9D9D;
                    color: #333333;
                    background: #fff;
                }
            }
           
       }
    }
}
</style>