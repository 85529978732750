<template>
    <div class="video-card-wrap">
        <div class="left-con">
            <div class="back" @click="GoBack">
                <i class="el-icon-back"></i>
                <span>返回</span>
            </div>
            <div class="top-title flexBetweenCenter" v-if="detail">
                <div class="flexBetweenCenter" style="margin-bottom: 8px;">
                    <!-- <el-tooltip effect="dark" placement="top-end">
                        <div slot="content">
                            <span v-if="detail.workTypeName">【 {{ detail.workTypeName }} 】</span>
                            <span> {{ detail.userName }}</span>
                        </div>
                        <div class="name singeLine" style="max-width: 600px;">
                            <span>【 {{ detail.workTypeName }} 】</span>
                            <span> {{ detail.userName }}</span>
                        </div>
                    </el-tooltip> -->
                    <span> {{ detail.userName }}</span>
                    <span class="tag" v-if="detail.empJobWantVO"> {{ detail.empJobWantVO.workTypesName }}</span>
                </div>
                <div style="margin-bottom: 8px;">
                    <span 
                        class="tag download"
                        style="margin-right: 10px;"
                        v-if="detail.list && detail.list.length > 0 && detail.list[0].videoUrl"
                        @click="downloadFile(detail.list[0].videoUrl)">点击下载面试视频</span>
                    <span class="color88 w144" >{{ detail.createTime }}</span>
                </div>
            </div>
            <div class="videoBox" v-if="detail.list && detail.list.length">
                <video
                    ref="videoPlayer"
                    controls
                    controlist="nodownload"
                    height="100%"
                    preload="auto"
                    :src="detail.list[0].videoUrl"></video>
                <img
                    @click="palyVideo"
                    class="play-img"
                    v-if="playImgShow"
                    ref="playImg"
                    src="@/assets/intelligentInterview/play.png" alt="">
            </div>
        </div>

        <!--右侧 hr个人简介-->
        <hrCard ref="hrCard" style="margin-left: 16px;" />
        <!-- 工人岗位展示 -->
        <div class="workCard-wrap" v-if="identity == 1">
            <workCard v-if="detail.companyWorkWantsVO && detail.companyWorkWantsVO.id" :workList="detail.companyWorkWantsVO" :detail="detail"/>
           <!-- 有测评的分 企业和个人的"面试者总体表现" 显示位置不同 企业的显示在下面 个人的显示在右边   没有测评则都显示在下面 -->
            <div class="score-card" :style="detail.companyWorkWantsVO && detail.companyWorkWantsVO.id ? 'margin-top : 14px' : ''" v-if="detail.interviewResult">
                <div class="title">面试者总体表现</div>
                <div class="score">{{ detail.interviewAppraise }}分</div>
                <!-- <div class="info">{{ detail.interviewResult }}</div> -->
                <div class="info">
                  <div class="interviewResult" v-for="(item,index) in detail.interviewResult" :key="index">{{ item}} {{item ? '。' : ''}} </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import workCard from '@/views/personalCenter/components/historyInterview/workCard'
import hrCard from '@/views/personalCenter/interviewRecord/components/hrCard'
export default {
    components: {
        workCard,
        hrCard,
    },
    data() {
        return {
            examType: null,
            detail: {},
            identity: null,
            playImgShow: false,
        };
    },
    mounted() {
        this.examType = this.$route.query.examType
    },
    methods: {
        initInfo(detail, identity) {
            if (detail.interviewResult && typeof detail.interviewResult === 'string') {  // 面试评分
              detail.interviewResult = detail.interviewResult.split('。')
            }
            this.detail = detail
            this.identity = identity
            this.$refs.hrCard.initInfo(this.detail, this.identity)
            this.$nextTick(() => {
                let ele = document.querySelector('.workCard-wrap')
                if (ele) {
                    ele.style.height = document.querySelector('.left-con').offsetHeight + 'px'
                }
            })
        },
        GoBack() {
            if (this.identity == 2) {   // hr
                let name2 = this.$route.query.name2
                let companyWorkwantId = this.$route.query.companyWorkwantId || null
                name2 ? this.$router.push({ path: '/personalCenter/indexHr', query: { name: name2, isDetail: 1, companyWorkwantId: companyWorkwantId} }) :  this.$router.push({ path: '/personalCenter/indexHr', query: { name: '面试记录', } });
            } else {  // 工人
                this.$router.push({ path: '/personalCenter/index', query: { name: '面试记录', } });
            }
        },
        // 播放按钮
        palyVideo() {
            let video = this.$refs.videoPlayer;
            video.play()
            this.playImgShow = false
        },
        downloadFile(url) {
            window.open(url)
        },
    }
}
</script>
<style lang='scss' scoped>
* {
    box-sizing: border-box;
}
/* 滚动条整体部分 */  
::-webkit-scrollbar {  
    width: 12px; /* 对垂直滚动条有效 */  
    height: 12px; /* 对水平滚动条有效 */  
}  
  
/* 滚动条滑块 */  
::-webkit-scrollbar-thumb {  
    background-color: darkgrey;  
    border-radius: 10px; /* 使滚动条滑块边角圆滑 */  
    border: 2px solid transparent; /* 边框颜色，设置为透明 */  
    background-clip: content-box; /* 让边框内缩一定的距离 */  
}
::v-deep.video-card-wrap {
    display: flex;
    .workCard-wrap {
        width: 400px;
        margin-left: 16px;
        display: flex;
        flex-direction: column;
        .score-card {
            flex: 1;
            // 评分
            padding: 22px 40px 22px 22px;
            background: #FFFFFF;
            border-radius: 12px;
            // margin-top: 14px;
            overflow: auto;
            .title {
                font-weight: 500;
                font-size: 18px;
                color: #333333;

                display: flex;
                align-items: center;

                &:before {
                    content: '';
                    margin-right: 8px;
                    width: 3px;
                    height: 15px;
                    background: #3076FF;
                }
            }
            .score {
                margin-top: 12px;
                font-weight: 600;
                font-size: 55px;
                color: #1A67E2;
                line-height: 77px;
            }

            .info {
                margin-top: 17px;
                font-weight: 400;
                font-size: 16px;
                color: #333333;
                line-height: 22px;
            }
            .interviewResult {
              font-size: 18px;
              line-height: 22px;
              margin-bottom: 10px;
            }

        }
    }

    .left-con {
        // width: 850px;
        flex: 1;
        width: 0;
        padding: 30px 37px 37px 37px;
        background: #FFFFFF;
        border-radius: 12px;
        // margin-right: 41px;
        height: max-content;
        .top-title {
            flex-wrap: wrap;
            margin-bottom: 10px;
        }
        .name {
            margin-right: 13px;
        }

        .back {
            font-weight: 500;
            // font-weight: bold;
            font-size: 22px;
            color: #333333;
            line-height: 30px;
            cursor: pointer;
            margin-bottom: 37px;

            .el-icon-back {
                font-size: 20px;
                font-weight: bold;
                margin-right: 9px;
            }
        }

        .nameBox {
            flex: 1;
            margin-right: 40px;
            display: flex;
            align-items: center;
            // overflow: hidden;
        }
        .download {
            display: inline-block;
            padding: 3px 7px;
            margin-left: 5px;
            line-height: 20px;
            cursor: pointer;
        }
        .tag {
            padding: 3px 7px;
            // height: 26px;
            // line-height: 26px;
            background: #EBF4FD;
            border-radius: 2px;
            font-size: 14px;
            color: #285DAB;
            line-height: 20px;
            width: max-content;
        }

        .color88 {
            color: #888;
            padding-right: 17px;
        }

        .flex1 {
            flex: 1;
        }

        .w144 {
            width: 180px;
            font-weight: 400;
            font-size: 16px;
            color: #888888;
        }

        .videoBox {
            margin-top: 22px;
            position: relative;
            display: flex;
            justify-content: center;
            height: 500px;

            video {
                width: 100% !important;
                border-radius: 4px;
                width: fit-content;
            }

            .videoElement {
                object-fit: fill;
            }

            .play-img {
                width: 94px;
                height: 94px;

                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);

                cursor: pointer;
            }
        }

    }

}
</style>
